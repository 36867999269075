import SearchIcon from '@mui/icons-material/Search';
import { TextField, TextFieldProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDelayedSearch } from '~/hooks/useDelayedSearch';

type Props = TextFieldProps & {
  onSearch: (search: string) => void;
  hasSearchIcon?: boolean;
  defaultValue?: string;
};

export const Search = ({ onSearch, defaultValue, hasSearchIcon = true, ...rest }: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState(defaultValue || '');
  const searchTerm = useDelayedSearch(search);

  const onEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onSearch(search.trim());
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0 && searchTerm.length < 3) return;

    onSearch(searchTerm.trim());
  }, [searchTerm]);

  return (
    <TextField
      fullWidth
      hiddenLabel
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      variant="filled"
      InputProps={{
        endAdornment: hasSearchIcon ? <SearchIcon /> : undefined,
      }}
      placeholder={t('msg_label_search')}
      onKeyDown={onEnterPress}
      {...rest}
    />
  );
};
