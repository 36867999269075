import { Chip, styled } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const LabelChip = styled(Chip)({
  borderRadius: pxToRem(4),
  textTransform: 'uppercase',
  fontWeight: 700,
  cursor: 'inherit',
});
