export enum AmplitudeEvent {
  PageOpened = 'app opened',
  RegistrationPhoneSubmitted = 'registration: phone submitted',
  RegistrationOTPSubmitted = 'registration: OTP submitted',
  LoginCompleted = 'log-in completed',
  SignUpCompleted = 'sign-up completed',
  SegmentSelected = 'segment selected',
  PublicInvoiceOpened = 'public invoice opened',
  SalesLeadRegistered = 'sales lead registered',
  InvoiceListRefreshed = 'invoice list refreshed',
  InvoiceNewStarted = 'invoice new started',
  InvoiceApproved = 'invoice approved',
  InvoiceSent = 'invoice sent',
  InvoiceOpened = 'invoice opened',
  LoginRedirected = 'registration: login redirected',
  RegistrationRedirected = 'login: registration redirected',
  RegistrationIDSubmitted = 'registration: ID submitted',
  LoginIDSubmitted = 'login: ID submitted',
  IntroCompleted = 'intro completed',
  CustomerListRefreshed = 'customer list refreshed',
  CustomerNewStarted = 'customer new started',
  CustomerCreated = 'customer created',
  ServicesListRefreshed = 'services list refreshed',
  ServiceNewStarted = 'service new started',
  ServiceCreated = 'service created',
  ExpensesListRefreshed = 'expenses list refreshed',
  ExpenseNewStarted = 'expense new started',
  ExpenseCreated = 'expense created',
  LoginAuthenticated = 'login: authenticated',
  RegistrationStarted = 'registration: started',
  LoginStarted = 'login: started',
  UserDataDeletionRegistered = 'user data deletion registered',
  AgentChatOpened = 'agent chat opened',
  AgentChatSubmitted = 'agent chat submitted',
}
