import { useQuery } from '@tanstack/react-query';

import { Operation } from '~/types/invoice';

import { model } from '../';

export const linkableIncomesQueryKey = 'linkableIncomes';

export const useLinkableIncomes = (id?: string) => {
  const query = useQuery<Operation[]>({
    queryFn: () => model.getLinkableIncomes(id),
    queryKey: [linkableIncomesQueryKey],
    enabled: !!id,
  });
  return query;
};
