import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Paper, Typography, Button, Stack, IconButton } from '@mui/material';
import i18next from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useResponsive } from '~/hooks/useResponsive';
import { colors } from '~/theme/colors';
import { LanguageCodes } from '~/types/appConfig';
import { InvoiceStatus } from '~/types/invoice';
import { formatDateShort } from '~/utils/formatDate';
import { formatCurrency, formatCurrencyNumber } from '~/utils/formatNumber';

export interface StatusCardProps {
  status: InvoiceStatus;
  total: number;
  paymentDue: Date;
  onButtonClick: () => void;
  buttonTitle: string;
  tLanguage?: LanguageCodes;
  currency_symbol: string;
  onCloseClick?: () => void;
}

const colorMap: { [key in InvoiceStatus]: string } = {
  [InvoiceStatus.Paid]: colors.primary.main,
  [InvoiceStatus.Unpaid]: colors.other.statusUnpaid,
  [InvoiceStatus.Overdue]: colors.other.statusOverdue,
  [InvoiceStatus.Draft]: '',
  [InvoiceStatus.Proforma]: '',
};

const statusLabelMap: { [key in InvoiceStatus]: string } = {
  [InvoiceStatus.Paid]: 'msg_invoice_status_paid',
  [InvoiceStatus.Unpaid]: 'msg_invoice_status_unpaid',
  [InvoiceStatus.Overdue]: 'msg_invoice_status_overdue',
  [InvoiceStatus.Draft]: '',
  [InvoiceStatus.Proforma]: '',
};

const StatusCard: FC<StatusCardProps> = ({
  status,
  total,
  paymentDue,
  onButtonClick,
  buttonTitle,
  tLanguage,
  currency_symbol,
  onCloseClick,
}) => {
  const { t } = useTranslation();
  const isMobile = useResponsive('down', 'sm');

  const translate = tLanguage ? i18next.getFixedT(tLanguage) : t;
  return (
    <Grid data-testid="status-card-testid" item sx={{ width: { xs: '100%', sm: 'auto' } }}>
      <Paper
        data-testid="status-color-paper-testid"
        sx={{
          backgroundColor: colorMap[status],
          borderRadius: 4,
          width: { xs: '100%', sm: 320 },
          p: 4,
          ...(status === InvoiceStatus.Paid && { p: 0, pt: 4 }),
        }}
      >
        {isMobile && onCloseClick && (
          <Box
            sx={{
              position: 'absolute',
              top: 6,
              right: 6,
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: '#ECEDEF',
            }}
          >
            <IconButton onClick={onCloseClick}>
              <CloseIcon sx={{ width: 15, height: 15 }} />
            </IconButton>
          </Box>
        )}
        {status === InvoiceStatus.Paid && (
          <Grid container>
            <Grid
              justifyContent="center"
              alignItems="center"
              width="100%"
              sx={{ display: 'grid', rowGap: 1, pb: 3 }}
              item
            >
              <Box display="flex" justifyContent="center">
                <Box
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    display: 'flex',
                    height: '26px',
                    width: '26px',
                    borderRadius: '50%',
                    backgroundColor: colors.background.paper,
                    m: 0,
                  }}
                >
                  <CheckIcon sx={{ width: '16px', height: '16px', color: colors.primary.main }} />
                </Box>
              </Box>
              <Typography
                textAlign="center"
                variant="h6"
                color={colors.primary.contrastText}
                sx={{ fontWeight: 600 }}
              >
                {`${translate('msg_invoice_status_paid')}`}
              </Typography>
            </Grid>
            <Box
              data-testid="status-color-box-testid"
              display="flex"
              justifyContent="space-between"
              sx={{
                backgroundColor: '#36ADAD',
                p: 2,
                pl: 4,
                pr: 4,
                width: '100%',
                borderBottomLeftRadius: 16,
                borderBottomRightRadius: 16,
              }}
            >
              <Typography
                textAlign="center"
                variant="body1"
                color={colors.primary.contrastText}
                sx={{ fontWeight: 600 }}
              >
                {`${translate('msg_label_full_amount_field_label')}`}
              </Typography>
              <Typography
                textAlign="center"
                variant="body1"
                color={colors.primary.contrastText}
                sx={{ fontWeight: 600 }}
              >
                {formatCurrencyNumber(total)}
              </Typography>
            </Box>
          </Grid>
        )}
        {status !== InvoiceStatus.Paid && (
          <>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={8}>
                <Typography
                  color={
                    status === InvoiceStatus.Unpaid
                      ? colors.primary.text
                      : colors.primary.contrastText
                  }
                  variant="body2"
                >
                  {translate(statusLabelMap[status])}
                </Typography>
                <Typography
                  variant="h6"
                  color={
                    status === InvoiceStatus.Unpaid
                      ? colors.primary.text
                      : colors.primary.contrastText
                  }
                  sx={{ fontWeight: 600 }}
                >
                  {formatCurrency(total, true, currency_symbol)}
                </Typography>
              </Grid>
              <Grid item xs={4} textAlign="right" style={{ display: 'grid', gap: 2 }}>
                <Typography
                  variant="body2"
                  color={
                    status === InvoiceStatus.Unpaid
                      ? colors.primary.text
                      : colors.primary.contrastText
                  }
                >
                  {translate('msg_pdf_invoice_due_date')}
                </Typography>
                <Typography
                  variant="body2"
                  color={
                    status === InvoiceStatus.Unpaid
                      ? colors.primary.text
                      : colors.primary.contrastText
                  }
                  sx={{ fontWeight: 600 }}
                >
                  {formatDateShort(paymentDue)}
                </Typography>
              </Grid>
            </Grid>
            <Stack sx={{ pt: 1 }} alignItems="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                onClick={onButtonClick}
                style={{
                  color: colors.primary.main,
                  backgroundColor: colors.background.paper,
                  maxWidth: '260px',
                }}
              >
                {translate(buttonTitle)}
              </Button>
            </Stack>
          </>
        )}
      </Paper>
    </Grid>
  );
};
export default StatusCard;
