import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableCell, Checkbox, Typography } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { DeleteInvoiceDialog } from '~/components/Dialog/DeleteInvoiceDialog';
import { SendInvoiceDialog } from '~/components/Dialog/SendInvoiceDialog';
import { InvoiceStatusLabel } from '~/components/Label';
import { MenuItem, TableActionsMenu } from '~/components/Menu/TableActionsMenu';
import { ClickableTableRow } from '~/components/Table';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Invoices';
import { invoicesIndexQueryKey } from '~/models/Invoices/hooks';
import { ROUTES } from '~/router/Routes';
import { Color } from '~/types/color';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn } from '~/types/formik';
import { Invoice, InvoiceStatus, SendInvoiceFormFields } from '~/types/invoice';
import { queryClient } from '~/utils';
import { formatDateShort, getDateDifferenceInDays } from '~/utils/formatDate';
import { formatCurrency } from '~/utils/formatNumber';
import { getInvoiceStatus } from '~/utils/getInvoiceStatus';

export type Props = {
  row: Invoice;
  selected: boolean;
  onSelect: (id: string) => void;
};

export const TableRow = ({ row, selected, onSelect }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const { id, customer, customer_name, number, total, payment_due, issue_date, pdf } = row;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);
  const [showRevokeDialog, setShowRevokeDialog] = useState(false);

  const invoiceStatus = getInvoiceStatus(row);

  const onSend = async (
    values: SendInvoiceFormFields,
    setErrors: FormikSetErrorsFn<SendInvoiceFormFields>,
  ) => {
    try {
      if (invoiceStatus === InvoiceStatus.Draft) {
        await model.finalize(id);
      }

      await model.send(id, values);

      queryClient.invalidateQueries([invoicesIndexQueryKey]);

      setShowSendDialog(false);

      toast.success(t('msg_success_invoice_sent'));
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };

  const issueDateOrDaysOverdue = () => {
    if (invoiceStatus === InvoiceStatus.Overdue) {
      return (
        <Typography variant="body2" color="error">
          {getDateDifferenceInDays(payment_due)} {t('msg_label_overdue')}
        </Typography>
      );
    }

    return formatDateShort(issue_date);
  };

  const onClick = () =>
    navigate(
      generatePath(
        invoiceStatus === InvoiceStatus.Draft
          ? ROUTES.DASHBOARD_INVOICES_EDIT
          : ROUTES.DASHBOARD_INVOICES_PREVIEW,
        { id },
      ),
    );

  const navigateToEdit = () => {
    navigate(generatePath(ROUTES.DASHBOARD_INVOICES_EDIT, { id }));
  };

  const onEdit = () => {
    if (invoiceStatus === InvoiceStatus.Draft) {
      navigateToEdit();
    } else {
      setShowRevokeDialog(true);
    }
  };

  const menuItems: MenuItem[] = [
    {
      label: t('msg_btn_preview_invoice'),
      icon: <VisibilityIcon />,
      linkTo: generatePath(ROUTES.DASHBOARD_INVOICES_PREVIEW, { id }),
    },
    {
      label: t(
        invoiceStatus === InvoiceStatus.Draft
          ? 'msg_btn_edit_invoice'
          : 'msg_btn_revoke_and_edit_invoice',
      ),
      icon: <EditIcon />,
      onClick: onEdit,
    },
    {
      label: t('msg_btn_send_invoice'),
      icon: <SendIcon />,
      onClick: () => setShowSendDialog(true),
    },
    {
      label: t('msg_btn_delete'),
      icon: <DeleteIcon color="error" />,
      onClick: () => setShowDeleteDialog(true),
      color: Color.Error,
    },
  ];

  return (
    <>
      <ClickableTableRow hover>
        <TableCell padding="checkbox">
          <Checkbox color="primary" checked={selected} onChange={() => onSelect(id)} />
        </TableCell>
        <TableCell onClick={onClick}>{customer_name}</TableCell>
        <TableCell onClick={onClick}>{number}</TableCell>
        <TableCell onClick={onClick}>
          <InvoiceStatusLabel status={invoiceStatus} />
        </TableCell>
        <TableCell onClick={onClick}>{issueDateOrDaysOverdue()}</TableCell>
        <TableCell onClick={onClick} align="right">
          {formatCurrency(total, true, base_currency.symbol_native)}
        </TableCell>
        <TableCell padding="checkbox">
          <TableActionsMenu menuItems={menuItems} />
        </TableCell>
      </ClickableTableRow>

      <DeleteInvoiceDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onSuccess={() => selected && onSelect(id)}
      />

      <SendInvoiceDialog
        invoiceId={id}
        customerId={customer}
        open={showSendDialog}
        onSubmit={onSend}
        onClose={() => setShowSendDialog(false)}
        pdf={pdf}
      />

      <ConfirmationDialog
        open={showRevokeDialog}
        title={t('msg_revoke_invoice_title')}
        onClose={() => setShowRevokeDialog(false)}
        actions={[
          {
            label: t('msg_btn_revoke_and_edit_invoice'),
            onClick: () => navigateToEdit(),
            color: 'error',
          },
          {
            label: t('msg_btn_cancel'),
            onClick: () => setShowRevokeDialog(false),
          },
        ]}
      />

      <DeleteInvoiceDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
