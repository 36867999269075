import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { GoBackButton } from '~/components/Button/GoBackButton';
import { DeleteExpenseDialog } from '~/components/Dialog/DeleteExpenseDialog';
import { useNavigateBack } from '~/hooks/useNavigateBack';
import { ROUTES } from '~/router/Routes';

type Props = {
  onGoBack?: () => void;
};

export const Header = ({ onGoBack }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigateBack = useNavigateBack();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onDeleteSuccess = () => navigateBack(ROUTES.DASHBOARD_EXPENSES);

  return (
    <>
      <Box sx={{ mb: 2.5 }}>
        <GoBackButton onClick={onGoBack} />
      </Box>

      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        justifyContent={{ lg: 'space-between', xs: 'start' }}
        alignItems={{ lg: 'center', xs: 'start' }}
        spacing={2}
        sx={{ mb: 3 }}
      >
        <Typography variant="h5">{t('msg_expense_screen_title')}</Typography>

        {id && (
          <Button
            variant="square"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={() => setShowDeleteDialog(true)}
          >
            {t('msg_btn_delete')}
          </Button>
        )}
      </Stack>

      {id && (
        <DeleteExpenseDialog
          id={id}
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onSuccess={onDeleteSuccess}
        />
      )}
    </>
  );
};
