import { ArrowDropDown, ContentCopy } from '@mui/icons-material';
import {
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  styled,
  AccordionProps,
  IconButton,
  Grid,
  Box,
} from '@mui/material';
import { FC, ReactNode } from 'react';

import { colors } from '~/theme/colors';

interface Detail {
  title: string;
  description: string;
  hasBorder?: boolean;
}

interface DetailsAccordionProps {
  title: string;
  details?: Detail[];
  expanded?: boolean;
  children?: ReactNode;
}

const StyledAccordion = styled(Accordion)<AccordionProps>(() => ({
  '& .MuiAccordionDetails-root': {
    padding: 0,
  },
  '& .MuiAccordion-root': {
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiPaper-root': {
    '&:before': {
      display: 'none',
    },
  },
}));

const DetailRow: FC<Detail> = ({ title, description, hasBorder }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      sx={{ pt: 2, pb: 2, ...(hasBorder && { borderBottom: `1px solid ${colors.other.divider}` }) }}
    >
      <Grid item>
        <Box style={{ maxWidth: '200px' }}>
          <Typography fontSize={12} color="text.secondary">
            {title}
          </Typography>
        </Box>
        <Box style={{ maxWidth: '200px', textOverflow: 'ellipsis' }}>
          <Typography noWrap fontSize={14} color="text.secondary">
            {description}
          </Typography>
        </Box>
      </Grid>
      <IconButton onClick={() => navigator.clipboard.writeText(description)}>
        <ContentCopy sx={{ width: 18, height: 18 }} />
      </IconButton>
    </Grid>
  );
};

const DetailsAccordion: FC<DetailsAccordionProps> = ({ title, details, expanded, children }) => {
  return (
    <StyledAccordion
      data-testid="details-accordion-testid"
      disableGutters
      defaultExpanded={expanded}
      sx={{
        width: { xs: '100%', sm: '320px' },
        p: 4,
        borderRadius: 4,
        '& .MuiButtonBase-root': {
          padding: 0,
        },
        '&:before': {
          display: 'none',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDown />}
        sx={{
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
      >
        <Typography title={title} noWrap width={200} variant="h6" fontWeight={600}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '& .MuiAccordionDetails-root': {
            padding: 0,
          },
        }}
      >
        {details?.map((detail, index) => {
          return (
            <DetailRow
              key={detail.title}
              title={detail.title}
              description={detail.description}
              hasBorder={details.length - 1 !== index}
            />
          );
        })}
        {children}
      </AccordionDetails>
    </StyledAccordion>
  );
};
export default DetailsAccordion;
