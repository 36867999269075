import { styled } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const FlagContainer = styled('div')({
  height: pxToRem(21),
  '& img': {
    width: 'auto',
    height: pxToRem(21),
  },
});
