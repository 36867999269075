import { Paper, styled } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const DateRangePickerWrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  padding: theme.spacing(0, 1, 0, 2),
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: open ? theme.palette.primary.main : 'transparent',
  borderRadius: pxToRem(4),

  '& .MuiDateRangePickerInput-root': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .MuiInputBase-root': {
    border: 'none !important',

    '.MuiInputBase-input': {
      maxWidth: [pxToRem(95), '!important'],
      paddingRight: 0,
      paddingLeft: 0,

      '&:not(:placeholder-shown)': {
        textAlign: 'center',
      },
    },
  },
}));
