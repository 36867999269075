import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { Formik, Form, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { FormikDatePicker } from '~/components/Form/DatePicker';
import { FilePicker } from '~/components/Form/FilePicker';
import { Select } from '~/components/Form/Select';
import { MerchantSelectorBox } from '~/components/Form/SelectorBox/MerchantSelectorBox';
import { FormikTextField } from '~/components/Form/TextField';
import { useAuth } from '~/context/AuthContext';
import { useNavigateBack } from '~/hooks/useNavigateBack';
import { ROUTES } from '~/router/Routes';
import { ExpenseFormFields } from '~/types/expense';
import { FormikSetErrorsFn } from '~/types/formik';
import { formatCurrency, formatNumericStringToNumber } from '~/utils/formatNumber';
import { pxToRem } from '~/utils/getFontValue';

import { Header } from '../Header';

import { acceptFileTypes } from './config';
import { InputBoxTitle, InputBoxSubTitle } from './ExpenseDataForm.style';
import { ExpenseItemsTable } from './ExpenseItemsTable';
import { TableRow } from './ExpenseItemsTable/TableRow';
import { validationSchema } from './validationSchema';

export type Props = {
  initialValues: ExpenseFormFields;
  onSave: (
    values: ExpenseFormFields,
    setErrors: FormikSetErrorsFn<ExpenseFormFields>,
  ) => Promise<boolean>;
};

let formikDirty = false;

export const ExpenseDataForm = ({ initialValues, onSave }: Props) => {
  const { t } = useTranslation();
  const navigateBack = useNavigateBack();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);

  const handleSubmit = async (
    values: ExpenseFormFields,
    { setErrors }: FormikHelpers<ExpenseFormFields>,
  ) => {
    const success = await onSave(values, setErrors);
    if (success) {
      navigateBack(ROUTES.DASHBOARD_EXPENSES);
    }
  };

  const onClose = () => {
    setShowSaveChangesDialog(false);
    navigateBack(ROUTES.DASHBOARD_EXPENSES);
  };

  const onGoBack = () => {
    if (formikDirty) {
      setShowSaveChangesDialog(true);
    } else {
      onClose();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, setErrors, isSubmitting, dirty }) => {
        formikDirty = dirty;

        const touchSaveAndClose = async () => {
          const success = await onSave(values, setErrors);
          if (success) {
            onClose();
          }
        };

        return (
          <>
            <Header onGoBack={onGoBack} />

            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <MerchantSelectorBox name="merchant" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack gap={1}>
                    <FormikDatePicker
                      name="issue_date"
                      label={t('msg_label_expense_date')}
                      variant="filled"
                      fullWidth
                    />

                    <Select
                      name="currency"
                      variant="filled"
                      label={t('msg_label_currency')}
                      value={base_currency.code}
                      fullWidth
                      disabled
                    >
                      <MenuItem value={base_currency.code}>
                        {base_currency.name}, {base_currency.symbol_native}
                      </MenuItem>
                    </Select>

                    <FormikTextField
                      name="receipt_number"
                      label={t('msg_label_receipt_number')}
                      variant="filled"
                      fullWidth
                    />
                  </Stack>
                </Grid>
              </Grid>

              <ExpenseItemsTable>
                <TableRow />
              </ExpenseItemsTable>

              <Paper sx={{ mt: 3, p: 2 }}>
                <Stack direction="row" spacing={2} sx={{ mb: 1.5 }}>
                  <InputBoxTitle variant="body2">{t('msg_label_related_documents')}</InputBoxTitle>
                  <InputBoxSubTitle variant="body2">
                    {t('msg_label_related_documents_info')}
                  </InputBoxSubTitle>
                </Stack>
                <FilePicker name="receipts" accept={acceptFileTypes} />
              </Paper>

              <Stack direction="row" justifyContent="end">
                <Box>
                  <Stack direction="row" justifyContent="space-between" my={4}>
                    <Typography variant="h6">{t('msg_total_price')}</Typography>
                    <Typography variant="h6">
                      {formatCurrency(
                        formatNumericStringToNumber(String(values.amount)),
                        false,
                        base_currency.symbol_native,
                      )}
                    </Typography>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      sx={{ width: { xs: 1, md: pxToRem(338) } }}
                    >
                      {t('msg_btn_save_and_close_expense')}
                    </LoadingButton>
                  </Stack>
                </Box>
              </Stack>
            </Form>

            <ConfirmationDialog
              open={showSaveChangesDialog}
              title={t('msg_save_expense_changes_title')}
              onClose={() => setShowSaveChangesDialog(false)}
              actions={[
                {
                  label: t('msg_btn_save_expense'),
                  onClick: () => {
                    touchSaveAndClose();
                    setShowSaveChangesDialog(false);
                  },
                  color: 'inherit',
                },
                {
                  label: t('msg_btn_leave_without_saving'),
                  onClick: onClose,
                  color: 'error',
                },
              ]}
            />
          </>
        );
      }}
    </Formik>
  );
};
