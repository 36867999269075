import { Expense } from '~/types/expense';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<Expense>[] = [
  {
    id: 'merchant__name',
    label: 'msg_label_merchant_name',
    width: '20%',
    sortable: true,
  },
  {
    id: 'description',
    label: 'msg_label_expense_details',
    width: '30%',
  },
  {
    id: 'issue_date',
    label: 'msg_label_expense_date',
    width: '15%',
    sortable: true,
  },
  {
    id: 'files',
    label: 'msg_label_expense_attachments_count',
    width: '15%',
  },
  {
    id: 'full_amount',
    label: 'msg_label_expense_full_amount',
  },
  {
    id: 'id',
    label: '',
  },
];
