import { useMutation } from '@tanstack/react-query';

import { AmplitudeEvent } from '~/types/amplitude';
import { Customer } from '~/types/customer';
import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';
import { sendAmplitudeData } from '~/utils/amplitude';

import { model } from '../';

import { customersQueryKey } from './useCustomers';
import { suggestedCustomersQueryKey } from './useSuggestedCustomers';

export const useCreateCustomer = ({ onSuccess }: MutationProps<Customer>) =>
  useMutation(model.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([customersQueryKey]);
      queryClient.invalidateQueries(suggestedCustomersQueryKey);
      sendAmplitudeData(AmplitudeEvent.CustomerCreated, { name: data.name, id: data.id });
      onSuccess?.(data);
    },
  });
