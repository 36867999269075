import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, IconButton, TextField } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Customer, CustomerListData } from '~/types/customer';

import { CustomerSelectorMenu } from './CustomerSelectorMenu';

export type Props = {
  onChange: (customer?: CustomerListData) => void;
  defaultValue?: CustomerListData;
};

export const CustomerSelector = ({ onChange, defaultValue }: Props) => {
  const { t } = useTranslation();

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerListData | undefined>(
    defaultValue,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onSelectCustomer = (customer?: Customer) => {
    setSelectedCustomer(customer);
    onChange(customer ? { id: customer?.id, name: customer?.name } : undefined);
    closeMenu();
  };

  const onClear = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setSelectedCustomer(undefined);
    onChange(undefined);
  };

  const endAdornment = () => (
    <>
      {selectedCustomer && (
        <IconButton onClick={onClear}>
          <ClearIcon />
        </IconButton>
      )}
      {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </>
  );

  return (
    <FormControl fullWidth>
      <TextField
        variant="filled"
        placeholder={t('msg_label_customer')}
        value={selectedCustomer?.name || ''}
        onClick={openMenu}
        InputProps={{
          readOnly: true,
          endAdornment: endAdornment(),
        }}
        focused={open}
        hiddenLabel
        fullWidth
      />

      <CustomerSelectorMenu
        anchorEl={anchorEl}
        onSelectCustomer={onSelectCustomer}
        onClose={closeMenu}
        open={open}
        hasCreateButton={false}
      />
    </FormControl>
  );
};
