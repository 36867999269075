import { IconButton, useTheme } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { ArrowBackFilled } from '~/components/Icon/ArrowBackFilled';

export const GoBackButton = ({ color: _color, sx, ...rest }: ButtonProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  return (
    <IconButton aria-label={t('btn_go_back')} sx={{ ...sx, padding: 0 }} {...rest}>
      <ArrowBackFilled color={palette.text.primary} />
    </IconButton>
  );
};
