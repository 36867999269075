import { AppConfig } from '~/types/appConfig';
import { api } from '~/utils/api';

const URL = {
  GET: '/api/app-config/',
};

export const model = {
  get: () => api.get<AppConfig>(URL.GET, { platform: 'webapp' }),
};
