import { Box, Stack, Typography } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useLocation, useNavigate } from 'react-router-dom';

import { GoBackButton } from '~/components/Button/GoBackButton';
import { ErrorsList } from '~/components/ErrorsList';
import { RESEND_TIMER_IN_SECONDS } from '~/config/constants';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useAppConfig } from '~/context/AppConfigContext';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Auth';
import { ROUTES } from '~/router/Routes';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { sendAmplitudeData, setAmplitudeUserId } from '~/utils/amplitude';
import { pxToRem } from '~/utils/getFontValue';

import { VerificationCodeContainer } from './PhoneVerificationForm.style';

type Props = {
  onBack: () => void;
  phone: string;
};

export const PhoneVerificationForm = ({ onBack, phone }: Props) => {
  const { t } = useTranslation();
  const { verify } = useAuth();
  const { onChangeLanguage } = useAppConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [timeLeft, setTimeLeft] = useState(RESEND_TIMER_IN_SECONDS);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    document.querySelector('input')?.focus();
  }, []);

  useEffect(() => {
    setTimeLeft(RESEND_TIMER_IN_SECONDS);
    const interval = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft === 0) {
          clearInterval(interval);
        }
        return timeLeft - 1 >= 0 ? timeLeft - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [inProgress]);

  const handleComplete = async (verificationCode: string) => {
    try {
      const urlObj = new URL(window.location.href);
      const redirectLink = new URLSearchParams(urlObj.search).get('go');

      if (redirectLink) {
        localStorage.setItem(localStorageKeys.invoiceGeneratorRedirectLink, redirectLink);
      }

      const user = await verify(phone, verificationCode);
      if (user) {
        setAmplitudeUserId(`${user.id}`);
        if (user.completed_registration) {
          sendAmplitudeData(AmplitudeEvent.LoginAuthenticated, {
            id_type: 'phone',
            status: 'success',
            method: 'OTP',
          });
        }
        onChangeLanguage(user.language);
        navigate(ROUTES.DASHBOARD_INVOICES);
      }

      if (location.state && location.state.fromPublicInvoiceId) {
        const navId = location.state.fromPublicInvoiceId;
        navigate(generatePath(ROUTES.DASHBOARD_INVOICES_PREVIEW, { id: navId }));
        return;
      }

      navigate(ROUTES.DASHBOARD_INVOICES);
    } catch (error) {
      sendAmplitudeData(AmplitudeEvent.LoginAuthenticated, {
        id_type: 'phone',
        status: 'failed',
        method: 'OTP',
      });
      if (error instanceof ValidationError) {
        setErrors(error.nonFieldErrors);
      }
    }
  };

  const resendVerificationCode = async () => {
    setInProgress(true);
    try {
      await model.phoneLogin(phone);
    } catch (error) {
      console.error(error);
    }
    setInProgress(false);
  };

  return (
    <Stack alignItems="center" sx={{ width: 360 }} spacing={2.5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: 1 }}>
        <GoBackButton onClick={onBack} />
        <Typography variant="h5" sx={{ fontSize: pxToRem(34) }} textAlign="center">
          {t('msg_enter_verification_code')}
        </Typography>
        <Box sx={{ width: 32 }} />
      </Stack>

      <VerificationCodeContainer>
        <MuiOtpInput
          value={verificationCode}
          onChange={setVerificationCode}
          onComplete={handleComplete}
          TextFieldsProps={{
            placeholder: ' ',
            type: 'number',
            autoComplete: 'off',
          }}
        />
      </VerificationCodeContainer>

      <ErrorsList errors={errors} sx={{ width: 286 }} />

      <Stack spacing={0.625}>
        <Typography variant="body2" textAlign="center">
          {t('msg_code_send_to_phone')} {phone}
        </Typography>

        <Typography variant="body2" textAlign="center">
          {timeLeft === 0 ? (
            <Link to="#" onClick={resendVerificationCode}>
              {t('msg_resend_verification_code')}
            </Link>
          ) : (
            `${t('msg_time_left_to_resend_code')} ${timeLeft}${t('msg_second_short')}`
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};
