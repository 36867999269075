import { Company } from './company';
import { Subscription } from './subscription';

export type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthResponse = {
  token: string;
};

export type ResponseWithDetail = {
  detail: string;
  redirect_url?: string;
};

export enum RegistrationProcessSteps {
  finished = 'finished',
}

export type CurrentUser = {
  id: number;
  full_name: string;
  email: string;
  phone: string;
  country: string;
  has_agreed_terms: boolean;
  active_company: Company;
  personal_no: string;
  completed_registration: boolean;
  avatar?: string;
  language: string;
  subscription: Subscription;
  show_free_trial_timeline_notification: boolean;
  referral_code: string;
  referral_source: string;
  user_segment?: number;
  current_reg_step: RegistrationProcessSteps;
};

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: CurrentUser;
};

export type AuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: CurrentUser;
  fetchUser: () => Promise<void>;
  verify: (email: string, activationKey: string) => Promise<CurrentUser | undefined>;
  setTokenToLocalStorage: (token: string, shouldLogin?: boolean) => Promise<void>;
  logout: () => Promise<void>;
  loginAndSetUserToContext: () => void;
};

export type ChangePasswordRequest = {
  password: string;
  passwordConfirmation: string;
};

export type ProfileFormFields = {
  email: string;
  phone: string;
  full_name: string;
  avatar?: string;
};
