import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';

import { useDeleteMerchant } from '~/models/Merchant/hooks/useDeleteMerchant';
import { NotFoundError, ValidationError } from '~/types/error';

import { ConfirmationDialog } from '../ConfirmationDialog';

type Props = {
  id: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export const DeleteMerchantDialog = ({ id, open, onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useDeleteMerchant({
    onSuccess: () => {
      toast.success(t('msg_success_merchant_deleted'));
      onClose();

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onDelete = async () => {
    try {
      await mutateAsync(id);
    } catch (error) {
      if ((error instanceof ValidationError || error instanceof NotFoundError) && error.detail) {
        toast.error(t(error.detail));
      }
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      title={t('msg_title_delete_merchant')}
      onClose={onClose}
      actions={[
        {
          label: t('msg_btn_delete_merchant_confirm'),
          color: 'error',
          onClick: onDelete,
          showIsLoading: true,
        },
        {
          label: t('msg_btn_cancel'),
          onClick: onClose,
          color: 'inherit',
        },
      ]}
      isLoading={isLoading}
    />
  );
};
