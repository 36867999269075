import { pxToRem } from '../utils/getFontValue';

import { text } from './colors';

const typography = {
  fontFamily: 'Inter, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 300,
    fontSize: pxToRem(96),
    lineHeight: 112 / 96,
    letterSpacing: -1.5,
  },
  h2: {
    fontWeight: 300,
    fontSize: pxToRem(60),
    lineHeight: 72 / 60,
    letterSpacing: -0.5,
  },
  h3: {
    fontWeight: 700,
    fontSize: pxToRem(48),
    lineHeight: 56 / 48,
  },
  h4: {
    fontWeight: 600,
    fontSize: pxToRem(34),
    lineHeight: 42 / 34,
    letterSpacing: 0.25,
  },
  h5: {
    fontWeight: 700,
    fontSize: pxToRem(24),
    lineHeight: 32 / 24,
    color: text.primary,
  },
  h6: {
    fontWeight: 600,
    fontSize: pxToRem(20),
    lineHeight: 32 / 20,
    letterSpacing: 0.15,
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: 28 / 16,
    letterSpacing: 0.15,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: pxToRem(14),
    lineHeight: 22 / 14,
    letterSpacing: 0.1,
  },
  body1: {
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: 24 / 16,
    letterSpacing: 0.15,
  },
  body2: {
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: 20 / 14,
    letterSpacing: 0.17,
  },
  buttonLarge: {
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: 26 / 16,
  },
  buttonMedium: {
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: 24 / 14,
  },
  buttonSmall: {
    fontWeight: 600,
    fontSize: pxToRem(14),
    lineHeight: 22 / 14,
  },
  caption: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: 20 / 12,
    letterSpacing: 0.4,
  },
  overline: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: 32 / 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  avatarLetter: {
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: 1,
    letterSpacing: 0.14,
  },
  inputLabel: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: 1,
    letterSpacing: 0.15,
  },
  helperText: {
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: 20 / 12,
    letterSpacing: 0.4,
  },
  inputText: {
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: 24 / 16,
    letterSpacing: 0.15,
  },
  tooltip: {
    fontWeight: 500,
    fontSize: pxToRem(10),
    lineHeight: 14 / 10,
  },
} as const;

export default typography;
