import Resizer from 'react-image-file-resizer';

export const resizeImageFile = (file: File): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      900,
      900,
      'jpg',
      99,
      0,
      (file) => {
        resolve(file as File); // we set what type we return lower, so cast type here
      },
      'file',
    );
  });
