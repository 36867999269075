import * as Yup from 'yup';

export const invoiceItemValidationSchema = Yup.object().shape({
  item: Yup.string().required(''),
  amount: Yup.string().required(''),
  price: Yup.string().required(''),
});

export const validationSchema = Yup.object().shape({
  issue_date: Yup.string()
    .typeError('msg_error_required_field')
    .required('msg_error_required_field'),
  invoice_items: Yup.array()
    .of(invoiceItemValidationSchema)
    .required('msg_error_required_field')
    .min(1, 'msg_error_required_field'),
});
