import { InvoiceItem } from '~/types/invoice';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<InvoiceItem>[] = [
  {
    id: 'id',
    label: '#',
  },
  {
    id: 'item',
    label: 'msg_label_invoice_item_name',
    disablePadding: true,
  },
  {
    id: 'amount',
    label: 'msg_label_invoice_amount',
    width: '15%',
  },
  {
    id: 'price',
    label: 'msg_label_price',
    disablePadding: true,
  },
  {
    id: 'total',
    label: 'msg_invoice_item_total',
    numeric: true,
    disablePadding: true,
  },
];
