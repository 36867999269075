import { Box, Table } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TableOverlaySpinner } from '~/components/Spinner/TableOverlaySpinner';
import { generateTableRowsSkeleton, TableBody } from '~/components/Table';
import { EmptyTableContent } from '~/components/Table/EmptyTableContent';
import { useCachedData } from '~/hooks/useCachedData';
import { useTableRowsSelection } from '~/hooks/useTableRowsSelection';
import { Expense } from '~/types/expense';
import { SortDirection } from '~/types/table';

import { headCells } from './config';
import { TableHead } from './TableHead';
import { TableRow } from './TableRow';

export type Props = {
  items?: Expense[];
  count?: number;
  isLoading?: boolean;
  currentSort?: keyof Expense | string;
  sortDirection?: SortDirection;
  onSort: (column: keyof Expense | string) => void;
};

export const ExpensesTable = ({
  items,
  count,
  isLoading = false,
  currentSort,
  sortDirection,
  onSort,
}: Props) => {
  const { t } = useTranslation();
  const cached = useCachedData<Expense[]>(items);

  const { selected, selectedAll, onSelect, onSelectAll, onClearSelection } = useTableRowsSelection(
    count,
    items?.map((item) => item.id),
  );

  return (
    <Table>
      <TableHead
        headCells={headCells}
        currentSort={currentSort}
        sortDirection={sortDirection}
        onSort={onSort}
        selected={selected}
        selectedAll={selectedAll}
        onSelectAll={onSelectAll}
        onClearSelection={onClearSelection}
      />

      <TableBody isEmpty={!!cached?.length}>
        {generateTableRowsSkeleton(headCells, isLoading && !cached, true)}

        {cached?.map((row) => (
          <TableRow
            key={row.id}
            row={row}
            selected={selected.includes(row.id)}
            onSelect={onSelect}
          />
        ))}

        {!cached?.length && !isLoading && (
          <EmptyTableContent headCells={headCells} hasCheckbox>
            <Box
              component="img"
              sx={{
                height: 300,
                width: 300,
                maxHeight: { xs: 160, md: 300 },
                maxWidth: { xs: 160, md: 300 },
                resize: 'cover',
              }}
              alt="The house from the offer."
              src={require('../../../../assets/images/Expenses.png')}
            />
          </EmptyTableContent>
        )}

        {!!cached?.length && isLoading && <TableOverlaySpinner />}
      </TableBody>
    </Table>
  );
};
