import {
  Checkbox,
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Expense } from '~/types/expense';
import { HeadCell, SortDirection } from '~/types/table';

import { TableToolbar } from '../TableToolbar';

type Props = {
  headCells: HeadCell<Expense>[];
  currentSort?: keyof Expense | string;
  sortDirection?: SortDirection;
  onSort: (column: keyof Expense | string) => void;
  selected: string[];
  selectedAll: boolean;
  onSelectAll: () => void;
  onClearSelection: () => void;
};

export const TableHead = ({
  headCells,
  currentSort,
  sortDirection,
  onSort,
  selected,
  selectedAll,
  onSelectAll,
  onClearSelection,
}: Props) => {
  const { t } = useTranslation();

  return (
    <MuiTableHead sx={{ position: 'relative' }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={selected.length > 0 && !selectedAll}
            checked={selectedAll}
            onChange={onSelectAll}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={currentSort === headCell.id ? sortDirection : false}
            width={headCell.width}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={currentSort === headCell.id}
                direction={
                  currentSort === headCell.id && sortDirection === SortDirection.Desc
                    ? SortDirection.Desc
                    : SortDirection.Asc
                }
                onClick={() => onSort(headCell.id)}
              >
                {t(headCell.label)}
              </TableSortLabel>
            ) : (
              t(headCell.label)
            )}
          </TableCell>
        ))}
      </TableRow>
      <TableToolbar
        selected={selected}
        selectedAll={selectedAll}
        onSelectAll={onSelectAll}
        onClearSelection={onClearSelection}
      />
    </MuiTableHead>
  );
};
