import { useQuery } from '@tanstack/react-query';

import { Income, IncomeListOptions } from '~/types/income';
import { PaginationData } from '~/types/pagination';

import { model } from '../';

import { incomesQueryKey } from './consts';

export const useIncomes = (params?: IncomeListOptions) =>
  useQuery<PaginationData<Income>>([incomesQueryKey, params], () => model.index(params));
