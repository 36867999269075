import { alpha, Box, IconButton, Paper, Stack, styled } from '@mui/material';

import { colors } from '~/theme/colors';
import { pxToRem } from '~/utils/getFontValue';

export const CarouselContainer = styled(Box)({
  position: 'relative',
  '&, .carousel-root, .carousel-slider, .slider-wrapper, .slider': {
    height: '100%',
  },
});

export const CarouselItem = styled(Paper)(({ theme }) => ({
  background: `linear-gradient(180deg, 
    ${alpha(theme.palette.common.white, 0.6)} 0%, 
    ${alpha(theme.palette.common.white, 0)} 56.45%), 
    ${colors.primary.lighter}`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  overflow: 'hidden',
}));

export const CarouselItemImage = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'src',
})<{ src: string }>(({ src }) => ({
  height: '100%',
  width: '100%',
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '30% 40%',
  backgroundSize: 'cover',
}));

export const CarouselItemContent = styled(Stack)(({ theme }) => ({
  background: colors.primary.darker,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(8, 8, 17, 8),
  gap: theme.spacing(3),
  textAlign: 'center',
  alignItems: 'center',
}));

export const CarouselIndicatorsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(8),
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  height: pxToRem(24),
}));

export const CarouselIndicator = styled('button', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  width: pxToRem(24),
  height: pxToRem(4),
  borderRadius: pxToRem(8),
  background: alpha(theme.palette.common.white, active ? 1 : 0.4),
  border: 'none',
  cursor: 'pointer',
}));

export const CarouselIndicatorButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));
