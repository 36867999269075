import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '~/context/AuthContext';
import { AmountRange } from '~/types/range';
import { formatCurrencyNumber, formatNumber } from '~/utils/formatNumber';
import { pxToRem } from '~/utils/getFontValue';

import { FormattedTextField } from '../TextField';

type Props = {
  value: AmountRange;
  onChange: (range: AmountRange) => void;
};

export const AmountRangePicker = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const elementRef = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [min, setMin] = useState<string | undefined>(
    value.min ? formatCurrencyNumber(value.min) : undefined,
  );
  const [max, setMax] = useState<string | undefined>(
    value.max ? formatCurrencyNumber(value.max) : undefined,
  );

  const onClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    onChange({});
    setMin(undefined);
    setMax(undefined);
  };

  const onApply = () => {
    onChange({
      min: min ? formatNumber(min) : undefined,
      max: max ? formatNumber(max) : undefined,
    });
    setOpen(false);
  };

  const formattedValue = () => {
    if (value.min != undefined && value.max != undefined) {
      return `${formatCurrencyNumber(value.min)} - ${formatCurrencyNumber(value.max)}`;
    } else if (value.min != undefined) {
      return `${t('msg_label_from')} ${formatCurrencyNumber(value.min)}`;
    } else if (value.max != undefined) {
      return `${t('msg_label_to')} ${formatCurrencyNumber(value.max)}`;
    } else {
      return '';
    }
  };

  const endAdornment = () => (
    <>
      {(value.min != undefined || value.max != undefined) && (
        <IconButton name={t('btn_clear')} onClick={onClear}>
          <ClearIcon />
        </IconButton>
      )}
      <Box onClick={() => setOpen(!open)}>{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</Box>
    </>
  );

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box ref={elementRef}>
        <TextField
          variant="filled"
          InputProps={{
            readOnly: true,
            endAdornment: endAdornment(),
          }}
          value={formattedValue()}
          onFocus={() => setOpen(true)}
          placeholder={t('msg_label_amount_range')}
          hiddenLabel
          fullWidth
        />

        <Popper
          open={open}
          anchorEl={elementRef.current}
          sx={{
            minWidth: pxToRem(260),
            width: elementRef.current?.offsetWidth,
            boxShadow: (theme) => theme.shadows[20],
          }}
        >
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <FormattedTextField
                  name="min"
                  variant="filled"
                  color="grey"
                  placeholder={t('msg_label_from')}
                  value={min}
                  onChange={(e) => setMin(e.target.value)}
                  InputProps={{
                    endAdornment: base_currency.symbol_native,
                  }}
                  hiddenLabel
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormattedTextField
                  name="max"
                  variant="filled"
                  color="grey"
                  placeholder={t('msg_label_to')}
                  value={max}
                  onChange={(e) => setMax(e.target.value)}
                  InputProps={{
                    endAdornment: base_currency.symbol_native,
                  }}
                  hiddenLabel
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button onClick={onApply} size="large" variant="contained" fullWidth>
                  {t('btn_apply')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
