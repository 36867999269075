import { Theme } from '@mui/material';

export const TableCell = (theme: Theme) => {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.divider,
        },
      },
    },
  };
};
