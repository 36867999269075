import {
  Currency,
  EnabledCountry,
  EnabledLanguage,
  ExpenseCategory,
  SupportLink,
} from './appConfig';
import { ActionMap, CurrentUser } from './auth';

export type AppConfigContextType = {
  isInitialized: boolean;
  version: string;
  defaultSupportLink: SupportLink;
  enabledLanguages: EnabledLanguage[];
  enabledCountries: EnabledCountry[];
  expenseCategories: ExpenseCategory[];
  companyCategories: string[];
  enabledCompanyCategories: string[];
  currencies: Currency[];
  selectedLanguage: EnabledLanguage | undefined;
  onChangeLanguage: (languageCode: string) => void;
};

export enum AuthTypes {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

export type AuthPayload = {
  [AuthTypes.Initial]: {
    isAuthenticated: boolean;
    user: CurrentUser;
  };
  [AuthTypes.Login]: {
    user: CurrentUser;
  };
  [AuthTypes.Logout]: undefined;
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];
