import { useQuery } from '@tanstack/react-query';

import { InvoiceItemTemplate, InvoiceItemTemplatesListOptions } from '~/types/invoiceItemTemplate';
import { PaginationData } from '~/types/pagination';

import { model } from '..';

export const invoiceItemTemplatesQueryKey = 'invoice-item-templates';

export const useInvoiceItemTemplates = (params?: InvoiceItemTemplatesListOptions) =>
  useQuery<PaginationData<InvoiceItemTemplate>>([invoiceItemTemplatesQueryKey, params], () =>
    model.index(params),
  );
