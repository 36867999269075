import { styled, Box } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

import { config } from './config';

export const MenuItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number }>(({ width }) => ({
  maxWidth: width
    ? pxToRem(Math.max(width - config.menuItemHorizontalPadding, config.menuItemMaxWidth))
    : 'auto',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
