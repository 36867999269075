import { CurrentUser } from '~/types/auth';
import { AvailableCompaniesResponse } from '~/types/company';
import { UserSegment } from '~/types/userSegment';
import { api } from '~/utils/api';

type PartialCurrentUser = Partial<CurrentUser>;

const URL = {
  USER_SEGMENTS: '/api/user-segments/',
  AVAILABLE_COMPANIES: '/api/current-user/available-companies/',
  USER: '/api/current-user/',
};

export const model = {
  userSegments: () => api.get<UserSegment[]>(URL.USER_SEGMENTS),
  availableCompanies: () => api.get<AvailableCompaniesResponse>(URL.AVAILABLE_COMPANIES),
  getUser: () => api.get<CurrentUser>(URL.USER),
  patchUser: (data: PartialCurrentUser) => api.patch(URL.USER, data),
};
