import { Accept } from 'react-dropzone';

import { ExpenseCategory } from '~/types/appConfig';
import { ExpenseFormFields } from '~/types/expense';
import { formatDateShort } from '~/utils/formatDate';

export const initialValues: ExpenseFormFields = {
  issue_date: formatDateShort(new Date().toISOString(), 'yyyy-MM-dd'),
  receipt_number: '',
  description: '',
  category: ExpenseCategory.General,
  amount: '',
  full_amount: '',
  percentage: '100',
  receipts: [],
};

export const acceptFileTypes: Accept = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'application/pdf': [],
};
