import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useInvoicesStatusSummary } from '~/models/Invoices/hooks';
import { InvoiceStatus } from '~/types/invoice';

type Props = {
  selectedStatus?: InvoiceStatus;
  onChangeStatus: (status?: InvoiceStatus) => void;
};

export const InvoicesStatusFilters = ({ selectedStatus, onChangeStatus }: Props) => {
  const { data } = useInvoicesStatusSummary();
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <Box>
      <Button
        variant="square"
        color={selectedStatus === InvoiceStatus.Unpaid ? 'white' : undefined}
        onClick={() => onChangeStatus(InvoiceStatus.Unpaid)}
      >
        {t('msg_invoice_status_unpaid')} ({data.is_unpaid_count ?? 0})
      </Button>

      <Button
        variant="square"
        color={selectedStatus === InvoiceStatus.Overdue ? 'white' : undefined}
        onClick={() => onChangeStatus(InvoiceStatus.Overdue)}
      >
        {t('msg_invoice_status_overdue')} ({data.is_overdue_count ?? 0})
      </Button>

      <Button
        variant="square"
        color={selectedStatus === InvoiceStatus.Draft ? 'white' : undefined}
        onClick={() => onChangeStatus(InvoiceStatus.Draft)}
      >
        {t('msg_invoice_status_draft')} ({data.is_draft_count ?? 0})
      </Button>

      <Button
        variant="square"
        color={selectedStatus === InvoiceStatus.Paid ? 'white' : undefined}
        onClick={() => onChangeStatus(InvoiceStatus.Paid)}
      >
        {t('msg_invoice_status_paid')} ({data.is_paid_count ?? 0})
      </Button>

      <Button
        variant="square"
        color={selectedStatus === InvoiceStatus.Proforma ? 'white' : undefined}
        onClick={() => onChangeStatus(InvoiceStatus.Proforma)}
      >
        {t('msg_tag_is_proforma')} ({data.is_proforma_count ?? 0})
      </Button>

      <Button
        variant="square"
        color={selectedStatus === undefined ? 'white' : undefined}
        onClick={() => onChangeStatus(undefined)}
      >
        {t('msg_label_all_invoices')} ({data.total_count ?? 0})
      </Button>
    </Box>
  );
};
