import { useQuery } from '@tanstack/react-query';

import { model } from '../auth';

export interface CountryOfResidence {
  code: string;
  name: string;
  is_default: boolean;
}

export const useCountriesOfResidence = () =>
  useQuery<CountryOfResidence[]>(['countriesOfResidence'], () => model.countriesOfResidence());
