import { Theme } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const StepIcon = (theme: Theme) => {
  return {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.action.focus,
          text: {
            fill: theme.palette.text.secondary,
          },
          '&.Mui-active': {
            color: theme.palette.common.white,
            borderWidth: pxToRem(1),
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            borderRadius: '50%',
            text: {
              fill: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
};
