import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';

import { model } from '~/models/Customer';
import { useEditCustomer } from '~/models/Customer/hooks';
import { Customer, CustomerFormFields } from '~/types/customer';
import { ValidationError } from '~/types/error';

import { CustomerFormField, initialEmptyValues } from './config';
import { CustomerFormDialog } from './CustomerFormDialog';

type Props = {
  id: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: (customer: Customer) => void;
};

export const EditCustomerFormDialog = ({ id, open, onClose, onSuccess }: Props) => {
  const [initialValues, setInitialValues] = useState<CustomerFormFields>(initialEmptyValues);
  const { mutateAsync, isLoading } = useEditCustomer({
    onSuccess: (customer) => {
      if (customer && onSuccess) {
        onSuccess(customer);
      }
      onClose();
    },
  });

  const fetchCustomer = async () => {
    const customer = await model.get(id);
    const initialValues: CustomerFormFields = {
      [CustomerFormField.name]: customer.name,
      [CustomerFormField.company_number]: customer.company_number,
      [CustomerFormField.vat_number]: customer.vat_number,
      [CustomerFormField.address]: customer.address,
      [CustomerFormField.email]: customer.email,
      [CustomerFormField.phone]: customer.phone,
      [CustomerFormField.website]: customer.website,
      [CustomerFormField.contact_person]: customer.contact_person,
      [CustomerFormField.soft_collection]: customer.soft_collection,
    };

    setInitialValues(initialValues);
  };

  const onSubmit = async (
    data: CustomerFormFields,
    { setErrors, resetForm }: FormikHelpers<CustomerFormFields>,
  ) => {
    try {
      await mutateAsync({ id, data });
      resetForm();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
      }
    }
  };

  useEffect(() => {
    if (!open) return;

    fetchCustomer();
  }, [open]);

  return (
    <CustomerFormDialog
      title="msg_edit_customer_title"
      open={open}
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};
