import { Theme } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const Dialog = (theme: Theme) => {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: pxToRem(8),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: pxToRem(24),
          lineHeight: pxToRem(32),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(3.5),
          paddingRight: theme.spacing(3.5),
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'start',
          padding: theme.spacing(1, 3.5, 3, 3.5),
          gap: theme.spacing(1),
        },
      },
    },
  };
};
