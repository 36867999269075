import { Delete, ArrowDropDown } from '@mui/icons-material';
import {
  TableRow as MuiTableRow,
  TableCell,
  IconButton,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';
import { useField } from 'formik';
import { useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomVatPercentageDialog } from '~/components/Dialog/CustomVatPercentageDialog/CustomVatPercentageDialog';
import { FormikTableCellSelect } from '~/components/Form/Select';
import {
  FormattedFormikTextField,
  FormikTableCellTextField,
  TableCellTextField,
} from '~/components/Form/TextField';
import { MenuItemContainer } from '~/components/Menu/MenuItem';
import { InvoiceItemSelectorMenu } from '~/components/Selector/InvoiceItemSelector';
import { useAuth } from '~/context/AuthContext';
import { useOnClickOutside } from '~/hooks/useOnClickOutside';
import { VatRate } from '~/types/invoice';
import {
  convertDecimalToFormatted,
  formatCurrency,
  formatCurrencyNumber,
  formatNumber,
  formatNumericStringToNumber,
  isDecimalNumber,
} from '~/utils/formatNumber';

export type Props = {
  index: number;
  onRemove: (index: number) => void;
  canBeRemoved?: boolean;
  vatEnabled?: boolean;
  vatRates?: VatRate[];
  withVat?: boolean;
};

export const convertDecimalToPercentage = (number: number) => {
  const numberString = number.toString();

  const [integerPart, fractionalPart] = numberString.split('.');

  if (parseInt(integerPart, 10) < 10) {
    return Number(`1.0${integerPart}${fractionalPart}`);
  }

  return Number(`1.${integerPart}${fractionalPart}`);
};

export const TableRow = ({
  index,
  onRemove,
  canBeRemoved = true,
  vatEnabled,
  vatRates,
  withVat,
}: Props) => {
  const id = useId();
  const amountFieldRef = useRef<HTMLInputElement>(null) as React.MutableRefObject<HTMLInputElement>;
  const dropdownButtonRef = useRef(null);
  const vatRateCellRef = useRef<HTMLTableCellElement>(
    null,
  ) as React.MutableRefObject<HTMLTableCellElement>;
  const vatRateFieldRef = useRef<HTMLInputElement>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [invoiceItemField] = useField(`invoice_items.${index}`);
  const [itemField] = useField(`invoice_items.${index}.item`);
  const [amountField] = useField(`invoice_items.${index}.amount`);
  const [priceField] = useField(`invoice_items.${index}.price`);
  const [vatRateField, _, helpers] = useField(`invoice_items.${index}.vat_percentage`);
  const [vatSumField, __, vatSumFieldHelpers] = useField(`invoice_items.${index}.vat_sum`);
  const [totalWithoutVatField, ___, totalWithoutVatFieldHelpers] = useField(
    `invoice_items.${index}.total_without_vat`,
  );
  const [totalField, ____, totalFieldHelpers] = useField(`invoice_items.${index}.total`);

  useOnClickOutside(dropdownButtonRef, () => setVatDropdownIsOpen(false));

  useEffect(() => {
    if (priceField.value && amountField.value && vatRateField.value && vatEnabled) {
      const priceTimesAmount =
        formatNumericStringToNumber(String(priceField.value)) * amountField.value;

      const totalPriceWithoutVat = !withVat
        ? priceTimesAmount
        : priceTimesAmount /
          (isDecimalNumber(vatRateField.value)
            ? convertDecimalToPercentage(vatRateField.value)
            : String(vatRateField.value).length === 1
            ? Number(`1.0${vatRateField.value}`)
            : Number(`1.${vatRateField.value}`));

      const value = !withVat
        ? priceTimesAmount *
          (isDecimalNumber(vatRateField.value)
            ? Number(convertDecimalToFormatted(vatRateField.value))
            : String(vatRateField.value).length === 1
            ? Number(`0.0${vatRateField.value}`)
            : Number(`0.${vatRateField.value}`))
        : priceTimesAmount - totalPriceWithoutVat;

      vatSumFieldHelpers.setValue(value);
    }
    if (priceField.value && amountField.value && vatRateField.value && vatEnabled) {
      const value = !withVat
        ? formatNumericStringToNumber(String(priceField.value)) * amountField.value
        : (formatNumericStringToNumber(String(priceField.value)) * amountField.value) /
          (isDecimalNumber(vatRateField.value)
            ? convertDecimalToPercentage(vatRateField.value)
            : String(vatRateField.value).length === 1
            ? Number(`1.0${vatRateField.value}`)
            : Number(`1.${vatRateField.value}`));
      totalWithoutVatFieldHelpers.setValue(value);
    }
    if (priceField.value && amountField.value) {
      const priceTimesAmount = formatNumericStringToNumber(priceField.value) * amountField.value;

      totalFieldHelpers.setValue(+priceTimesAmount.toFixed(2) * 100);
    }
  }, [vatRateField.value, priceField.value, amountField.value, withVat]);

  const [customVatDialogIsOpen, setCustomVatDialogIsOpen] = useState(false);
  const [customVatRate, setCustomVatRate] = useState<number | undefined>(
    vatRates?.find((item) => item.description === 'msg_vat_rate_other')?.vat_percentage,
  );
  const [vatDropdownIsOpen, setVatDropdownIsOpen] = useState(false);

  const handleVatSelect = (value: any) => {
    if (vatRates?.find((item) => item.vat_percentage === value)) {
      helpers.setValue(value);
      setVatDropdownIsOpen(false);
    }
    if (value === 1000) {
      setCustomVatDialogIsOpen(true);
      setVatDropdownIsOpen(false);
    }
  };

  const handleCustomVatRateSubmit = () => {
    helpers.setValue(customVatRate);
    setCustomVatDialogIsOpen(false);
  };

  const handleCustomVatDialogClose = () => {
    helpers.setValue(vatRates?.[0].vat_percentage || 0);
    setCustomVatDialogIsOpen(false);
  };

  const handleCustomVatChange = (value: string) => {
    const newVal = value.replace(/,/g, '.');
    setCustomVatRate(+newVal);
  };

  const renderVatSelectItems = (vatRates?: VatRate[]) => {
    if (!vatRates?.length) {
      return [{ vat_percentage: 0, description: 'msg_vat_rate_other' }].map((item, index) => (
        <MenuItem key={`${id}-${index}`} value={item.vat_percentage} sx={{ whiteSpace: 'normal' }}>
          <MenuItemContainer width={vatRateCellRef?.current?.clientWidth}>
            {t(item.description)}
          </MenuItemContainer>
        </MenuItem>
      ));
    }
    return vatRates?.map((item, index) => (
      <MenuItem key={`${id}-${index}`} value={item.vat_percentage} sx={{ whiteSpace: 'normal' }}>
        <MenuItemContainer width={vatRateCellRef?.current?.clientWidth}>
          {t(item.description)}
        </MenuItemContainer>
      </MenuItem>
    ));
  };

  return (
    <MuiTableRow>
      <TableCell padding="none">
        <InvoiceItemSelectorMenu
          value={itemField.value}
          onChange={(invoiceItemTemplate) => {
            const { id, default_item_name, default_amount, default_price } = invoiceItemTemplate;
            invoiceItemField.onChange({
              target: {
                name: invoiceItemField.name,
                value: {
                  template: id,
                  item: default_item_name,
                  amount: formatNumber(default_amount),
                  price: formatCurrencyNumber(default_price),
                  vat_percentage: vatRateField.value,
                },
              },
            });

            amountFieldRef.current?.focus();
          }}
          initiallyOpen={index !== 0 && !itemField.value}
          initialServiceName={invoiceItemField.value.item}
        >
          <FormikTableCellTextField
            variant="filled"
            name={`invoice_items.${index}.item`}
            placeholder={t('msg_enter_invoice_item_name')}
            fullWidth
            hiddenLabel
            autoFocus={index !== 0 && !itemField.value}
          />
        </InvoiceItemSelectorMenu>
      </TableCell>
      <TableCell padding="none">
        <FormikTableCellTextField
          inputRef={amountFieldRef}
          type="number"
          variant="filled"
          name={`invoice_items.${index}.amount`}
          placeholder="1"
          inputProps={{
            min: 0,
            step: 0.01,
          }}
          fullWidth
          hiddenLabel
        />
      </TableCell>
      <TableCell padding="none">
        <FormattedFormikTextField
          variant="filled"
          name={`invoice_items.${index}.price`}
          InputProps={{
            endAdornment: base_currency.symbol_native,
          }}
          placeholder="0,00"
          hiddenLabel
          square={true}
          fullWidth
        />
      </TableCell>
      {vatEnabled && (
        <TableCell ref={vatRateCellRef} padding="none" sx={{ position: 'relative' }}>
          <FormikTableCellSelect
            inputRef={vatRateFieldRef}
            variant="filled"
            name={vatRateField.name}
            fullWidth
            hiddenLabel
            onChange={(e) => handleVatSelect(e.target.value)}
            open={vatDropdownIsOpen}
          >
            {renderVatSelectItems(vatRates)}
          </FormikTableCellSelect>
          {!customVatDialogIsOpen && (
            <Box
              ref={dropdownButtonRef}
              sx={{
                position: 'absolute',
                fontSize: '16px',
                top: 0,
                left: 0,
                background: '#FFF',
                width: '100%',
                height: '100%',
                zIndex: 100,
                padding: '16px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              onClick={() => setVatDropdownIsOpen(true)}
            >
              <Typography>
                {vatRateField.value !== null && vatRateField.value !== undefined
                  ? `${vatRateField.value}%`
                  : t('msg_enter')}
              </Typography>
              <ArrowDropDown />
            </Box>
          )}
        </TableCell>
      )}
      {vatEnabled && (
        <TableCell padding="none">
          <TableCellTextField
            variant="filled"
            InputProps={{
              readOnly: true,
            }}
            inputProps={{
              sx: {
                textAlign: 'right',
              },
            }}
            value={formatCurrency(
              formatNumericStringToNumber(priceField.value) *
                Number(amountField.value) *
                (vatRateField.value
                  ? isDecimalNumber(vatRateField.value)
                    ? Number(convertDecimalToFormatted(vatRateField.value))
                    : vatRateField.value >= 10
                    ? Number(`0.${vatRateField.value}`)
                    : Number(`0.0${vatRateField.value}`)
                  : 1),
              false,
              base_currency.symbol_native,
            )}
            placeholder={formatCurrency(0, true, base_currency.symbol_native)}
            hiddenLabel
            fullWidth
          />
        </TableCell>
      )}
      <TableCell padding="none">
        <TableCellTextField
          variant="filled"
          InputProps={{
            readOnly: true,
          }}
          inputProps={{
            sx: {
              textAlign: 'right',
            },
          }}
          value={
            vatEnabled
              ? formatCurrency(
                  formatNumericStringToNumber(priceField.value) * Number(amountField.value) +
                    (vatRateField.value && !withVat
                      ? formatNumericStringToNumber(priceField.value) *
                        Number(amountField.value) *
                        (isDecimalNumber(vatRateField.value)
                          ? Number(convertDecimalToFormatted(vatRateField.value))
                          : vatRateField.value >= 10
                          ? Number(`0.${vatRateField.value}`)
                          : Number(`0.0${vatRateField.value}`))
                      : 0),
                  false,
                  base_currency.symbol_native,
                )
              : formatCurrency(
                  formatNumericStringToNumber(priceField.value) * Number(amountField.value),
                  false,
                  base_currency.symbol_native,
                )
          }
          placeholder={formatCurrency(0, true, base_currency.symbol_native)}
          hiddenLabel
          fullWidth
        />
      </TableCell>
      <TableCell padding="checkbox">
        <IconButton
          aria-label={t('invoice_delete_action_sheet_yes')}
          onClick={() => onRemove(index)}
          disabled={!canBeRemoved}
        >
          <Delete />
        </IconButton>
      </TableCell>
      <CustomVatPercentageDialog
        title={t('msg_vat_rate_other')}
        open={customVatDialogIsOpen}
        onClose={handleCustomVatDialogClose}
        onSubmit={handleCustomVatRateSubmit}
        onChange={(val) => handleCustomVatChange(val.toString())}
      />
    </MuiTableRow>
  );
};
