import { Box, Table } from '@mui/material';

import { TableOverlaySpinner } from '~/components/Spinner/TableOverlaySpinner';
import { generateTableRowsSkeleton, TableBody } from '~/components/Table';
import { EmptyTableContent } from '~/components/Table/EmptyTableContent';
import { TableHead } from '~/components/Table/TableHead';
import { useCachedData } from '~/hooks/useCachedData';
import { InvoiceItemTemplate } from '~/types/invoiceItemTemplate';

import { headCells } from './config';
import { TableRow } from './TableRow';

export type Props = {
  items?: InvoiceItemTemplate[];
  isLoading?: boolean;
};

export const ServicesTable = ({ items, isLoading = false }: Props) => {
  const cached = useCachedData<InvoiceItemTemplate[]>(items);

  return (
    <Table>
      <TableHead headCells={headCells} />
      <TableBody isEmpty={!!cached?.length}>
        {generateTableRowsSkeleton(headCells, isLoading && !cached, true)}

        {cached?.map((row) => (
          <TableRow key={row.id} row={row} />
        ))}

        {!cached?.length && !isLoading && (
          <EmptyTableContent headCells={headCells} hasCheckbox>
            <Box
              component="img"
              sx={{
                height: 300,
                width: 300,
                maxHeight: { xs: 160, md: 300 },
                maxWidth: { xs: 160, md: 300 },
                resize: 'cover',
              }}
              alt="The house from the offer."
              src={require('../../../assets/images/Services.png')}
            />
          </EmptyTableContent>
        )}

        {!!cached?.length && isLoading && <TableOverlaySpinner />}
      </TableBody>
    </Table>
  );
};
