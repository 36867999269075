import numeral from 'numeral';

export const formatCurrencyNumber = (number: number | string, passedCents = true) => {
  return numeral(number)
    .divide(passedCents ? 100 : 1)
    .format('0,0.00')
    .replace(/,/g, ' ')
    .replace('.', ',');
};

export const formatCurrency = (
  number: number | string,
  passedCents = true,
  currencySymbol: string,
) => {
  return formatCurrencyNumber(number, passedCents) + ' ' + currencySymbol;
};

export const formatNumber = (number: number | string): number => {
  // TODO: this function also returns "1055" from "10,55" :D
  return numeral(number).value() ?? 0;
};

export const formatNumericStringToNumber = (number?: string | undefined | null): number => {
  // TODO: test out this function. "10.55" gets turned to "1055"
  if (!number) {
    return 0;
  }

  if (typeof number === 'string' && number.trim().startsWith(',')) {
    number = '0' + number.replace(',', '.');
  }

  return (
    numeral(
      String(number)
        .replace(/[^\d,-]/g, '')
        .replace(/,/g, '.'),
    ).value() ?? 0
  );
};

export const centsToSum = (cents: number | string): number => {
  return formatNumber(cents) / 100;
};

export const centsToSumInputValue = (cents: number | string): string => {
  return formatCurrencyNumber(cents).replace(/ /g, '');
};

export const formatFieldValueToNumber = (value: string) => {
  return Number(value.replace(',', '.'));
};

export const isDecimalNumber = (number: number) => {
  return number % 1 !== 0;
};

export const convertDecimalToFormatted = (number: number) => {
  const numberString = number.toString();
  if (numberString.includes('.')) {
    const [integerPart, fractionalPart] = numberString.split('.');
    if (parseInt(integerPart, 10) < 10) {
      return `0.0${integerPart}${fractionalPart}`;
    }
    return `0.${integerPart}${fractionalPart}`;
  }
  return numberString;
};
