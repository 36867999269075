import { Theme } from '@mui/material';

export const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: theme.shadows[0],
        },
      },
    },
  };
};
