import { useMutation } from '@tanstack/react-query';

import { Customer } from '~/types/customer';
import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '../';

import { customersQueryKey } from './useCustomers';
import { suggestedCustomersQueryKey } from './useSuggestedCustomers';

export const useEditCustomer = ({ onSuccess }: MutationProps<Customer>) =>
  useMutation(model.patch, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([customersQueryKey]);
      queryClient.invalidateQueries(suggestedCustomersQueryKey);
      onSuccess?.(data);
    },
  });
