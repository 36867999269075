import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel as CarouselComponent } from 'react-responsive-carousel';

import {
  CarouselContainer,
  CarouselIndicator,
  CarouselIndicatorButton,
  CarouselIndicatorsContainer,
  CarouselItem,
  CarouselItemContent,
  CarouselItemImage,
} from './Carouse.style';
import { carouselItems } from './config';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

export const Carousel = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);

  const onPrev = () => {
    setActiveIndex(activeIndex - 1 < 0 ? carouselItems.length - 1 : activeIndex - 1);
  };

  const onNext = () => {
    setActiveIndex(activeIndex + 1 > carouselItems.length ? 0 : activeIndex + 1);
  };

  return (
    <CarouselContainer data-testid="carousel">
      <CarouselComponent
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        onChange={(index) => setActiveIndex(index)}
        selectedItem={activeIndex}
        showArrows={false}
        showIndicators={false}
        showThumbs={false}
        interval={5000}
      >
        {carouselItems.map((item, index) => (
          <CarouselItem key={index} square>
            <CarouselItemImage src={item.image} />
            <CarouselItemContent>
              <Typography variant="h4" sx={{ maxWidth: 312 }}>
                {t(item.titleLabel)}
              </Typography>
              <Typography variant="body2" sx={{ maxWidth: 312 }}>
                {t(item.subtitleLabel)}
              </Typography>
            </CarouselItemContent>
          </CarouselItem>
        ))}
      </CarouselComponent>
      <CarouselIndicatorsContainer>
        <CarouselIndicatorButton onClick={onPrev} aria-label="Previous">
          <ChevronLeftIcon />
        </CarouselIndicatorButton>
        {carouselItems.map((_item, index) => (
          <CarouselIndicator
            key={index}
            active={index == activeIndex}
            onClick={() => setActiveIndex(index)}
          />
        ))}
        <CarouselIndicatorButton onClick={onNext} aria-label="Next">
          <ChevronRightIcon />
        </CarouselIndicatorButton>
      </CarouselIndicatorsContainer>
    </CarouselContainer>
  );
};
