import { useQuery } from '@tanstack/react-query';

import { model } from '../';

import { expensesQueryKey } from '.';

export const expensesSuggestedDescriptionsQueryKey = [expensesQueryKey, 'suggested-descriptions'];

export const useExpenseDescriptionSuggestions = () =>
  useQuery<string[]>(expensesSuggestedDescriptionsQueryKey, model.suggestedDescriptions);
