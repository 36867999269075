import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SelectProps,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useField } from 'formik';

export type Props = SelectProps & {
  name: string;
  hiddenLabel?: boolean;
};

export const Select = ({ name, label, fullWidth, variant, hiddenLabel, ...props }: Props) => (
  <FormControl fullWidth={fullWidth} hiddenLabel={hiddenLabel}>
    {label && (
      <InputLabel htmlFor={name} variant={variant} sx={hiddenLabel ? visuallyHidden : undefined}>
        {label}
      </InputLabel>
    )}
    <MuiSelect id={name} label={label} variant={variant} {...props} />
  </FormControl>
);

export const FormikSelect = ({
  name,
  label,
  fullWidth,
  variant,
  hiddenLabel,
  defaultValue,
  placeholder,
  ...props
}: Props) => {
  const [field, meta] = useField({ id: name, name });

  return (
    <FormControl fullWidth={fullWidth} hiddenLabel={hiddenLabel}>
      {label && (
        <InputLabel htmlFor={name} variant={variant} sx={hiddenLabel ? visuallyHidden : undefined}>
          {label}
        </InputLabel>
      )}
      {placeholder && (
        <InputLabel
          shrink={false}
          htmlFor={name}
          variant={variant}
          sx={hiddenLabel ? visuallyHidden : undefined}
        >
          {placeholder}
        </InputLabel>
      )}
      <MuiSelect
        id={name}
        error={meta.touched && !!meta.error}
        label={label}
        variant={variant}
        defaultValue={defaultValue}
        {...field}
        {...props}
      />
      {meta.touched && !!meta.error && (
        <FormHelperText error={meta.touched && !!meta.error}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};
