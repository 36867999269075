import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  email: Yup.string().trim().email('msg_error_invalid_email_format').notRequired(),
  phone: Yup.string()
    .trim()
    .required('msg_error_required_field')
    .matches(/^[0-9]+$/, 'msg_error_invalid_phone')
    .max(15, 'msg_error_invalid_phone'),
});
