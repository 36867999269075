import { generatePath } from 'react-router-dom';

import {
  InvoiceItemTemplate,
  InvoiceItemTemplateFormFields,
  InvoiceItemTemplateMutationProps,
  InvoiceItemTemplatesListOptions,
} from '~/types/invoiceItemTemplate';
import { PaginationData } from '~/types/pagination';
import { api } from '~/utils/api';

const URL = {
  INDEX: '/api/invoice-item-templates/',
  GET: '/api/invoice-item-templates/:id/',
};

export const model = {
  index: (options?: InvoiceItemTemplatesListOptions) =>
    api.get<PaginationData<InvoiceItemTemplate>>(URL.INDEX, options),
  get: (id: number) => api.get<InvoiceItemTemplate>(generatePath(URL.GET, { id })),
  create: (data: InvoiceItemTemplateFormFields) => api.post<InvoiceItemTemplate>(URL.INDEX, data),
  patch: ({ id, data }: InvoiceItemTemplateMutationProps) =>
    api.patch<InvoiceItemTemplate>(generatePath(URL.GET, { id }), data),
  delete: (id: number) => api.delete(generatePath(URL.GET, { id })),
};
