import { FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';

import { useCreateInvoiceItemTemplate } from '~/models/InvoiceItemTemplate/hooks/useCreateInvoiceItemTemplate';
import { NotFoundError, ValidationError } from '~/types/error';
import { InvoiceItemTemplate, InvoiceItemTemplateFormFields } from '~/types/invoiceItemTemplate';
import { formatNumber } from '~/utils/formatNumber';

import { initialEmptyValues } from './config';
import { ServiceFormDialog } from './ServiceFormDialog';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess?: (data: InvoiceItemTemplate) => void;
  initialServiceName?: string;
};

export const CreateServiceFormDialog = ({
  open,
  onClose,
  onSuccess,
  initialServiceName,
}: Props) => {
  const { t } = useTranslation();

  const { mutateAsync } = useCreateInvoiceItemTemplate({
    onSuccess: (data) => {
      if (data && onSuccess) {
        onSuccess(data);
      }
      onClose();
      toast.success(t('msg_success_service_created'));
    },
  });

  const onSubmit = async (
    values: InvoiceItemTemplateFormFields,
    { setErrors, resetForm }: FormikHelpers<InvoiceItemTemplateFormFields>,
  ) => {
    try {
      const { default_item_name, default_amount, default_price, notes } = values;

      await mutateAsync({
        default_item_name,
        default_amount,
        default_price: formatNumber(default_price),
        notes,
      });
      resetForm();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      } else if (error instanceof NotFoundError && error.detail) {
        toast.error(t(error.detail));
      } else {
        toast.error(t('msg_error_service_create_failed'));
      }
    }
  };

  return (
    <ServiceFormDialog
      title="msg_add_new_service_title"
      open={open}
      initialValues={
        initialServiceName
          ? { ...initialEmptyValues, default_item_name: initialServiceName }
          : initialEmptyValues
      }
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
