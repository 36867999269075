import SaveIcon from '@mui/icons-material/Save';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikTextField } from '~/components/Form/TextField';
import { MerchantFormFields } from '~/types/merchant';

import { GenericDialog } from '../GenericDialog';

import { tabs } from './config';
import { validationSchema } from './validationSchema';

type Props = {
  title: string;
  open: boolean;
  initialValues: MerchantFormFields;
  onClose: () => void;
  onSubmit: (values: MerchantFormFields, formikHelpers: FormikHelpers<MerchantFormFields>) => void;
  isLoading: boolean;
};

export const MerchantFormDialog = ({
  title,
  open,
  initialValues,
  onClose,
  onSubmit,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, resetForm }) => (
        <GenericDialog
          title={t(title)}
          open={open}
          closeBtnLabel={t('msg_btn_cancel')}
          onClose={() => {
            resetForm();
            onClose();
          }}
          submitBtnLabel={t('msg_btn_save')}
          submitBtnIcon={<SaveIcon />}
          onSubmit={submitForm}
          isLoading={isLoading}
        >
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={(_, value) => setTabIndex(value)}
            sx={{ mb: 3 }}
          >
            {tabs.map((tab, index) => (
              <ToggleButton key={tab} value={index} selected={tabIndex === index} color="standard">
                {t(tab)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {tabIndex == 0 && (
            <Stack gap={1}>
              <FormikTextField
                name="name"
                label={t('msg_label_name')}
                variant="filled"
                color="grey"
                autoFocus
                fullWidth
              />
              <FormikTextField
                name="company_code"
                label={t('msg_pdf_invoice_customer_company_number')}
                variant="filled"
                color="grey"
                fullWidth
              />
              <FormikTextField
                name="vat_number"
                label={t('msg_pdf_invoice_customer_vat_number')}
                variant="filled"
                color="grey"
                fullWidth
              />
              <FormikTextField
                name="address"
                label={t('msg_label_company_post_address')}
                variant="filled"
                color="grey"
                fullWidth
              />
            </Stack>
          )}

          {tabIndex == 1 && (
            <Stack gap={1}>
              <FormikTextField
                name="email"
                label={t('msg_label_email')}
                variant="filled"
                color="grey"
                autoFocus
                fullWidth
              />
              <FormikTextField
                name="phone"
                label={t('msg_label_phone')}
                variant="filled"
                color="grey"
                fullWidth
              />
              <FormikTextField
                name="website"
                label={t('msg_label_website')}
                variant="filled"
                color="grey"
                fullWidth
              />
              <FormikTextField
                name="contact_person"
                label={t('msg_label_contact_person')}
                variant="filled"
                color="grey"
                fullWidth
              />
            </Stack>
          )}
        </GenericDialog>
      )}
    </Formik>
  );
};
