import { Stack, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { LanguageSelector } from '~/components/Selector/LanguageSelector';
import { useAuth } from '~/context/AuthContext';
import { ROUTES } from '~/router/Routes';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  return (
    <Stack>
      <Typography variant="h1">{user?.phone}</Typography>

      <Box>
        <LanguageSelector countryCode={user?.active_company?.country} />
      </Box>

      <Button
        onClick={async () => {
          await logout();
          navigate(ROUTES.DEFAULT);
        }}
      >
        Logout
      </Button>
    </Stack>
  );
};
