import axios from 'axios';

import { localStorageKeys } from '~/config/localStorageKeys';
import { ROUTES } from '~/router/Routes';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(localStorageKeys.token);

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Token ${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem(localStorageKeys.token);
      if (window.location.pathname !== ROUTES.DEFAULT) {
        window.location.href = ROUTES.DEFAULT;
      }
    }

    return Promise.reject(error);
  },
);

export { axiosInstance };
