import { Navigate, useRoutes, Outlet } from 'react-router-dom';

import { DashboardLayout } from '~/components/Layout/DashboardLayout';
import { RegisterLayout } from '~/components/Layout/RegisterLayout/RegisterLayout';
import { AuthGuard, GuestGuard } from '~/guards';
import { CreateNewPassword } from '~/pages/Auth/CreateNewPassword/CreateNewPassword';
import { ForgotPassword } from '~/pages/Auth/ForgotPassword/ForgotPassword';
import { Login } from '~/pages/Auth/Login/Login';
import { RegisterIncome } from '~/pages/Auth/Register/RegisterIncome';
import { RegisterIntroduce } from '~/pages/Auth/Register/RegisterIntroduce';
import { SignUp } from '~/pages/Auth/SignUp/SignUp';
import { VerifyEmail } from '~/pages/Auth/VerifyEmail/VerifyEmail';
import { ComingSoon } from '~/pages/Blank';
import { Customers } from '~/pages/Customers';
import { Dashboard } from '~/pages/Dashboard';
import { DeleteUserData } from '~/pages/DeleteUserData/DeleteUserData';
import { ExpensesList } from '~/pages/Expenses';
import { CreateExpenseForm } from '~/pages/Expenses/ExpenseForm';
import { EditExpenseForm } from '~/pages/Expenses/ExpenseForm/EditExpenseForm';
import { CreateIncomeForm, EditIncomeForm } from '~/pages/Incomes/IncomeForm';
import { IncomesList } from '~/pages/Incomes/IncomesList';
import { InvoiceForm } from '~/pages/Invoices/InvoiceForm';
import { InvoicePlatform } from '~/pages/Invoices/InvoicePlatform';
import { InvoicePreview } from '~/pages/Invoices/InvoicePreview';
import { InvoicesList } from '~/pages/Invoices/InvoicesList';
import { MarketingLead } from '~/pages/MarketingLead/MarketingLead';
import { Services } from '~/pages/Services';
import { BusinessSettings } from '~/pages/Settings/BusinessSettings';
import { MyAutomationSettings } from '~/pages/Settings/MyAutomationSettings';
import { ProfileSettings } from '~/pages/Settings/ProfileSettings';

import { ROUTES } from './Routes';

export const Router = () =>
  useRoutes([
    {
      path: ROUTES.DEFAULT,
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        {
          path: ROUTES.SIGNUP,
          element: <SignUp />,
        },
        {
          path: ROUTES.LOGIN,
          element: <Login />,
        },
      ],
    },
    {
      path: ROUTES.DASHBOARD,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={ROUTES.DASHBOARD_INVOICES} replace />, index: true },
        {
          path: ROUTES.DASHBOARD_INVOICES,
          element: <InvoicesList />,
        },
        {
          path: ROUTES.DASHBOARD_INVOICES_PREVIEW,
          element: <InvoicePreview />,
        },
        {
          path: ROUTES.DASHBOARD_INVOICES_NEW,
          element: <InvoiceForm />,
        },
        {
          path: ROUTES.DASHBOARD_INVOICES_EDIT,
          element: <InvoiceForm />,
        },
        {
          path: ROUTES.DASHBOARD_SERVICES,
          element: <Services />,
        },
        {
          path: ROUTES.DASHBOARD_CUSTOMERS,
          element: <Customers />,
        },
        {
          path: ROUTES.DASHBOARD_MAIN,
          element: <ComingSoon />,
        },
        {
          path: ROUTES.DASHBOARD_INCOME,
          element: <IncomesList />,
        },
        {
          path: ROUTES.DASHBOARD_INCOME_NEW,
          element: <CreateIncomeForm />,
        },
        {
          path: ROUTES.DASHBOARD_INCOME_EDIT,
          element: <EditIncomeForm />,
        },
        {
          path: ROUTES.DASHBOARD_EXPENSES,
          element: <ExpensesList />,
        },
        {
          path: ROUTES.DASHBOARD_EXPENSES_NEW,
          element: <CreateExpenseForm />,
        },
        {
          path: ROUTES.DASHBOARD_EXPENSES_EDIT,
          element: <EditExpenseForm />,
        },
        {
          path: ROUTES.DASHBOARD_GOALS,
          element: <ComingSoon />,
        },
        {
          path: ROUTES.DASHBOARD_TAXES,
          element: <ComingSoon />,
        },
        {
          path: ROUTES.DASHBOARD_BANK,
          element: <ComingSoon />,
        },
        {
          path: ROUTES.DASHBOARD_SETTINGS_PROFILE,
          element: <ProfileSettings />,
        },
        {
          path: ROUTES.DASHBOARD_SETTINGS_BUSINESS,
          element: <BusinessSettings />,
        },
        {
          path: ROUTES.DASHBOARD_SETTINGS_MY_AUTOMATION,
          element: <MyAutomationSettings />,
        },
        {
          path: '*',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: ROUTES.REGISTER,
      element: (
        <AuthGuard mustCompleteRegistration={false} redirectIfRegistrationComplete={true}>
          <RegisterLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={ROUTES.REGISTER_INCOME} replace />, index: true },
        {
          path: ROUTES.REGISTER_INTRODUCE,
          element: <RegisterIntroduce />,
        },
        {
          path: ROUTES.REGISTER_INCOME,
          element: <RegisterIncome />,
        },
      ],
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: ROUTES.CREATE_NEW_PASSWORD,
      element: <CreateNewPassword />,
    },
    {
      path: ROUTES.VERIFY_EMAIL,
      element: <VerifyEmail />,
    },
    {
      path: ROUTES.INVOICE,
      element: <InvoicePlatform />,
    },
    {
      path: ROUTES.PAY_SUCCESS,
      element: <div>Payment successful</div>,
    },
    {
      path: ROUTES.MARKETING_LEAD,
      element: <MarketingLead />,
    },
    {
      path: ROUTES.DELETE_USER_DATA,
      element: <DeleteUserData />,
    },
  ]);
