import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useDelayedSearch } from '~/hooks/useDelayedSearch';
import { InvoiceItemTemplate } from '~/types/invoiceItemTemplate';

import { model } from '../invoiceItemTemplate';

export const useInvoiceItemTemplatesSuggestions = (search: string) => {
  const [suggestions, setSuggestions] = useState<InvoiceItemTemplate[]>([]);
  const searchTerm = useDelayedSearch(search);
  const { mutate, isLoading } = useMutation(model.index, {
    onSuccess: ({ items }) => {
      setSuggestions(items);
    },
  });

  useEffect(() => {
    mutate({ search: searchTerm, limit: 5 });
  }, [searchTerm]);

  return { suggestions, isLoading };
};
