import { Box, styled } from '@mui/material';

export const EditorWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    'focused' !== prop && 'error' !== prop && 'minRows' !== prop && 'whiteBg' !== prop,
})<{ focused?: boolean; error?: boolean; minRows?: number; whiteBg?: boolean }>(
  ({ theme, focused, error, minRows, whiteBg }) => ({
    position: 'relative',
    height: '58px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: error ? theme.palette.error.main : 'transparent',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 1.5),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    cursor: 'text',

    '& .public-DraftEditorPlaceholder-root': {
      color: error ? theme.palette.error.main : theme.palette.text.secondary,
      position: 'absolute',
      left: theme.spacing(1.5),
    },

    ...(focused && {
      borderColor: theme.palette.primary.main,
    }),

    ...(whiteBg && {
      backgroundColor: theme.palette.background.paper,
    }),

    ...(minRows && {
      '& .custom-editor': {
        minHeight: `${minRows * 1.5}rem`,
      },
    }),
  }),
);
