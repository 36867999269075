import { useMutation } from '@tanstack/react-query';

import { useSuggestedInvoiceItemsQueryKey } from '~/models/InvoiceItem/hooks';
import { InvoiceItemTemplate } from '~/types/invoiceItemTemplate';
import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '../';

import { invoiceItemTemplatesQueryKey } from './useInvoiceItemTemplates';

export const useEditInvoiceItemTemplate = ({ onSuccess }: MutationProps<InvoiceItemTemplate>) =>
  useMutation(model.patch, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([invoiceItemTemplatesQueryKey]);
      queryClient.invalidateQueries(useSuggestedInvoiceItemsQueryKey);
      onSuccess?.(data);
    },
  });
