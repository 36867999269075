import { generatePath } from 'react-router-dom';

import {
  Merchant,
  MerchantFormFields,
  MerchantListOptions,
  MerchantMutationProps,
} from '~/types/merchant';
import { PaginationData } from '~/types/pagination';
import { api } from '~/utils/api';

const URL = {
  INDEX: '/api/merchants/',
  GET: '/api/merchants/:id/',
};

export const model = {
  index: (options?: MerchantListOptions) => api.get<PaginationData<Merchant>>(URL.INDEX, options),
  get: (id: number) => api.get<Merchant>(generatePath(URL.GET, { id })),
  delete: (id: number) => api.delete(generatePath(URL.GET, { id })),
  create: (data: MerchantFormFields) => api.post<Merchant>(URL.INDEX, data),
  patch: ({ id, data }: MerchantMutationProps) =>
    api.patch<Merchant>(generatePath(URL.GET, { id }), data),
};
