// appConfig context
import i18next from 'i18next';
import { createContext, useContext, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';

import { FullPageSpinner } from '~/components/Spinner';
import { DEFAULT_MESSENGER_SUPPORT_ID } from '~/config/constants';
import { localStorageKeys } from '~/config/localStorageKeys';
import { model } from '~/models/AppConfig';
import { model as AuthModel } from '~/models/Auth';
import {
  Currency,
  EnabledCountry,
  EnabledLanguage,
  ExpenseCategory,
  LanguageCodes,
  SupportLink,
} from '~/types/appConfig';
import { AppConfigContextType } from '~/types/context';
import { transformTranslationsToResource } from '~/utils';
import { isProdEnv } from '~/utils/env';
import { logger } from '~/utils/logger';

export const AppConfig = createContext<AppConfigContextType>({
  isInitialized: false,
  version: '',
  defaultSupportLink: {
    messenger_id: DEFAULT_MESSENGER_SUPPORT_ID,
    mob_link: '',
  },
  enabledCountries: [],
  enabledLanguages: [],
  expenseCategories: [],
  companyCategories: [],
  enabledCompanyCategories: [],
  currencies: [],
  selectedLanguage: undefined,
  onChangeLanguage: (_language: string): void => undefined,
});

export const AppConfigProvider = ({ children }: React.PropsWithChildren) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const [version, setVersion] = useState('');
  const [defaultSupportLink, setDefaultSupportLink] = useState<SupportLink>({
    mob_link: '',
    messenger_id: DEFAULT_MESSENGER_SUPPORT_ID,
  });
  const [enabledLanguages, setEnabledLanguages] = useState<EnabledLanguage[]>([]);
  const [enabledCountries, setEnabledCountries] = useState<EnabledCountry[]>([]);
  const [expenseCategories, setExpenseCategories] = useState<ExpenseCategory[]>([]);
  const [companyCategories, setCompanyCategories] = useState<string[]>([]);
  const [enabledCompanyCategories, setEnabledCompanyCategories] = useState<string[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<EnabledLanguage | undefined>(undefined);

  useEffect(() => {
    if (isInitialized) return;

    initialize();
  });

  const MAX_RETRY_COUNT = 5;

  const initialize = async () => {
    let retryCount = 0;

    while (retryCount < MAX_RETRY_COUNT) {
      try {
        const {
          version,
          defaultSupportLink,
          enabledCountries,
          enabledLanguages,
          defaultLanguage,
          expenseCategories,
          companyCategories,
          enabledCompanyCategories,
          currencies,
          translations,
        } = await model.get();

        let language = localStorage.getItem(localStorageKeys.language);

        setVersion(version);
        setDefaultSupportLink(defaultSupportLink);
        setEnabledCountries(enabledCountries);
        setEnabledLanguages(enabledLanguages);
        setExpenseCategories(expenseCategories);
        setCompanyCategories(companyCategories);
        setEnabledCompanyCategories(enabledCompanyCategories);
        setCurrencies(currencies);

        if (!language) {
          language = defaultLanguage || LanguageCodes.lt;
          localStorage.setItem(localStorageKeys.language, defaultLanguage);
        }

        const newSelectedLanguage = enabledLanguages.find((x) => x.code === language);
        setSelectedLanguage(newSelectedLanguage);

        const resource = transformTranslationsToResource(translations);
        await i18next.use(initReactI18next).init({
          lng: language,
          resources: resource,
        });

        break;
      } catch (error) {
        logger.logException(error);
        logger.logException(`API call attempt ${retryCount + 1} failed`);
        if (!isProdEnv) {
          console.error(`API call attempt ${retryCount + 1} failed`);
          console.error(error);
        }

        retryCount++;

        if (retryCount < MAX_RETRY_COUNT) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        } else {
          logger.logException('Max retry count reached. Failed to initialize.');
          console.error('Max retry count reached. Failed to initialize.');
          setIsInitialized(true);
        }
      }
    }
    setIsInitialized(true);
  };

  const onChangeLanguage = async (language: string) => {
    const token = window.localStorage.getItem(localStorageKeys.token);
    token && (await AuthModel.changeLanguage(language));
    const selectedLang = enabledLanguages.find((x) => x.code === language);
    setSelectedLanguage(selectedLang);
    i18next.changeLanguage(language);
    localStorage.setItem(localStorageKeys.language, language);
  };

  if (!isInitialized) {
    return <FullPageSpinner />;
  }

  return (
    <AppConfig.Provider
      value={{
        isInitialized,
        version,
        enabledLanguages,
        expenseCategories,
        companyCategories,
        enabledCompanyCategories,
        currencies,
        selectedLanguage,
        onChangeLanguage,
        defaultSupportLink,
        enabledCountries,
      }}
    >
      {children}
    </AppConfig.Provider>
  );
};

export const useAppConfig = () => useContext(AppConfig);
