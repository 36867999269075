import HelpIcon from '@mui/icons-material/Help';
import { AppBar, Button, Container, Grid, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Logo } from '~/components/Logo';
import { DEEPFIN_SUPPORT_EMAIL, REGISTRATION_STEPS_COUNT } from '~/config/constants';
import { useRegistrationStepper } from '~/context/RegistrationStepperContext';

export const RegistrationAppBar = () => {
  const { t } = useTranslation();
  const { activeStep } = useRegistrationStepper();

  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="lg">
        <Grid container paddingY={3.25} alignItems="center">
          <Grid item xs={4}>
            <Stack alignItems="start" justifyContent="center">
              <Logo height={24} />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {Array(REGISTRATION_STEPS_COUNT)
                .fill(0)
                .map((_step, index) => (
                  <Step key={index}>
                    <StepLabel />
                  </Step>
                ))}
            </Stepper>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end">
              <Button
                href={`mailto:${DEEPFIN_SUPPORT_EMAIL}`}
                variant="outlined"
                startIcon={<HelpIcon />}
              >
                {t('msg_btn_support')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
