import { alpha, Box, styled } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

import { PICKER_HEIGHT } from './config';

export const FilePickerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDragActive',
})<{ isDragActive?: boolean }>(({ theme, isDragActive = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(2),
  borderWidth: pxToRem(1),
  borderStyle: 'dashed',
  borderColor: isDragActive ? theme.palette.primary.main : theme.palette.grey[500],
  cursor: 'pointer',
  transition: theme.transitions.create(['border-color', 'background-color'], {
    duration: theme.transitions.duration.short,
  }),
  minHeight: pxToRem(PICKER_HEIGHT),
  textAlign: 'center',

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));

export const FilesList = styled(Box)({
  width: '100%',
  maxHeight: pxToRem(PICKER_HEIGHT),
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: pxToRem(1),
});

export const FilesListItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'deleted',
})<{ deleted?: boolean }>(({ theme, deleted = false }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0, 0, 0, 1.5),
  cursor: 'pointer',
  userSelect: 'none',
  backgroundColor: deleted ? alpha(theme.palette.error.main, 0.1) : 'transparent',
  color: deleted ? theme.palette.error.main : 'inherit',

  '&:hover': {
    backgroundColor: deleted
      ? alpha(theme.palette.error.main, 0.15)
      : theme.palette.background.default,
  },
}));
