import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useState, MouseEvent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateServiceFormDialog } from '~/components/Dialog/ServiceFormDialog';
import { useAuth } from '~/context/AuthContext';
import { useInvoiceItemTemplatesSuggestions } from '~/models/InvoiceItemTemplate/hooks';
import { InvoiceItemTemplate } from '~/types/invoiceItemTemplate';
import { formatCurrency } from '~/utils/formatNumber';

import { FooterMenuItem } from '../CustomerSelector';

import { Popper } from './InvoiceItemSelectorMenu.style';

type Props = React.PropsWithChildren & {
  value?: string;
  onChange: (item: InvoiceItemTemplate) => void;
  initiallyOpen?: boolean;
  initialServiceName?: string;
};

export const InvoiceItemSelectorMenu = ({
  value,
  children,
  onChange,
  initiallyOpen,
  initialServiceName,
}: Props) => {
  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const { suggestions, isLoading } = useInvoiceItemTemplatesSuggestions(value || '');
  const [showAddServiceDialog, setShowAddServiceDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const loadingContent = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <CircularProgress />
      </Stack>
    );
  };

  const noResults = () => {
    if (isLoading || suggestions.length) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <Typography>{t('msg_label_no_results')}</Typography>
      </Stack>
    );
  };

  useEffect(() => {
    if (!initiallyOpen) {
      return;
    }

    setAnchorEl(divRef.current);
  }, [divRef]);

  const onSelect = (item: InvoiceItemTemplate) => {
    onChange(item);
    setShowAddServiceDialog(false);
    closeMenu();
  };

  return (
    <>
      <ClickAwayListener onClickAway={closeMenu}>
        <Box>
          <div ref={divRef} onClick={openMenu}>
            {children}
          </div>

          {open && (
            <Popper id="transition-popper" anchorEl={anchorEl} width={anchorEl?.clientWidth} open>
              <Paper>
                <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                  {loadingContent()}
                  {noResults()}

                  {suggestions.map((invoiceItemTemplate) => (
                    <MenuItem
                      key={invoiceItemTemplate.id}
                      onClick={() => onSelect(invoiceItemTemplate)}
                    >
                      <Box component="div" title={invoiceItemTemplate.default_item_name}>
                        <Typography sx={{ maxWidth: 280 }} noWrap>
                          {invoiceItemTemplate.default_item_name}
                        </Typography>
                      </Box>
                      <Box sx={{ flexGrow: 1 }} />
                      <Typography noWrap>
                        {formatCurrency(
                          invoiceItemTemplate.default_total,
                          true,
                          base_currency.symbol_native,
                        )}
                      </Typography>
                    </MenuItem>
                  ))}
                </Box>

                <FooterMenuItem onClick={() => setShowAddServiceDialog(true)} selected>
                  <AddCircleIcon />
                  <Typography variant="button" textTransform="none">
                    {t('msg_invoice_item_add_new')}
                  </Typography>
                </FooterMenuItem>
              </Paper>
            </Popper>
          )}
        </Box>
      </ClickAwayListener>

      <CreateServiceFormDialog
        open={showAddServiceDialog}
        onClose={() => setShowAddServiceDialog(false)}
        onSuccess={onSelect}
        initialServiceName={initialServiceName}
      />
    </>
  );
};
