import { CircularProgress } from '@mui/material';

import { TableOverlayWrapper } from './TableOverlaySpinner.style';

export const TableOverlaySpinner = () => (
  <TableOverlayWrapper>
    <td>
      <CircularProgress />
    </td>
  </TableOverlayWrapper>
);
