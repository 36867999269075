import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';

import { useDeleteInvoiceItemTemplate } from '~/models/InvoiceItemTemplate/hooks';
import { NotFoundError } from '~/types/error';

import { ConfirmationDialog } from '../ConfirmationDialog';

type Props = {
  id: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export const DeleteServiceDialog = ({ id, open, onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useDeleteInvoiceItemTemplate({
    onSuccess: () => {
      toast.success(t('msg_success_service_deleted'));
      onClose();

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onDelete = async () => {
    try {
      await mutateAsync(id);
    } catch (error) {
      if (error instanceof NotFoundError && error.detail) {
        toast.error(t(error.detail));
      }
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      title={t('msg_title_delete_service')}
      onClose={onClose}
      actions={[
        {
          label: t('msg_btn_delete_service_confirm'),
          color: 'error',
          onClick: onDelete,
          showIsLoading: true,
        },
        {
          label: t('msg_btn_delete_service_cancel'),
          onClick: onClose,
          color: 'inherit',
        },
      ]}
      isLoading={isLoading}
    />
  );
};
