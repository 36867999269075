import { Box, styled } from '@mui/material';
import { keyframes } from '@mui/system';

import { colors } from '~/theme/colors';
const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

const Dot = styled('div')(() => ({
  display: 'inline-block',
  backgroundColor: colors.other.chatMessageLightGreen,
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  margin: '0 5px',
  // Animation
  animation: `${pulse} 1.75s infinite`,
}));

export const PulsingDots = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Dot sx={{ animationDelay: '0s' }} />
      <Dot sx={{ animationDelay: '0.5s' }} />
      <Dot sx={{ animationDelay: '1s' }} />
    </Box>
  );
};
