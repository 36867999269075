import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { AvatarPicker } from '~/components/Form/AvatarPicker';
import { FormikTextField } from '~/components/Form/TextField';
import { LanguageSelector } from '~/components/Selector/LanguageSelector';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Auth';
import { ProfileFormFields } from '~/types/auth';
import { ValidationError } from '~/types/error';
import { pxToRem } from '~/utils/getFontValue';

import { validationSchema } from './validationSchema';

export const ProfileSettings = () => {
  const { t } = useTranslation();
  const { user, fetchUser } = useAuth();

  const initialValues: ProfileFormFields = useMemo(
    () => ({
      full_name: user?.full_name || '',
      email: user?.email || '',
      phone: user?.phone || '',
      avatar: user?.avatar,
    }),
    [user],
  );

  const onSubmit = async (
    values: ProfileFormFields,
    { setErrors }: FormikHelpers<ProfileFormFields>,
  ) => {
    const { phone } = values;

    if (!isValidPhoneNumber(phone)) {
      setErrors({ phone: t('msg_error_invalid_phone') });
      return;
    }

    try {
      await model.updateProfile(values);
      await fetchUser();
    } catch (error) {
      if (error instanceof ValidationError<ProfileFormFields>) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((message) => toast.error(t(message)));
      }
    }
  };

  return (
    <>
      <Box sx={{ float: 'right' }}>
        <LanguageSelector countryCode={user?.active_company?.country} keepLanguageSetting />
      </Box>

      <Container maxWidth="sm">
        <Typography variant="h5" lineHeight={pxToRem(48)}>
          {t('msg_label_profile')}
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form encType="multipart/form-data">
              <Box my={3}>
                <AvatarPicker name="avatar" />
              </Box>

              <Stack spacing={1}>
                <FormikTextField
                  name="full_name"
                  label={t('msg_label_name')}
                  placeholder={t('msg_label_name')}
                  variant="filled"
                />

                <FormikTextField
                  name="phone"
                  type="tel"
                  label={t('msg_label_phone')}
                  placeholder={t('msg_label_phone')}
                  variant="filled"
                  disabled
                />

                <FormikTextField
                  name="email"
                  type="email"
                  label={t('msg_label_email')}
                  placeholder={t('msg_label_email')}
                  variant="filled"
                />

                <Box pt={4.75}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    size="large"
                    startIcon={<SaveIcon />}
                    loading={isSubmitting}
                  >
                    {t('msg_btn_save')}
                  </LoadingButton>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};
