import { useQuery } from '@tanstack/react-query';

import { Customer } from '~/types/customer';

import { model } from '../';

import { customersQueryKey } from './useCustomers';

export const suggestedCustomersQueryKey = [customersQueryKey, 'suggested'];

export const useSuggestedCustomers = () =>
  useQuery<Customer[]>(suggestedCustomersQueryKey, model.suggestedCustomers);
