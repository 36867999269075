import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const LocationHistoryContext = createContext<string[]>([]);

export const LocationHistoryProvider = ({ children }: { children: React.ReactNode }) => {
  const [locationList, setLocationList] = useState<string[]>([]);

  const location = useLocation();

  useEffect(() => {
    setLocationList((locationList) => [...locationList, location.pathname]);
  }, [location.pathname]);

  return (
    <LocationHistoryContext.Provider value={locationList}>
      {children}
    </LocationHistoryContext.Provider>
  );
};

export const useLocationHistoryContext = () => useContext(LocationHistoryContext);
