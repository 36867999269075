import { Grid, Paper } from '@mui/material';

import { useResponsive } from '~/hooks/useResponsive';

import { Carousel } from './Carousel';

export const WelcomeBackLayout = ({ children }: React.PropsWithChildren) => {
  const isDesktop = useResponsive('up', 'md');

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      {isDesktop && (
        <Grid item xs={false} md={6}>
          <Carousel />
        </Grid>
      )}
      <Grid item xs={12} md={6} component={Paper} square>
        {children}
      </Grid>
    </Grid>
  );
};
