import { Button, Grid, Stack, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { FormikTextField } from '~/components/Form/TextField';
import { Logo } from '~/components/Logo';
import { useAppConfig } from '~/context/AppConfigContext';
import { model } from '~/models/Auth';
import { ROUTES } from '~/router/Routes';
import { ValidationError } from '~/types/error';
import { logger } from '~/utils/logger';

import { validationSchema } from './validationSchema';

type FormFields = {
  password: string;
  confirmPassword: string;
};

export const CreateNewPassword: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { onChangeLanguage } = useAppConfig();

  const [showForm, setShowForm] = useState(true);

  const uuid = searchParams.get('uuid'); // TODO: move these variables to a scope where it's used
  const token = searchParams.get('token');
  const language = searchParams.get('lang');
  const fromMobileApp = location.search.includes('&app=mob');

  useEffect(() => {
    if (language) {
      onChangeLanguage(language);
    }
  }, [language]);

  const onSubmit = async (
    { password, confirmPassword }: FormFields,
    { setErrors }: FormikHelpers<FormFields>,
  ) => {
    try {
      const { detail, redirect_url } = await model.createNewPassword(
        password,
        confirmPassword,
        uuid,
        token,
        fromMobileApp,
      );
      detail && toast.success(`${t(detail)}`);
      setShowForm(false);
      if (redirect_url) {
        window.location.href = redirect_url;
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.detail && toast.error(t(error.detail));
      }
      logger.logException(error);
    }
  };

  return (
    <Grid
      container
      component="main"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh', width: '100vw' }}
    >
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, values }) => (
          <Stack alignItems="center" sx={{ maxWidth: '360px', width: '100%', paddingX: 2 }}>
            <Logo width={142} height={24} />
            <Stack rowGap={4} sx={{ marginTop: '72px', width: '100%' }}>
              <Stack alignItems="center" rowGap={2}>
                <Typography textAlign={'center'} variant="h5" sx={{ wordBreak: 'break-word' }}>
                  {showForm ? t('msg_create_new_password') : t('msg_password_reset_successfully')}
                </Typography>
              </Stack>
              {showForm ? (
                <>
                  <Stack rowGap={2}>
                    <FormikTextField
                      name="password"
                      label={t('msg_label_password')}
                      variant="filled"
                      type="password"
                    />

                    <FormikTextField
                      name="confirmPassword"
                      label={t('msg_label_confirm_password')}
                      variant="filled"
                      type="password"
                    />
                  </Stack>
                  <Button
                    disabled={!values.password || !values.confirmPassword}
                    fullWidth
                    onClick={submitForm}
                    variant="contained"
                    size="large"
                  >
                    {t('btn_submit')}
                  </Button>
                </>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={() => navigate(ROUTES.LOGIN)}
                >
                  {t('btn_back_to_login')}
                </Button>
              )}
            </Stack>
          </Stack>
        )}
      </Formik>
    </Grid>
  );
};
