import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';

import { useDeleteExpense } from '~/models/Expense/hooks/useDeleteExpense';
import { NotFoundError, ValidationError } from '~/types/error';

import { ConfirmationDialog } from '../ConfirmationDialog';

type Props = {
  id: string;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export const DeleteExpenseDialog = ({ id, open, onClose, onSuccess }: Props) => {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useDeleteExpense({
    onSuccess: () => {
      toast.success(t('msg_success_expense_deleted'));
      onClose();

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onDelete = async () => {
    try {
      await mutateAsync(id);
    } catch (error) {
      if (error instanceof ValidationError) {
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      } else if (error instanceof NotFoundError && error.detail) {
        toast.error(t(error.detail));
      }
    }
  };

  return (
    <ConfirmationDialog
      open={open}
      title={t('msg_action_delete_title')}
      onClose={onClose}
      actions={[
        {
          label: t('msg_btn_delete_expense_confirm'),
          color: 'error',
          onClick: onDelete,
          showIsLoading: true,
        },
        {
          label: t('msg_btn_cancel'),
          onClick: onClose,
          color: 'inherit',
        },
      ]}
      isLoading={isLoading}
    />
  );
};
