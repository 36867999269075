import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'material-react-toastify';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import 'material-react-toastify/dist/ReactToastify.css';

import { AppConfigProvider } from './context/AppConfigContext';
import { AuthProvider } from './context/AuthContext';
import { LocationHistoryProvider } from './context/LocationHistoryContext';
import reportWebVitals from './reportWebVitals';
import { Router } from './router';
import { theme } from './theme';
import { AmplitudeEvent } from './types/amplitude';
import { queryClient } from './utils';
import { initAmplitude, sendAmplitudeData } from './utils/amplitude';
import { isProdEnv } from './utils/env';

const tagManagerArgs = {
  gtmId: 'GTM-T66JRD7',
};

if (isProdEnv) {
  TagManager.initialize(tagManagerArgs);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

initAmplitude();
sendAmplitudeData(AmplitudeEvent.PageOpened);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter basename="/">
          <AppConfigProvider>
            <AuthProvider>
              <LocationHistoryProvider>
                <Router />
              </LocationHistoryProvider>
            </AuthProvider>
          </AppConfigProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
    <ToastContainer position="top-center" />
  </>,
);

reportWebVitals();
