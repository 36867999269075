import i18next from 'i18next';
import { generatePath } from 'react-router-dom';

import { AuthResponse, CurrentUser, ResponseWithDetail } from '~/types/auth';
import { api } from '~/utils/api';

import { CountryOfResidence } from './hooks/useCountriesOfResidence';

const URL = {
  CURRENT_USER: '/api/current-user/',
  PHONE_LOGIN: '/api/auth/phone/login/',
  VERIFY: '/api/verify/',
  LOGOUT: '/api/logout/',
  COMPLETE_REGISTRATION: '/api/complete-registration/',
  RESET_PASSWORD: '/api/auth/email/reset-password/',
  RESET_PASSWORD_MOBILE_APP: '/api/auth/email/reset-password/?app=mob',
  CONFIRM_PASSWORD: '/api/auth/email/confirm-password/:uuid/:token/',
  CONFIRM_PASSWORD_MOBILE_APP: '/api/auth/email/confirm-password/:uuid/:token/?app=mob',
  VERIFY_EMAIL: '/api/auth/email/verify/:uuid/:token/',
  COUNTRIES_OF_RESIDENCE: '/api/cls/countries/',
  CHANGE_LANGUAGE: '/api/change-language/',
};

export const model = {
  currentUser: () => api.get<CurrentUser>(URL.CURRENT_USER),
  phoneLogin: (phone: string) => {
    const language = i18next.language.toUpperCase();
    return api.post(URL.PHONE_LOGIN, { phone, language });
  },
  verify: (phone: string, activationKey: string) =>
    api.post<AuthResponse>(URL.VERIFY, { phone, activation_key: activationKey }),
  logout: () => api.post(URL.LOGOUT),
  fillInUserData: (data: Partial<CurrentUser>) => api.patch(URL.CURRENT_USER, data),
  updateProfile: (data: Partial<CurrentUser>) =>
    api.patch(URL.CURRENT_USER, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  completeRegistration: () => api.patch(URL.COMPLETE_REGISTRATION, {}),
  resetPassword: (email: string, fromMobileApp: boolean, language: string | null) =>
    api.post<ResponseWithDetail>(
      fromMobileApp ? URL.RESET_PASSWORD_MOBILE_APP : URL.RESET_PASSWORD,
      { email, language },
    ),
  createNewPassword: (
    password: string,
    passwordConfirmation: string,
    uuid: string | null,
    token: string | null,
    fromMobileApp: boolean,
  ) =>
    api.post<Partial<ResponseWithDetail>>(
      generatePath(fromMobileApp ? URL.CONFIRM_PASSWORD_MOBILE_APP : URL.CONFIRM_PASSWORD, {
        uuid,
        token,
      }),
      {
        password,
        confirm_password: passwordConfirmation,
      },
    ),
  verifyEmail: (uuid: string | null, token: string | null) =>
    api.post<ResponseWithDetail>(generatePath(URL.VERIFY_EMAIL, { uuid, token })),
  changeActiveCompany: (data: { active_company: number }) => api.patch(URL.CURRENT_USER, data),
  countriesOfResidence: () => api.get<CountryOfResidence[]>(URL.COUNTRIES_OF_RESIDENCE),
  changeLanguage: (language: string) => api.post(URL.CHANGE_LANGUAGE, { language }),
};
