import { useQuery } from '@tanstack/react-query';

import { Expense, ExpenseListOptions } from '~/types/expense';
import { PaginationData } from '~/types/pagination';

import { model } from '../';

export const expensesQueryKey = 'expenses';

export const useExpenses = (params?: ExpenseListOptions) =>
  useQuery<PaginationData<Expense>>([expensesQueryKey, params], () => model.index(params));
