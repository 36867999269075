import { FormikErrors } from 'formik';

export type ApiValidationError<T> = Map<keyof T, string[]>;

type ErrorWithNonFieldErrors = { non_field_errors?: string[] };

type ErrorWithDetail = { detail?: string };

type GenericError = { error?: string };

export class ValidationError<T> extends Error {
  formikErrors: FormikErrors<T>;
  nonFieldErrors: string[];
  detail?: string;
  error?: string;

  constructor(errors: ApiValidationError<T>) {
    super('Validation error');
    const result: FormikErrors<T> = {};
    Object.assign(
      result,
      ...Array.from(Object.entries(errors)).map(([key, value]) => ({ [key]: value[0] })),
    );
    this.nonFieldErrors = (errors as ErrorWithNonFieldErrors).non_field_errors || [];
    this.detail = (errors as ErrorWithDetail).detail;
    this.formikErrors = result;
    this.error = (errors as GenericError).error;
  }
}

export class NotFoundError extends Error {
  detail?: string;

  constructor(error: Error) {
    super('Not found');
    this.detail = (error as ErrorWithDetail).detail;
  }
}
