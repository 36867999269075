import { createContext, useContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { FullPageSpinner } from '~/components/Spinner';
import { localStorageKeys } from '~/config/localStorageKeys';
import { model } from '~/models/Auth';
import { ROUTES } from '~/router/Routes';
import { AuthState, AuthContextType } from '~/types/auth';
import { AuthActions, AuthTypes } from '~/types/context';
import { setAmplitudeUserId } from '~/utils/amplitude';

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: undefined!,
};

const AuthReducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        isInitialized: true,
        user: undefined!,
      };

    default:
      return state;
  }
};

export const AuthContext = createContext<AuthContextType>(undefined!);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const token = window.localStorage.getItem(localStorageKeys.token);

      if (token) {
        const user = await model.currentUser();

        dispatch({
          type: AuthTypes.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: AuthTypes.Initial,
          payload: {
            isAuthenticated: false,
            user: undefined!,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: AuthTypes.Initial,
        payload: {
          isAuthenticated: false,
          user: undefined!,
        },
      });
    }
  };

  const setTokenToLocalStorage = async (token: string, shouldLogin = true) => {
    if (token) {
      localStorage.setItem(localStorageKeys.token, token);

      if (shouldLogin) {
        const user = await model.currentUser();
        if (user) {
          setAmplitudeUserId(`${user.id}`);
        }
        dispatch({
          type: AuthTypes.Login,
          payload: {
            user,
          },
        });
      }
    }
  };

  const loginAndSetUserToContext = async () => {
    const user = await model.currentUser();
    if (user) {
      setAmplitudeUserId(`${user.id}`);
    }
    dispatch({
      type: AuthTypes.Login,
      payload: {
        user,
      },
    });
  };

  const verify = async (phone: string, activationKey: string) => {
    const { token } = await model.verify(phone, activationKey);

    if (token) {
      localStorage.setItem(localStorageKeys.token, token);
      const user = await model.currentUser();

      dispatch({
        type: AuthTypes.Login,
        payload: {
          user,
        },
      });

      return user;
    }
  };

  const logout = async () => {
    await model.logout();
    localStorage.removeItem(localStorageKeys.token);
    dispatch({ type: AuthTypes.Logout });
    navigate(ROUTES.DEFAULT);
  };

  if (!state.isInitialized) {
    return <FullPageSpinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        fetchUser,
        verify,
        setTokenToLocalStorage,
        logout,
        loginAndSetUserToContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
