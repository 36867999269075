import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CenteredSpinner } from '~/components/Spinner';
import { CURRENT_INVOICES_LIST_SEARCH_QUERY } from '~/config/constants';
import { useAuth } from '~/context/AuthContext';
import { model as invoiceItemModel } from '~/models/InvoiceItem';
import { model as invoiceModel } from '~/models/Invoices';
import { invoicesIndexQueryKey, invoicesStatusSummaryQueryKey } from '~/models/Invoices/hooks';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn, FormikSetFieldValueFn } from '~/types/formik';
import { Invoice, InvoiceFormFields, InvoiceStatus, InvoiceType } from '~/types/invoice';
import { queryClient } from '~/utils';
import { sendAmplitudeData } from '~/utils/amplitude';
import {
  convertDecimalToFormatted,
  formatCurrencyNumber,
  formatNumber,
  isDecimalNumber,
} from '~/utils/formatNumber';
import { getInvoiceStatus } from '~/utils/getInvoiceStatus';

import { InvoiceDataForm, InvoiceFormField, invoiceItemValidationSchema } from './InvoiceDataForm';
import { emptyInvoiceItem } from './InvoiceDataForm/config';
import { convertDecimalToPercentage } from './InvoiceDataForm/InvoiceItemsTable/TableRow/TableRow';

export const convertToFloat = (number: string | number) => {
  return parseFloat(`${number}`.replace(',', '.'));
};

export const convertInvoiceMoneyToCents = (money: number) => {
  const cent = convertToFloat(money) * 100;
  return cent.toFixed(2);
};

function convertStringToNumber(inputString: string) {
  const cleanString = inputString.replace(/[^\d]/g, '');
  const numberValue = parseFloat(cleanString);
  return isNaN(numberValue) ? 0 : numberValue;
}

export const InvoiceForm = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    user: { active_company },
  } = useAuth();

  const currentInvoicesListSearchQuery = sessionStorage.getItem(CURRENT_INVOICES_LIST_SEARCH_QUERY);

  const [pdf, setPdf] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingAndClosing, setIsSavingAndClosing] = useState(false);
  const [isDeletingAndClosing, setIsDeletingAndClosing] = useState(false);

  const [initialValues, setInitialValues] = useState<InvoiceFormFields>(undefined!); // TODO: looks fishy here
  const [invoiceType, setInvoiceType] = useState<InvoiceType>();

  const createDraft = async () => {
    setIsLoading(true);
    try {
      const invoice = await invoiceModel.createDraft();
      const vatRates = await invoiceModel.getVatRates();

      setPdf(invoice.pdf);

      queryClient.invalidateQueries([invoicesIndexQueryKey]);
      queryClient.invalidateQueries([invoicesStatusSummaryQueryKey]);

      const initialValues: InvoiceFormFields = {
        [InvoiceFormField.id]: invoice.id,
        [InvoiceFormField.customer]: invoice.customer,
        [InvoiceFormField.number]: invoice.number,
        [InvoiceFormField.issue_date]: invoice.issue_date,
        [InvoiceFormField.payment_due]: invoice.payment_due,
        [InvoiceFormField.invoice_items]: [
          emptyInvoiceItem(
            vatRates.length && (!!active_company.vat_code || active_company?.country === 'ES')
              ? vatRates[0].vat_percentage
              : undefined,
          ),
        ],
        [InvoiceFormField.notes]: invoice.notes,
        [InvoiceFormField.language]: invoice.language,
        [InvoiceFormField.s_spain_irpf_percentage]: active_company?.s_spain_irpf_percentage,
        [InvoiceFormField.invoice_type]: invoice.invoice_type,
      };
      setInitialValues(initialValues);
      setInvoiceType(InvoiceType.invoice);
    } catch (error) {
      onInvoiceFailure();
    }

    setIsLoading(false);
  };

  const getInvoice = async () => {
    if (!id) {
      return onInvoiceFailure();
    }
    setIsLoading(true);

    try {
      const invoice = await invoiceModel.get(id);

      setPdf(invoice.pdf);

      const initialValues = {
        id: invoice.id,
        customer: invoice.customer,
        number: invoice.number,
        issue_date: invoice.issue_date,
        payment_due: invoice.payment_due,
        invoice_items: invoice.invoice_items.map((item) => ({
          ...item,
          amount: item.amount,
          price: formatCurrencyNumber(item.price),
        })),
        notes: invoice.notes,
        language: invoice.language,
        spain_irpf_amount: invoice.spain_irpf_amount,
        invoice_type: invoice.invoice_type,
      };

      setInitialValues(initialValues);

      const invoiceStatus = getInvoiceStatus(invoice);

      if (invoiceStatus !== InvoiceStatus.Draft) {
        await invoiceModel.revoke(id);
      }
    } catch (error) {
      onInvoiceFailure();
    }

    setIsLoading(false);
  };

  const onSave = async (
    values: InvoiceFormFields,
    setErrors: FormikSetErrorsFn<InvoiceFormFields>,
    setFieldValue: FormikSetFieldValueFn,
    finalize = false,
    withVat?: boolean,
    vatEnabled?: boolean,
  ): Promise<{ success: boolean; invoice: Invoice | undefined }> => {
    let success = true;
    let invoice: Invoice | undefined;
    const invoiceId = initialValues.id;

    const formattedValues = {
      ...values,
      invoice_items: values.invoice_items.map((item) => {
        if (
          item.total_without_vat &&
          item.vat_sum &&
          item.vat_percentage &&
          (!!active_company.vat_code || active_company.country === 'ES')
        ) {
          const price = convertStringToNumber(item.price.toString());

          const priceTimesAmount = Number(item.amount.toString().replace(',', '.')) * Number(price);

          const totalPriceWithoutVat = !withVat
            ? priceTimesAmount
            : priceTimesAmount /
              (isDecimalNumber(item.vat_percentage)
                ? convertDecimalToPercentage(item.vat_percentage)
                : String(item.vat_percentage).length === 1
                ? Number(`1.0${item.vat_percentage}`)
                : Number(`1.${item.vat_percentage}`));

          const totalPrice = vatEnabled
            ? !withVat
              ? (priceTimesAmount * item.vat_percentage) / 100 + priceTimesAmount
              : priceTimesAmount
            : priceTimesAmount;

          const totalVat = !withVat
            ? priceTimesAmount *
              (isDecimalNumber(item.vat_percentage)
                ? Number(convertDecimalToFormatted(item.vat_percentage))
                : String(item.vat_percentage).length === 1
                ? Number(`0.0${item.vat_percentage}`)
                : Number(`0.${item.vat_percentage}`))
            : priceTimesAmount - totalPriceWithoutVat;

          const convertedPriceWithVat = totalPriceWithoutVat / Number(item.amount);

          return {
            ...item,
            amount: String(item.amount),
            total_without_vat: Math.round(totalPriceWithoutVat),
            vat_sum: Math.round(totalVat),
            total: Math.round(totalPrice),
            price: withVat ? Math.round(convertedPriceWithVat) : price,
          };
        } else {
          return item;
        }
      }),
    };

    if (!invoiceId) {
      return { success: false, invoice };
    }

    const invoiceItemsValid = await Promise.all(
      formattedValues.invoice_items.map(async (invoiceItem) =>
        invoiceItemValidationSchema
          .validate(invoiceItem)
          .then(() => true)
          .catch(() => false),
      ),
    );

    if (!invoiceItemsValid.every(Boolean)) {
      return { success: false, invoice };
    }

    const newItems = formattedValues.invoice_items.filter((item) => !item.id);

    for (const item of newItems) {
      try {
        const invoiceItem = await invoiceItemModel.create(invoiceId, {
          ...item,
          price: formatNumber(item.price),
          aux_recalculate_totals: false,
        });

        const index = formattedValues.invoice_items.indexOf(item);

        setFieldValue(`invoice_items.${index}.id`, invoiceItem.id);
      } catch (error) {
        if (error instanceof ValidationError && setErrors) {
          setErrors(error.formikErrors);
        }
        toast(t('msg_error_add_item_failed'), { type: 'error' });
        success = false;
      }
    }

    const deletedItems = initialValues.invoice_items.filter(
      (item) => !formattedValues.invoice_items.find((i) => i.id == item.id),
    );

    for (const item of deletedItems) {
      try {
        if (item.id) {
          await invoiceItemModel.delete(invoiceId, item.id);
        }
      } catch (error) {
        toast(t('msg_error_update_item_failed'), { type: 'error' });
        success = false;
      }
    }

    const updatedItems = formattedValues.invoice_items.filter(
      (item) => item.id && initialValues.invoice_items.find((i) => i.id == item.id),
    );

    for (const item of updatedItems) {
      try {
        if (item.id) {
          await invoiceItemModel.update(invoiceId, item.id, {
            ...item,
            price: formatNumber(item.price),
          });
        }
      } catch (error) {
        if (error instanceof ValidationError && setErrors) {
          setErrors(error.formikErrors);
        }
        toast(t('msg_error_update_item_failed'), { type: 'error' });
        success = false;
      }
    }

    try {
      invoice = await invoiceModel.patch(invoiceId, formattedValues);
    } catch (error) {
      if (error instanceof ValidationError && setErrors) {
        setErrors(error.formikErrors);
      }
      toast(t('msg_error_invoice_draft_failed'), { type: 'error' });
      success = false;
    }

    if (finalize) {
      try {
        invoice = await invoiceModel.finalize(invoiceId);
      } catch (error) {
        if (error instanceof ValidationError && setErrors) {
          setErrors(error.formikErrors);
        }
        toast(t('msg_error_invoice_draft_failed'), { type: 'error' });
        success = false;
      }
    }

    if (invoice?.pdf) {
      setPdf(invoice.pdf);
    }

    queryClient.invalidateQueries([invoicesIndexQueryKey]);
    queryClient.invalidateQueries([invoicesStatusSummaryQueryKey]);

    setInitialValues(formattedValues);
    return { success, invoice };
  };

  const onSaveAndClose = async (
    values: InvoiceFormFields,
    setErrors: FormikSetErrorsFn<InvoiceFormFields>,
    setFieldValue: FormikSetFieldValueFn,
    finalize = false,
    withVat?: boolean,
    vatEnabled?: boolean,
  ) => {
    setIsSavingAndClosing(true);

    const success = await onSave(values, setErrors, setFieldValue, finalize, withVat, vatEnabled);

    setIsSavingAndClosing(false);

    const { customer, id, invoice_items, language } = values;

    sendAmplitudeData(AmplitudeEvent.InvoiceApproved, {
      customer,
      invoice_id: id,
      items_count: invoice_items.length,
      language,
    });

    if (success) {
      toast(t('msg_success_invoice_draft_saved'), { type: 'success' });
      currentInvoicesListSearchQuery ? navigate(currentInvoicesListSearchQuery) : navigate(-1);
    }
  };

  const onDeleteAndClose = async () => {
    setIsDeletingAndClosing(true);

    try {
      await invoiceModel.delete(initialValues.id!);
      queryClient.invalidateQueries([invoicesIndexQueryKey]);
      queryClient.invalidateQueries([invoicesStatusSummaryQueryKey]);
      currentInvoicesListSearchQuery ? navigate(currentInvoicesListSearchQuery) : navigate(-1);
    } catch (error) {
      toast(t('msg_error_invoices_delete_failed'), { type: 'error' });
    }

    setIsDeletingAndClosing(false);
  };

  const onInvoiceFailure = () => {
    toast.error(t('msg_error_get_invoice_failed'));
    currentInvoicesListSearchQuery ? navigate(currentInvoicesListSearchQuery) : navigate(-1);
  };

  useEffect(() => {
    if (id) {
      getInvoice();
    } else {
      createDraft();
    }
  }, []);

  useEffect(() => {
    if (!id && !!invoiceType) {
      sendAmplitudeData(AmplitudeEvent.InvoiceNewStarted, {
        invoiceType,
      });
    }
  }, [invoiceType]);

  if (isLoading || !initialValues) {
    return <CenteredSpinner />;
  }

  return (
    <InvoiceDataForm
      initialValues={initialValues}
      onSave={onSave}
      onSaveAndClose={onSaveAndClose}
      onDeleteAndClose={onDeleteAndClose}
      isSavingAndClosing={isSavingAndClosing}
      isDeletingAndClosing={isDeletingAndClosing}
      pdf={pdf}
      invoiceType={invoiceType}
      onInvoiceTypeChange={setInvoiceType}
    />
  );
};
