import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Stack, Typography } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { GoBackButton } from '~/components/Button/GoBackButton';
import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { DeleteInvoiceDialog } from '~/components/Dialog/DeleteInvoiceDialog';
import { SendInvoiceDialog } from '~/components/Dialog/SendInvoiceDialog';
import { InvoiceStatusLabel } from '~/components/Label';
import { model } from '~/models/Invoices';
import { invoicesIndexQueryKey } from '~/models/Invoices/hooks';
import { ROUTES } from '~/router/Routes';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn } from '~/types/formik';
import { Invoice, InvoiceStatus, InvoiceType, SendInvoiceFormFields } from '~/types/invoice';
import { queryClient } from '~/utils';
import { sendAmplitudeData } from '~/utils/amplitude';

type Props = {
  id: string;
  status: InvoiceStatus;
  invoice: Invoice;
};

export const Header = ({ id, status, invoice }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { pdf, customer: customerId, number, invoice_type } = invoice;

  const [showSendInvoiceDialog, setShowSendInvoiceDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showRevokeDialog, setShowRevokeDialog] = useState(false);
  const [showConvertProformaDialog, setShowConvertProformaDialog] = useState(false);

  const onSend = async (
    values: SendInvoiceFormFields,
    setErrors: FormikSetErrorsFn<SendInvoiceFormFields>,
  ) => {
    try {
      if (status === InvoiceStatus.Draft) {
        await model.finalize(id);
      }

      await model.send(id, values);

      queryClient.invalidateQueries([invoicesIndexQueryKey]);

      sendAmplitudeData(AmplitudeEvent.InvoiceSent);

      setShowSendInvoiceDialog(false);

      toast.success(t('msg_success_invoice_sent'));
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };

  const goBack = () => navigate(-1);

  const navigateToEdit = () => {
    navigate(generatePath(ROUTES.DASHBOARD_INVOICES_EDIT, { id }));
  };

  const onEdit = () => {
    if (status === InvoiceStatus.Draft) {
      navigateToEdit();
    } else {
      setShowRevokeDialog(true);
    }
  };

  const convertProformaToInvoice = async () => {
    try {
      await model.convertProformaToInvoice(id);
      queryClient.invalidateQueries([invoicesIndexQueryKey]);
      setShowConvertProformaDialog(false);
    } catch (error) {
      if (error instanceof ValidationError) {
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="row" sx={{ mb: 2.5 }}>
        <GoBackButton sx={{ mr: 1.5 }} onClick={goBack} />
        <Typography
          variant="h5"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          {invoice_type === InvoiceType.invoice ? t('msg_invoice_title') : t('msg_proforma_title')}{' '}
          {number}
          <InvoiceStatusLabel status={status} />
        </Typography>
      </Box>

      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        justifyContent={{ lg: 'space-between', xs: 'start' }}
        alignItems={{ lg: 'center', xs: 'start' }}
        spacing={2}
        sx={{ mb: 1.5 }}
      >
        <Stack direction="row" spacing={2} width="100%">
          <Button
            variant="outlined"
            size="large"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={() => setShowDeleteDialog(true)}
          >
            {t('invoice_delete_action_sheet_yes')}
          </Button>
          <Stack
            flex="1"
            alignItems="flex-end"
            justifyContent="flex-end"
            direction="row"
            gap={1}
            sx={{
              '&&': {
                m: 0,
              },
            }}
          >
            <Button variant="outlined" size="large" onClick={onEdit} startIcon={<Edit />}>
              {t(
                status === InvoiceStatus.Draft
                  ? 'msg_btn_edit_invoice'
                  : 'msg_btn_revoke_and_edit_invoice',
              )}
            </Button>

            <Button
              sx={{
                '&&': {
                  m: 0,
                },
              }}
              variant={invoice_type === InvoiceType.invoice ? 'contained' : 'outlined'}
              size="large"
              onClick={() => setShowSendInvoiceDialog(true)}
            >
              {t('msg_btn_send')}
            </Button>

            {invoice_type === InvoiceType.proforma ? (
              <Button
                sx={{
                  '&&': {
                    m: 0,
                  },
                }}
                variant="contained"
                size="large"
                onClick={() => setShowConvertProformaDialog(true)}
              >
                {t('msg_btn_convert_proforma')}
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </Stack>

      {showSendInvoiceDialog && (
        <SendInvoiceDialog
          invoiceId={id}
          open={showSendInvoiceDialog}
          customerId={customerId}
          onSubmit={onSend}
          onClose={() => setShowSendInvoiceDialog(false)}
          pdf={pdf}
        />
      )}

      <DeleteInvoiceDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onSuccess={goBack}
      />

      <ConfirmationDialog
        open={showRevokeDialog}
        title={t('msg_revoke_invoice_title')}
        onClose={() => setShowRevokeDialog(false)}
        actions={[
          {
            label: t('msg_btn_revoke_and_edit_invoice'),
            onClick: () => navigateToEdit(),
            color: 'error',
          },
          {
            label: t('msg_btn_cancel'),
            onClick: () => setShowRevokeDialog(false),
          },
        ]}
      />

      <ConfirmationDialog
        open={showConvertProformaDialog}
        title={t('msg_label_proforma_actionsheet_title')}
        onClose={() => setShowConvertProformaDialog(false)}
        actions={[
          {
            label: t('msg_btn_ok'),
            onClick: convertProformaToInvoice,
            color: 'error',
          },
          {
            label: t('msg_btn_cancel'),
            onClick: () => setShowConvertProformaDialog(false),
          },
        ]}
      />
    </>
  );
};
