import { Theme } from '@mui/material';

export const Menu = (theme: Theme) => {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: `${theme.shadows[24]} !important`,
        },
      },
    },
  };
};
