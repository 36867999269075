import { useQuery } from '@tanstack/react-query';

import { InvoiceType } from '~/types/invoice';

import { model } from '../';

const nextInvoiceNumberKey = 'nextInvoiceNumberKey';

export const useNextInvoiceNumber = (invoiceType: InvoiceType) =>
  useQuery<{ next_invoice_number: string }>([nextInvoiceNumberKey, invoiceType], () =>
    model.getNextInvoiceNumber(invoiceType),
  );
