import SaveIcon from '@mui/icons-material/Save';
import { Stack } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormikTextField, FormattedFormikTextField } from '~/components/Form/TextField';
import { useAuth } from '~/context/AuthContext';
import { InvoiceItemTemplateFormFields } from '~/types/invoiceItemTemplate';
import { formatCurrencyNumber } from '~/utils/formatNumber';

import { GenericDialog } from '../GenericDialog';

import { validationSchema } from '.';

type Props = {
  title: string;
  open: boolean;
  initialValues: InvoiceItemTemplateFormFields;
  onClose: () => void;
  onSubmit: (
    values: InvoiceItemTemplateFormFields,
    formikHelpers: FormikHelpers<InvoiceItemTemplateFormFields>,
  ) => Promise<void>;
};

export const ServiceFormDialog = ({ title, open, initialValues, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, resetForm, isSubmitting }) => (
        <GenericDialog
          title={t(title)}
          open={open}
          closeBtnLabel={t('msg_btn_cancel')}
          onClose={() => {
            resetForm();
            onClose();
          }}
          submitBtnLabel={t('msg_btn_save')}
          submitBtnIcon={<SaveIcon />}
          onSubmit={submitForm}
          isLoading={isSubmitting}
        >
          <Stack gap={1}>
            <FormikTextField
              name="default_item_name"
              label={t('msg_label_invoice_item_name')}
              variant="filled"
              color="grey"
              autoFocus
              fullWidth
            />
            <FormattedFormikTextField
              name="default_price"
              label={t('msg_label_price')}
              variant="filled"
              color="grey"
              InputProps={{
                endAdornment: base_currency.symbol_native,
              }}
              placeholder={formatCurrencyNumber(0)}
              fullWidth
            />
            <FormikTextField
              name="default_amount"
              label={t('msg_label_invoice_amount')}
              variant="filled"
              color="grey"
              type="number"
              fullWidth
            />
            <FormikTextField
              name="notes"
              label={t('msg_pdf_invoice_notes')}
              variant="filled"
              color="grey"
              minRows={2}
              multiline
              fullWidth
            />
          </Stack>
        </GenericDialog>
      )}
    </Formik>
  );
};
