import { Menu, MenuItem } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { useAppConfig } from '~/context/AppConfigContext';
import { useEnabledAppLanguages } from '~/hooks/useEnabledAppLanguages';
import { LanguageCodes } from '~/types/appConfig';
import { pxToRem } from '~/utils/getFontValue';

import { FlagIcon } from '../../Icon/FlagIcon';

import { LanguageSelectorButton } from './LanguageSelector.style';

interface LanguageSelectorProps {
  countryCode?: string;
  keepLanguageSetting?: boolean;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({
  countryCode,
  keepLanguageSetting = false,
}) => {
  const { selectedLanguage, onChangeLanguage } = useAppConfig();
  const { data: enabledAppLanguages, refetch } = useEnabledAppLanguages(countryCode);
  const languageItems =
    countryCode && !!enabledAppLanguages?.length
      ? enabledAppLanguages
      : [{ code: LanguageCodes.en, name: 'English', flag: null, is_default: true }];

  useEffect(() => {
    countryCode && refetch();

    if (!countryCode) {
      onChangeLanguage(LanguageCodes.en);
    } else if (enabledAppLanguages) {
      if (
        enabledAppLanguages?.some((lang) => lang.code === selectedLanguage?.code) &&
        keepLanguageSetting
      ) {
        return;
      }
      const defaultLangFromEnabledAppLanguages = enabledAppLanguages?.find(
        (lang) => lang.is_default,
      );
      defaultLangFromEnabledAppLanguages &&
        onChangeLanguage(defaultLangFromEnabledAppLanguages.code);
    }
  }, [countryCode, enabledAppLanguages]);

  useEffect(() => {
    if (enabledAppLanguages?.some((lang) => lang.code === selectedLanguage?.code)) {
      return;
    } else {
      const defaultLangFromEnabledAppLanguages = enabledAppLanguages?.find(
        (lang) => lang.is_default,
      );
      defaultLangFromEnabledAppLanguages &&
        onChangeLanguage(defaultLangFromEnabledAppLanguages.code);
    }
  }, [enabledAppLanguages]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const onClose = () => setAnchorEl(null);
  const onSelect = (languageCode: string) => {
    setAnchorEl(null);
    onChangeLanguage(languageCode);
  };

  const getFlagCountry = (languageCode?: string) => {
    switch (languageCode) {
      case 'en':
        return 'GB';
      default:
        return languageCode?.toUpperCase() ?? '';
    }
  };

  return (
    <>
      <LanguageSelectorButton
        id="language-button"
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
        variant="contained"
        startIcon={<FlagIcon country={getFlagCountry(selectedLanguage?.code)} />}
        color="grey"
        sx={{
          height: 40,
          padding: `${pxToRem(10)}rem`,
        }}
      >
        {selectedLanguage?.name}
      </LanguageSelectorButton>
      <Menu
        id="language-menu"
        aria-labelledby="language-button"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {languageItems.map((language) => (
          <MenuItem key={language.code} onClick={() => onSelect(language.code)}>
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
