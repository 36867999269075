import { generatePath } from 'react-router-dom';

import { Income, IncomeFormFields, IncomeListOptions } from '~/types/income';
import { PaginationData } from '~/types/pagination';
import { api } from '~/utils/api';

const URL = {
  INDEX: '/api/income/',
  GET: '/api/income/:id/',
  BULK_DELETE: '/api/operations/bulk-delete/',
};

export const model = {
  index: (params?: IncomeListOptions) => api.get<PaginationData<Income>>(URL.INDEX, params),
  get: (id: number) => api.get<Income>(generatePath(URL.GET, { id })),
  create: (data: Partial<Income>) => api.post<Income>(URL.INDEX, data),
  update: (id: number, data: IncomeFormFields) =>
    api.patch<Income>(generatePath(URL.GET, { id }), data),
  delete: (id?: number) => api.delete(generatePath(URL.GET, { id })),
  bulkDelete: (ids: string[]) => api.delete(URL.BULK_DELETE, {}, { ids }),
};
