import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, RadioGroup, Skeleton, Stack, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ErrorAlert } from '~/components/Alert';
import { GoBackButton } from '~/components/Button/GoBackButton';
import { SpacedButton } from '~/components/Button/SpacedButton';
import { ErrorsList } from '~/components/ErrorsList';
import { UserSegmentRadio } from '~/components/Form/Radio';
import { DEFAULT_SUBSCRIPTION_PLAN } from '~/config/constants';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useAuth } from '~/context/AuthContext';
import { useRegistrationStepper } from '~/context/RegistrationStepperContext';
import { AuthPages } from '~/guards';
import { model as authModel } from '~/models/Auth';
import { useUserSegments } from '~/models/User/hooks';
import { useCurrentUser } from '~/models/User/hooks/useCurrentUser';
import { ROUTES } from '~/router/Routes';
import { AmplitudeEvent } from '~/types/amplitude';
import { RegistrationProcessSteps } from '~/types/auth';
import { ValidationError } from '~/types/error';
import { FirebaseEvent } from '~/types/firebase';
import { PixelEvent } from '~/types/pixel';
import { sendAmplitudeData, setAmplitudeUserProperties } from '~/utils/amplitude';
import { logFirebaseEvent } from '~/utils/firebase';

declare const window: Window &
  typeof globalThis & {
    dataLayer: any;
  };

type FormFields = {
  user_segment: number;
};

export const RegisterIncome = () => {
  const { t } = useTranslation();
  const { setActiveStep } = useRegistrationStepper();
  const { user, fetchUser } = useAuth();
  const navigate = useNavigate();
  const { data, isLoading, error } = useUserSegments();
  const [nonFieldErrors, setNonFieldErrors] = useState<string[]>([]);
  const { data: currentUser } = useCurrentUser();

  useEffect(() => {
    setActiveStep(1);
  }, []);

  const onSubmit = async (
    { user_segment }: FormFields,
    { setErrors }: FormikHelpers<FormFields>,
  ) => {
    const selectedSegment = data?.find((segment) => segment.id == user_segment);

    try {
      await authModel.fillInUserData({ user_segment });
      window['dataLayer'].push({ event: 'sign_up' });
      window['dataLayer'].push({
        event: 'sign_up_enhanced',
        registration_id: currentUser?.id,
        email: currentUser?.email,
        phone: currentUser?.phone,
      });
      await fetchUser();
      setAmplitudeUserProperties({
        segment: selectedSegment?.label,
      });
      sendAmplitudeData(AmplitudeEvent.SegmentSelected, {
        start_segment: selectedSegment?.label,
      });
      onSkip();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
      }
    }
  };

  const onSkip = async () => {
    try {
      await authModel.completeRegistration();
      await authModel.updateProfile({ current_reg_step: RegistrationProcessSteps.finished });
      const invoiceGeneratorRedirectLink = localStorage.getItem(
        localStorageKeys.invoiceGeneratorRedirectLink,
      );

      ReactPixel.track(PixelEvent.completeRegistration);

      const amplitudeData = {
        app_country: currentUser?.country,
        plan: DEFAULT_SUBSCRIPTION_PLAN,
      };

      setAmplitudeUserProperties(amplitudeData);
      sendAmplitudeData(AmplitudeEvent.SignUpCompleted, amplitudeData);
      logFirebaseEvent(FirebaseEvent.WebAppSignUp);

      await fetchUser();
      localStorage.setItem(localStorageKeys.lastVisitedAuthPage, AuthPages.login);
      invoiceGeneratorRedirectLink
        ? navigate(invoiceGeneratorRedirectLink)
        : navigate(ROUTES.DASHBOARD_INVOICES);
    } catch (error) {
      if (error instanceof ValidationError) {
        setNonFieldErrors(error.nonFieldErrors);
      }
    }
  };

  const onGoBack = () => navigate(ROUTES.SIGNUP);

  return (
    <Formik
      initialValues={{
        user_segment: user?.user_segment ?? undefined!,
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm, values, errors }) => (
        <Stack spacing={3} paddingY={7}>
          <Box>
            <GoBackButton onClick={onGoBack} />
          </Box>

          <Stack spacing={1}>
            <Typography variant="h5">{t('msg_title_company_details_question')}</Typography>
            <Typography variant="body2">{t('msg_sub_title_company_details_question')}</Typography>
          </Stack>

          <ErrorsList errors={nonFieldErrors} />

          <RadioGroup name="user_segment">
            <Stack spacing={1}>
              {(error || errors.user_segment) && <ErrorAlert title={errors.user_segment} />}

              {isLoading &&
                Array(5)
                  .fill(0)
                  .map((_, index) => <Skeleton key={index} variant="rounded" height={75} />)}

              {data?.map((segment) => (
                <UserSegmentRadio
                  key={segment.id}
                  name="user_segment"
                  label={segment.label}
                  labelSubtitle={segment.label_subtitle}
                  value={segment.id}
                />
              ))}
            </Stack>
          </RadioGroup>

          <Box paddingTop={1}>
            <Grid container spacing={3}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <SpacedButton
                  variant="contained"
                  size="large"
                  endIcon={<ArrowForwardIcon />}
                  onClick={submitForm}
                  aria-label={t('btn_continue')}
                  fullWidth
                  disabled={!values.user_segment}
                >
                  {t('btn_continue')}
                </SpacedButton>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}
    </Formik>
  );
};
