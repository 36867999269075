export const DEFAULT_LANGUAGE = 'lt';
export const DEFAULT_COUNTRY_CODE = 'LT';
export const DEFAULT_CURRENCY = 'eur';
export const DEFAULT_CURRENCY_SYMBOL = '€';

export const DEFAULT_SUBSCRIPTION_PLAN = 'trial';

export const DEFAULT_UNIT = 'qnt';

export const RESEND_TIMER_IN_SECONDS = 60;
export const REGISTRATION_STEPS_COUNT = 3;

export const URL_PRIVACY_POLICY = 'https://deepfin.io/privacy-policy/';
export const URL_TERMS_AND_CONDITIONS = 'https://deepfin.io/deepfin-paslaugu-teikimo-salygos/';
export const PRIVACY_POLICY_URL_TRANSLATION = 'link_privacy_policy';
export const TERMS_AND_CONDITIONS_URL_TRANSLATION = 'link_terms_and_conditions';
export const DEEPFIN_ACADEMY_URL = 'https://deepfin.io/akademija/';
export const DEEPFIN_ACADEMY_URL_TRANSLATION = 'link_deepfin_academy';
export const DEEPFIN_DL_MOBILE_APPS_URL = 'https://deepfin.io/?openpopup=true';
export const DEEPFIN_DL_MOBILE_APPS_URL_TRANSLATION = 'link_deepfin_mobile_app';
export const DEEPFIN_HOMEPAGE_URL = 'https://deepfin.io/';
export const DEEPFIN_SUPPORT_EMAIL = 'support@deepfin.io';

export const DEFAULT_MESSENGER_SUPPORT_ID = 117921261295258;

export const APP_VERSION = '1.3';

export const FACEBOOK_PIXEL_ID = '211839544482415';

export const MAX_PHOTO_UPLOAD_SIZE = 2000000; // bytes, 2MB

export const CURRENT_INVOICES_LIST_SEARCH_QUERY = 'currentInvoicesListSearchQuery';
