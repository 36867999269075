import { Stack, styled } from '@mui/material';

import { numberTextFieldStyles } from '~/components/Form/TextField/TextField.style';
import { pxToRem } from '~/utils/getFontValue';

export const VerificationCodeContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2.5, 0, 0, 0),
  justifyItems: 'center',
  alignItems: 'center',
  '.MuiInputBase-root': {
    input: {
      padding: 0,
      width: pxToRem(56),
      height: pxToRem(64),
      textAlign: 'center',
      fontSize: pxToRem(20),
      caretColor: theme.palette.primary.main,
    },
    'input:not(:focus):placeholder-shown': {
      backgroundColor: theme.palette.action.disabledBackground,
      borderRadius: pxToRem(4),
    },
    'input:not(:focus):placeholder-shown + fieldset': {
      border: 'none',
    },
    ...numberTextFieldStyles,
  },
}));
