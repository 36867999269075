import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  issue_date: Yup.string()
    .typeError('msg_error_required_field')
    .required('msg_error_required_field'),
  receipt_number: Yup.string().nullable(),
  description: Yup.string().nullable(),
  category: Yup.string().when('description', {
    is: (description: string | undefined) => description && description.length > 0,
    then: Yup.string().required(''),
  }),
  amount: Yup.string().required(''),
});
