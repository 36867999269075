import { useMutation } from '@tanstack/react-query';

import { useSuggestedInvoiceItemsQueryKey } from '~/models/InvoiceItem/hooks';
import { AmplitudeEvent } from '~/types/amplitude';
import { InvoiceItemTemplate } from '~/types/invoiceItemTemplate';
import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';
import { sendAmplitudeData } from '~/utils/amplitude';

import { model } from '../';

import { invoiceItemTemplatesQueryKey } from './useInvoiceItemTemplates';

export const useCreateInvoiceItemTemplate = ({ onSuccess }: MutationProps<InvoiceItemTemplate>) =>
  useMutation(model.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([invoiceItemTemplatesQueryKey]);
      queryClient.invalidateQueries(useSuggestedInvoiceItemsQueryKey);
      sendAmplitudeData(AmplitudeEvent.ServiceCreated, {
        name: data.default_item_name,
        id: data.id,
      });
      onSuccess?.(data);
    },
  });
