import { FieldInputProps } from 'formik/dist/types';

import { useAppConfig } from '~/context/AppConfigContext';
import { Language } from '~/types/appConfig';

import { Select } from './Select';

interface LanguageSelectProps {
  label: string;
  field: FieldInputProps<string>;
  languageItems?: Language[];
}

export const LanguageSelect = ({ label, field, languageItems }: LanguageSelectProps) => {
  const config = useAppConfig();

  const items = languageItems
    ? languageItems.map((language) => ({
        value: language.code,
        label: language.name,
      }))
    : config.enabledLanguages.map((language) => ({
        value: language.code,
        label: language.name,
      }));

  return <Select items={items} label={label} {...field} />;
};
