import { Field, FieldProps, useField } from 'formik';

import { Select } from '~/components/Select/Select';

type Item = {
  value: string;
  label: string;
};

type FormikSelectProps = {
  name: string;
  label: string;
  items: Item[];
  alternativeStyle?: boolean;
};

export const FormikSelect = ({ name, label, items, alternativeStyle }: FormikSelectProps) => {
  const [field] = useField({ id: name, name });

  return (
    <Field {...field}>
      {(props: FieldProps<string>) => (
        <Select items={items} label={label} {...props.field} alternativeStyle={alternativeStyle} />
      )}
    </Field>
  );
};
