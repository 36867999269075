import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import {
  DateRangePicker as MuiDateRangePicker,
  DateRange as MuiDateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { DateRange } from '~/types/date';

import { DateRangePickerWrapper } from './DateRangePicker.style';

type Props = Omit<TextFieldProps, 'onChange'> & {
  hasClearButton?: boolean;
  onChange?: (dateRange: DateRange) => void;
  defaultValue?: DateRange;
};

export const DateRangePicker = ({
  hasClearButton = true,
  onChange,
  defaultValue,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const getDefaultValue = () => {
    if (!defaultValue) return [null, null] as MuiDateRange<DateTime>;

    const [start, end] = defaultValue;

    return [
      start ? DateTime.fromISO(start) : null,
      end ? DateTime.fromISO(end) : null,
    ] as MuiDateRange<DateTime>;
  };

  const [value, setValue] = useState<MuiDateRange<DateTime>>(getDefaultValue);

  const clearValue = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setValue([null, null]);

    if (onChange) {
      onChange([undefined, undefined]);
    }
  };

  const toggleCalendar = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const handleDateChange = (newValue: MuiDateRange<DateTime>) => {
    setValue(newValue);

    if (onChange) {
      const [start, end] = newValue;
      onChange([start?.toFormat('yyyy-LL-dd'), end?.toFormat('yyyy-LL-dd')]);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18next.language}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <DateRangePickerWrapper open={open} onClick={() => setOpen(true)}>
          <MuiDateRangePicker
            open={open}
            calendars={1}
            value={value}
            inputFormat="yyyy.MM.dd"
            onChange={handleDateChange}
            renderInput={(startProps, endProps) => (
              <>
                <Stack direction="row" alignItems="center" sx={{ width: 195, height: 56 }}>
                  {(value[0] || !value[1]) && (
                    <TextField
                      {...startProps}
                      {...rest}
                      inputProps={{
                        ...startProps.inputProps,
                        placeholder: t('msg_label_invoice_date'),
                      }}
                      label={null}
                      hiddenLabel
                    />
                  )}

                  {value.every(Boolean) && <Box>-</Box>}

                  {value[1] && <TextField {...endProps} {...rest} label={null} hiddenLabel />}
                </Stack>

                <Box>
                  {value.some(Boolean) && hasClearButton ? (
                    <IconButton aria-label={t('btn_clear')} onClick={clearValue}>
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton aria-label={t('btn_select')} onClick={toggleCalendar}>
                      <CalendarMonthIcon />
                    </IconButton>
                  )}
                </Box>
              </>
            )}
          />
        </DateRangePickerWrapper>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};
