import { FormikHelpers } from 'formik';

import { useCreateMerchant } from '~/models/Merchant/hooks';
import { ValidationError } from '~/types/error';
import { Merchant, MerchantFormFields } from '~/types/merchant';

import { initialEmptyValues } from './config';
import { MerchantFormDialog } from './MerchantFormDialog';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess?: (merchant: Merchant) => void;
};

export const CreateMerchantFormDialog = ({ open, onClose, onSuccess }: Props) => {
  const { mutateAsync, isLoading } = useCreateMerchant({
    onSuccess: (merchant) => {
      if (merchant && onSuccess) {
        onSuccess(merchant);
      }
      onClose();
    },
  });

  const onSubmit = async (
    values: MerchantFormFields,
    { setErrors, resetForm }: FormikHelpers<MerchantFormFields>,
  ) => {
    try {
      await mutateAsync(values);
      resetForm();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
      }
    }
  };

  return (
    <MerchantFormDialog
      title="msg_add_new_merchant_title"
      open={open}
      initialValues={initialEmptyValues}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};
