import { TableRow as MuiTableRow, TableCell } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormattedFormikTextField, FormikTableCellTextField } from '~/components/Form/TextField';
import { useAuth } from '~/context/AuthContext';

export const TableRow = () => {
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [notesField] = useField('notes');
  const [amountField] = useField('amount');

  return (
    <MuiTableRow>
      <TableCell padding="none">
        <FormikTableCellTextField
          variant="filled"
          name={notesField.name}
          placeholder={t('msg_comment')}
          hiddenLabel
          fullWidth
        />
      </TableCell>
      <TableCell padding="none">
        <FormattedFormikTextField
          variant="filled"
          name={amountField.name}
          InputProps={{
            endAdornment: base_currency.symbol_native,
          }}
          placeholder="0,00"
          hiddenLabel
          square={true}
          fullWidth
        />
      </TableCell>
    </MuiTableRow>
  );
};
