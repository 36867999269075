import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { ChangeEvent, FocusEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { numberTextFieldStyles, TableCellTextField } from './TextField.style';

export type Props = TextFieldProps & {
  name: string;
};

export type FormatedTextFieldProps = Props & {
  square?: boolean;
  max?: number;
};

export const FormikTextField = ({ name, ...props }: Props) => {
  const { t } = useTranslation();
  const [field, meta] = useField({ id: name, name });

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      id={name}
      error={meta.touched && meta.error !== undefined}
      helperText={(meta.touched && t(meta.error as string)) || props.helperText}
      sx={{ ...props.sx, ...(props.type === 'number' ? numberTextFieldStyles : {}) }}
      {...field}
      {...props}
      type={props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type}
      {...(props.type === 'password'
        ? {
            InputProps: {
              endAdornment:
                props.type === 'password' ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : undefined,
            },
          }
        : {})}
    />
  );
};

export const FormattedTextField = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  square = false,
  max,
  ...props
}: FormatedTextFieldProps) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^\d,-]/g, '');

    if (value.length) {
      if (value.startsWith(',')) {
        value = `0${value}`;
      }

      if (value.includes(',')) {
        const [integer, decimal] = value.split(',');

        if (!value.endsWith(',') && decimal.length > 2) {
          value = `${integer},${decimal.slice(0, 2)}`;
        }
      }
    }

    onChangeProp?.({
      ...event,
      target: {
        ...event.target,
        value,
      },
    });
  };

  const onBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    let { value } = event.target;

    if (value.length) {
      if (value.endsWith(',')) {
        value = `${value}00`;
      }

      if (value.length && value.startsWith(',')) {
        value = `0${value}`;
      }

      const [integer, decimal] = value.split(',');
      const float = parseFloat(`${integer}.${decimal}`);
      value = (max ? Math.min(float, max) : float).toFixed(2).replace('.', ',');
    }

    onChangeProp?.({
      ...event,
      target: {
        ...event.target,
        value,
      },
    });

    onBlurProp?.({
      ...event,
      target: {
        ...event.target,
        value,
      },
    });
  };

  return (
    <TableCellTextField
      id={name}
      sx={{ ...props.sx, ...numberTextFieldStyles }}
      type="tel"
      {...props}
      onChange={onChange}
      onBlur={onBlur}
      square={square}
    />
  );
};

export const FormattedFormikTextField = ({
  name,
  onChange: onChangeProp,
  onBlur: onBlurProp,
  square = false,
  max,
  ...props
}: FormatedTextFieldProps) => {
  const { t } = useTranslation();
  const [field, meta] = useField({ id: name, name });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.replace(/[^\d,.-]/g, '');

    if (value.length) {
      if (value.startsWith(',') || value.startsWith('.')) {
        value = `0${value}`;
      }

      if (value.includes(',')) {
        const [integer, decimal] = value.split(',');

        if (!value.endsWith(',') && decimal.length > 2) {
          value = `${integer},${decimal.slice(0, 2)}`;
        }
      }
    }

    onChangeProp?.({
      ...event,
      target: {
        ...event.target,
        value,
      },
    });

    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
  };

  const onBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    let { value } = event.target;

    if (value.length) {
      if (value.endsWith(',')) {
        value = `${value}00`;
      }

      if (value.length && value.startsWith(',')) {
        value = `0${value}`;
      }

      const [integer, decimal] = value.split(',');
      const float = parseFloat(`${integer}.${decimal}`);
      value = (max ? Math.min(float, max) : float).toFixed(2).replace('.', ',');
    }

    onBlurProp?.({
      ...event,
      target: {
        ...event.target,
        value,
      },
    });

    field.onChange({
      target: {
        name: field.name,
        value,
      },
    });
  };

  return (
    <TableCellTextField
      id={name}
      error={meta.touched && meta.error !== undefined}
      helperText={(meta.touched && t(meta.error as string)) || props.helperText}
      sx={{ ...props.sx, ...numberTextFieldStyles }}
      type="tel" // TODO: maybe find out why
      {...field}
      {...props}
      onChange={onChange}
      onBlur={onBlur}
      square={square}
    />
  );
};
