import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { LanguageCodes } from '~/types/appConfig';
import { Color } from '~/types/color';
import { InvoiceStatus } from '~/types/invoice';

import { LabelChip } from '../Chip';

type Props = {
  status: InvoiceStatus;
  tLanguage?: LanguageCodes;
};

export const InvoiceStatusLabel = ({ status, tLanguage }: Props) => {
  const { t } = useTranslation();
  const translate = tLanguage ? i18next.getFixedT(tLanguage) : t;

  let label = status.toString();
  let color: Color = Color.Default;

  switch (status) {
    case InvoiceStatus.Draft:
      label = 'msg_invoice_status_draft';
      break;
    case InvoiceStatus.Paid:
      label = 'msg_invoice_status_paid';
      color = Color.Primary;
      break;
    case InvoiceStatus.Unpaid:
      label = 'msg_invoice_status_unpaid';
      color = Color.Info;
      break;
    case InvoiceStatus.Overdue:
      label = 'msg_invoice_status_overdue';
      color = Color.Error;
      break;
    default:
      return <></>;
  }

  return <LabelChip color={color} label={translate(label)} />;
};
