import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Stack, Box, Typography, IconButton } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { text } from '~/theme/colors';
import { Operation } from '~/types/invoice';
import { formatDateShort } from '~/utils/formatDate';
import { formatCurrency } from '~/utils/formatNumber';

export const LinkedIncomeRow = ({
  item,
  currency_symbol,
  onDelete,
  onNavigate,
}: {
  item: Operation;
  currency_symbol: string;
  onDelete: () => void;
  onNavigate: () => void;
}) => {
  const { t } = useTranslation();
  const [showRedDeleteButton, setShowRedDeleteButton] = useState(false);
  return (
    <div
      onMouseEnter={() => setShowRedDeleteButton(true)}
      onMouseLeave={() => setShowRedDeleteButton(false)}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        gap={1}
        paddingBottom={1}
        alignItems="center"
      >
        <Box
          onClick={onNavigate}
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
        >
          <Typography fontSize={14} fontWeight="400" color={text.primary}>
            {item.title || t('msg_linked_income_placeholder')}
          </Typography>
          <Stack>
            <Typography fontSize={14} fontWeight="600" color={text.primary} textAlign="right">
              {formatCurrency(item.amount, true, currency_symbol)}
            </Typography>
            <Typography fontSize={14} fontWeight="400" color={text.primary}>
              {formatDateShort(item.issue_date)}
            </Typography>
          </Stack>
        </Box>
        <Stack>
          <IconButton onClick={onDelete} color={showRedDeleteButton ? 'error' : 'default'}>
            <DeleteOutlineOutlinedIcon sx={{ width: 22, height: 22 }} />
          </IconButton>
        </Stack>
      </Stack>
    </div>
  );
};
