import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Checkbox, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { toast } from 'material-react-toastify';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikRichTextEditor } from '~/components/Form/RichTextEditor';
import { FormikIOSSwitch } from '~/components/Form/Switch/FormikIOSSwitch';
import { FormikTextField } from '~/components/Form/TextField';
import { model as companyModel } from '~/models/Company';
import { model } from '~/models/Invoices';
import { useSaveAsPdf } from '~/models/Invoices/hooks';
import { useCurrentUser } from '~/models/User/hooks/useCurrentUser';
import { text } from '~/theme/colors';
import { FormikSetErrorsFn } from '~/types/formik';
import { SendInvoiceFormFields } from '~/types/invoice';
import { openInNewTab } from '~/utils/openInNewTab';

import { ConfirmationDialog } from '../ConfirmationDialog';
import { GenericDialog } from '../GenericDialog';

import { validationSchema } from './validationSchema';

export type Props = {
  open: boolean;
  invoiceId: string;
  customerId?: number | null;
  onSubmit: (
    values: SendInvoiceFormFields,
    setErrors: FormikSetErrorsFn<SendInvoiceFormFields>,
  ) => Promise<void>;
  onClose: () => void;
  pdf?: string;
};

export enum SendInvoiceFormField {
  recipient_email = 'recipient_email',
  content = 'content',
  soft_collection = 'soft_collection',
  copy_for_sender = 'copy_for_sender',
}

export const SendInvoiceDialog = ({ open, invoiceId, onSubmit, onClose, pdf }: Props) => {
  const saveAsPdf = useSaveAsPdf(invoiceId);
  const [isLoading, setIsLoading] = useState(true);
  const [customerEmail, setCustomerEmail] = useState('');
  const [softCollection, setSoftCollection] = useState(false);
  const [copyForSender, setCopyForSender] = useState(false);
  const [invoiceNoteDialogOpen, setInvoiceNoteDialogOpen] = useState(false);
  const { data: user } = useCurrentUser();
  const [newInvoiceNote, setNewInvoiceNote] = useState('');
  const { t } = useTranslation();

  const shouldOpenSaveInvoiceNoteDialog =
    newInvoiceNote.trim() !== user?.active_company?.default_invoice_note;

  useEffect(() => {
    user && setNewInvoiceNote(user?.active_company?.default_invoice_note);
  }, [user]);

  const fetchInitialData = async () => {
    if (!open) return;

    setIsLoading(true);

    try {
      const invoice = await model.get(invoiceId);
      setCustomerEmail(invoice.customer_email);
      // invoice.
      setSoftCollection(
        invoice.is_sent ? invoice.soft_collection : !!user?.active_company.soft_collection,
      );
    } catch (error) {
      toast.error(t('msg_label_error_modal_title'));
    }

    setIsLoading(false);
  };

  const onPreviewPdf = async () => {
    if (!pdf) {
      await saveAsPdf();
    } else {
      openInNewTab(pdf);
    }
  };

  const initialValues: SendInvoiceFormFields = useMemo(
    () => ({
      [SendInvoiceFormField.recipient_email]: customerEmail,
      [SendInvoiceFormField.copy_for_sender]: false,
      [SendInvoiceFormField.content]: user?.active_company?.default_invoice_note || '',
      [SendInvoiceFormField.soft_collection]: softCollection,
    }),
    [customerEmail, user],
  );

  useEffect(() => {
    fetchInitialData();
  }, [open]);

  const handleSaveNewInvoiceNote = (submitForm: () => Promise<void>) => {
    user && companyModel.patch(user?.active_company?.id, { default_invoice_note: newInvoiceNote });
    submitForm();
  };

  const submitFormAndCloseInvoiceNoteDialog = (submitForm: () => Promise<void>) => {
    setInvoiceNoteDialogOpen(false);
    submitForm();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) =>
        onSubmit({ ...values, copy_for_sender: copyForSender }, helpers.setErrors)
      }
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, resetForm }) => {
        return (
          <>
            <GenericDialog
              title={t('msg_label_email_header')}
              open={open}
              closeBtnLabel={t('msg_btn_cancel')}
              onClose={() => {
                onClose();
                resetForm();
              }}
              submitBtnLabel={t('msg_btn_send_invoice')}
              submitBtnIcon={<SendIcon />}
              onSubmit={
                shouldOpenSaveInvoiceNoteDialog
                  ? () => setInvoiceNoteDialogOpen(true)
                  : () => submitForm()
              }
              isLoading={isSubmitting}
            >
              <Button
                variant="square"
                startIcon={<VisibilityIcon />}
                onClick={onPreviewPdf}
                sx={{ px: 1 }}
              >
                {t('msg_btn_preview_invoice')}
              </Button>

              {isLoading && (
                <Stack gap={1} mt={2} height={238} alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Stack>
              )}

              {!isLoading && (
                <Stack gap={1} mt={2}>
                  <TextField
                    name="name"
                    label={t('msg_label_from')}
                    variant="filled"
                    color="grey"
                    value={user?.email}
                    fullWidth
                    disabled
                  />
                  <FormikTextField
                    name={SendInvoiceFormField.recipient_email}
                    label={t('msg_label_invoice_email_to')}
                    variant="filled"
                    color="grey"
                    autoFocus={!customerEmail}
                    fullWidth
                  />
                  <FormikRichTextEditor
                    name={SendInvoiceFormField.content}
                    placeholder={t('msg_label_invoice_email_message')}
                    minRows={3}
                    initialContent={user?.active_company?.default_invoice_note}
                    autoFocus={!!customerEmail}
                    onChange={(val) => setNewInvoiceNote(val)}
                  />
                  <FormikIOSSwitch
                    name={SendInvoiceFormField.soft_collection}
                    label={t('msg_label_invoice_soft_collection')}
                    sxStyle={{ m: 2 }}
                    labelPlacement="start"
                  />
                  <Stack
                    width="100%"
                    alignItems="center"
                    pl={1}
                    pr={2}
                    justifyContent="space-between"
                    flexDirection="row"
                  >
                    <Stack flexDirection="row" gap={1} alignItems="center">
                      <Checkbox
                        name={SendInvoiceFormField.copy_for_sender}
                        color="primary"
                        onChange={(e) => setCopyForSender(e.target.checked)}
                      />
                      <Typography>{t('copy_for_me')}</Typography>
                    </Stack>

                    <Typography variant="body2" color={text.disabled}>
                      {user?.active_company?.email}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </GenericDialog>
            <ConfirmationDialog
              open={invoiceNoteDialogOpen}
              title={t('msg_save_invoice_note_action_sheet_title')}
              onClose={() => setInvoiceNoteDialogOpen(false)}
              actions={[
                {
                  label: t('msg_save_invoice_note_action_sheet_yes'),
                  color: 'error',
                  onClick: () => handleSaveNewInvoiceNote(submitForm),
                  showIsLoading: true,
                },
                {
                  label: t('msg_save_invoice_note_action_sheet_no'),
                  onClick: () => submitFormAndCloseInvoiceNoteDialog(submitForm),
                  color: 'inherit',
                },
              ]}
              isLoading={isLoading}
            />
          </>
        );
      }}
    </Formik>
  );
};
