import { useMutation } from '@tanstack/react-query';

import { useSuggestedInvoiceItemsQueryKey } from '~/models/InvoiceItem/hooks';
import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '../';

import { invoiceItemTemplatesQueryKey } from './useInvoiceItemTemplates';

export const useDeleteInvoiceItemTemplate = ({ onSuccess }: MutationProps) =>
  useMutation(model.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries([invoiceItemTemplatesQueryKey]);
      queryClient.invalidateQueries(useSuggestedInvoiceItemsQueryKey);
      onSuccess?.();
    },
  });
