import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TableCell, Avatar, Typography, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditCustomerFormDialog } from '~/components/Dialog/CustomerFormDialog';
import { DeleteCustomerDialog } from '~/components/Dialog/DeleteCustomerDialog';
import { MenuItem, TableActionsMenu } from '~/components/Menu/TableActionsMenu';
import { ClickableTableRow } from '~/components/Table';
import { useAuth } from '~/context/AuthContext';
import { Color } from '~/types/color';
import { Customer } from '~/types/customer';
import { formatCurrency } from '~/utils/formatNumber';

type Props = {
  row: Customer;
};

export const TableRow = ({ row }: Props) => {
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const { id, name, company_number, invoice_count, amount_due, can_be_deleted } = row;

  const onEdit = () => setShowEditDialog(true);

  const menuItems: MenuItem[] = [
    {
      label: t('msg_btn_edit_customer'),
      icon: <EditIcon />,
      onClick: onEdit,
    },
    ...(can_be_deleted
      ? [
          {
            label: t('msg_btn_delete'),
            icon: <DeleteIcon color="error" />,
            onClick: () => setShowDeleteDialog(true),
            color: Color.Error,
          },
        ]
      : []),
  ];

  return (
    <>
      <ClickableTableRow hover>
        <TableCell onClick={onEdit}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Avatar
              sx={{ width: 48, height: 48, backgroundColor: 'grey.200', color: 'text.primary' }}
            >
              <Typography variant="h6">{name.slice(0, 2).toUpperCase()}</Typography>
            </Avatar>
            {name}
          </Stack>
        </TableCell>
        <TableCell onClick={onEdit}>{company_number}</TableCell>
        <TableCell onClick={onEdit}>{invoice_count}</TableCell>
        <TableCell onClick={onEdit}>
          {!amount_due ? '-' : formatCurrency(amount_due, true, base_currency.symbol_native)}
        </TableCell>
        <TableCell padding="checkbox">
          <TableActionsMenu menuItems={menuItems} />
        </TableCell>
      </ClickableTableRow>

      <EditCustomerFormDialog
        id={id}
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
      />

      <DeleteCustomerDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
