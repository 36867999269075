import { TableCell, TableHead as MuiTableHead, TableRow, Typography } from '@mui/material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { pickFontSize } from '~/pages/Invoices/InvoicePlatform';
import { LanguageCodes } from '~/types/appConfig';
import { HeadCell } from '~/types/table';

type Props<T> = {
  headCells: HeadCell<T>[];
  isMobile?: boolean;
  tLanguage?: LanguageCodes;
};

export const TableHead = <T,>({ headCells, isMobile, tLanguage }: Props<T>) => {
  const { t } = useTranslation();
  const translate = tLanguage ? i18next.getFixedT(tLanguage) : t;

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={String(headCell.id)}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{
              width: headCell.width,
              ...(index === 2 && isMobile && { p: 1 }),
            }}
          >
            <Typography fontWeight={600} fontSize={pickFontSize(isMobile)}>
              {translate(headCell.label)}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};
