import { useEffect, useMemo, useState } from 'react';

import { SortDirection } from '~/types/table';

export const useTableSorting = <T extends Record<string, unknown>>(
  initialSort?: keyof T | string,
  initialSortDirection?: SortDirection,
) => {
  const [ordering, setOrdering] = useState<string | undefined>(initialSort?.toString());
  const [currentSort, setCurrentSort] = useState<keyof T | undefined>(initialSort);
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialSortDirection,
  );

  useEffect(() => {
    setOrdering(`${sortDirection == SortDirection.Desc ? '-' : ''}${currentSort?.toString()}`);
  }, [currentSort, sortDirection]);

  const onSort = (sort: keyof T | string) => {
    if (sort === currentSort) {
      setSortDirection((prev) =>
        prev === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc,
      );
    } else {
      setCurrentSort(sort);
      setSortDirection(SortDirection.Asc);
    }
  };

  return useMemo(
    () => ({
      ordering,
      currentSort,
      sortDirection,
      onSort,
    }),
    [currentSort, sortDirection, onSort],
  );
};
