import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { RegistrationStepperProvider } from '~/context/RegistrationStepperContext';

import { RegistrationAppBar } from './RegistrationAppBar';

export const RegisterLayout = () => (
  <RegistrationStepperProvider>
    <RegistrationAppBar />
    <Container maxWidth="sm">
      <Outlet />
    </Container>
  </RegistrationStepperProvider>
);
