import { alpha, styled, TableRow as MuiTableRow } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  zIndex: 2,
  background: theme.palette.background.paper,

  '& .MuiTableCell-root': {
    background: alpha(theme.palette.primary.main, 0.08),
    height: pxToRem(58),
  },
}));
