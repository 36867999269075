import { Avatar } from '@mui/material';
import Flag from 'react-flagkit';

import { FlagContainer } from './FlagIcon.style';

type Props = {
  country: string;
};

export const FlagIcon = ({ country }: Props) => (
  <Avatar sx={{ width: 21, height: 21 }}>
    <FlagContainer>
      <Flag country={country} />
    </FlagContainer>
  </Avatar>
);
