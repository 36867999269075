import { styled, Popper as MuiPopper } from '@mui/material';

export const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number }>(({ theme, width = 360 }) => ({
  minWidth: 360,
  width: width,
  boxShadow: theme.shadows[20],
  zIndex: theme.zIndex.modal,
}));
