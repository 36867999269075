import { Invoice, InvoiceStatus, PaymentStatus } from '~/types/invoice';

export const getInvoiceStatus = (invoice: Invoice): InvoiceStatus => {
  if (invoice.is_draft) {
    return InvoiceStatus.Draft;
  } else if (invoice.is_payment_late) {
    return InvoiceStatus.Overdue;
  } else if (invoice.payment_status === PaymentStatus.Paid) {
    return InvoiceStatus.Paid;
  }

  return InvoiceStatus.Unpaid;
};
