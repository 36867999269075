import { Delete } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, FormControl, FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { SelectedFile } from '~/types/file';
import { openInNewTab } from '~/utils/openInNewTab';

import { FilePickerWrapper, FilesList, FilesListItem } from './FilePicker.style';

export type Props = {
  name: string;
  accept?: Accept;
};

export const FilePicker = ({ name, accept }: Props) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ id: name, name });
  const [files, setFiles] = useState<SelectedFile[]>(field.value || []);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = [...files, ...acceptedFiles.map((file) => ({ file, name: file.name }))];
      setFiles(newFiles);
      helpers.setValue(newFiles);
    },
    [files],
  );

  const onRemove = useCallback(
    (event: React.MouseEvent, index: number) => {
      event.stopPropagation();

      let newFiles = [];
      if (files[index].id) {
        newFiles = files;
        newFiles[index].isDeleted = !newFiles[index].isDeleted;
      } else {
        newFiles = files.filter((_, i) => i !== index);
      }

      setFiles(newFiles);
      helpers.setValue(newFiles);
    },
    [files],
  );

  const onClick = (file: File | string) => {
    if (typeof file === 'string') {
      openInNewTab(file);
    } else {
      openInNewTab(URL.createObjectURL(file));
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });

  return (
    <FormControl sx={{ width: 1 }}>
      <Stack
        direction={{
          xs: 'column',
          lg: 'row',
        }}
        spacing={3}
      >
        <FilePickerWrapper isDragActive={isDragActive} {...getRootProps()}>
          <input {...getInputProps()} />
          <UploadIcon fontSize="large" />
          {isDragActive ? (
            <Typography variant="body2">{t('msg_label_drop_files_here')}</Typography>
          ) : (
            <Typography variant="body2">
              {t('msg_label_drag_drop_files')}
              <br />
              {t('msg_label_pick_files')}
            </Typography>
          )}
        </FilePickerWrapper>
        {field.value && !!field.value.length && (
          <FilesList>
            {field.value.map((selectedFile: SelectedFile, index: number) => (
              <FilesListItem
                key={`${selectedFile.name}:${index}`}
                deleted={selectedFile.isDeleted}
                onClick={() => onClick(selectedFile.file)}
              >
                <Typography variant="body2">{selectedFile.name}</Typography>
                <Box>
                  <IconButton
                    size="small"
                    onClick={(event) => onRemove(event, index)}
                    color={selectedFile.isDeleted ? 'error' : undefined}
                    aria-label={t('msg_label_remove_file')}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </FilesListItem>
            ))}
          </FilesList>
        )}
      </Stack>
      {meta.touched && meta.error && (
        <FormHelperText error={meta.touched && meta.error !== undefined}>
          {t(meta.error as string)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
