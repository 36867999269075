export type Translations = Map<string, string>;
export type Agreements = Map<string, string>;

export type EnabledLanguage = {
  code: LanguageCodes;
  flag: string;
  name: string;
  is_default?: boolean;
};

export type Currency = {
  code: string;
  name: string;
  symbol: string;
};

export enum ExpenseCategory {
  General = 'GENERAL',
  Representational = 'REPRESENTATIONAL',
}
export interface EnabledCountry {
  code: string;
  default_currency: string;
  name: string;
  support_link: SupportLink;
}

export interface SupportLink {
  messenger_id: number;
  mob_link: string;
}

export interface AppConfig {
  version: string;
  defaultSupportLink: SupportLink;
  enabledCountries: EnabledCountry[];
  enabledLanguages: EnabledLanguage[];
  defaultLanguage: string;
  translations: Map<string, Translations>;
  expenseCategories: ExpenseCategory[];
  companyCategories: string[];
  companySubCategories: unknown;
  enabledCompanyCategories: string[];
  currencies: Currency[];
  agreement: Agreements;
}

export interface UserAppConfig {
  defaultLanguage: string;
}

export interface InitAppConfigPayload {
  appConfig: AppConfig;
  userConfig: UserAppConfig | null;
  userToken: string | null;
  secureData?: SecureLocalData;
}

export interface SecureLocalData {
  biometry: boolean;
}

export interface RegisterDevicePayload {
  registration_id: string;
  name: string;
}

export interface ChangeLanguagePayload {
  language: LanguageCodes;
}

export interface ChangeLanguageResult {
  language: LanguageCodes;
}

export enum LanguageCodes {
  lt = 'lt',
  en = 'en',
  ru = 'ru',
  es = 'es',
  fi = 'fi',
  lv = 'lv',
  at = 'at',
  be = 'be',
  dk = 'dk',
  cz = 'cz',
  de = 'de',
  fr = 'fr',
  no = 'no',
  nl = 'nl',
  gr = 'gr',
  hu = 'hu',
  ie = 'ie',
  it = 'it',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  se = 'se',
  si = 'si',
  ch = 'ch',
  us = 'us',
}

export interface Language {
  code: LanguageCodes;
  flag?: string;
  name: string;
}
