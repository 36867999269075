import { Box, Container, Drawer as MUIDrawer } from '@mui/material';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AIAgentChat } from '~/components/AIAgentChat/AIAgentChat';
import { shouldReinitializeSession } from '~/components/AIAgentChat/atoms/aiAgentChatAtom';
// import { SupportChat } from '~/components/SupportChat';
// import { useAppConfig } from '~/context/AppConfigContext';
// import { useAuth } from '~/context/AuthContext';
import { useResponsive } from '~/hooks/useResponsive';
// import { shouldHideSupportChat } from '~/utils/shouldHideSupportChat';

// Temporarily hiding messenger support chat

import { Main } from './DashboardLayout.style';
import { Drawer } from './Drawer';

export const DashboardLayout = () => {
  const isTablet = useResponsive('down', 'lg');

  // const { defaultSupportLink, enabledCountries } = useAppConfig();
  // const { user } = useAuth();
  const [_, setReinitializeSession] = useAtom(shouldReinitializeSession);

  // const supportChatMessengerId =
  //   enabledCountries?.find((country) => country.code === user.country)?.support_link.messenger_id ??
  //   defaultSupportLink.messenger_id;

  const [open, setOpen] = useState(false);
  const [aiChatDrawerIsOpen, setAiChatDrawerIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());

  const toggleDrawer = () => setOpen(!open);

  // listener for updating the ai agent chat session if the date changes
  useEffect(() => {
    const updateDateIfNeeded = () => {
      const now = new Date().toLocaleDateString();
      if (currentDate !== now) {
        setCurrentDate(now);
        setReinitializeSession(true);
      }
    };
    const intervalId = setInterval(updateDateIfNeeded, 60000);
    return () => clearInterval(intervalId);
  }, [currentDate]);

  return (
    <>
      <Drawer open={open} onToggle={toggleDrawer} onClose={() => setOpen(false)} />
      {/* Need this check here to avoid unnecessary AIAgentChat render and its keyboard listener being active in the whole app */}
      {aiChatDrawerIsOpen ? (
        <MUIDrawer
          anchor="right"
          open={aiChatDrawerIsOpen}
          onClose={() => setAiChatDrawerIsOpen(false)}
        >
          <AIAgentChat onClose={() => setAiChatDrawerIsOpen(false)} />
        </MUIDrawer>
      ) : null}
      <Main open={open} isTablet={isTablet}>
        <Container
          maxWidth={false}
          sx={{
            '&&': {
              p: 0,
            },
          }}
        >
          <Outlet />
        </Container>
        <Box
          component="div"
          onClick={() => setAiChatDrawerIsOpen(true)}
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 50,
            cursor: 'pointer',
          }}
        >
          <img src="/assets/images/AgentAIButton.svg" />
        </Box>
      </Main>
      {/* {!shouldHideSupportChat && (
        <SupportChat
          supportChatMessengerId={supportChatMessengerId}
          welcomeTitle={t('msg_messenger_welcome_title')}
        />
      )} */}
    </>
  );
};
