import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActionsButton } from '~/components/Button/ActionsButton';
import { Color } from '~/types/color';

export type MenuItem = {
  label: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  linkTo?: string;
  color?: Color;
  disabled?: boolean;
};

type Props = {
  menuItems: MenuItem[];
};

export const TableActionsMenu = ({ menuItems }: Props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <ActionsButton
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map(({ label, onClick, linkTo, icon, color, disabled }) => (
          <MenuItem
            key={label}
            onClick={() => {
              if (disabled) return;

              onClick?.();
              linkTo && navigate(linkTo);
              handleClose();
            }}
            sx={{ color: `${color}.main` }}
            disabled={disabled}
          >
            {icon && <ListItemIcon color={`${color}.main`}>{icon}</ListItemIcon>}
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
