import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupsIcon from '@mui/icons-material/Groups';
import ListIcon from '@mui/icons-material/List';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { Expenses } from '~/components/Icon/Expenses';
import { Income } from '~/components/Icon/Income';
import { ROUTES } from '~/router/Routes';

export const config = {
  drawerWidth: 260,
  menuItems: [
    {
      label: 'msg_invoices_title',
      icon: <ReceiptIcon />,
      link: ROUTES.DASHBOARD_INVOICES,
    },
    {
      label: 'msg_customers_title',
      icon: <GroupsIcon />,
      link: ROUTES.DASHBOARD_CUSTOMERS,
    },
    {
      label: 'msg_services_title',
      icon: <ListIcon />,
      link: ROUTES.DASHBOARD_SERVICES,
    },
    {
      label: 'msg_income_title',
      icon: <Income />,
      link: ROUTES.DASHBOARD_INCOME,
    },
    {
      label: 'msg_expense_screen_title',
      icon: <Expenses />,
      link: ROUTES.DASHBOARD_EXPENSES,
    },
    {
      label: 'msg_bank_title',
      icon: <AccountBalanceIcon />,
      link: ROUTES.DASHBOARD_BANK,
    },
  ],
  subMenuItems: [
    {
      label: 'msg_label_profile',
      icon: <ManageAccountsIcon />,
      link: ROUTES.DASHBOARD_SETTINGS_PROFILE,
    },
    {
      label: 'msg_label_business_details',
      icon: <AccountBalanceIcon />,
      link: ROUTES.DASHBOARD_SETTINGS_BUSINESS,
    },
    {
      label: 'msg_label_account_my_automation',
      icon: <MenuRoundedIcon />,
      link: ROUTES.DASHBOARD_SETTINGS_MY_AUTOMATION,
    },
  ],
};
