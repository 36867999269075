/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosError } from 'axios';

import { ApiValidationError, NotFoundError, ValidationError } from '~/types/error';

import { axiosInstance } from '.';

const handleError = <T>(error: unknown) => {
  if (error instanceof AxiosError<T>) {
    switch (error.response?.status) {
      case 400:
        throw new ValidationError<T>(error.response?.data as ApiValidationError<T>);
      case 403:
        throw new ValidationError<T>(error.response?.data as ApiValidationError<T>);
      case 404:
        throw new NotFoundError(error.response?.data);
    }
  }

  throw error;
};

export const api = {
  get: async <T>(url: string, params?: any) => {
    let responseData;

    try {
      const response = await axiosInstance.get<T>(url, { params });
      responseData = response.data;
    } catch (error) {
      handleError<T>(error);
    }

    return responseData as T;
  },

  post: async <T>(url: string, data?: any, headers?: any) => {
    let responseData;

    try {
      const response = await axiosInstance.post<T>(url, data, headers);
      responseData = response.data;
    } catch (error) {
      handleError<T>(error);
    }

    return responseData as T;
  },

  put: async <T>(url: string, data: any) => {
    let responseData;

    try {
      const response = await axiosInstance.put<T>(url, data);
      responseData = response.data;
    } catch (error) {
      handleError<T>(error);
    }

    return responseData as T;
  },

  patch: async <T>(url: string, data?: any, config?: any) => {
    let responseData;

    try {
      const response = await axiosInstance.patch<T>(url, data, config);
      responseData = response.data;
    } catch (error) {
      handleError<T>(error);
    }

    return responseData as T;
  },

  delete: async <T>(url: string, params?: any, data?: any) => {
    let responseData;

    try {
      const response = await axiosInstance.delete<T>(url, { params, data });
      responseData = response.data;
    } catch (error) {
      handleError<T>(error);
    }

    return responseData as T;
  },
};
