import { Avatar as MuiAvatar, Box, styled } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: pxToRem(128),
  height: pxToRem(128),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  borderRadius: '50%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
}));

export const EditContainer = styled(ButtonContainer)({
  top: 0,
  right: 0,
  transform: 'translate(50%, -50%)',
});

export const ClearContainer = styled(ButtonContainer)({
  bottom: 0,
  right: 0,
  transform: 'translate(50%, 50%)',
});
