import { Theme } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

import { colors } from '../colors';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
    white: true;
  }

  interface ButtonPropsVariantOverrides {
    square: true;
  }
}

export const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: pxToRem(20),
          boxShadow: theme.shadows[0],
        },
        sizeLarge: {
          borderRadius: pxToRem(24),
          minHeight: pxToRem(48),
        },
      },
      variants: [
        {
          props: { color: 'grey' },
          style: {
            backgroundColor: colors.background.default,
          },
        },
        {
          props: { color: 'white' },
          style: {
            backgroundColor: colors.background.paper,
          },
        },
        {
          props: { variant: 'square' },
          style: {
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'square', color: 'error' },
          style: {
            borderRadius: 0,
            color: theme.palette.error.main,
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: `${pxToRem(4)} !important`,
          textTransform: 'none',
          padding: theme.spacing(1, 2),
        },
      },
    },
  };
};
