import { Theme } from '@mui/material';

import { AppBar } from './AppBar';
import { Badge } from './Badge';
import { Button } from './Button';
import { DatePicker } from './DatePicker';
import { Dialog } from './Dialog';
import { Input } from './Input';
import { Menu } from './Menu';
import { Paper } from './Paper';
import { PickersPopper } from './PickersPopper';
import { StepIcon } from './StepIcon';
import { TableBody } from './TableBody';
import { TableCell } from './TableCell';

const componentsOverride = (theme: Theme) => {
  return Object.assign(
    AppBar(theme),
    Badge(),
    Button(theme),
    DatePicker(theme),
    Dialog(theme),
    Input(theme),
    Menu(theme),
    Paper(theme),
    PickersPopper(theme),
    StepIcon(theme),
    TableBody(),
    TableCell(theme),
  );
};

export default componentsOverride;
