import { ExpenseFormFields } from '~/types/expense';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<ExpenseFormFields>[] = [
  {
    id: 'description',
    label: 'msg_label_expense_description',
    width: '35%',
  },
  {
    id: 'category',
    label: 'msg_label_expense_category',
  },
  {
    id: 'full_amount',
    label: 'msg_expense_full_amount',
    width: '15%',
  },
  {
    id: 'percentage',
    label: 'msg_expense_percentage',
    width: '15%',
  },
  {
    id: 'amount',
    label: 'msg_expense_amount',
    width: '15%',
  },
];
