import { useQuery } from '@tanstack/react-query';

import { Invoice } from '~/types/invoice';

import { model } from '../';

export const invoicePayLinkQueryKey = 'invoicePayLink';

export const useInvoicePayLink = (invoiceId?: Invoice['id']) =>
  useQuery(
    [invoicePayLinkQueryKey, invoiceId],
    () => (invoiceId ? model.getPayLink(invoiceId) : null),
    {
      enabled: !!invoiceId,
    },
  );
