/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  init,
  setDeviceId,
  setUserId,
  Identify,
  identify,
  logEvent,
} from '@amplitude/analytics-browser';
import { BaseEvent } from '@amplitude/analytics-types';

import { APP_VERSION } from '~/config/constants';

export const initAmplitude = () => {
  const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '';
  init(amplitudeApiKey, undefined, {
    appVersion: APP_VERSION,
    minIdLength: 1,
  });
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId: string) => {
  setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: Record<string, any>) => {
  const user = new Identify();

  Object.entries(properties).forEach(([key, value]) => {
    user.set(key, value);
  });

  identify(user);
};

export const sendAmplitudeData = (
  eventType: string | BaseEvent,
  eventProperties?: Record<string, any>,
) => {
  logEvent(eventType, eventProperties);
};
