/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableCell, TableRow } from '@mui/material';

import { HeadCell } from '~/types/table';

type Props = {
  children?: React.ReactNode;
  headCells: HeadCell<any>[];
  hasCheckbox?: boolean;
  rowsCount?: number;
};

export const EmptyTableContent = ({
  children,
  headCells,
  hasCheckbox = false,
  rowsCount = 5,
}: Props) => (
  <TableRow sx={{ height: 53 * rowsCount }}>
    <TableCell colSpan={headCells.length + (hasCheckbox ? 1 : 0)} align="center">
      {children}
    </TableCell>
  </TableRow>
);
