import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { Formik, Form, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { FormikDatePicker } from '~/components/Form/DatePicker';
import { Select } from '~/components/Form/Select';
import { CustomerSelectorBox } from '~/components/Form/SelectorBox/CustomerSelectorBox';
import { useAuth } from '~/context/AuthContext';
import { useNavigateBack } from '~/hooks/useNavigateBack';
import { ROUTES } from '~/router/Routes';
import { FormikSetErrorsFn } from '~/types/formik';
import { IncomeFormFields } from '~/types/income';
import { formatCurrency, formatNumericStringToNumber } from '~/utils/formatNumber';
import { pxToRem } from '~/utils/getFontValue';

import { Header } from '../Header';

import { IncomeItemsTable } from './IncomeItemsTable';
import { TableRow } from './IncomeItemsTable/TableRow';
import { validationSchema } from './validationSchema';

export type Props = {
  initialValues: IncomeFormFields;
  onSave: (
    values: IncomeFormFields,
    setErrors: FormikSetErrorsFn<IncomeFormFields>,
  ) => Promise<boolean>;
};

export const IncomeDataForm = ({ initialValues, onSave }: Props) => {
  const { t } = useTranslation();
  const navigateBack = useNavigateBack();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [dirty, setDirty] = useState(false);
  const [showSaveChangesDialog, setShowSaveChangesDialog] = useState(false);

  const handleSubmit = async (
    values: IncomeFormFields,
    { setErrors }: FormikHelpers<IncomeFormFields>,
  ) => {
    const success = await onSave(values, setErrors);
    if (success) {
      navigateBack(ROUTES.DASHBOARD_INCOME);
    }
  };

  const onClose = () => {
    setShowSaveChangesDialog(false);
    navigateBack(ROUTES.DASHBOARD_INCOME);
  };

  const onGoBack = () => {
    if (dirty) {
      setShowSaveChangesDialog(true);
    } else {
      onClose();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, setErrors, isSubmitting, dirty }) => {
        setDirty(dirty);

        const touchSaveAndClose = async () => {
          const success = await onSave(values, setErrors);
          if (success) {
            onClose();
          }
        };

        return (
          <>
            <Header onGoBack={onGoBack} />

            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <CustomerSelectorBox name="customer" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack gap={1}>
                    <FormikDatePicker
                      name="issue_date"
                      label={t('msg_label_income_date')}
                      variant="filled"
                      fullWidth
                    />

                    <Select
                      name="currency"
                      variant="filled"
                      label={t('msg_label_currency')}
                      value={base_currency.code}
                      fullWidth
                      disabled
                    >
                      <MenuItem value={base_currency.code}>
                        {base_currency.name}, {base_currency.symbol_native}
                      </MenuItem>
                    </Select>
                  </Stack>
                </Grid>
              </Grid>

              <IncomeItemsTable>
                <TableRow />
              </IncomeItemsTable>

              <Stack direction="row" justifyContent="end">
                <Box>
                  <Stack direction="row" justifyContent="space-between" my={4}>
                    <Typography variant="h6">{t('msg_total_price')}</Typography>
                    <Typography variant="h6">
                      {formatCurrency(
                        formatNumericStringToNumber(String(values.amount)),
                        false,
                        base_currency.symbol_native,
                      )}
                    </Typography>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={2}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      sx={{ width: { xs: 1, md: pxToRem(338) } }}
                    >
                      {t('msg_btn_save_and_close_income')}
                    </LoadingButton>
                  </Stack>
                </Box>
              </Stack>
            </Form>

            <ConfirmationDialog
              open={showSaveChangesDialog}
              title={t('msg_save_income_changes_title')}
              onClose={() => setShowSaveChangesDialog(false)}
              actions={[
                {
                  label: t('msg_btn_save_income'),
                  onClick: () => {
                    touchSaveAndClose();
                    setShowSaveChangesDialog(false);
                  },
                  color: 'inherit',
                },
                {
                  label: t('msg_btn_leave_without_saving'),
                  onClick: onClose,
                  color: 'error',
                },
              ]}
            />
          </>
        );
      }}
    </Formik>
  );
};
