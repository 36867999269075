import { Grid, Stack, Typography, Button, InputAdornment, Box, MenuItem } from '@mui/material';
import codes, { ICountryCodeItem } from 'country-calling-code';
import { Formik, FormikProps } from 'formik';
import { toast } from 'material-react-toastify';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect } from '~/components/Form/Select';
import { FormikTextField } from '~/components/Form/TextField';
import { FlagIcon } from '~/components/Icon/FlagIcon';
import { Logo } from '~/components/Logo';
import { LanguageSelector } from '~/components/Selector/LanguageSelector';
import { DEFAULT_COUNTRY_CODE } from '~/config/constants';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useUsersLocationByIp } from '~/hooks/useUsersLocationByIp';
import { useCountriesOfResidence } from '~/models/Auth/hooks/useCountriesOfResidence';
import { model } from '~/models/Invoices';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn } from '~/types/formik';
import { SalesLeadFormFields } from '~/types/invoice';
import { sendAmplitudeData } from '~/utils/amplitude';
import { pxToRem } from '~/utils/getFontValue';

import { validationSchema } from './validationSchema';

export const DeleteUserData: FC = () => {
  const formikRef = useRef<FormikProps<SalesLeadFormFields>>(null);

  const { t } = useTranslation();
  const { location: locationByIp } = useUsersLocationByIp();
  const { data: countriesOfResidence } = useCountriesOfResidence();

  const [showForm, setShowForm] = useState(true);
  const [selectedPhoneCountry, setSelectedPhoneCountry] = useState<ICountryCodeItem | undefined>(
    undefined,
  );
  const [countryOfResidence, setCountryOfResidence] = useState<string | undefined>('');

  useEffect(() => {
    const countryOfResidenceCode = countryOfResidence
      ? codes.find((code) => code.isoCode2 === countryOfResidence)
      : codes.find((code) => code.isoCode2 === locationByIp?.countryCode);

    formikRef?.current?.setFieldValue('countryCode', countryOfResidenceCode?.isoCode2);

    countryOfResidenceCode && setSelectedPhoneCountry(countryOfResidenceCode);
  }, [countryOfResidence, locationByIp]);

  useEffect(() => {
    const setCountryOfResidencePhoneCode = async () => {
      const countryOfResidenceLocalStorageValue = await localStorage.getItem(
        localStorageKeys.countryOfResidence,
      );

      if (countryOfResidenceLocalStorageValue) {
        setCountryOfResidence(countryOfResidenceLocalStorageValue);
      } else if (
        locationByIp &&
        countriesOfResidence?.find((country) => country.code === locationByIp.countryCode)
      ) {
        setCountryOfResidence(locationByIp?.countryCode);
        localStorage.setItem(localStorageKeys.countryOfResidence, locationByIp?.countryCode);
      }
    };
    setCountryOfResidencePhoneCode();
  }, [locationByIp, countriesOfResidence]);

  useEffect(() => {
    handleCountryChange(DEFAULT_COUNTRY_CODE);
  }, []);

  const handleCountryChange = (countryCode: string) => {
    const country = codes.find((item) => item.isoCode2 === countryCode);
    setSelectedPhoneCountry(country);
  };

  const registerUserDataDeletion = async (
    values: SalesLeadFormFields,
    setErrors: FormikSetErrorsFn<SalesLeadFormFields>,
    resetForm: () => void,
  ) => {
    try {
      sendAmplitudeData(AmplitudeEvent.UserDataDeletionRegistered, {
        email: values.email,
        phone: values.phone,
      });
      await model.registerMarketingLead({
        ...values,
        phone: `+${selectedPhoneCountry?.countryCodes[0]}${values.phone}`,
        campaign: 'delete user data',
      });
      toast.success(t('msg_success_user_data_deleted'));
      resetForm();
      handleCountryChange(DEFAULT_COUNTRY_CODE);
      setShowForm(false);
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };
  return (
    <Grid
      container
      component="main"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh' }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          email: '',
          phone: '',
          note: '',
          countryCode: selectedPhoneCountry?.isoCode2 || DEFAULT_COUNTRY_CODE,
        }}
        onSubmit={(values, helpers) =>
          registerUserDataDeletion(values, helpers.setErrors, helpers.resetForm)
        }
        validationSchema={validationSchema}
      >
        {({ submitForm, values, setFieldValue, errors }) => (
          <Stack alignItems="center" sx={{ maxWidth: '480px', paddingX: 2 }}>
            <Stack direction="row" justifyContent="end" marginBottom={3} sx={{ width: 1 }}>
              <LanguageSelector countryCode={countryOfResidence} />
            </Stack>
            <Logo width={142} height={24} />
            <Stack rowGap={4} sx={{ paddingTop: 9 }}>
              <Typography textAlign={'center'} variant="h5" sx={{ wordBreak: 'break-word' }}>
                {t('msg_delete_user_data_title')}
              </Typography>
              {showForm && (
                <Typography textAlign={'center'} variant="body1" sx={{ wordBreak: 'break-word' }}>
                  {t('msg_delete_user_data_comments')}
                </Typography>
              )}
              {showForm ? (
                <>
                  <Stack rowGap={2}>
                    <FormikTextField name="email" label={t('msg_label_email')} type="email" />
                    <Stack width="100%" direction="row" gap={pxToRem(18)}>
                      <Box>
                        <FormikSelect
                          name="countryCode"
                          startAdornment={
                            <InputAdornment position="start">
                              <FlagIcon country={values.countryCode || ''} />
                            </InputAdornment>
                          }
                          renderValue={(_value) =>
                            selectedPhoneCountry?.isoCode2 || DEFAULT_COUNTRY_CODE
                          }
                          onChange={(event) => {
                            const countryCode = event.target.value as string;
                            handleCountryChange(countryCode);
                            setFieldValue('countryCode', countryCode);
                          }}
                        >
                          {codes.map((code) => (
                            <MenuItem key={code.country} value={code.isoCode2}>
                              {code.country}
                            </MenuItem>
                          ))}
                        </FormikSelect>
                      </Box>
                      <Box width="100%">
                        <FormikTextField
                          name="phone"
                          onChange={(event) => {
                            const phone = event.target.value;
                            setFieldValue('phone', phone);
                          }}
                          error={!!errors.phone}
                          helperText={t(errors.phone || '')}
                          placeholder={t('msg_label_your_number')}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{selectedPhoneCountry?.countryCodes[0]}
                              </InputAdornment>
                            ),
                            inputProps: {
                              min: 0,
                            },
                          }}
                          type="number"
                          autoComplete="off"
                          fullWidth
                        />
                      </Box>
                    </Stack>
                    <FormikTextField name="note" label={t('msg_label_note')} />
                  </Stack>
                  <Button
                    disabled={!values.email || !values.phone}
                    fullWidth
                    onClick={submitForm}
                    variant="contained"
                    size="large"
                  >
                    {t('btn_submit')}
                  </Button>
                </>
              ) : (
                <Typography textAlign="center">{t('msg_delete_user_data_success')}</Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Formik>
    </Grid>
  );
};
