import { LanguageCodes } from './appConfig';
import { BaseCurrency } from './company';

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  Overdue = 'OVERDUE',
  Proforma = 'PROFORMA',
}

export enum PaymentStatus {
  Unpaid = 'UNPAID',
  PartlyPaid = 'PARTLY_PAID',
  Paid = 'PAID',
}

export type InvoicePayment = {
  amount: number;
  date: Date;
};

export type InvoiceItem = {
  amount: string;
  item: string;
  price: number | string;
  total: number;
  created_at: Date;
  id: number;
  invoice: number;
  updated_at: Date;
  vat_percentage?: number;
  vat_sum?: number;
  total_without_vat?: number;
};

export enum InvoiceType {
  invoice = 'INVOICE',
  proforma = 'PROFORMA',
}

export type Invoice = {
  id: string;
  user_id: string;
  invoice_items: InvoiceItem[];
  is_payment_late: boolean;
  is_already_paid: boolean;
  left_to_pay: number;
  company_name: string;
  company_address: string;
  company_email: string;
  company_phone: string;
  company_license_no: string;
  company_vat_code: string;
  company_iban: string;
  customer_name: string;
  customer_address: string;
  customer_email: string;
  customer_phone: string;
  customer_vat_number: string;
  customer_company_number: string;
  issue_date: Date;
  is_draft: boolean;
  is_hidden: boolean;
  source: string;
  number: string;
  notes: string;
  payment_due: Date;
  total: number;
  payment_status: PaymentStatus;
  pdf: string;
  is_sent: boolean;
  customer: number | null;
  payments: InvoicePayment[];
  currency: string;
  soft_collection: boolean;
  language: LanguageCodes;
  created_at: Date;
  updated_at: Date;
  vat_sum: number;
  total_without_vat: number;
  pdf_template?: number;
  invoice_currency: BaseCurrency;
  s_spain_irpf_percentage?: number;
  spain_irpf_amount?: number;
  linked_incomes?: Operation[];
  invoice_type: InvoiceType;
};

export type Operation = {
  amount: number;
  id: number;
  is_draft: false;
  operation_type: OperationType;
  issue_date: Date;
  title: string;
  subtitle: string;
  created_at: Date;
  linked_invoices: Operation[];
  linked_incomes: Operation[];
  created_by_mark_as_paid: boolean;
};

export type InvoiceListOptions = {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: string;
  is_unpaid?: boolean;
  is_paid?: boolean;
  is_draft?: boolean;
  is_overdue?: boolean;
  is_proforma?: boolean;
  issue_date_from?: string;
  issue_date_to?: string;
  customer?: number;
};

export type InvoicesStatusSummary = {
  is_draft_count: number | null;
  is_overdue_count: number | null;
  is_unpaid_count: number | null;
  is_paid_count: number | null;
  is_proforma_count: number | null;
  total_count: number | null;
};

export type InvoiceFormItemFields = {
  id?: number;
  item: string;
  price: number | string;
  amount: string;
  total?: number;
  vat_percentage?: number;
  vat_sum?: number;
  total_without_vat?: number;
  aux_recalculate_totals?: boolean;
};

export type InvoiceFormFields = {
  id: string;
  customer?: number | null;
  number?: string;
  issue_date?: Date;
  payment_due?: Date;
  notes?: string;
  invoice_items: InvoiceFormItemFields[];
  status?: InvoiceStatus;
  soft_collection?: boolean;
  language?: LanguageCodes;
  pdf_template?: number;
  s_spain_irpf_percentage?: string;
  spain_irpf_amount?: number;
  invoice_type: InvoiceType;
};

export type SendInvoiceFormFields = {
  recipient_email: string;
  content: string;
  soft_collection: boolean;
  copy_for_sender?: boolean;
  sender_email?: string;
};

export type ForwardInvoiceFormFields = {
  recipient_email: string;
};

export type SalesLeadFormFields = {
  email: string | null;
  phone?: string | null;
  note?: string | null;
  campaign?: string | null;
  countryCode?: string;
};

export type MarkAsPaidFormFields = {
  amount: string | number;
  date: string;
};

export type InvoiceSaveAsPdfResponse = {
  pdf: string;
};

export type InvoiceGetPdfResponse = {
  content: string;
};

export type InvoicePermissionSettingsFields = {
  soft_collection: boolean;
};

export type InvoicePayLinkResponse = {
  url: 'string' | null;
};
export interface VatRate {
  vat_percentage: number;
  description: string;
}

export enum OperationType {
  expense = 'Expense',
  invoice = 'Invoice',
  income = 'Income',
  personalOperation = 'PersonalOperation',
  historicalOperation = 'HistoricalOperation',
}
