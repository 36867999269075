import { useCallback, useState } from 'react';

export const useTableRowsSelection = (count = 0, rows: string[] = []) => {
  const [selected, setSelected] = useState<string[]>([]);

  const selectedAll = !!count && selected && selected.length === count;

  const onSelect = useCallback(
    (id: string) => {
      if (selected.includes(id)) {
        setSelected(selected.filter((item) => item !== id));
      } else {
        setSelected([...selected, id]);
      }
    },
    [selected],
  );

  const onSelectAll = useCallback(() => {
    if (rows.every((row) => selected.includes(row))) {
      setSelected(selected.filter((row) => !rows.includes(row)));
    } else {
      setSelected(
        [...selected, ...rows].filter((value, index, self) => self.indexOf(value) === index),
      );
    }
  }, [selected, rows]);

  const onClearSelection = () => setSelected([]);

  return {
    selected,
    selectedAll,
    onSelect,
    onSelectAll,
    onClearSelection,
  };
};
