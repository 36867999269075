import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CenteredSpinner } from '~/components/Spinner';
import { model } from '~/models/Expense';
import { ROUTES } from '~/router/Routes';
import { ValidationError } from '~/types/error';
import { ExpenseFormFields } from '~/types/expense';
import { SelectedFile } from '~/types/file';
import { FormikSetErrorsFn } from '~/types/formik';
import { formatCurrencyNumber, formatNumber } from '~/utils/formatNumber';

import { ExpenseDataForm, initialValues as emptyValues } from './ExpenseDataForm';

export const EditExpenseForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<ExpenseFormFields>(emptyValues);

  const getExpense = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      const expense = await model.get(id);
      const receiptsData = await model.getReceipts(id, expense.receipt_count);

      const receipts = receiptsData.items.map((receipt) => ({
        id: receipt.id,
        name: receipt.file.toString().split('/').pop(),
        file: receipt.file,
      }));
      
      const initialValues: ExpenseFormFields = {
        merchant: expense.merchant.id,
        amount: formatCurrencyNumber(expense.amount),
        category: expense.category,
        issue_date: expense.issue_date,
        description: expense.description,
        receipt_number: expense.receipt_number,
        receipts: receipts,
        full_amount: formatCurrencyNumber(expense.full_amount),
        percentage: `${((expense.amount / expense.full_amount || 1) * 100).toFixed(2)}`
      };

      setInitialValues(initialValues);
    } catch (error) {
      onExpenseFailure();
    }

    setIsLoading(false);
  };

  const onSave = async (
    values: ExpenseFormFields,
    setErrors: FormikSetErrorsFn<ExpenseFormFields>,
  ) => {
    if (!id) return false;

    let success = true;

    try {
      const amount = formatNumber(values.amount ?? 0);
      const full_amount = formatNumber(values.full_amount ?? 0);
      await model.update(
        id,
        {
          ...values,
          amount,
          full_amount,
        }
      );
    } catch (error) {
      if (error instanceof ValidationError && setErrors) {
        setErrors(error.formikErrors);
      }
      toast.error(t('msg_error_expense_save_failed'));
      success = false;
    }

    try {
      const newReceipts = values.receipts.filter((receipt: SelectedFile) => !receipt.id);
      await Promise.all(newReceipts.map(async (receipt: SelectedFile) => { return await model.addReceipt(id, receipt); }))
    } catch (error) {
      if (error instanceof ValidationError<SelectedFile> && error.formikErrors.file) {
        toast.error(error.formikErrors.file as string);
      }
      success = false;
    }

    try {
      const deletedReceipts = initialValues.receipts.filter((receipt: SelectedFile) => receipt.isDeleted && receipt.id);
      await Promise.all(deletedReceipts.map(async (receipt: SelectedFile) => { return await model.deleteReceipt(id, receipt.id!); }))
    } catch (error) {
      if (error instanceof ValidationError && error.detail) {
        toast.error(error.detail);
      }
      success = false;
    }

    try {
      await model.finalize(id);
    } catch (error) {
      if (error instanceof ValidationError && setErrors) {
        setErrors(error.formikErrors);
      }
      toast.error(t('msg_error_expense_save_failed'));
      success = false;
    }

    if (success) {
      toast.success(t('msg_success_expense_saved'));
    }

    return success;
  };

  const onExpenseFailure = () => {
    toast.error(t('msg_error_get_expense_failed'));
    navigate(ROUTES.DASHBOARD_EXPENSES);
  };

  useEffect(() => {
    getExpense();
  }, [id]);

  if (isLoading || !initialValues) {
    return <CenteredSpinner />;
  }

  return (
    <ExpenseDataForm
      initialValues={initialValues}
      onSave={onSave}
    />
  );
};
