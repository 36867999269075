import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Avatar, IconButton, Typography, ListItemIcon, Menu, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteMerchantDialog } from '~/components/Dialog/DeleteMerchantDialog';
import { EditMerchantFormDialog } from '~/components/Dialog/MerchantFormDialog';
import { Merchant } from '~/types/merchant';

import { ListItemText, MenuItem } from '../CustomerSelector';

type Props = {
  merchant: Merchant;
  onSelectMerchant: (merchant: Merchant) => void;
};

export const MerchantSelectorMenuItem = ({ merchant, onSelectMerchant }: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const open = Boolean(anchorEl);

  const { id, name, can_be_deleted } = merchant;

  const onClickActions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onCloseActions = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem onClick={() => onSelectMerchant(merchant)}>
        <ListItemIcon>
          <Avatar
            sx={{ width: 35, height: 35, backgroundColor: 'grey.200', color: 'text.primary' }}
          >
            <Typography variant="caption">{name.slice(0, 2).toUpperCase()}</Typography>
          </Avatar>
        </ListItemIcon>

        <ListItemText>
          <Typography sx={{ maxWidth: 280, pl: 1 }} noWrap>
            {name}
          </Typography>
        </ListItemText>

        <Box width={40}>
          <IconButton onClick={onClickActions}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      </MenuItem>

      <Menu anchorEl={anchorEl} open={open} onClose={onCloseActions}>
        <MenuItem onClick={() => setShowEditDialog(true)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t('msg_btn_edit_merchant')}</ListItemText>
        </MenuItem>
        {can_be_deleted && (
          <MenuItem
            sx={{ color: (theme) => theme.palette.error.main }}
            onClick={() => setShowDeleteDialog(true)}
          >
            <ListItemIcon>
              <DeleteIcon sx={{ color: (theme) => theme.palette.error.main }} />
            </ListItemIcon>
            <ListItemText>{t('msg_btn_delete_merchant')}</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <EditMerchantFormDialog
        id={id}
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
      />

      <DeleteMerchantDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
