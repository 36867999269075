import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { pxToRem } from '~/utils/getFontValue';

type Props = {
  title: string;
};

export const Blank = ({ title }: Props) => {
  const { t } = useTranslation();

  return (
    <Typography variant="h5" lineHeight={pxToRem(48)}>
      {t(title)}
    </Typography>
  );
};

export const ComingSoon = () => {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ height: '100%' }}
      justifyContent="space-between"
      alignItems="center"
      padding={5}
      spacing={3}
    >
      <Typography variant="h5" lineHeight={pxToRem(48)}>
        {t('msg_label_coming_soon')}
      </Typography>
      <Box
        component="img"
        sx={{
          height: 300,
          width: 300,
          maxHeight: { xs: 160, md: 300 },
          maxWidth: { xs: 160, md: 300 },
          resize: 'cover',
        }}
        alt="The house from the offer."
        src={require('../../assets/images/BankLink.png')}
      />
    </Stack>
  );
};

export const NotFound = () => <Blank title="msg_label_not_found" />;
