import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  Typography,
  Checkbox,
} from '@mui/material';
import codes, { ICountryCodeItem } from 'country-calling-code';
import { Formik, Form, useFormik, FormikHelpers, FormikProps } from 'formik';
import { toast } from 'material-react-toastify';
import { FC, useEffect, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormikSelect } from '~/components/Form/Select';
import { FormikTextField } from '~/components/Form/TextField';
import { FlagIcon } from '~/components/Icon/FlagIcon';
import { WelcomeBackLayout } from '~/components/Layout/WelcomeBackLayout';
import { Logo } from '~/components/Logo';
import { LanguageSelector } from '~/components/Selector/LanguageSelector';
import { FullPageSpinner } from '~/components/Spinner';
import { SupportChat } from '~/components/SupportChat';
import { TopBanner } from '~/components/TopBanner/TopBanner';
import {
  DEEPFIN_SUPPORT_EMAIL,
  DEFAULT_COUNTRY_CODE,
  PRIVACY_POLICY_URL_TRANSLATION,
  TERMS_AND_CONDITIONS_URL_TRANSLATION,
} from '~/config/constants';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useAppConfig } from '~/context/AppConfigContext';
import { useAuth } from '~/context/AuthContext';
import { AuthPages } from '~/guards';
import { useResponsive } from '~/hooks/useResponsive';
import { useUsersLocationByIp } from '~/hooks/useUsersLocationByIp';
import { useCountriesOfResidence } from '~/models/Auth/hooks/useCountriesOfResidence';
import { useEmailRegistration } from '~/models/Auth/hooks/useEmailRegistration';
import { model as companyModel } from '~/models/Company';
import { model } from '~/models/User';
import { ROUTES } from '~/router/Routes';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { sendAmplitudeData } from '~/utils/amplitude';
import { pxToRem } from '~/utils/getFontValue';
import { logger } from '~/utils/logger';
import { shouldHideSupportChat } from '~/utils/shouldHideSupportChat';

import { validationSchema } from './validationSchema';

export interface EmailRegistrationFormValues {
  email: string;
  password: string;
  countryCode: string;
  phone: string;
  country: string;
  full_name: string;
  referral_source: string;
}

export enum SignUpFormField {
  full_name = 'full_name',
  country = 'country',
  email = 'email',
  password = 'password',
  phone = 'phone',
  referral_source = 'referral_source',
  countryCode = 'countryCode',
}

export const SignUp: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const generatedId = useId();
  const formikRef = useRef<FormikProps<EmailRegistrationFormValues>>(null);
  const isMobile = useResponsive('down', 'sm');
  const { loginAndSetUserToContext } = useAuth();
  const { defaultSupportLink, enabledCountries } = useAppConfig();

  const [phone, setPhone] = useState<string>('');
  const location = useLocation();
  const [selectedPhoneCountry, setSelectedPhoneCountry] = useState<ICountryCodeItem | undefined>(
    undefined,
  );
  const [referralCheckboxIsChecked, setReferralCheckboxIsChecked] = useState(false);
  const [countryOfResidence, setCountryOfResidence] = useState<string | undefined>('');

  const { location: locationByIp, isLoading: isLoadingUsersLocation } = useUsersLocationByIp();
  const { data: countriesOfResidence, isLoading: isLoadingCountriesOfResidence } =
    useCountriesOfResidence();

  const supportChatMessengerId =
    enabledCountries?.find((country) => country.code === countryOfResidence)?.support_link
      ?.messenger_id ?? defaultSupportLink.messenger_id;

  useEffect(() => {
    sendAmplitudeData(AmplitudeEvent.RegistrationStarted);
  }, []);

  useEffect(() => {
    const setCountryOfResidencePhoneCode = async () => {
      const countryOfResidenceLocalStorageValue = await localStorage.getItem(
        localStorageKeys.countryOfResidence,
      );

      if (countryOfResidenceLocalStorageValue) {
        setCountryOfResidence(countryOfResidenceLocalStorageValue);
      } else if (
        locationByIp &&
        countriesOfResidence?.find((country) => country.code === locationByIp.countryCode)
      ) {
        setCountryOfResidence(locationByIp?.countryCode);
        localStorage.setItem(localStorageKeys.countryOfResidence, locationByIp?.countryCode);
      }
    };
    setCountryOfResidencePhoneCode();
  }, [locationByIp, countriesOfResidence]);

  useEffect(() => {
    const countryOfResidenceCode = countryOfResidence
      ? codes.find((code) => code.isoCode2 === countryOfResidence)
      : codes.find((code) => code.isoCode2 === locationByIp?.countryCode);

    formikRef?.current?.setFieldValue('countryCode', countryOfResidenceCode?.isoCode2);

    countryOfResidenceCode && setSelectedPhoneCountry(countryOfResidenceCode);
  }, [countryOfResidence, locationByIp]);

  const handleSubmit = async (
    values: EmailRegistrationFormValues,
    { setErrors }: FormikHelpers<EmailRegistrationFormValues>,
  ) => {
    try {
      const { email, password, full_name, phone, referral_source } = values;
      sendAmplitudeData(AmplitudeEvent.IntroCompleted, { process: 'registration' });
      await registerEmail({ email, password });
      await companyModel.create(
        phone ? `+${selectedPhoneCountry?.countryCodes[0]}${phone}` : '',
        email,
      );
      await model.patchUser({
        full_name,
        country: countryOfResidence,
        referral_source: referralCheckboxIsChecked ? referral_source : '',
      });
      sendAmplitudeData(AmplitudeEvent.RegistrationIDSubmitted, { result: 'success' });
      loginAndSetUserToContext();
    } catch (e) {
      sendAmplitudeData(AmplitudeEvent.RegistrationIDSubmitted, { result: 'error' });

      if (e instanceof ValidationError) {
        e.nonFieldErrors[0] && toast.error(t(e.nonFieldErrors[0]));
        e.error && toast.error(t(e.error));
        e.formikErrors && setErrors(e.formikErrors);
      }
    }
  };

  const { setErrors } = useFormik({
    initialValues: {
      countryCode: selectedPhoneCountry?.isoCode2 || DEFAULT_COUNTRY_CODE,
      phone: phone?.replace(`+${selectedPhoneCountry?.countryCodes[0]}`, '') || '',
      country: '',
      email: '',
      password: '',
      full_name: '',
      referral_source: '',
    },
    onSubmit: handleSubmit,
  });

  const { isLoading, mutateAsync: registerEmail } = useEmailRegistration(setErrors);

  useEffect(() => {
    localStorage.setItem(localStorageKeys.lastVisitedAuthPage, AuthPages.signup);
  }, []);

  useEffect(() => {
    handleCountryChange(DEFAULT_COUNTRY_CODE);
  }, []);

  const handleCountryChange = (countryCode: string) => {
    const country = codes.find((item) => item.isoCode2 === countryCode);
    setSelectedPhoneCountry(country);
  };

  useEffect(() => {
    if (location.search.includes('?phone=')) {
      const phone = location.search.split('?phone=')[1];
      setPhone(phone);
    }
  }, [location]);

  useEffect(() => {
    if (t('msg_btn_support') === 'msg_btn_support') {
      logger.logMessage('Translations are not working properly!!!');
    }
  }, [t]);

  const handleNavigateToLogin = () => {
    sendAmplitudeData(AmplitudeEvent.LoginRedirected);
    navigate(ROUTES.LOGIN);
  };

  if (isLoadingUsersLocation || isLoadingCountriesOfResidence) return <FullPageSpinner />;

  return (
    <WelcomeBackLayout>
      {isMobile && <TopBanner title={t('msg_open_app')} buttonTitle={t('msg_btn_open_app')} />}
      <Stack
        justifyContent="space-between"
        alignItems="center"
        paddingX={{ xs: 2, md: 5 }}
        paddingY={2}
        spacing={3}
        sx={{ height: '100%' }}
      >
        <Stack width="100%">
          <Stack direction="row" justifyContent="end" sx={{ width: 1 }}>
            <LanguageSelector countryCode={countryOfResidence} />
          </Stack>
          <Stack direction="row" justifyContent="center" paddingTop={2}>
            <Logo width={142} height={24} />
          </Stack>
        </Stack>
        <Formik
          innerRef={formikRef}
          initialValues={{
            countryCode: selectedPhoneCountry?.isoCode2 || DEFAULT_COUNTRY_CODE,
            phone: phone?.replace(`+${selectedPhoneCountry?.countryCodes[0]}`, '') || '',
            country: '',
            email: '',
            password: '',
            full_name: '',
            referral_source: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <Stack alignItems="flex-start" width="100%">
                  <Typography fontSize={24} fontWeight={700}>
                    {t('msg_register_title')}
                  </Typography>
                  <Stack paddingTop={1} flexDirection="row" alignItems="center">
                    <Typography fontSize={14} fontWeight={400}>
                      {t('msg_already_a_member')}
                    </Typography>
                    <Button
                      variant="text"
                      sx={{ padding: 0, marginLeft: 1 }}
                      onClick={handleNavigateToLogin}
                    >
                      {t('msg_btn_login')}
                    </Button>
                  </Stack>
                </Stack>
                <Stack
                  spacing={1}
                  mt={3}
                  sx={{ width: { xs: '100%', lg: 480 }, maxWidth: 480 }}
                  alignItems="center"
                >
                  <FormikTextField
                    name={SignUpFormField.full_name}
                    label={t('msg_label_company_name')}
                    placeholder={t('msg_label_company_name')}
                    sx={{ width: '100%' }}
                    autoFocus
                  />
                  {!!countriesOfResidence?.length && (
                    <Stack width="100%">
                      <FormikSelect
                        value={countryOfResidence}
                        name={SignUpFormField.country}
                        onChange={(e) => {
                          setFieldValue(SignUpFormField.countryCode, e.target.value as string);
                          setCountryOfResidence(e.target.value as string);
                          localStorage.setItem(
                            localStorageKeys.countryOfResidence,
                            e.target.value as string,
                          );
                        }}
                        placeholder={!countryOfResidence ? t('msg_select_your_country') : ''}
                      >
                        {countriesOfResidence?.map((country) => (
                          <MenuItem key={`${generatedId}${country.code}`} value={country.code}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </FormikSelect>
                    </Stack>
                  )}

                  <FormikTextField
                    sx={{ width: '100%' }}
                    name={SignUpFormField.email}
                    label={t('msg_label_email')}
                    type="text"
                  />

                  <FormikTextField
                    sx={{ width: '100%' }}
                    name={SignUpFormField.password}
                    label={t('msg_password_new')}
                    type="password"
                  />
                  <Stack width="100%" direction="row" gap={pxToRem(18)}>
                    <Box>
                      <FormikSelect
                        name="countryCode"
                        startAdornment={
                          <InputAdornment position="start">
                            <FlagIcon country={values.countryCode} />
                          </InputAdornment>
                        }
                        renderValue={(_value) =>
                          selectedPhoneCountry?.isoCode2 || DEFAULT_COUNTRY_CODE
                        }
                        onChange={(event) => {
                          const countryCode = event.target.value as string;
                          handleCountryChange(countryCode);
                          setFieldValue('countryCode', countryCode);
                        }}
                      >
                        {codes.map((code) => (
                          <MenuItem key={code.country} value={code.isoCode2}>
                            {code.country}
                          </MenuItem>
                        ))}
                      </FormikSelect>
                    </Box>
                    <Box width="100%">
                      <FormikTextField
                        name={SignUpFormField.phone}
                        onChange={(event) => {
                          const phone = event.target.value;
                          setFieldValue('phone', phone);
                        }}
                        error={!!errors.phone}
                        helperText={t(errors.phone || '')}
                        placeholder={t('msg_label_your_number')}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +{selectedPhoneCountry?.countryCodes[0]}
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                          },
                        }}
                        type="number"
                        autoComplete="off"
                        fullWidth
                      />
                    </Box>
                  </Stack>
                  <Stack flexDirection="row" alignItems="center" width="100%">
                    <Checkbox
                      size="small"
                      checked={referralCheckboxIsChecked}
                      onClick={() => setReferralCheckboxIsChecked((prevState) => !prevState)}
                    />
                    <Typography>{t('msg_i_have_a_referral_code')}</Typography>
                  </Stack>
                  {referralCheckboxIsChecked && (
                    <FormikTextField
                      sx={{ width: '100%' }}
                      name={SignUpFormField.referral_source}
                      label={t('msg_label_referral_code')}
                      type="text"
                    />
                  )}
                  <Stack width={'100%'} marginTop={7}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isLoading}
                      endIcon={<ArrowForwardIcon />}
                    >
                      {t('btn_continue')}
                    </LoadingButton>
                  </Stack>
                  <Stack marginTop={2} flexDirection="row">
                    <Typography
                      component="p"
                      variant="caption"
                      color="text.secondary"
                      textAlign="center"
                    >
                      {t('msg_agree_text')}{' '}
                      <Link
                        href={t(PRIVACY_POLICY_URL_TRANSLATION)}
                        component="a"
                        color="inherit"
                        target="_blank"
                      >
                        {t('msg_btn_btn_privacy_policy')}
                      </Link>{' '}
                      <Link
                        href={t(TERMS_AND_CONDITIONS_URL_TRANSLATION)}
                        component="a"
                        color="inherit"
                        target="_blank"
                      >
                        {t('msg_btn_terms_of_use')}
                      </Link>
                    </Typography>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
        <Stack alignItems="center" spacing={5} sx={{ width: 360 }}>
          <Button
            variant="outlined"
            startIcon={<HelpIcon />}
            href={`mailto:${DEEPFIN_SUPPORT_EMAIL}`}
          >
            {t('msg_btn_support')}
          </Button>
        </Stack>
      </Stack>
      {!shouldHideSupportChat && (
        <SupportChat
          supportChatMessengerId={supportChatMessengerId}
          welcomeTitle={t('msg_messenger_welcome_title')}
        />
      )}
    </WelcomeBackLayout>
  );
};
