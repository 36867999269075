/* eslint-disable @typescript-eslint/no-explicit-any */
import { HeadCell } from '~/types/table';

import { TableRowSkeleton } from './TableRowSkeleton';

export const generateTableRowsSkeleton = (
  headCells: HeadCell<any>[] = [],
  isLoading = false,
  hasCheckbox = false,
  rowsCount = 5,
): React.ReactNode[] => {
  if (!isLoading) return [];

  return Array(rowsCount)
    .fill(0)
    .map((_, index) => (
      <TableRowSkeleton key={index} headCells={headCells} hasCheckbox={hasCheckbox} />
    ));
};
