import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GoBackButton } from '~/components/Button/GoBackButton';
import { SpacedButton } from '~/components/Button/SpacedButton';
import { FormikTextField } from '~/components/Form/TextField';
import { useAuth } from '~/context/AuthContext';
import { useRegistrationStepper } from '~/context/RegistrationStepperContext';
import { model } from '~/models/Auth';
import { ROUTES } from '~/router/Routes';
import { ValidationError } from '~/types/error';

import { validationSchema } from './validationSchema';

type FormFields = {
  full_name: string;
  email: string;
};

export const RegisterIntroduce = () => {
  const { t } = useTranslation();
  const { setActiveStep } = useRegistrationStepper();
  const { user, logout, fetchUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const onSubmit = async (
    { email, full_name }: FormFields,
    { setErrors }: FormikHelpers<FormFields>,
  ) => {
    try {
      await model.fillInUserData({ email, full_name: full_name.trim() });
      fetchUser();
      navigate(ROUTES.REGISTER_INCOME);
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        full_name: user?.full_name || '',
        email: user?.email || '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm }) => (
        <Stack spacing={3} paddingY={7}>
          <Box>
            <GoBackButton onClick={logout} />
          </Box>

          <Stack spacing={1}>
            <Typography variant="h5">{t('msg_label_register_title_first')}</Typography>
            <Typography variant="body2">{t('msg_label_register_subtitle_first')}</Typography>
          </Stack>

          <Stack spacing={1}>
            <FormikTextField
              name="full_name"
              label={`${t('msg_label_name')}*`}
              variant="filled"
              autoFocus
            />
            <FormikTextField
              name="email"
              label={`${t('msg_label_email')}*`}
              variant="filled"
              type="email"
            />
          </Stack>

          <Grid container justifyContent="end" paddingTop={1}>
            <Grid item xs={6}>
              <SpacedButton
                variant="contained"
                size="large"
                endIcon={<ArrowForwardIcon />}
                onClick={submitForm}
                aria-label={t('btn_continue')}
                fullWidth
              >
                {t('btn_continue')}
              </SpacedButton>
            </Grid>
          </Grid>
        </Stack>
      )}
    </Formik>
  );
};
