import { MerchantFormFields } from '~/types/merchant';

export const tabs = ['msg_label_requisites', 'msg_label_general_information'];

export const initialEmptyValues: MerchantFormFields = {
  name: '',
  company_code: '',
  vat_number: '',
  address: '',
  email: '',
  phone: '',
  website: '',
  contact_person: '',
};
