import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = React.PropsWithChildren &
  DialogProps & {
    title: string;
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    onChange: (value: string) => void;
  };

export const CustomVatPercentageDialog = ({ title, open, onClose, onSubmit, onChange }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Box>{title}</Box>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box mt={3}>
          <TextField
            name={'vat'}
            label={t('msg_vat_rate_other')}
            variant="filled"
            color="primary"
            autoFocus
            fullWidth
            required={false}
            onChange={(e) => onChange(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onSubmit} variant="contained" color="primary" size="large">
          {t('btn_submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
