import { Box, FormControlLabel } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IOSSwitch from '~/components/Switch/IOSSwitch';
import { model } from '~/models/Invoices';
import { invoicesIndexQueryKey } from '~/models/Invoices/hooks';
import { colors } from '~/theme/colors';
import { ValidationError } from '~/types/error';
import { Invoice } from '~/types/invoice';
import { queryClient } from '~/utils';

type Props = {
  invoice: Invoice;
};

export const SoftCollectionToggle = ({ invoice }: Props) => {
  const { t } = useTranslation();

  const { id, soft_collection: initialSoftCollection } = invoice;
  const [softCollection, setSoftCollection] = useState(initialSoftCollection);

  const onSoftCollectionToggle = async (checked: boolean) => {
    try {
      setSoftCollection(checked);
      await model.permissionSettings(id, { soft_collection: checked });
      queryClient.invalidateQueries([invoicesIndexQueryKey]);
    } catch (error) {
      if (error instanceof ValidationError) {
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };

  return (
    <Box
      sx={{
        width: '320px',
        p: 4,
        borderRadius: 4,
        backgroundColor: colors.background.paper,
      }}
    >
      <FormControlLabel
        sx={{ m: 0, gap: 2, display: 'flex', flex: 1, justifyContent: 'space-between' }}
        labelPlacement="start"
        label={t('msg_label_soft_collection')}
        control={<IOSSwitch />}
        checked={softCollection}
        onChange={(_, checked) => onSoftCollectionToggle(checked)}
      />
    </Box>
  );
};
