import { useMutation } from '@tanstack/react-query';

import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '..';

import { expensesQueryKey } from '.';

export const useDeleteExpenseBulk = ({ onSuccess }: MutationProps) =>
  useMutation(model.bulkDelete, {
    onSuccess: () => {
      queryClient.invalidateQueries([expensesQueryKey]);
      onSuccess?.();
    },
  });
