import { alpha, Box, Drawer, ListItem, styled, Button as MuiButton } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

import { config } from './config';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  justifyContent: 'space-between',
  minHeight: pxToRem(89),
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  '.MuiListItemIcon-root': {
    minWidth: pxToRem(40),
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create(['background-color', 'color'], {
      duration: theme.transitions.duration.shortest,
    }),

    '&, .MuiListItemIcon-root, .MuiListItem-root': {
      color: theme.palette.primary.contrastText,
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export const MainDrawer = styled(Drawer)({
  width: config.drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: config.drawerWidth,
    boxSizing: 'border-box',
  },
});

export const SubDrawer = styled(Drawer)(({ theme }) => ({
  width: config.drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: config.drawerWidth,
    boxSizing: 'border-box',
    left: config.drawerWidth,
    paddingTop: theme.spacing(3),
  },
}));

export const UserSettingsButton = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3, 2),
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  cursor: 'pointer',
  userSelect: 'none',
}));

export const Version = styled(Box)(({ theme }) => ({
  ...theme.typography.caption,
  color: alpha(theme.palette.text.secondary, 0.5),
  padding: theme.spacing(0, 2),
}));

export const CompanyButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  padding: theme.spacing(1),
  width: '100%',
  borderRadius: theme.spacing(0.5),
  flexDirection: 'row',
  textAlign: 'left',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  ...(active && {
    '& .MuiTouchRipple-root': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  }),
}));
