import {
  TableRow as MuiTableRow,
  TableCell,
  TableBody,
  TableFooter,
  Typography,
  TableContainer,
} from '@mui/material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { TableHead } from '~/components/Table/TableHead';
import { LanguageCodes } from '~/types/appConfig';
import { InvoiceItem } from '~/types/invoice';
import { formatCurrency } from '~/utils/formatNumber';

import { pickFontSize } from '../../InvoicePlatform';

import { TableRow } from './TableRow';

import { headCells, Table } from '.';

type Props = {
  items: InvoiceItem[];
  total: number;
  isMobile?: boolean;
  tLanguage?: LanguageCodes;
  vat_sum?: number;
  total_without_vat?: number;
  currency_symbol: string;
  irpfAmount?: number;
};

export const InvoiceItemsTable = ({
  items,
  total,
  total_without_vat,
  vat_sum,
  isMobile,
  tLanguage,
  currency_symbol,
  irpfAmount,
}: Props) => {
  const { t } = useTranslation();
  const translate = tLanguage ? i18next.getFixedT(tLanguage) : t;

  return (
    <TableContainer sx={{ mt: 8 }}>
      <Table width="auto">
        <TableHead headCells={headCells} isMobile={isMobile} tLanguage={tLanguage} />
        <TableBody>
          {items.map((row, index) => (
            <TableRow
              key={row.id}
              index={index}
              row={row}
              isMobile={isMobile}
              currencySymbolNative={currency_symbol}
            />
          ))}
        </TableBody>
        <TableFooter>
          {total_without_vat !== undefined && !!vat_sum && (
            <MuiTableRow>
              <TableCell colSpan={3} />
              <TableCell padding="none">
                <Typography
                  fontSize={pickFontSize(isMobile)}
                  sx={{ width: { sm: '115px', md: '200px' } }}
                >
                  {translate('msg_total_without_vat')}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ p: { xs: 0, sm: 2 } }}>
                <Typography
                  fontSize={pickFontSize(isMobile)}
                  sx={{ width: { sm: '115px' }, textAlign: 'right' }}
                >
                  {formatCurrency(total_without_vat, true, currency_symbol)}
                </Typography>
              </TableCell>
            </MuiTableRow>
          )}
          {vat_sum !== undefined && !!vat_sum && (
            <MuiTableRow>
              <TableCell colSpan={3} />
              <TableCell padding="none">
                <Typography fontSize={pickFontSize(isMobile)} sx={{ width: { sm: '115px' } }}>
                  {translate('msg_vat')}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ p: { xs: 0, sm: 2 } }}>
                <Typography
                  fontSize={pickFontSize(isMobile)}
                  sx={{ width: { sm: '115px' }, textAlign: 'right' }}
                >
                  {formatCurrency(vat_sum, true, currency_symbol)}
                </Typography>
              </TableCell>
            </MuiTableRow>
          )}
          {irpfAmount && (
            <MuiTableRow>
              <TableCell colSpan={3} />
              <TableCell padding="none">
                <Typography fontSize={pickFontSize(isMobile)} sx={{ width: { sm: '115px' } }}>
                  {translate('msg_irpf_title')}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ p: { xs: 0, sm: 2 } }}>
                <Typography
                  fontSize={pickFontSize(isMobile)}
                  sx={{ width: { sm: '115px' }, textAlign: 'right' }}
                >
                  {`-${formatCurrency(irpfAmount, true, currency_symbol)}`}
                </Typography>
              </TableCell>
            </MuiTableRow>
          )}
          <MuiTableRow>
            <TableCell colSpan={3} />
            <TableCell padding="none">
              <Typography
                fontSize={pickFontSize(isMobile)}
                sx={{ fontWeight: 700, width: { sm: '115px' } }}
              >
                {translate('msg_total_price')}
              </Typography>
            </TableCell>
            <TableCell align="right" sx={{ p: { xs: 0, sm: 2 } }}>
              <Typography
                fontSize={pickFontSize(isMobile)}
                sx={{ fontWeight: 700, width: { sm: '115px' }, textAlign: 'right' }}
              >
                {formatCurrency(total, true, currency_symbol)}
              </Typography>
            </TableCell>
          </MuiTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
