import { generatePath } from 'react-router-dom';

import { Customer, CustomerListOptions, CustomerMutationProps } from '~/types/customer';
import { PaginationData } from '~/types/pagination';
import { api } from '~/utils/api';

const URL = {
  INDEX: '/api/customers/',
  SUGGESTED_CUSTOMERS: '/api/suggested-customers/',
  GET: '/api/customers/:id/',
};

export const model = {
  index: (params?: CustomerListOptions) => api.get<PaginationData<Customer>>(URL.INDEX, params),
  suggestedCustomers: () => api.get<Customer[]>(URL.SUGGESTED_CUSTOMERS),
  get: (id: number) => api.get<Customer>(generatePath(URL.GET, { id })),
  create: (data: Partial<Customer>) => api.post<Customer>(URL.INDEX, data),
  delete: (id: number) => api.delete(generatePath(URL.GET, { id })),
  patch: ({ id, data }: CustomerMutationProps) =>
    api.patch<Customer>(generatePath(URL.GET, { id }), data),
};
