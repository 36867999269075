import { useMutation } from '@tanstack/react-query';

import { api } from '~/utils/api';
import { logger } from '~/utils/logger';

import { model } from '../aiAgentChat';

interface AgentChatMessage {
  human_input: string;
}

export const useSendNewAIAgentChatMessage = (
  sessionId: number | undefined,
  onSuccess: (text: string) => void,
) => {
  const mutation = useMutation({
    mutationFn: (data: AgentChatMessage) =>
      api.post<string>(model.sendNewChatMessage(sessionId), data),
    onSuccess: (res) => onSuccess(res),
    onError: (err) => logger.logException(err),
  });
  return mutation;
};
