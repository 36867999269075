import { Paper, TableBody, TableContainer } from '@mui/material';
import { PropsWithChildren } from 'react';

import { InvoiceItemsFormTable } from '~/components/Table';
import { TableHead } from '~/components/Table/TableHead';

import { formHeadCells } from './config';

interface InvoiceItemsTableProps extends PropsWithChildren {
  vatEnabled: boolean;
}

export const InvoiceItemsTable = ({ children, vatEnabled }: InvoiceItemsTableProps) => (
  <TableContainer component={Paper} sx={{ mt: 3 }}>
    <InvoiceItemsFormTable>
      <TableHead headCells={formHeadCells(vatEnabled)} />
      <TableBody>{children}</TableBody>
    </InvoiceItemsFormTable>
  </TableContainer>
);
