export const colors = {
  primary: {
    darker: '#008080',
    dark: '#0D6565',
    main: '#039999',
    light: '#53C2C2',
    lighter: '#BCE6E1',
    contrastText: '#FFFFFF',
    text: '#000000',
  },
  action: {
    disabledBackground: '#F0EEF3',
  },
  background: {
    default: '#F6F7F9',
    paper: '#FFFFFF',
    muiGray: '#EEEEEE',
  },
  other: {
    divider: '#EEF0F3',
    statusUnpaid: '#ED6C0233',
    statusOverdue: '#EF5350',
    chatMessageLightGreen: '#CAE7E7',
  },
  secondary: {
    darkGrey: '#768988',
  },
};

export const text = {
  primary: '#2F3046',
  secondary: '#2f3046bf',
  disabled: '#2f30468c',
};
