import { Paper, styled } from '@mui/material';

export const SelectorBoxWrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error = false }) => ({
  height: '100%',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: error ? theme.palette.error.main : 'transparent',
  borderRadius: theme.shape.borderRadius,
}));
