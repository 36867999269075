import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  Stack,
  SwipeableDrawer,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import i18next from 'i18next';
import { toast } from 'material-react-toastify';
import { FC, useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ForwardInvoiceDialog from '~/components/Dialog/ForwardInvoiceDialog/ForwardInvoiceDialog';
import SalesLeadDialog from '~/components/Dialog/SalesLeadDialog/SalesLeadDialog';
import { InvoiceStatusLabel } from '~/components/Label';
import { Logo } from '~/components/Logo';
import { FullPageSpinner } from '~/components/Spinner';
import { DEFAULT_CURRENCY_SYMBOL } from '~/config/constants';
import { useAuth } from '~/context/AuthContext';
import { useResponsive } from '~/hooks/useResponsive';
import { model } from '~/models/Invoices';
import { usePlatform, useSaveAsPdf } from '~/models/Invoices/hooks';
import { useInvoicePayLink } from '~/models/Invoices/hooks/useInvoicePayLink';
import { ROUTES } from '~/router/Routes';
import { colors } from '~/theme/colors';
import { AmplitudeEvent } from '~/types/amplitude';
import { LanguageCodes } from '~/types/appConfig';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn } from '~/types/formik';
import {
  ForwardInvoiceFormFields,
  InvoiceStatus,
  InvoiceType,
  SalesLeadFormFields,
} from '~/types/invoice';
import { sendAmplitudeData, setAmplitudeUserId } from '~/utils/amplitude';
import { formatDate } from '~/utils/formatDate';
import { formatCurrency } from '~/utils/formatNumber';
import { getInvoiceStatus } from '~/utils/getInvoiceStatus';
import { openInNewTab } from '~/utils/openInNewTab';

import { InvoiceItemsTable } from '../InvoicePreview';

import DetailsAccordion from './DetailsAccordion/DetailsAccordion';
import StatusCard from './StatusCard/StatusCard';

const useStyles = makeStyles({
  bottomShadow: {
    boxShadow: '0px -40px 61px 85px rgba(255,255,255)',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

interface ButtonProps {
  title: string;
  onClick?: () => void;
}

interface SignInBlockProps {
  isMobile: boolean;
  title: string;
  customer: string;
  primaryButton: ButtonProps;
  secondaryButton: ButtonProps;
  additionalText: string;
}

const SignInBlock = ({
  isMobile,
  title,
  customer,
  primaryButton,
  secondaryButton,
  additionalText,
}: SignInBlockProps) => {
  return (
    <Paper
      sx={{
        borderRadius: isMobile ? 8 : 4,
        width: { xs: '100%', sm: 360 },
        p: 4,
        mt: !isMobile ? 2 : 0,
        backgroundColor: isMobile ? colors.background.default : colors.background.paper,
        position: 'relative',
      }}
    >
      <Stack rowGap={2}>
        <Stack rowGap={1} alignItems={isMobile ? 'center' : 'initial'}>
          <Typography
            variant={isMobile ? 'h6' : undefined}
            fontSize={!isMobile ? pickFontSize(isMobile) : undefined}
          >
            {title}
          </Typography>
          <Typography
            variant={isMobile ? undefined : 'h5'}
            fontSize={isMobile ? '14px' : undefined}
            sx={{ mb: 1 }}
          >
            {customer}
          </Typography>
        </Stack>
        <Stack rowGap={1} alignItems="center">
          <Button
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            onClick={primaryButton.onClick}
          >
            {primaryButton.title}
          </Button>
          <Button fullWidth variant="outlined" size="large" onClick={secondaryButton.onClick}>
            {secondaryButton.title}
          </Button>
          <Typography fontSize={14} color="rgba(0, 0, 0, 0.26)">
            {additionalText}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export const pickFontSize = (mobile: boolean | undefined) => (mobile ? 12 : 16);

export const InvoicePlatform: FC = () => {
  const isMobile = useResponsive('down', 'sm');
  const { isAuthenticated } = useAuth();
  const [forwardInvoiceDialogIsVisible, setForwardInvoiceDialogIsVisible] = useState(false);
  const [salesLeadDialogIsVisible, setSalesLeadDialogIsVisible] = useState(false);
  const [indicatorShadowIsVisible, setIndicatorShadowIsVisible] = useState(true);
  const [drawerIsVisible, setDrawerIsVisible] = useState(isMobile);
  const [statusCardIsVisibleOnMobile, setStatusCardIsVisibleOnMobile] = useState(isMobile);
  const navigate = useNavigate();
  const { id } = useParams();
  const classes = useStyles();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('success')) {
      toast.success(t('msg_invoice_platform_payment_success'));
    }
    if (searchParams.get('cancelled')) {
      toast.warning(t('msg_invoice_platform_payment_cancelled'));
    }
  }, []);

  const listenToScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

    if (bottom) {
      setIndicatorShadowIsVisible(false);
    }
    if (window.scrollY === 0) {
      setIndicatorShadowIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  if (!id) {
    return <Navigate to={ROUTES.ERROR_404} />;
  }

  const saveAsPdf = useSaveAsPdf(id);

  const { data: invoice, isLoading: isInvoiceLoading } = usePlatform(id);

  const { data: invoicePayLink, isLoading: isInvoicePayLinkLoading } = useInvoicePayLink(
    invoice?.id,
  );

  const t: TFunction = i18next.getFixedT(invoice?.language ?? LanguageCodes.en);

  useEffect(() => {
    if (invoice) {
      setAmplitudeUserId(invoice.user_id);
      sendAmplitudeData(AmplitudeEvent.PublicInvoiceOpened, {
        invoice_id: invoice.id,
      });
    }
  }, [invoice]);

  const onSaveAsPdf = async () => {
    if (!invoice?.pdf) {
      await saveAsPdf();
    } else {
      openInNewTab(invoice.pdf);
    }
  };

  const forwardPlatformInvoice = async (
    values: ForwardInvoiceFormFields,
    setErrors: FormikSetErrorsFn<ForwardInvoiceFormFields>,
  ) => {
    try {
      await model.forwardInvoice(id, values);
      toast.success(t('msg_success_invoice_sent'));
      setForwardInvoiceDialogIsVisible(false);
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };

  const registerSalesLead = async (
    values: SalesLeadFormFields,
    setErrors: FormikSetErrorsFn<SalesLeadFormFields>,
  ) => {
    try {
      sendAmplitudeData(AmplitudeEvent.SalesLeadRegistered, {
        product: 'b2b platform',
        email: values.email,
      });
      await model.registerMarketingLead({
        ...values,
        note: customer_name,
        campaign: 'platform',
      });
      toast.success(t('msg_success_email_registered'));
      setSalesLeadDialogIsVisible(false);
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };

  if (!invoice && !isInvoiceLoading) {
    return <Navigate to={ROUTES.ERROR_404} />;
  }

  if (isInvoiceLoading || isInvoicePayLinkLoading) {
    return <FullPageSpinner />;
  }

  const {
    number,
    company_name,
    company_address,
    company_license_no,
    company_vat_code,
    company_iban,
    customer_name,
    customer_vat_number,
    customer_company_number,
    customer_address,
    issue_date,
    payment_due,
    invoice_items,
    total,
    left_to_pay,
    notes,
    vat_sum,
    total_without_vat,
    spain_irpf_amount,
    invoice_type,
  } = invoice;

  return (
    <>
      <Grid
        container
        sx={{
          p: { xs: 1 },
          pt: { sm: 5 },
          flexDirection: { xs: 'column', sm: 'row' },
          flexWrap: { xs: 'nowrap', sm: 'wrap' },
        }}
        rowGap={{ xs: 1, sm: 4 }}
        columnSpacing={{ sm: 5 }}
      >
        <Grid item xs={0} sm={3}>
          <Grid container justifyContent="center">
            <Grid item>
              {!isMobile && (
                <Box
                  onClick={() => {
                    navigate(ROUTES.DEFAULT);
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Logo width={130} height={21} />
                </Box>
              )}
              {!isAuthenticated && !isMobile && (
                <SignInBlock
                  isMobile={isMobile}
                  title={t('msg_is_it_your_company')}
                  customer={customer_name}
                  primaryButton={{
                    title: t('msg_btn_sign_in'),
                    onClick: () => setSalesLeadDialogIsVisible(true),
                  }}
                  secondaryButton={{
                    title: t('msg_btn_create_account'),
                    onClick: () => setSalesLeadDialogIsVisible(true),
                  }}
                  additionalText={t('msg_create_account_soon')}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack
            direction="row"
            justifyContent={{ xs: 'space-between', sm: 'start' }}
            alignItems="center"
            spacing={2}
            sx={{ mb: 1.5, gap: { sm: '2px' } }}
          >
            {isMobile ? (
              <Box
                onClick={() => {
                  navigate(ROUTES.DEFAULT);
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Logo width={90} height={15} />
              </Box>
            ) : (
              <Typography
                variant="h5"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
              >
                {invoice_type === InvoiceType.invoice
                  ? t('msg_invoice_title')
                  : t('msg_proforma_title')}{' '}
                {number}
              </Typography>
            )}
            <InvoiceStatusLabel status={getInvoiceStatus(invoice)} tLanguage={invoice?.language} />
          </Stack>
          <Paper
            sx={{
              p: {
                xs: 1,
                sm: 2,
                md: 5,
              },
              position: 'relative',
            }}
          >
            <Typography variant={isMobile ? 'h6' : 'h3'}>
              {invoice_type === InvoiceType.invoice
                ? t('msg_invoice_title')
                : t('msg_proforma_title')}
            </Typography>
            <Typography fontSize={pickFontSize(isMobile)}>{number}</Typography>
            <Button
              variant="contained"
              size="large"
              color="grey"
              startIcon={<PictureAsPdfIcon />}
              onClick={() => onSaveAsPdf()}
              sx={{
                position: 'absolute',
                right: isMobile ? 8 : 16,
                top: isMobile ? 8 : 16,
              }}
            >
              {t('msg_btn_save_as_pdf')}
            </Button>

            <Grid
              container
              spacing={2}
              sx={{
                mt: {
                  xs: 2,
                  md: 8,
                },
              }}
            >
              <Grid item xs={6}>
                <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ mb: 1 }}>
                  {t('msg_invoice_billed_by')}
                </Typography>
                {company_name && (
                  <Typography noWrap fontSize={pickFontSize(isMobile)}>
                    {company_name}
                  </Typography>
                )}
                {company_address && (
                  <Typography fontSize={pickFontSize(isMobile)}>{company_address}</Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ mb: 1 }}>
                  {t('msg_invoice_billed_to')}
                </Typography>
                {customer_name && (
                  <Typography noWrap fontSize={pickFontSize(isMobile)}>
                    {customer_name}
                  </Typography>
                )}
                {customer_vat_number && (
                  <Typography fontSize={pickFontSize(isMobile)}>
                    {t('msg_pdf_invoice_customer_company_number')} {customer_company_number}
                  </Typography>
                )}
                {customer_vat_number && (
                  <Typography fontSize={pickFontSize(isMobile)}>
                    {t('msg_label_vat_number')} {customer_vat_number}
                  </Typography>
                )}
                {customer_address && (
                  <Typography fontSize={pickFontSize(isMobile)}>{customer_address}</Typography>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                mt: {
                  xs: 2,
                  md: 4,
                },
              }}
            >
              <Grid item xs={6}>
                <Typography fontSize={pickFontSize(isMobile)}>
                  {t('msg_label_invoice_date')}
                </Typography>
                <Typography fontSize={pickFontSize(isMobile)}>{formatDate(issue_date)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={pickFontSize(isMobile)}>
                  {t('msg_pdf_invoice_due_date')}
                </Typography>
                <Typography fontSize={pickFontSize(isMobile)}>{formatDate(payment_due)}</Typography>
              </Grid>
            </Grid>

            <InvoiceItemsTable
              items={invoice_items}
              total={total}
              total_without_vat={company_vat_code ? total_without_vat : undefined}
              vat_sum={company_vat_code ? vat_sum : undefined}
              isMobile={isMobile}
              tLanguage={invoice?.language}
              currency_symbol={invoice?.invoice_currency.symbol_native}
              irpfAmount={spain_irpf_amount}
            />

            <Divider
              sx={{
                my: {
                  xs: 2,
                  md: 5,
                },
              }}
            />

            <Box sx={{ pb: 5 }}>
              <Typography variant={isMobile ? 'h6' : 'h5'} sx={{ mb: 1 }}>
                {t('msg_comment')}
              </Typography>
              <Typography fontSize={pickFontSize(isMobile)}>{notes}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3} sx={{ pt: { md: 6 } }}>
          <Grid
            container
            rowGap={1}
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}
          >
            <StatusCard
              status={getInvoiceStatus(invoice)}
              total={getInvoiceStatus(invoice) === InvoiceStatus.Unpaid ? left_to_pay : total}
              paymentDue={payment_due}
              onButtonClick={() => {
                if (invoicePayLink?.url) {
                  window.location.href = invoicePayLink?.url;
                } else {
                  setForwardInvoiceDialogIsVisible(true);
                }
              }}
              buttonTitle={invoicePayLink?.url ? 'btn_pay_now' : 'btn_forward'}
              tLanguage={invoice?.language}
              currency_symbol={invoice.invoice_currency.symbol_native}
            />
            <DetailsAccordion
              title={company_name}
              details={[
                { title: t('msg_label_company_post_address'), description: company_address },
                { title: t('msg_label_company_license_no'), description: company_license_no },
                { title: t('msg_company_vat_no'), description: company_vat_code },
              ]}
            />
            <DetailsAccordion
              title={t('msg_payment_details')}
              details={[
                { title: t('msg_label_company_name'), description: company_name },
                { title: t('msg_label_company_iban'), description: company_iban },
                {
                  title: t('msg_label_invoice_amount'),
                  description: formatCurrency(
                    total,
                    true,
                    invoice.invoice_currency.symbol_native || DEFAULT_CURRENCY_SYMBOL,
                  ),
                },
                {
                  title: t('msg_tax_info_payment_purpose'),
                  description: `${t('msg_pdf_invoice_according_to')} ${number}`,
                },
              ]}
              expanded
            />
          </Grid>
        </Grid>
        {isMobile && (
          <Grid xs={12} sm={0}>
            <SignInBlock
              isMobile={isMobile}
              title={t('msg_is_it_your_company')}
              customer={customer_name}
              primaryButton={{
                title: t('msg_btn_sign_in'),
                onClick: () => setSalesLeadDialogIsVisible(true),
              }}
              secondaryButton={{ title: t('msg_btn_create_account') }}
              additionalText={t('msg_create_account_soon')}
            />
          </Grid>
        )}
      </Grid>
      {indicatorShadowIsVisible && isMobile && <Box className={classes.bottomShadow} />}
      {isMobile && statusCardIsVisibleOnMobile && (
        <SwipeableDrawer
          hideBackdrop
          anchor="bottom"
          open={drawerIsVisible}
          onOpen={() => setDrawerIsVisible(true)}
          onClose={() => setDrawerIsVisible(false)}
          disableScrollLock
        >
          <StatusCard
            status={getInvoiceStatus(invoice)}
            total={getInvoiceStatus(invoice) === InvoiceStatus.Unpaid ? left_to_pay : total}
            paymentDue={payment_due}
            onButtonClick={() => {
              if (invoicePayLink?.url) {
                window.location.href = invoicePayLink?.url;
              } else {
                setForwardInvoiceDialogIsVisible(true);
              }
            }}
            buttonTitle={invoicePayLink?.url ? 'btn_pay_now' : 'btn_forward'}
            tLanguage={invoice?.language}
            currency_symbol={invoice.invoice_currency.symbol_native}
            onCloseClick={() => setStatusCardIsVisibleOnMobile(false)}
          />
        </SwipeableDrawer>
      )}
      <ForwardInvoiceDialog
        open={forwardInvoiceDialogIsVisible}
        onSubmit={forwardPlatformInvoice}
        onClose={() => setForwardInvoiceDialogIsVisible(false)}
      />
      <SalesLeadDialog
        open={salesLeadDialogIsVisible}
        isMobile={isMobile}
        onSubmit={registerSalesLead}
        onClose={() => setSalesLeadDialogIsVisible(false)}
        tLanguage={invoice?.language}
      />
    </>
  );
};
