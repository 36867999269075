import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MerchantSelectorMenu } from '~/components/Selector/MerchantSelector/MerchantSelectorMenu';
import { model } from '~/models/Merchant';
import { Merchant } from '~/types/merchant';

import { SelectorBoxWrapper } from '../SelectorBox.style';

type Props = {
  name: string;
};

export const MerchantSelectorBox = ({ name }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [field, meta] = useField({ id: name, name });
  const [merchant, setMerchant] = useState<Merchant | undefined>();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const onSelectMerchant = (merchant?: Merchant) => {
    setMerchant(merchant);
    field.onChange({ target: { name, value: merchant?.id } });
    closeMenu();
  };

  const merchantInfo = () => (
    <Stack direction="row" justifyContent="space-between">
      <Stack padding={2}>
        <Typography sx={{ fontWeight: 700 }}>{merchant?.name}</Typography>
        <Typography>{merchant?.company_code}</Typography>
        <Typography>{merchant?.vat_number}</Typography>
        <Typography>{merchant?.address}</Typography>
      </Stack>
      <Box>
        <Button onClick={openMenu} variant="square" startIcon={<EditIcon />}>
          {t('msg_btn_edit')}
        </Button>
      </Box>
    </Stack>
  );

  const fetchInitialMerchant = async () => {
    if (!meta.initialValue || isLoading) return;

    setIsLoading(true);

    try {
      const merchant = await model.get(meta.initialValue);
      setMerchant(merchant);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitialMerchant();
  }, [meta.initialValue]);

  const merchantSelector = () => (
    <Button variant="square" onClick={openMenu} sx={{ height: 1 }} fullWidth>
      <Stack direction="column" justifyContent="center" alignItems="center" sx={{ w: 1 }}>
        <AddCircleIcon color="primary" />
        <Typography variant="button" textTransform="none" color="primary">
          {t('msg_label_add_merchant')}
        </Typography>
      </Stack>
    </Button>
  );

  return (
    <>
      <SelectorBoxWrapper ref={ref} error={!!meta.error && meta.touched}>
        {merchant ? merchantInfo() : merchantSelector()}
      </SelectorBoxWrapper>
      <MerchantSelectorMenu
        open={open}
        anchorEl={ref?.current}
        onSelectMerchant={onSelectMerchant}
        onClose={closeMenu}
      />
    </>
  );
};
