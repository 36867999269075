import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerSelectorMenu } from '~/components/Selector/CustomerSelector/CustomerSelectorMenu';
import { model } from '~/models/Customer';
import { Customer } from '~/types/customer';

import { SelectorBoxWrapper } from '../SelectorBox.style';

type Props = {
  name: string;
};

export const CustomerSelectorBox = ({ name }: Props) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [field, meta] = useField({ id: name, name });
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const onSelectCustomer = (customer?: Customer) => {
    setCustomer(customer);
    field.onChange({ target: { name, value: customer?.id } });
    closeMenu();
  };

  const customerInfo = () => (
    <Stack direction="row" justifyContent="space-between">
      <Stack padding={2}>
        <Typography sx={{ fontWeight: 700 }}>{customer?.name}</Typography>
        <Typography>{customer?.company_number}</Typography>
        <Typography>{customer?.vat_number}</Typography>
        <Typography>{customer?.address}</Typography>
      </Stack>
      <Box>
        <Button onClick={openMenu} variant="square" startIcon={<EditIcon />}>
          {t('msg_btn_edit')}
        </Button>
      </Box>
    </Stack>
  );

  const fetchInitialCustomer = async () => {
    if (!meta.initialValue || isLoading) return;

    setIsLoading(true);

    try {
      const customer = await model.get(meta.initialValue);
      setCustomer(customer);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchInitialCustomer();
  }, [meta.initialValue]);

  const customerSelector = () => (
    <Button variant="square" onClick={openMenu} sx={{ height: 1 }} fullWidth>
      <Stack direction="column" justifyContent="center" alignItems="center" sx={{ w: 1 }}>
        <AddCircleIcon color="primary" />
        <Typography variant="button" textTransform="none" color="primary">
          {t('msg_label_add_customer')}
        </Typography>
      </Stack>
    </Button>
  );

  return (
    <>
      <SelectorBoxWrapper ref={ref} error={!!meta.error && meta.touched}>
        {customer ? customerInfo() : customerSelector()}
      </SelectorBoxWrapper>
      <CustomerSelectorMenu
        open={open}
        anchorEl={ref?.current}
        onSelectCustomer={onSelectCustomer}
        onClose={closeMenu}
      />
    </>
  );
};
