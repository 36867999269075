import {
  styled,
  MenuItem as MuiMenuItem,
  Popover as MuiPopover,
  ListItemText as MuiListItemText,
} from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const Popover = styled(MuiPopover, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number }>(({ theme, width = 360 }) => ({
  '.MuiPaper-root': {
    minWidth: Math.max(width, 360),
    maxWidth: Math.max(width, 360),
    boxShadow: theme.shadows[20],
  },
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 2),

  '& .MuiListItemIcon-root': {
    minWidth: pxToRem(36),
  },
}));

export const FooterMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  justifyContent: 'center',
  color: theme.palette.primary.main,
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}));

export const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  paddingRight: theme.spacing(2),
}));
