import { Customer } from '~/types/customer';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<Customer>[] = [
  {
    id: 'name',
    label: 'msg_label_customer',
    sortable: true,
  },
  {
    id: 'company_number',
    label: 'msg_label_customer_code',
  },
  {
    id: 'invoice_count',
    label: 'msg_label_customer_invoices',
  },
  {
    id: 'amount_due',
    label: 'msg_label_customer_due_payments',
  },
  {
    id: 'id',
    label: '',
  },
];
