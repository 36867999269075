import axios from 'axios';
import { ICountryCodeItem } from 'country-calling-code';
import { useEffect, useState } from 'react';

import { logger } from '~/utils/logger';

interface Response {
  data: {
    country_code: string;
    country_name: string;
  };
}

export const useUsersLocationByIp = () => {
  const [location, setLocation] = useState<{ country: string; countryCode: string }>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getUsersLocationByIp = () => {
      setIsLoading(true);
      const url = 'https://ipapi.co/json/';
      axios
        .get(url)
        .then((response: Response) => {
          setLocation({
            country: response.data.country_name,
            countryCode: response.data.country_code,
          });
        })
        .catch((error) => {
          logger.logException(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getUsersLocationByIp();
  }, []);
  return { location, isLoading };
};
