import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';

import { openInNewTab } from '~/utils/openInNewTab';

import { model } from '../invoices';

export const useSaveAsPdf = (id: string) => {
  const { t } = useTranslation();

  const saveAsPdf = async () => {
    try {
      const pdfResponse = await model.pdf(id);
      openInNewTab(pdfResponse.pdf);
    } catch (error) {
      toast.error(t('msg_error_invoice_save_failed'));
    }
  };

  return saveAsPdf;
};
