import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikDatePicker } from '~/components/Form/DatePicker';
import { FormattedFormikTextField } from '~/components/Form/TextField';
import { CenteredSpinner } from '~/components/Spinner';
import { model } from '~/models/Invoices';
import { invoicesIndexQueryKey, useInvoice } from '~/models/Invoices/hooks';
import { linkableIncomesQueryKey } from '~/models/Invoices/hooks/useLinkableIncomes';
import { colors, text } from '~/theme/colors';
import { ValidationError } from '~/types/error';
import { MarkAsPaidFormFields, Operation, OperationType } from '~/types/invoice';
import { queryClient } from '~/utils';
import { formatDateShort } from '~/utils/formatDate';
import {
  centsToSumInputValue,
  centsToSum,
  formatNumber,
  formatCurrency,
} from '~/utils/formatNumber';
import { logger } from '~/utils/logger';

import { validationSchema } from './validationSchema';

export type Props = {
  invoiceId: string;
  open: boolean;
  onClose: () => void;
  linkableIncomes?: Operation[];
  currencySymbol: string;
  onInvoiceRefetch?: () => void;
};

const chooseIncomeIcon = (type: OperationType) => {
  switch (type) {
    case OperationType.income:
      return <PaymentsOutlinedIcon style={{ color: text.secondary }} />;
    case OperationType.personalOperation:
      return <AccountBalanceOutlinedIcon style={{ color: text.secondary }} />;
    default:
      return;
  }
};

export const MarkInvoiceAsPaidDialog = ({
  invoiceId,
  open,
  onClose,
  linkableIncomes,
  currencySymbol,
  onInvoiceRefetch,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useInvoice(invoiceId);
  const date = formatDateShort(new Date().toISOString(), 'yyyy-MM-dd');
  const [isLinkingIncome, setIsLinkingIncome] = useState(false);

  const initialValues: MarkAsPaidFormFields = {
    amount: data ? centsToSumInputValue(data?.left_to_pay) : '',
    date,
  };

  const onSubmit = async (
    values: MarkAsPaidFormFields,
    { setErrors }: FormikHelpers<MarkAsPaidFormFields>,
  ) => {
    try {
      const { amount, date } = values;

      await model.addPayment(invoiceId, {
        amount: formatNumber(amount),
        date,
      });

      toast.success(t('msg_success_invoice_marked_paid'));
      queryClient.invalidateQueries([invoicesIndexQueryKey]);
      queryClient.invalidateQueries([invoicesIndexQueryKey, invoiceId]);
      onClose();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);

        error.nonFieldErrors.forEach((message) => toast.error(t(message)));
      } else {
        toast.error(t('msg_error_invoice_mark_paid_failed'));
      }
    }
  };

  const onLinkIncomeToInvoice = async (incomeId: number, resetFormCallback: () => void) => {
    try {
      setIsLinkingIncome(true);
      await model.linkIncomeToInvoice(invoiceId, incomeId);
      onInvoiceRefetch?.();
      resetFormCallback();
      queryClient.invalidateQueries([linkableIncomesQueryKey]);
      onClose();
      setIsLinkingIncome(false);
    } catch (e) {
      setIsLinkingIncome(false);
      logger.logException(e);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack>
                <Box>{t('msg_title_mark_paid')}</Box>
              </Stack>
              <IconButton
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <>
              <Grid
                container
                gap={1}
                alignItems="center"
                paddingX={3}
                paddingBottom={linkableIncomes?.length ? 0 : 2}
              >
                <Grid xs={5} item>
                  <FormattedFormikTextField
                    name="amount"
                    label={`${t('msg_pdf_invoice_amount')}*`}
                    variant="filled"
                    color="grey"
                    max={data ? centsToSum(data.left_to_pay) : undefined}
                    autoFocus
                    fullWidth
                  />
                </Grid>

                <Grid xs={5} item>
                  <FormikDatePicker
                    name="date"
                    label={`${t('msg_label_invoice_date')}*`}
                    variant="filled"
                    color="grey"
                    fullWidth
                  />
                </Grid>
                <Grid xs={1} item>
                  <LoadingButton
                    onClick={submitForm}
                    variant="contained"
                    color="primary"
                    size="large"
                    loading={isSubmitting}
                  >
                    {t('msg_btn_save')}
                  </LoadingButton>
                </Grid>
              </Grid>
              {!!linkableIncomes?.length && (
                <Stack
                  marginTop={3}
                  paddingY={2}
                  paddingX={3}
                  sx={{ backgroundColor: colors.background.default }}
                >
                  <Typography
                    fontSize={16}
                    fontWeight="500"
                    color={text.secondary}
                    marginBottom={2}
                  >
                    {t('msg_suggested_incomes')}
                  </Typography>
                  <List style={{ maxHeight: '220px', overflow: 'auto' }}>
                    {isLinkingIncome || isSubmitting ? (
                      <CenteredSpinner height={220} />
                    ) : (
                      linkableIncomes.map((item) => {
                        return (
                          <ListItem key={item.id} sx={{ px: 0, py: 0.5 }}>
                            <Box
                              padding={2}
                              sx={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                borderRadius: '4px',
                                backgroundColor: colors.background.paper,
                              }}
                              onClick={() => onLinkIncomeToInvoice(item.id, resetForm)}
                            >
                              <Grid container>
                                <Grid item xs={6}>
                                  <Stack flexDirection="row" gap={1} alignItems="center">
                                    {chooseIncomeIcon(item.operation_type)}
                                    <Typography fontSize={14} fontWeight="400" color={text.primary}>
                                      {t(item.title)}
                                    </Typography>
                                  </Stack>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography
                                    fontSize={14}
                                    fontWeight="400"
                                    color={text.primary}
                                    textAlign="right"
                                  >
                                    {formatDateShort(item.created_at)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography
                                    fontSize={14}
                                    fontWeight="400"
                                    color={text.primary}
                                    textAlign="right"
                                  >
                                    {formatCurrency(item.amount, true, currencySymbol)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </ListItem>
                        );
                      })
                    )}
                  </List>
                </Stack>
              )}
            </>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
};
