import { useMutation } from '@tanstack/react-query';

import { Merchant } from '~/types/merchant';
import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '../';

import { merchantsQueryKey } from './useMerchants';

export const useCreateMerchant = ({ onSuccess }: MutationProps<Merchant>) =>
  useMutation(model.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([merchantsQueryKey]);
      onSuccess?.(data);
    },
  });
