import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  customer: Yup.string().nullable(),
  name: Yup.string().nullable(),
  issue_date: Yup.string()
    .typeError('msg_error_required_field')
    .required('msg_error_required_field'),
  amount: Yup.string().required(''),
});
