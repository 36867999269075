import { useMutation } from '@tanstack/react-query';
import { FormikErrors } from 'formik';
import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAppConfig } from '~/context/AppConfigContext';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Auth';
import { LoginFormValues } from '~/pages/Auth/Login/Login';
import { ROUTES } from '~/router/Routes';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { sendAmplitudeData } from '~/utils/amplitude';
import { api } from '~/utils/api';
import { logger } from '~/utils/logger';

import { TokenResponse } from './useEmailRegistration';

const URL = '/api/auth/email/login/';

export interface EmailLoginForm {
  email: string;
  password: string;
}

export const useEmailLogin = (onError?: (errors: FormikErrors<LoginFormValues>) => void) => {
  const { setTokenToLocalStorage } = useAuth();
  const navigate = useNavigate();
  const { onChangeLanguage } = useAppConfig();

  const { t } = useTranslation();
  const mutation = useMutation({
    mutationKey: ['emailLogin'],
    mutationFn: (data: EmailLoginForm) => api.post<TokenResponse>(URL, data),
    onError: (err: unknown) => {
      logger.logException(err);

      sendAmplitudeData(AmplitudeEvent.LoginAuthenticated, {
        id_type: 'email',
        method: 'password',
        status: 'failed',
      });
      if (err instanceof ValidationError) {
        onError?.(err.formikErrors);
        err.error && toast.error(t(err.error));
      }
    },
    onSuccess: async (res: TokenResponse) => {
      await setTokenToLocalStorage(res.token);
      const user = await model.currentUser();
      user?.language && onChangeLanguage(user?.language);
      sendAmplitudeData(AmplitudeEvent.LoginAuthenticated, {
        id_type: 'email',
        method: 'password',
        status: 'success',
      });
      navigate(ROUTES.DASHBOARD_INVOICES);
    },
  });
  return mutation;
};
