import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { FullPageSpinner } from '~/components/Spinner';
import { useAuth } from '~/context/AuthContext';
import { ROUTES } from '~/router/Routes';

type Props = {
  children: React.ReactNode;
  mustCompleteRegistration?: boolean;
  redirectIfRegistrationComplete?: boolean;
};

export const AuthGuard = ({
  children,
  mustCompleteRegistration = true,
  redirectIfRegistrationComplete = false,
}: Props) => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <FullPageSpinner />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={ROUTES.DEFAULT} />;
  }

  if (mustCompleteRegistration && !user.completed_registration) {
    return <Navigate to={ROUTES.REGISTER} />;
  }

  if (redirectIfRegistrationComplete && user.completed_registration) {
    return <Navigate to={ROUTES.DASHBOARD} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};
