import { IconProps } from '~/types/icon';

export const Expenses = (props: IconProps) => {
  const { size = '24', color = 'currentColor', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.75 14.5312C20.3438 13.9688 19.5625 13.8438 19 14.25L15.25 17.0312H11.4688C11.2188 17.0312 11 16.7812 11 16.5312C11 16.25 11.25 16.0312 11.4688 16.0312H13.9375C14.4062 16.0312 14.875 15.6875 14.9688 15.1875C15.0625 14.5625 14.5938 14.0312 13.9688 14.0312H8.96875C8.15625 14.0312 7.28125 14.3125 6.625 14.8438L5.1875 16.0312L3.46875 16C3.21875 16 3 16.25 3 16.5V19.5C3 19.7812 3.21875 20 3.46875 20H14.2812C14.9688 20 15.6562 19.7812 16.1875 19.375L20.4375 16.2812C21.0312 15.875 21.1562 15.0938 20.75 14.5312ZM11.7188 9.46875C11.4688 9.4375 11.1562 9.34375 10.875 9.25L10.75 9.1875C10.3438 9.03125 9.90625 9.25 9.78125 9.625C9.65625 9.96875 9.84375 10.4375 10.2188 10.5625L10.375 10.625C10.6562 10.7188 10.9375 10.8125 11.25 10.875V11.25C11.25 11.6875 11.5625 12 12 12C12.4062 12 12.75 11.6875 12.75 11.25V10.9375C13.5312 10.75 14.0625 10.25 14.1875 9.5C14.4375 7.9375 12.9688 7.53125 12.1562 7.28125L12 7.25C11.1875 7 11.2188 6.90625 11.25 6.78125C11.2812 6.5625 11.7188 6.46875 12.25 6.53125C12.4062 6.5625 12.5938 6.625 12.875 6.71875C13.25 6.84375 13.6875 6.65625 13.8125 6.25C13.9375 5.84375 13.75 5.4375 13.3438 5.28125C13.125 5.21875 12.9062 5.15625 12.75 5.125V4.75C12.75 4.34375 12.4062 4 12 4C11.5625 4 11.25 4.34375 11.25 4.75V5.09375C10.4375 5.28125 9.90625 5.78125 9.75 6.53125C9.5 8.0625 11 8.5 11.5625 8.6875L11.7812 8.75C12.7812 9.03125 12.75 9.09375 12.7188 9.25C12.6875 9.46875 12.25 9.5625 11.7188 9.46875Z"
        fill={color}
      />
    </svg>
  );
};
