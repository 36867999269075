import { Box, CircularProgress } from '@mui/material';

type Props = {
  height?: number;
};

export const CenteredSpinner = ({ height = 200 }: Props) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    height={height}
  >
    <CircularProgress />
  </Box>
);
