import { IconProps } from '~/types/icon';

export const Attachment = (props: IconProps) => {
  const { size = '24', color = 'currentColor', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.25 6.32031C15.4688 5.57031 14.25 5.57031 13.4688 6.32031L7.71875 12.0703C6.40625 13.3828 6.40625 15.5391 7.71875 16.8516C9.03125 18.1641 11.1875 18.1641 12.5 16.8516L17.25 12.1016C17.5938 11.7578 18.125 11.7578 18.4688 12.1016C18.8125 12.4453 18.8125 12.9766 18.4688 13.3203L13.7188 18.0703C11.7188 20.0703 8.5 20.0703 6.5 18.0703C4.5 16.0703 4.5 12.8516 6.5 10.8516L12.25 5.10156C13.6875 3.63281 16.0312 3.63281 17.4688 5.10156C18.9375 6.53906 18.9375 8.88281 17.4688 10.3203L11.9688 15.8203C11.0938 16.7266 9.625 16.7266 8.75 15.8203C7.84375 14.9453 7.84375 13.4766 8.75 12.6016L13.25 8.10156C13.5938 7.75781 14.125 7.75781 14.4688 8.10156C14.8125 8.44531 14.8125 8.97656 14.4688 9.32031L9.96875 13.8203C9.78125 14.0391 9.78125 14.3828 9.96875 14.6016C10.1875 14.7891 10.5312 14.7891 10.75 14.6016L16.25 9.10156C17 8.32031 17 7.10156 16.25 6.32031Z"
        fill={color}
        fillOpacity="0.54"
      />
    </svg>
  );
};
