import { FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { model } from '~/models/InvoiceItemTemplate';
import { useEditInvoiceItemTemplate } from '~/models/InvoiceItemTemplate/hooks';
import { ValidationError } from '~/types/error';
import { InvoiceItemTemplate, InvoiceItemTemplateFormFields } from '~/types/invoiceItemTemplate';
import { formatCurrencyNumber, formatNumber } from '~/utils/formatNumber';

import { initialEmptyValues } from './config';
import { ServiceFormDialog } from './ServiceFormDialog';

type Props = {
  id: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: (data: InvoiceItemTemplate) => void;
};

export const EditServiceFormDialog = ({ id, open, onClose, onSuccess }: Props) => {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] =
    useState<InvoiceItemTemplateFormFields>(initialEmptyValues);
  const { mutateAsync } = useEditInvoiceItemTemplate({
    onSuccess: (data) => {
      if (data && onSuccess) {
        onSuccess(data);
      }
      onClose();
    },
  });

  const fetchInvoiceItemTemplate = async () => {
    const { default_item_name, default_amount, default_price, notes } = await model.get(id);

    const initialValues: InvoiceItemTemplateFormFields = {
      default_item_name,
      default_amount: Number(default_amount),
      default_price: formatCurrencyNumber(default_price),
      notes,
    };

    setInitialValues(initialValues);
  };

  const onSubmit = async (
    { default_item_name, default_amount, default_price, notes }: InvoiceItemTemplateFormFields,
    { setErrors, resetForm }: FormikHelpers<InvoiceItemTemplateFormFields>,
  ) => {
    try {
      const data = {
        default_item_name,
        default_amount: Number(default_amount),
        default_price: formatNumber(default_price),
        notes,
      };

      await mutateAsync({ id, data });
      resetForm();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((error) => toast.error(t(error)));
      }
    }
  };

  useEffect(() => {
    if (!open) return;

    fetchInvoiceItemTemplate();
  }, [open]);

  return (
    <ServiceFormDialog
      title="msg_edit_service_title"
      open={open}
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
