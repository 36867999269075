import { alpha, Button as MuiButton, styled } from '@mui/material';

export const Button = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  padding: theme.spacing(0.5),
  minWidth: 0,
  borderRadius: theme.spacing(0.5),

  ...(active && {
    '& .MuiTouchRipple-root': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  }),
}));
