import i18next from 'i18next';
import { MessengerChat } from 'react-messenger-chat-plugin';

import { colors } from '~/theme/colors';

export const SupportChat = ({
  supportChatMessengerId,
  welcomeTitle,
}: {
  supportChatMessengerId: number;
  welcomeTitle: string;
}) => {
  return (
    <MessengerChat
      pageId={supportChatMessengerId.toString()}
      language={i18next.language}
      themeColor={colors.primary.main}
      loggedInGreeting={welcomeTitle}
      loggedOutGreeting={welcomeTitle}
      greetingDialogDisplay="hide"
    />
  );
};
