import { Box, Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

export const SpacedButton = ({ children, ...rest }: ButtonProps) => (
  <Button {...rest}>
    <Box sx={{ width: 1 }} textAlign="center">
      {children}
    </Box>
  </Button>
);
