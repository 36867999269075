import { Invoice } from '~/types/invoice';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<Invoice>[] = [
  {
    id: 'customer__name',
    label: 'msg_label_customer',
    sortable: true,
  },
  {
    id: 'number',
    label: 'msg_label_invoice_number',
    sortable: true,
  },
  {
    id: 'payment_status',
    label: 'msg_label_status',
  },
  {
    id: 'issue_date',
    label: 'msg_label_invoice_date',
    sortable: true,
  },
  {
    id: 'total',
    label: 'msg_pdf_invoice_amount',
    numeric: true,
  },
  {
    id: 'id',
    label: '',
  },
];
