import { TableRow as MuiTableRow, TableCell, Typography } from '@mui/material';

import { pickFontSize } from '~/pages/Invoices/InvoicePlatform';
import { InvoiceItem } from '~/types/invoice';
import { formatCurrency, formatNumber } from '~/utils/formatNumber';

type Props = {
  index: number;
  row: InvoiceItem;
  isMobile?: boolean;
  currencySymbolNative: string;
};

export const TableRow = ({ index, row, isMobile, currencySymbolNative }: Props) => (
  <MuiTableRow>
    <TableCell sx={{ pl: 0 }}>
      <Typography fontSize={pickFontSize(isMobile)}>{index + 1}</Typography>
    </TableCell>
    <TableCell padding="none" sx={{ p: 0 }}>
      <Typography fontSize={pickFontSize(isMobile)}>{row.item}</Typography>
    </TableCell>
    <TableCell sx={{ p: { xs: 1, sm: 2 } }}>
      <Typography fontSize={pickFontSize(isMobile)}>{formatNumber(row.amount)}</Typography>
    </TableCell>
    <TableCell padding="none" sx={{ width: { sm: '115px' } }}>
      <Typography fontSize={pickFontSize(isMobile)}>
        {' '}
        {formatCurrency(row.price, true, currencySymbolNative)}
      </Typography>
    </TableCell>
    <TableCell padding="none" align="right" sx={{ width: { sm: '115px' } }}>
      <Typography fontSize={pickFontSize(isMobile)}>
        {formatCurrency(row.total, true, currencySymbolNative)}
      </Typography>
    </TableCell>
  </MuiTableRow>
);
