/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton, TableCell, TableRow, Typography } from '@mui/material';

import { HeadCell } from '~/types/table';

type Props = {
  headCells: HeadCell<any>[];
  hasCheckbox?: boolean;
};

export const TableRowSkeleton = ({ headCells, hasCheckbox = false }: Props) => {
  return (
    <TableRow>
      {Array(headCells.length + (hasCheckbox ? 2 : 1))
        .fill(0)
        .map((_, index) => (
          <TableCell key={index}>
            <Typography variant="body2">
              <Skeleton />
            </Typography>
          </TableCell>
        ))}
    </TableRow>
  );
};
