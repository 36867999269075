import { useQuery } from '@tanstack/react-query';

import { InvoiceType } from '~/types/invoice';

import { model } from '../';

export type PdfTemplate = {
  id: number;
  description: string;
  is_default: boolean;
  is_active: boolean;
};

export type PaginatedItems<T> = {
  items: T[];
  count: number;
  isFetching: boolean;
  currentPage: number;
  offset: number;
  totalPages: number;
};

export const useInvoiceTemplateList = (invoiceType?: InvoiceType) => {
  const query = useQuery<PaginatedItems<PdfTemplate>>({
    queryFn: () => model.getInvoiceTemplateList(invoiceType),
    queryKey: ['invoiceTemplateList', invoiceType],
  });
  return query;
};
