import { Theme } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    grey: true;
  }
}

export const Input = (theme: Theme) => {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: `${theme.palette.grey[300]} !important`,
            borderColor: theme.palette.grey[300],
          },
        },
        input: {
          '&::-webkit-contacts-auto-fill-button': {
            visibility: 'hidden',
            display: 'none !important',
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: pxToRem(4),
          '&, &:hover, &.Mui-focused': {
            backgroundColor: theme.palette.common.white,
          },
          '&[autocomplete="off"]::-webkit-contacts-auto-fill-button, &[autocomplete="off"]::-webkit-credentials-auto-fill-button':
            {
              visibility: 'hidden',
              display: 'none !important',
              height: 0,
              width: 0,
              margin: 0,
            },
          '&.MuiInputBase-colorGrey': {
            backgroundColor: theme.palette.background.default,
          },
        },
        underline: {
          borderWidth: pxToRem(1),
          borderStyle: 'solid',
          borderColor: 'transparent',
          '&:before, &:after': {
            display: 'none',
          },
          '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
          },
        },
        error: {
          '&, &.Mui-focused': {
            borderColor: `${theme.palette.error.main} !important`,
          },
        },
      },
    },
  };
};
