import { createContext, useContext, useState } from 'react';

type RegistrationStepperContextType = {
  activeStep: number;
  setActiveStep: (step: number) => void;
};

const RegistrationStepperContext = createContext<RegistrationStepperContextType>({
  activeStep: 0,
  setActiveStep: (_step: number) => undefined,
});

export const RegistrationStepperProvider = ({ children }: React.PropsWithChildren) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <RegistrationStepperContext.Provider value={{ activeStep, setActiveStep }}>
      {children}
    </RegistrationStepperContext.Provider>
  );
};

export const useRegistrationStepper = () => useContext(RegistrationStepperContext);
