import { useMutation } from '@tanstack/react-query';

import { AmplitudeEvent } from '~/types/amplitude';
import { sendAmplitudeData } from '~/utils/amplitude';
import { api } from '~/utils/api';
import { logger } from '~/utils/logger';

import { model } from '../aiAgentChat';

interface TokenResponse {
  id: number;
}

export const useInitAIAgentChatSession = (onSuccess: (id: number) => void) => {
  const mutation = useMutation({
    mutationFn: () => api.post<TokenResponse>(model.init()),
    onError: (err: unknown) => logger.logException(err),
    onSuccess: (res: TokenResponse) => {
      sendAmplitudeData(AmplitudeEvent.AgentChatOpened, { sessionId: res.id });
      onSuccess(res.id);
    },
  });
  return mutation;
};
