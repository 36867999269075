import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Stack } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

import { Button } from '.';

type Props = ButtonProps & {
  active?: boolean;
};

export const ActionsButton = ({ children, active = false, ...rest }: Props) => (
  <Button active={active} {...rest}>
    <Stack color="text.primary" justifyContent="center">
      {children || <MoreVertIcon />}
    </Stack>
  </Button>
);
