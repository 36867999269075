import { useQuery } from '@tanstack/react-query';

import { Invoice } from '~/types/invoice';

import { model } from '../';

import { invoicesIndexQueryKey } from './useInvoices';

export const usePlatform = (id: string) =>
  useQuery<Invoice>([invoicesIndexQueryKey, id], () => model.getPublicInvoice(id));
