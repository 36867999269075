import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Stack, Avatar, IconButton, Typography, ListItemIcon, Menu, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EditCustomerFormDialog } from '~/components/Dialog/CustomerFormDialog';
import { DeleteCustomerDialog } from '~/components/Dialog/DeleteCustomerDialog';
import { Customer } from '~/types/customer';

import { ListItemText, MenuItem } from './CustomerSelector.style';

type Props = {
  customer: Customer;
  onSelectCustomer: (customer: Customer) => void;
};

export const CustomerSelectorMenuItem = ({ customer, onSelectCustomer }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteCustomerDialog, setShowDeleteCustomerDialog] = useState(false);
  const open = Boolean(anchorEl);

  const { id, name, company_number, can_be_deleted } = customer;

  const onClickActions = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onCloseActions = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem onClick={() => onSelectCustomer(customer)}>
        <ListItemIcon>
          <Avatar
            sx={{ width: 35, height: 35, backgroundColor: 'grey.200', color: 'text.primary' }}
          >
            <Typography variant="caption">{name.slice(0, 2).toUpperCase()}</Typography>
          </Avatar>
        </ListItemIcon>

        <ListItemText>
          <Typography sx={{ maxWidth: 280, pl: 1 }} noWrap>
            {name}
          </Typography>
        </ListItemText>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography>{company_number}</Typography>

          <Box width={40}>
            {id > 0 && (
              <IconButton onClick={onClickActions}>
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </Stack>
      </MenuItem>

      <Menu anchorEl={anchorEl} open={open} onClose={onCloseActions}>
        <MenuItem onClick={() => setShowEditDialog(true)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t('msg_btn_edit_customer')}</ListItemText>
        </MenuItem>
        {can_be_deleted && (
          <MenuItem
            onClick={() => setShowDeleteCustomerDialog(true)}
            sx={{ color: (theme) => theme.palette.error.main }}
          >
            <ListItemIcon>
              <DeleteIcon sx={{ color: (theme) => theme.palette.error.main }} />
            </ListItemIcon>
            <ListItemText>{t('msg_btn_delete_customer')}</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <EditCustomerFormDialog
        id={id}
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
      />

      <DeleteCustomerDialog
        id={id}
        open={showDeleteCustomerDialog}
        onClose={() => setShowDeleteCustomerDialog(false)}
      />
    </>
  );
};
