import { useQuery } from '@tanstack/react-query';

import { EnabledLanguage } from '~/types/appConfig';
import { api } from '~/utils/api';

const URL = (countryCode: string | undefined) =>
  `api/cls/languages/enabled/?country_code=${countryCode}`;

export const useEnabledAppLanguages = (countryCode: string | undefined) => {
  const query = useQuery<EnabledLanguage[]>({
    queryFn: () => api.get(URL(countryCode)),
    queryKey: ['enabledAppLanguages'],
    enabled: !!countryCode,
  });
  return query;
};
