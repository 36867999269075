import { IncomeFormFields } from '~/types/income';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<IncomeFormFields>[] = [
  {
    id: 'notes',
    label: 'msg_comment',
    width: '70%',
  },
  {
    id: 'amount',
    label: 'msg_label_income_amount',
    width: '30%',
  },
];
