import { IconProps } from '~/types/icon';

export const Income = (props: IconProps) => {
  const { size = '24', color = 'currentColor', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.5 5H6.5C5.65625 5 5 5.6875 5 6.5V14.5C5 15.3438 5.65625 16 6.5 16H20.5C21.3125 16 22 15.3438 22 14.5V6.5C22 5.6875 21.3125 5 20.5 5ZM6.5 14.5V12.5C7.59375 12.5 8.5 13.4062 8.5 14.5H6.5ZM6.5 8.5V6.5H8.5C8.5 7.625 7.59375 8.5 6.5 8.5ZM13.4688 13.5C12.0625 13.5 10.9688 12.1875 10.9688 10.5C10.9688 8.84375 12.0625 7.5 13.4688 7.5C14.8438 7.5 15.9688 8.84375 15.9688 10.5C15.9688 12.1562 14.875 13.5 13.4688 13.5ZM20.5 14.5H18.5C18.5 13.4062 19.375 12.5 20.5 12.5V14.5ZM20.5 8.5C19.375 8.5 18.5 7.625 18.5 6.5H20.5V8.5ZM18 19H5.75C3.65625 19 2 17.3438 2 15.25V7.75C2 7.34375 2.3125 7 2.75 7C3.15625 7 3.5 7.34375 3.5 7.75V15.25C3.5 16.5 4.5 17.5 5.75 17.5H18C18.4062 17.5 18.75 17.8438 18.75 18.25C18.75 18.6875 18.4062 19 18 19Z"
        fill={color}
      />
    </svg>
  );
};
