import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TableCell } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteServiceDialog } from '~/components/Dialog/DeleteServiceDialog';
import { EditServiceFormDialog } from '~/components/Dialog/ServiceFormDialog';
import { MenuItem, TableActionsMenu } from '~/components/Menu/TableActionsMenu';
import { ClickableTableRow } from '~/components/Table';
import { useAuth } from '~/context/AuthContext';
import { Color } from '~/types/color';
import { InvoiceItemTemplate } from '~/types/invoiceItemTemplate';
import { formatCurrency } from '~/utils/formatNumber';

type Props = {
  row: InvoiceItemTemplate;
};

export const TableRow = ({ row }: Props) => {
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { id, default_item_name, default_price } = row;

  const onEdit = () => setShowEditDialog(true);

  const menuItems: MenuItem[] = [
    {
      label: t('msg_btn_edit_service'),
      icon: <EditIcon />,
      onClick: onEdit,
    },
    {
      label: t('msg_btn_delete'),
      icon: <DeleteIcon color="error" />,
      onClick: () => setShowDeleteDialog(true),
      color: Color.Error,
    },
  ];

  return (
    <>
      <ClickableTableRow hover>
        <TableCell onClick={onEdit}>{default_item_name}</TableCell>
        <TableCell align="right" onClick={onEdit}>
          {formatCurrency(default_price, true, base_currency.symbol_native)}
        </TableCell>
        <TableCell padding="checkbox">
          <TableActionsMenu menuItems={menuItems} />
        </TableCell>
      </ClickableTableRow>

      <EditServiceFormDialog
        id={id}
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
      />

      <DeleteServiceDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
