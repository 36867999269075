import { useQuery } from '@tanstack/react-query';

import { AvailableCompaniesResponse } from '~/types/company';

import { model } from '../';

export const queryKey = ['availableCompanies'];

export const useAvailableCompanies = () =>
  useQuery<AvailableCompaniesResponse>(queryKey, () => model.availableCompanies());
