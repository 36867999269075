import { TextField, TextFieldProps } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { useField } from 'formik';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type Props = TextFieldProps & {
  name: string;
  value?: any;
  onChange?: (val: string) => void;
};

export const FormikDatePicker = ({ name, onChange, value: passedValue, ...props }: Props) => {
  const { t } = useTranslation();
  const [field, meta] = useField({ id: name, name });
  const [value, setValue] = useState<DateTime | null>(
    field.value ? DateTime.fromISO(field.value) : null,
  );
  useEffect(() => {
    passedValue && setValue(DateTime.fromISO(passedValue));
  }, [passedValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18next.language}>
      <DesktopDatePicker
        label="Date desktop"
        inputFormat="yyyy.LL.dd"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          field.onChange({
            target: {
              name: field.name,
              value: newValue?.toISODate(),
            },
          });
          newValue && onChange?.(newValue?.toISODate());
        }}
        renderInput={(params) => (
          <TextField
            id={name}
            {...params}
            {...field}
            {...props}
            error={Boolean(meta.error) || params.error}
            helperText={(meta.error && t(meta.error as string)) || props.helperText}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              let newValue = event.target.value;

              if (newValue) {
                const [year, month, day] = newValue.split('.');

                if (year && month && day) {
                  const date = DateTime.fromObject({
                    year: parseInt(year, 10),
                    month: parseInt(month, 10),
                    day: parseInt(day, 10),
                  });

                  if (date.isValid) {
                    newValue = date.toISODate();
                    setValue(date);
                    event.target.value = newValue;
                    field.onChange({
                      target: {
                        name: field.name,
                        value: newValue,
                      },
                    });
                  }
                }
              }
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
