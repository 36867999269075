import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TableCell, Checkbox, Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { DeleteIncomeDialog } from '~/components/Dialog/DeleteIncomeDialog';
import { MenuItem, TableActionsMenu } from '~/components/Menu/TableActionsMenu';
import { ClickableTableRow, NoWrapCellText } from '~/components/Table';
import { useAuth } from '~/context/AuthContext';
import { ROUTES } from '~/router/Routes';
import { Color } from '~/types/color';
import { Income } from '~/types/income';
import { formatDateShort } from '~/utils/formatDate';
import { formatCurrency } from '~/utils/formatNumber';

export type Props = {
  row: Income;
  selected: boolean;
  onSelect: (id: string) => void;
};

export const TableRow = ({ row, selected, onSelect }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { id, customer_name, issue_date, notes, amount } = row;

  const onEdit = () => navigate(generatePath(ROUTES.DASHBOARD_INCOME_EDIT, { id }));

  const menuItems: MenuItem[] = [
    {
      label: t('msg_btn_edit_income'),
      icon: <EditIcon />,
      onClick: onEdit,
    },
    {
      label: t('msg_btn_delete_income'),
      icon: <DeleteIcon color="error" />,
      onClick: () => setShowDeleteDialog(true),
      color: Color.Error,
    },
  ];

  return (
    <>
      <ClickableTableRow hover>
        <TableCell padding="checkbox">
          <Checkbox color="primary" checked={selected} onChange={() => onSelect(`${id}`)} />
        </TableCell>
        <TableCell onClick={onEdit}>{customer_name}</TableCell>
        <TableCell onClick={onEdit}>{formatDateShort(issue_date)}</TableCell>
        <TableCell onClick={onEdit}>
          <Box
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: {
                xs: 200,
                sm: 250,
                md: 350,
                lg: 500,
              },
            }}
          >
            {notes}
          </Box>
        </TableCell>
        <TableCell onClick={onEdit} align="right" sx={{ pr: 3 }}>
          <NoWrapCellText>
            {formatCurrency(amount, true, base_currency.symbol_native)}
          </NoWrapCellText>
        </TableCell>
        <TableCell padding="checkbox">
          <TableActionsMenu menuItems={menuItems} />
        </TableCell>
      </ClickableTableRow>

      <DeleteIncomeDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onSuccess={() => selected && onSelect(`${id}`)}
      />
    </>
  );
};
