import { generatePath } from 'react-router-dom';

import { Customer, CustomerListOptions } from '~/types/customer';
import { InvoiceFormItemFields, InvoiceItem } from '~/types/invoice';
import { PaginationData } from '~/types/pagination';
import { api } from '~/utils/api';

const URL = {
  INDEX: '/api/invoices/:invoiceId/invoice-items/',
  SUGGESTED_ITEMS: '/api/suggested-invoice-items/',
  GET: '/api/invoices/:invoiceId/invoice-items/:id/',
  READ: '/api/suggested-invoice-items/:invoiceItemId/',
};

export const model = {
  index: (params?: CustomerListOptions) => api.get<PaginationData<Customer>>(URL.INDEX, params),
  suggestedItems: () => api.get<string[]>(URL.SUGGESTED_ITEMS),
  create: (invoiceId: string, data: InvoiceFormItemFields) =>
    api.post<InvoiceItem>(generatePath(URL.INDEX, { invoiceId }), data),
  delete: (invoiceId: string, id: number) => api.delete(generatePath(URL.GET, { invoiceId, id })),
  update: (invoiceId: string, id: number, data: InvoiceFormItemFields) =>
    api.put<InvoiceItem>(generatePath(URL.GET, { invoiceId, id }), data),
  patch: (invoiceId: string, invoiceItemId: number, data: InvoiceFormItemFields) =>
    api.patch<InvoiceItem>(generatePath(URL.READ, { invoiceId, invoiceItemId }), data),
};
