import { Box, Button, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { codes, ICountryCodeItem } from 'country-calling-code';
import { Formik, Form, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { FormikSelect } from '~/components/Form/Select';
import { FormikTextField } from '~/components/Form/TextField';
import { FlagIcon } from '~/components/Icon/FlagIcon';
import { DEFAULT_COUNTRY_CODE } from '~/config/constants';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useUsersLocationByIp } from '~/hooks/useUsersLocationByIp';
import { model } from '~/models/Auth';
import { ValidationError } from '~/types/error';

import { FieldsContainer } from './PhoneNumberForm.style';
import { validationSchema } from './validationSchema';

type Props = {
  phone: string;
  countryCallingCode: string;
  onSubmit: (phone: string) => void;
};

type FormValues = {
  countryCode: string;
  number: string;
};

export const PhoneNumberForm = ({ phone, countryCallingCode, onSubmit }: Props) => {
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState<ICountryCodeItem | undefined>(undefined);

  const { location: locationByIp } = useUsersLocationByIp();

  useEffect(() => {
    if (countryCallingCode) {
      setSelectedCountry(codes.find((code) => code.countryCodes[0] === countryCallingCode));
    } else {
      const setCountryOfResidencePhoneCode = async () => {
        const countryOfResidenceLocalStorageValue = await localStorage.getItem(
          localStorageKeys.countryOfResidence,
        );

        const countryOfResidenceCode = countryOfResidenceLocalStorageValue
          ? codes.find((code) => code.isoCode2 === countryOfResidenceLocalStorageValue)
          : codes.find((code) => code.isoCode2 === locationByIp?.countryCode);

        countryOfResidenceCode && setSelectedCountry(countryOfResidenceCode);
      };
      setCountryOfResidencePhoneCode();
    }
  }, [locationByIp, countryCallingCode]);

  useEffect(() => {
    handleCountryChange(DEFAULT_COUNTRY_CODE);
  }, []);

  const handleCountryChange = (countryCode: string) => {
    const country = codes.find((item) => item.isoCode2 === countryCode);
    setSelectedCountry(country);
  };

  const handleSubmit = async (
    values?: FormValues,
    formikHelpers?: FormikHelpers<FormValues>,
    callingCode?: string,
    phone?: string,
  ) => {
    const { setErrors } = formikHelpers || {};
    try {
      const number = values
        ? `+${selectedCountry?.countryCodes[0]}${values.number}`
        : `+${callingCode}${phone}`;

      if (!isValidPhoneNumber(number) && setErrors) {
        setErrors({ number: t('msg_error_invalid_phone') });
        return;
      }

      await model.phoneLogin(number);
      onSubmit(number);
    } catch (error) {
      if (error instanceof ValidationError && setErrors) {
        setErrors(error.formikErrors);
      }
    }
  };

  useEffect(() => {
    if (countryCallingCode && phone) {
      handleSubmit(undefined, undefined, countryCallingCode, phone);
    }
  }, [countryCallingCode, phone]);

  return (
    <Formik
      initialValues={
        {
          countryCode: selectedCountry?.isoCode2 || DEFAULT_COUNTRY_CODE,
          number: phone?.replace(`+${selectedCountry?.countryCodes[0]}`, '') || '',
        } as FormValues
      }
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Stack
            spacing={3}
            sx={{ width: { xs: '100%', lg: 480 }, maxWidth: 480 }}
            alignItems="center"
          >
            <FieldsContainer width="100%" direction="row">
              <Box>
                <FormikSelect
                  name="countryCode"
                  startAdornment={
                    <InputAdornment position="start">
                      <FlagIcon country={values.countryCode} />
                    </InputAdornment>
                  }
                  renderValue={(_value) => selectedCountry?.isoCode2 || DEFAULT_COUNTRY_CODE}
                  onChange={(event) => {
                    const countryCode = event.target.value as string;
                    handleCountryChange(countryCode);
                    setFieldValue('countryCode', countryCode);
                  }}
                >
                  {codes.map((code) => (
                    <MenuItem key={code.country} value={code.isoCode2}>
                      {code.country}
                    </MenuItem>
                  ))}
                </FormikSelect>
              </Box>
              <Box width="100%">
                <FormikTextField
                  name="number"
                  onChange={(event) => {
                    const phone = event.target.value;
                    setFieldValue('number', phone);
                  }}
                  placeholder={t('msg_label_your_number')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        +{selectedCountry?.countryCodes[0]}
                      </InputAdornment>
                    ),
                    inputProps: {
                      min: 0,
                    },
                  }}
                  type="number"
                  autoComplete="off"
                  fullWidth
                  autoFocus
                />
              </Box>
            </FieldsContainer>
            <Button fullWidth type="submit" variant="contained" size="large">
              {t('msg_btn_login_phone')}
            </Button>
            <Typography variant="caption" color="textSecondary" textAlign="center">
              {t('msg_label_phone_form_additional_text')}
            </Typography>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
