import { useQuery } from '@tanstack/react-query';

import { Merchant, MerchantListOptions } from '~/types/merchant';
import { PaginationData } from '~/types/pagination';

import { model } from '../';

export const merchantsQueryKey = 'merchants';

export const useMerchants = (params?: MerchantListOptions) =>
  useQuery<PaginationData<Merchant>>([merchantsQueryKey, params], () => model.index(params));
