import Delete from '@mui/icons-material/Delete';
import { TableCell, Checkbox, Stack, Box, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeleteExpenseBulkDialog } from '~/components/Dialog/DeleteExpenseBulkDialog';
import { TableRow } from '~/components/Table/TableToolbar/TableToolbar.style';

export type Props = {
  selected: string[];
  selectedAll: boolean;
  onSelectAll: () => void;
  onClearSelection: () => void;
};

export const TableToolbar = ({ selected, selectedAll, onSelectAll, onClearSelection }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  if (!selected.length && !selectedAll) {
    return null;
  }

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={selected.length > 0 && !selectedAll}
            checked={selectedAll}
            onChange={onSelectAll}
          />
        </TableCell>
        <TableCell width="100%" padding="none">
          <Stack direction="row" justifyContent="space-between" alignItems="center" px={2}>
            <Box>
              {selected.length} {t('msg_label_selected')}
            </Box>
            <Box>
              <Button
                name={t('msg_btn_delete')}
                startIcon={<Delete />}
                color="error"
                onClick={() => setShowModal(true)}
              >
                {t('msg_btn_delete')}
              </Button>
            </Box>
          </Stack>
        </TableCell>
      </TableRow>

      <DeleteExpenseBulkDialog
        ids={selected}
        open={showModal}
        onClose={() => setShowModal(false)}
        onSuccess={onClearSelection}
      />
    </>
  );
};
