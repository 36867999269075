import { Theme } from '@mui/material';

export const PickersPopper = (theme: Theme) => {
  return {
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          boxShadow: theme.shadows[24],
        },
      },
    },
  };
};
