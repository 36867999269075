import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, CircularProgress, Fade, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateCustomerFormDialog } from '~/components/Dialog/CustomerFormDialog';
import { useCustomers, useSuggestedCustomers } from '~/models/Customer/hooks';
import { Customer } from '~/types/customer';

import { Search } from '../../Search';

import { FooterMenuItem, Popover } from './CustomerSelector.style';
import { CustomerSelectorMenuItem } from './CustomerSelectorMenuItem';

type Props = {
  anchorEl: Element | null;
  open?: boolean;
  onSelectCustomer: (customer?: Customer) => void;
  onClose: () => void;
  hasCreateButton?: boolean;
};

export const CustomerSelectorMenu = ({
  anchorEl,
  open = false,
  onSelectCustomer,
  onClose,
  hasCreateButton = true,
}: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [showAddCustomerDialog, setShowAddCustomerDialog] = useState(false);

  const { data: suggestedCustomers, isLoading: isLoadingSuggested } = useSuggestedCustomers();
  const { data: customers, isLoading: isLoadingSearched } = useCustomers({ search });

  const onSearch = (newSearchTerm: string) => {
    const searchTerm = newSearchTerm.trim();
    if (searchTerm === search || (searchTerm.length > 0 && searchTerm.length < 3)) return;

    setSearch(searchTerm);
  };

  const loadingContent = () => {
    const isLoading = isLoadingSuggested || isLoadingSearched;

    if (!isLoading) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <CircularProgress />
      </Stack>
    );
  };

  const noResults = () => {
    const isLoading = isLoadingSuggested || isLoadingSearched;
    const hasResults = !search.length || customers?.items.length;

    if (isLoading || hasResults) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <Typography>{t('msg_label_no_results')}</Typography>
      </Stack>
    );
  };

  const selectCreatedCustomer = (customer: Customer) => {
    onSelectCustomer(customer);
    onClose();
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        width={anchorEl?.clientWidth}
      >
        <Box
          padding={2}
          sx={{
            minWidth: anchorEl?.clientWidth,
          }}
        >
          <Search
            onSearch={onSearch}
            fullWidth
            variant="outlined"
            hasSearchIcon={false}
            autoFocus={true}
            placeholder={t('msg_label_search_public_customer')}
          />
        </Box>

        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          {loadingContent()}
          {noResults()}

          {(!search ? suggestedCustomers : customers?.items)?.map((customer) => (
            <CustomerSelectorMenuItem
              key={customer.id}
              customer={customer}
              onSelectCustomer={() => onSelectCustomer(customer)}
            />
          ))}
        </Box>

        {hasCreateButton && (
          <FooterMenuItem onClick={() => setShowAddCustomerDialog(true)} selected>
            <AddCircleIcon />
            <Typography variant="button" textTransform="none">
              {t('msg_label_add_new_customer')}
            </Typography>
          </FooterMenuItem>
        )}
      </Popover>

      <CreateCustomerFormDialog
        open={showAddCustomerDialog}
        onClose={() => setShowAddCustomerDialog(false)}
        onSuccess={selectCreatedCustomer}
        initialCustomerName={search}
      />
    </>
  );
};
