import { generatePath } from 'react-router-dom';

import { Company } from '~/types/company';
import { api } from '~/utils/api';

const URL = {
  CREATE: '/api/companies/',
  GET: '/api/companies/:id/',
};

export const model = {
  create: (phone: string, email: string) => api.post(URL.CREATE, { phone, email }),
  patch: (id: number, data: Partial<Omit<Company, 'id'>>) =>
    api.patch(generatePath(URL.GET, { id }), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
};
