import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, generatePath, useNavigate, useParams } from 'react-router-dom';

import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { MarkInvoiceAsPaidDialog } from '~/components/Dialog/MarkInvoiceAsPaidDialog';
import { LinkedIncomeRow } from '~/components/LinkedIncomeRow/LinkedIncomeRow';
import { useAuth } from '~/context/AuthContext';
import { useResponsive } from '~/hooks/useResponsive';
import { model as incomeModel } from '~/models/Income';
import { model } from '~/models/Invoices';
import { useInvoice, useSaveAsPdf } from '~/models/Invoices/hooks';
import {
  linkableIncomesQueryKey,
  useLinkableIncomes,
} from '~/models/Invoices/hooks/useLinkableIncomes';
import { ROUTES } from '~/router/Routes';
import { AmplitudeEvent } from '~/types/amplitude';
import { InvoiceStatus, InvoiceType, Operation } from '~/types/invoice';
import { queryClient } from '~/utils';
import { sendAmplitudeData } from '~/utils/amplitude';
import { formatDate } from '~/utils/formatDate';
import { getInvoiceStatus } from '~/utils/getInvoiceStatus';
import { logger } from '~/utils/logger';
import { openInNewTab } from '~/utils/openInNewTab';

import DetailsAccordion from '../InvoicePlatform/DetailsAccordion/DetailsAccordion';
import StatusCard from '../InvoicePlatform/StatusCard/StatusCard';

import { Header } from './Header';
import { InvoiceItemsTable } from './InvoiceItemsTable';
import { SoftCollectionToggle } from './SoftCollectionToggle';

export const InvoicePreview = () => {
  const isTablet = useResponsive('down', 'lg');
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showMarkAsPaidDialog, setShowMarkAsPaidDialog] = useState(false);
  const [showUnlinkIncomeModal, setShowUnlinkIncomeModal] = useState(false);
  const [incomeIdForDeletion, setIncomeIdForDeletion] = useState<number>();
  const [isUnlinkingIncome, setIsUnlinkingIncome] = useState(false);

  const { user } = useAuth();
  const { data: linkableIncomes } = useLinkableIncomes(id);

  if (!id) {
    return <Navigate to={ROUTES.ERROR_404} />;
  }

  useEffect(() => {
    sendAmplitudeData(AmplitudeEvent.InvoiceOpened);
  }, []);

  const saveAsPdf = useSaveAsPdf(id);

  if (!id) {
    return <Navigate to={ROUTES.ERROR_404} />;
  }

  const { data, isLoading, refetch } = useInvoice(id);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!data && !isLoading) {
    return <Navigate to={ROUTES.ERROR_404} />;
  }

  const {
    number,
    company_name,
    company_address,
    customer_name,
    customer_vat_number,
    customer_company_number,
    customer_address,
    issue_date,
    payment_due,
    invoice_items,
    total,
    left_to_pay,
    notes,
    vat_sum,
    total_without_vat,
    spain_irpf_amount,
    linked_incomes,
  } = data;

  const onSaveAsPdf = async () => {
    if (!data.pdf) {
      await saveAsPdf();
    } else {
      openInNewTab(data.pdf);
    }
  };

  const onNavigateToIncomeEdit = (incomeId: number) =>
    navigate(generatePath(ROUTES.DASHBOARD_INCOME_EDIT, { id: incomeId }));

  const onUnlinkIncome = async (incomeId?: number, createdByMarkAsPaid?: boolean) => {
    if (createdByMarkAsPaid) {
      setIncomeIdForDeletion(incomeId);
      setShowUnlinkIncomeModal(true);
    } else {
      try {
        setIsUnlinkingIncome(true);
        await model.unlinkIncome(id, incomeId);
        refetch();
        queryClient.invalidateQueries([linkableIncomesQueryKey]);
        setIsUnlinkingIncome(false);
        setShowUnlinkIncomeModal(false);
      } catch (e) {
        logger.logException(e);
        setIsUnlinkingIncome(false);
      }
    }
  };

  const onDeleteIncome = async (incomeId?: number) => {
    try {
      setIsUnlinkingIncome(true);
      await incomeModel.delete(incomeId);
      refetch();
      queryClient.invalidateQueries([linkableIncomesQueryKey]);
      setIncomeIdForDeletion(undefined);
      setIsUnlinkingIncome(false);
      setShowUnlinkIncomeModal(false);
    } catch (e) {
      setIsUnlinkingIncome(false);
      logger.logException(e);
    }
  };

  const renderLinkedIncomes = (items: Operation[]) => {
    return (
      <Stack gap={1} paddingTop={1}>
        {items.map((item) => {
          return (
            <LinkedIncomeRow
              key={item.id}
              item={item}
              currency_symbol={user?.active_company?.base_currency.symbol_native}
              onDelete={() => onUnlinkIncome(item.id, item.created_by_mark_as_paid)}
              onNavigate={() => onNavigateToIncomeEdit(item.id)}
            />
          );
        })}
      </Stack>
    );
  };

  const closeUnlinkIncomeModal = () => {
    setIncomeIdForDeletion(undefined);
    setShowUnlinkIncomeModal(false);
  };

  return (
    <>
      <Grid
        container
        rowGap={4}
        columnSpacing={5}
        sx={{
          '&&': {
            px: 0,
            width: '100%',
            marginLeft: 0,
          },
        }}
      >
        <Grid
          item
          xs={12}
          lg={9}
          sx={{
            p: isTablet ? 0 : 0,
            '&&': {
              pl: 0,
            },
          }}
        >
          <Header id={id} invoice={data} status={getInvoiceStatus(data)} />

          <Paper
            sx={{
              p: {
                xs: 2,
                md: 8,
              },
              position: 'relative',
            }}
          >
            <Typography variant="h3">
              {data.invoice_type === InvoiceType.invoice
                ? t('msg_invoice_title')
                : t('msg_proforma_title')}
            </Typography>
            <Typography variant="body1">{number}</Typography>
            <Button
              variant="contained"
              size="large"
              color="grey"
              startIcon={<PictureAsPdfIcon />}
              onClick={() => onSaveAsPdf()}
              sx={{
                position: { xs: 'relative', sm: 'absolute' },
                right: 18,
                top: 18,
              }}
            >
              {t('msg_btn_save_as_pdf')}
            </Button>
            <Grid
              container
              spacing={2}
              sx={{
                mt: {
                  xs: 2,
                  md: 8,
                },
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  {t('msg_invoice_billed_by')}
                </Typography>
                {company_name && <Typography variant="body1">{company_name}</Typography>}
                {company_address && <Typography variant="body1">{company_address}</Typography>}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  {t('msg_invoice_billed_to')}
                </Typography>
                {customer_name && <Typography variant="body1">{customer_name}</Typography>}
                {customer_vat_number && (
                  <Typography variant="body1">
                    {t('msg_pdf_invoice_customer_company_number')} {customer_company_number}
                  </Typography>
                )}
                {customer_vat_number && (
                  <Typography variant="body1">
                    {t('msg_label_vat_number')} {customer_vat_number}
                  </Typography>
                )}
                {customer_address && <Typography variant="body1">{customer_address}</Typography>}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                mt: {
                  xs: 2,
                  md: 4,
                },
              }}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="body1">{t('msg_label_invoice_date')}</Typography>
                <Typography variant="body1">{formatDate(issue_date)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">{t('msg_pdf_invoice_due_date')}</Typography>
                <Typography variant="body1">{formatDate(payment_due)}</Typography>
              </Grid>
            </Grid>
            <InvoiceItemsTable
              items={invoice_items}
              total={total}
              total_without_vat={
                !!user.active_company.vat_code || user.active_company.country === 'ES'
                  ? total_without_vat
                  : undefined
              }
              vat_sum={
                !!user.active_company.vat_code || user.active_company.country === 'ES'
                  ? vat_sum
                  : undefined
              }
              currency_symbol={user?.active_company?.base_currency.symbol_native}
              irpfAmount={spain_irpf_amount}
            />
            <Divider
              sx={{
                my: {
                  xs: 2,
                  md: 5,
                },
              }}
            />
            {notes && (
              <Box sx={{ pb: 5 }}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  {t('msg_comment')}
                </Typography>
                <Typography variant="body1">{notes}</Typography>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            '&&': {
              pl: isTablet ? 0 : 5,
            },
          }}
        >
          <Grid
            container
            rowGap={1}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start',
            }}
          >
            <StatusCard
              status={getInvoiceStatus(data)}
              total={getInvoiceStatus(data) === InvoiceStatus.Unpaid ? left_to_pay : total}
              paymentDue={payment_due}
              onButtonClick={() => setShowMarkAsPaidDialog(true)}
              buttonTitle="msg_btn_mark_paid"
              currency_symbol={user?.active_company?.base_currency.symbol_native}
            />
            <SoftCollectionToggle invoice={data} />
            {!!linked_incomes?.length && (
              <DetailsAccordion title={t('msg_payments_received')} expanded>
                {renderLinkedIncomes(linked_incomes)}
              </DetailsAccordion>
            )}
            <DetailsAccordion
              title={customer_name}
              details={[
                {
                  title: t('msg_label_customer_company_number'),
                  description: customer_company_number,
                },
                { title: t('msg_company_vat_no'), description: customer_vat_number },
                { title: t('msg_label_address'), description: customer_address },
              ]}
              expanded
            />
          </Grid>
        </Grid>
      </Grid>
      <MarkInvoiceAsPaidDialog
        invoiceId={id}
        open={showMarkAsPaidDialog}
        onClose={() => setShowMarkAsPaidDialog(false)}
        linkableIncomes={linkableIncomes}
        currencySymbol={user?.active_company?.base_currency?.symbol_native}
        onInvoiceRefetch={refetch}
      />
      <ConfirmationDialog
        open={showUnlinkIncomeModal}
        title={t('income_action_sheet_delete_title')}
        onClose={closeUnlinkIncomeModal}
        actions={[
          {
            label: t('income_delete_action_sheet_yes'),
            color: 'error',
            onClick: () => onDeleteIncome(incomeIdForDeletion),
            showIsLoading: true,
          },
          {
            label: t('income_delete_action_sheet_no'),
            color: 'inherit',
            onClick: () => onUnlinkIncome(incomeIdForDeletion),
          },
        ]}
        isLoading={isUnlinkingIncome}
      />
    </>
  );
};
