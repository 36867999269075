import { TableBody as MuiTableBody, styled, TableRow as MuiTableRow, Table } from '@mui/material';

export const TableBody = styled(MuiTableBody, {
  shouldForwardProp: (prop) => prop != 'isEmpty' && prop != 'minRows',
})<{
  isEmpty?: boolean;
  minRows?: number;
}>(({ isEmpty = false, minRows = 5 }) => ({
  height: isEmpty ? 0 : minRows * 53,
}));

export const ClickableTableRow = styled(MuiTableRow)({
  cursor: 'pointer',
});

export const InvoiceItemsFormTable = styled(Table)(({ theme }) => ({
  'td:not(:last-of-type)': {
    borderRightWidth: 1,
    borderRightColor: theme.palette.divider,
    borderRightStyle: 'solid',
  },
}));

export const NoWrapCellText = styled('span')({
  whiteSpace: 'nowrap',
});
