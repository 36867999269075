import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TablePaginationProps,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { rowsPerPageOptions } from '~/config/pagination';

export type Props = Omit<TablePaginationProps, 'onPageChange' | 'onRowsPerPageChange'> & {
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
};

export const TablePagination = ({
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openRowsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeRowsMenu = () => {
    setAnchorEl(null);
  };

  const handleOnRowsPerPageChange = (rowsPerPage: number) => {
    onPageChange(1);
    onRowsPerPageChange(rowsPerPage);
    closeRowsMenu();
  };

  const onPrevPage = () => onPageChange(page - 1);

  const onNextPage = () => onPageChange(page + 1);

  const displayedRowsOutOf = () => {
    const from = (page - 1) * rowsPerPage + 1;
    const to = Math.min((page - 1) * rowsPerPage + rowsPerPage, count);

    return `${from} - ${to} ${t('msg_pagination_from_to_of')} ${count}`;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingY={1}
      paddingLeft={2.5}
      paddingRight={0.5}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="caption">{t('msg_pagination_rows_per_page')}</Typography>
        <Box>
          <Button
            color="inherit"
            aria-label={t('msg_pagination_rows_per_page')}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={openRowsMenu}
            size="small"
            endIcon={
              open ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />
            }
          >
            {rowsPerPage}
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={closeRowsMenu} TransitionComponent={Fade}>
            {rowsPerPageOptions.map((option) => (
              <MenuItem
                key={option}
                onClick={() => handleOnRowsPerPageChange(option)}
                selected={option === rowsPerPage}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="caption" mr={2}>
          {displayedRowsOutOf()}
        </Typography>
        <IconButton onClick={onPrevPage} aria-label={t('msg_pagination_previous_page')}>
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
        <IconButton onClick={onNextPage} aria-label={t('msg_pagination_next_page')}>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
