import { ContentCopy } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikTextField } from '~/components/Form/TextField';
import { FormikSetErrorsFn } from '~/types/formik';
import { ForwardInvoiceFormFields } from '~/types/invoice';

import { GenericDialog } from '../GenericDialog';

import { validationSchema } from './validationSchema';

export type Props = {
  open: boolean;
  customerId?: number | null;
  onSubmit: (
    values: ForwardInvoiceFormFields,
    setErrors: FormikSetErrorsFn<ForwardInvoiceFormFields>,
  ) => Promise<void>;
  onClose: () => void;
};

enum ForwardInvoiceFormField {
  recipient_email = 'recipient_email',
}

const ForwardInvoiceDialog = ({ open, onSubmit, onClose }: Props) => {
  const { t } = useTranslation();

  const initialValues: ForwardInvoiceFormFields = useMemo(
    () => ({
      recipient_email: '',
    }),
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => onSubmit(values, helpers.setErrors)}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, resetForm }) => (
        <GenericDialog
          title={t('msg_share_title')}
          subtitle={t('msg_enter_email_to_forward')}
          open={open}
          closeBtnLabel={t('msg_btn_cancel')}
          onClose={() => {
            onClose();
            resetForm();
          }}
          submitBtnLabel={t('msg_btn_send_message')}
          submitBtnIcon={<SendIcon />}
          onSubmit={submitForm}
          isLoading={isSubmitting}
          secondaryActionButton={{
            title: t('msg_btn_copy_link'),
            onClick: () => navigator.clipboard.writeText(window.location.href),
            icon: <ContentCopy />,
          }}
        >
          <Box mt={2}>
            <FormikTextField
              name={ForwardInvoiceFormField.recipient_email}
              label={t('msg_label_invoice_email_to')}
              variant="filled"
              color="grey"
              autoFocus
              fullWidth
            />
          </Box>
        </GenericDialog>
      )}
    </Formik>
  );
};

export default ForwardInvoiceDialog;
