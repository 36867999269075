type Props = {
  width?: number;
  height?: number;
};

export const Logo = ({ width, height }: Props) => (
  <img
    data-testid="deepfin-logo-testid"
    src="/assets/images/logo.svg"
    alt="Deepfin"
    width={width}
    height={height}
  />
);
