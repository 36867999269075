import { Alert, AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  title?: string;
};

export const ErrorAlert = ({ title }: Props) => {
  const { t } = useTranslation();

  return (
    <Alert severity="error">
      <AlertTitle sx={{ m: 0, alignItems: 'center' }}>
        {t(title || 'msg_alert_error_title')}
      </AlertTitle>
    </Alert>
  );
};
