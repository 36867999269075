import { InvoiceItemTemplate } from '~/types/invoiceItemTemplate';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<InvoiceItemTemplate>[] = [
  {
    id: 'default_item_name',
    label: 'msg_label_invoice_item_name',
  },
  {
    id: 'default_price',
    label: 'msg_label_price',
    numeric: true,
  },
  {
    id: 'id',
    label: '',
  },
];
