import {
  Box,
  CircularProgress,
  ClickAwayListener,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useState, MouseEvent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useExpenseDescriptionSuggestions } from '~/models/Expense/hooks/useExpenseDescriptionSuggestions';

import { Popper } from '../InvoiceItemSelector/InvoiceItemSelectorMenu.style';

type Props = React.PropsWithChildren & {
  onChange: (suggestion: string) => void;
  initiallyOpen?: boolean;
};

export const ExpenseDescriptionSelector = ({ children, onChange, initiallyOpen }: Props) => {
  const divRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { t } = useTranslation();
  const { data: suggestions, isLoading } = useExpenseDescriptionSuggestions();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);

  const openMenu = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const loadingContent = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <CircularProgress />
      </Stack>
    );
  };

  const noResults = () => {
    if (isLoading || suggestions?.length) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <Typography>{t('msg_label_no_results')}</Typography>
      </Stack>
    );
  };

  useEffect(() => {
    if (!initiallyOpen) {
      return;
    }

    setAnchorEl(divRef.current);
  }, [divRef]);

  const onSelect = (suggestion: string) => {
    onChange(suggestion);
    closeMenu();
  };

  return (
    <ClickAwayListener onClickAway={closeMenu}>
      <Box>
        <div ref={divRef} onClick={openMenu}>
          {children}
        </div>

        {open && (
          <Popper id="transition-popper" anchorEl={anchorEl} width={anchorEl?.clientWidth} open>
            <Paper>
              <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
                {loadingContent()}
                {noResults()}

                {suggestions?.map((suggestion) => (
                  <MenuItem key={suggestion} onClick={() => onSelect(suggestion)}>
                    <Typography sx={{ maxWidth: 280 }} noWrap>
                      {suggestion}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Paper>
          </Popper>
        )}
      </Box>
    </ClickAwayListener>
  );
};
