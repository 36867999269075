import { Button, Grid, Stack, Typography } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FormikTextField } from '~/components/Form/TextField';
import { Logo } from '~/components/Logo';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useAppConfig } from '~/context/AppConfigContext';
import { model } from '~/models/Auth';
import { ValidationError } from '~/types/error';
import { logger } from '~/utils/logger';

import { validationSchema } from './validationSchema';

type FormFields = {
  email: string;
};

export const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { onChangeLanguage } = useAppConfig();
  const fromMobileApp = location.search.includes('?app=mob'); // TODO: can also probably be moved to a scope where it's used
  const [searchParams] = useSearchParams();
  const language = searchParams.get('lang');

  const [emailIsSent, setEmailIsSent] = useState(false);

  useEffect(() => {
    if (language) {
      onChangeLanguage(language);
    }
  }, [language]);

  const onSubmit = async ({ email }: FormFields, { setErrors }: FormikHelpers<FormFields>) => {
    try {
      const languageFromLocalStorage = localStorage.getItem(localStorageKeys.language);
      const { detail } = await model.resetPassword(
        email,
        fromMobileApp,
        language || languageFromLocalStorage,
      );
      toast.success(`${t(detail)} ${email}`, { style: { flexWrap: 'wrap' } });
      setEmailIsSent(true);
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
        error.detail && toast.error(t(error.detail));
        logger.logException(error);
      }
    }
  };

  return (
    <Grid
      container
      component="main"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh', width: '100vw' }}
    >
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, values }) => (
          <Stack alignItems="center" sx={{ maxWidth: '360px', width: '100%', paddingX: 2 }}>
            <Logo width={142} height={24} />
            <Stack rowGap={4} sx={{ marginTop: '72px', width: '100%' }}>
              <Stack alignItems="center" rowGap={2}>
                <Typography textAlign={'center'} variant="h5" sx={{ wordBreak: 'break-word' }}>
                  {emailIsSent ? t('msg_password_reset_sent') : t('msg_forgot_password')}
                </Typography>
                {!emailIsSent && (
                  <Typography textAlign={'center'} sx={{ wordBreak: 'break-word' }}>
                    {t('msg_forgot_password_description')}
                  </Typography>
                )}
              </Stack>
              {!emailIsSent && (
                <>
                  <FormikTextField
                    name="email"
                    label={`${t('msg_label_email')}*`}
                    variant="filled"
                    type="email"
                  />
                  <Button
                    fullWidth
                    onClick={submitForm}
                    variant="contained"
                    size="large"
                    disabled={!values.email}
                  >
                    {t('btn_reset_password')}
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        )}
      </Formik>
    </Grid>
  );
};
