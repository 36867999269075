import { Typography, styled } from '@mui/material';

export const InputBoxTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  color: theme.palette.text.primary,
}));

export const InputBoxSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 400,
  color: theme.palette.text.secondary,
}));
