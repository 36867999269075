import { CircularProgress, Stack, Paper } from '@mui/material';

import { Logo } from '../Logo';

export const FullPageSpinner = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    sx={{ height: '100vh' }}
    component={Paper}
    square
    spacing={10}
  >
    <Logo width={360} />
    <CircularProgress />
  </Stack>
);
