import { styled, Table as MuiTable } from '@mui/material';

export const Table = styled(MuiTable)(({ theme }) => ({
  'td, th': {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,

    '&:first-of-type': {
      paddingLeft: 0,
    },

    '&:last-of-type': {
      paddingRight: 0,
    },
  },

  tfoot: {
    'td, th': {
      border: 'none',
    },
  },
}));
