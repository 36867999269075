import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Modal,
  Paper,
  Stack,
  IconButton,
  Typography,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import codes, { ICountryCodeItem } from 'country-calling-code';
import { Formik } from 'formik';
import i18next from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikSelect } from '~/components/Form/Select';
import { FormikTextField } from '~/components/Form/TextField';
import { FlagIcon } from '~/components/Icon/FlagIcon';
import { DEFAULT_COUNTRY_CODE } from '~/config/constants';
import { FieldsContainer } from '~/pages/Auth/WelcomeBack/PhoneNumberForm/PhoneNumberForm.style';
import { colors } from '~/theme/colors';
import { LanguageCodes } from '~/types/appConfig';
import { FormikSetErrorsFn } from '~/types/formik';
import { SalesLeadFormFields } from '~/types/invoice';

import { validationSchema } from './validationSchema';

export type Props = {
  open: boolean;
  isMobile: boolean;
  onSubmit: (
    values: SalesLeadFormFields,
    setErrors: FormikSetErrorsFn<SalesLeadFormFields>,
  ) => Promise<void>;
  onClose: () => void;
  tLanguage?: LanguageCodes;
};

export enum FormFields {
  email = 'email',
  phone = 'phone',
  countryCode = 'countryCode',
}

const SalesLeadDialog = ({ open, isMobile, onSubmit, onClose, tLanguage }: Props) => {
  const { t } = useTranslation();
  const translate = tLanguage ? i18next.getFixedT(tLanguage) : t;

  const [selectedCountry, setSelectedCountry] = useState<ICountryCodeItem | undefined>(undefined);

  useEffect(() => {
    handleCountryChange(DEFAULT_COUNTRY_CODE);
  }, []);

  const handleCountryChange = (countryCode: string) => {
    const country = codes.find((item) => item.isoCode2 === countryCode);
    setSelectedCountry(country);
  };

  const initialValues: SalesLeadFormFields = useMemo(
    () => ({
      [FormFields.email]: '',
      [FormFields.countryCode]: selectedCountry?.isoCode2 || DEFAULT_COUNTRY_CODE,
      [FormFields.phone]: '',
    }),
    [],
  );

  const handleClose = (resetFn: () => void) => {
    onClose();
    resetFn();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, helpers) => onSubmit(values, helpers.setErrors)}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, submitForm, isSubmitting, resetForm, setFieldValue }) => (
        <Modal
          open={open}
          onClose={() => handleClose(resetForm)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Paper
            sx={{
              position: 'relative',
              borderRadius: 5,
              backgroundColor: colors.background.default,
              width: isMobile ? '90%' : '1184px',
            }}
          >
            <Box sx={{ position: 'absolute', top: '8px', right: '8px' }}>
              <IconButton
                onClick={() => handleClose(resetForm)}
                aria-label={translate('msg_btn_close')}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container padding={4}>
              {!isMobile && (
                <Grid item xs={6}>
                  <img
                    style={{ maxWidth: '100%', maxHeight: '530px' }}
                    src="/assets/images/sales-lead-dialogue-hero.webp"
                    alt="sales-lead-image"
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Stack
                  flexDirection="column"
                  justifyContent="center"
                  paddingTop={4}
                  height="100%"
                  sx={{ pr: { sm: 4 } }}
                >
                  <Typography variant={isMobile ? 'h6' : 'h5'}>
                    {translate('msg_sales_lead_modal_title')}
                  </Typography>
                  <Box mt={4}>
                    <Typography fontSize={isMobile ? 12 : 16}>
                      {translate('msg_sales_lead_modal_subtitle')}
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <FormikTextField
                      name={FormFields.email}
                      label={translate('msg_label_email')}
                      variant="filled"
                      color="primary"
                      autoFocus
                      fullWidth
                      required={false}
                    />
                  </Box>
                  <FieldsContainer direction="row" paddingTop={3}>
                    <Box>
                      <FormikSelect
                        name="countryCode"
                        startAdornment={
                          values.countryCode && (
                            <InputAdornment position="start">
                              <FlagIcon country={values.countryCode} />
                            </InputAdornment>
                          )
                        }
                        renderValue={(_value) => selectedCountry?.isoCode2 || DEFAULT_COUNTRY_CODE}
                        onChange={(event) => {
                          const countryCode = event.target.value as string;
                          handleCountryChange(countryCode);
                          setFieldValue('countryCode', countryCode);
                        }}
                      >
                        {codes.map((code) => (
                          <MenuItem key={code.country} value={code.isoCode2}>
                            {code.country}
                          </MenuItem>
                        ))}
                      </FormikSelect>
                    </Box>
                    <Box>
                      <FormikTextField
                        name="phone"
                        onChange={(event) => {
                          const phone = event.target.value;
                          setFieldValue('phone', phone);
                        }}
                        placeholder={t('msg_label_your_number')}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +{selectedCountry?.countryCodes[0]}
                            </InputAdornment>
                          ),
                          inputProps: {
                            min: 0,
                          },
                        }}
                        type="number"
                        autoComplete="off"
                        fullWidth
                        autoFocus
                      />
                    </Box>
                  </FieldsContainer>
                  <Box alignSelf="flex-end" mt={4}>
                    <LoadingButton
                      onClick={submitForm}
                      variant="contained"
                      color="primary"
                      size="large"
                      type="button"
                      loading={isSubmitting}
                    >
                      {translate('msg_btn_confirm')}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      )}
    </Formik>
  );
};

export default SalesLeadDialog;
