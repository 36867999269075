import { useMutation } from '@tanstack/react-query';
import { FormikErrors } from 'formik';
import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';

import { useAppConfig } from '~/context/AppConfigContext';
import { useAuth } from '~/context/AuthContext';
import { EmailRegistrationFormValues } from '~/pages/Auth/SignUp/SignUp';
import { ValidationError } from '~/types/error';
import { api } from '~/utils/api';
import { logger } from '~/utils/logger';

const URL = '/api/auth/email/registration/';

export interface EmailRegistrationForm {
  email: string;
  password: string;
  phone?: string;
}

export interface TokenResponse {
  token: string;
}

export const useEmailRegistration = (
  onError?: (errors: FormikErrors<EmailRegistrationFormValues>) => void,
) => {
  const { setTokenToLocalStorage } = useAuth();
  const { selectedLanguage } = useAppConfig();

  const { t } = useTranslation();
  const mutation = useMutation({
    mutationKey: ['emailRegistration'],
    mutationFn: (data: EmailRegistrationForm) =>
      api.post<TokenResponse>(URL, { ...data, language: selectedLanguage?.code }),
    onError: (err: unknown) => {
      logger.logException(err);
      if (err instanceof ValidationError) {
        onError?.(err.formikErrors);
        err.error && toast.error(t(err.error));
      }
    },
    onSuccess: async (res: TokenResponse) => {
      await setTokenToLocalStorage(res.token, false);
    },
  });
  return mutation;
};
