import { Grid, Stack, Typography } from '@mui/material';
import { toast } from 'material-react-toastify';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Logo } from '~/components/Logo';
import { useAppConfig } from '~/context/AppConfigContext';
import { model } from '~/models/Invoices';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { sendAmplitudeData } from '~/utils/amplitude';
import { logger } from '~/utils/logger';

export const MarketingLead: FC = () => {
  const { t } = useTranslation();
  const [registerDone, setRegisterDone] = useState(false);
  const [searchParams] = useSearchParams();
  const { onChangeLanguage } = useAppConfig();

  const email = searchParams.get('email');
  const phone = searchParams.get('phone');
  const note = searchParams.get('note');
  const campaign = searchParams.get('campaign');
  const language = searchParams.get('lang');

  useEffect(() => {
    if (language) {
      onChangeLanguage(language);
    }
  }, [language]);

  useEffect(() => {
    const registerSalesLead = async () => {
      try {
        sendAmplitudeData(AmplitudeEvent.SalesLeadRegistered, {
          product: 'b2b platform',
          email: email,
        });
        await model.registerMarketingLead({ email, phone, note, campaign });
        setRegisterDone(true);
      } catch (error) {
        if (error instanceof ValidationError) {
          logger.logException(error);
          toast.error(t('msg_error_something_went_wrong'));
        }
      }
    };
    if (email && phone && note && campaign) registerSalesLead();
  }, [email, phone, note, campaign]);

  return (
    <Grid
      container
      component="main"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh' }}
    >
      <Stack alignItems="center" sx={{ maxWidth: '400px', width: '100%', paddingX: 2 }}>
        <Logo width={142} height={24} />
        <Stack rowGap={4} sx={{ paddingTop: '72px' }}>
          <Typography textAlign={'center'} variant="h5" sx={{ wordBreak: 'break-word' }}>
            {registerDone && t('msg_marketing_lead_register_done')}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
};
