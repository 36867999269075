import { Button, Paper, Stack, Typography, TableContainer, Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { DateRangePicker } from '~/components/Form/DatePicker';
import { Search } from '~/components/Search';
import { TablePagination } from '~/components/Table/TablePagination';
import { usePagination } from '~/hooks/usePagination';
import { useTableSorting } from '~/hooks/useTableSorting';
import { useIncomes } from '~/models/Income/hooks';
import { ROUTES } from '~/router/Routes';
import { DateRange } from '~/types/date';
import { Income } from '~/types/income';
import { SortDirection } from '~/types/table';

import { IncomesTable } from './IncomesTable';

enum SearchParam {
  search = 'search',
  dateRange = 'dateRange',
}

export const IncomesList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(searchParams.get(SearchParam.search) || '');
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const initialDateRange = searchParams.get(SearchParam.dateRange);

    if (initialDateRange) {
      return JSON.parse(initialDateRange);
    }
    return [undefined, undefined];
  });

  const { ordering, currentSort, sortDirection, onSort } = useTableSorting<Income>(
    'issue_date',
    SortDirection.Desc,
  );

  const { offset, page, rowsPerPage, onPageChange, onRowsPerPageChange } = usePagination(count);

  const getDateFrom = () => (!dateRange[0] && dateRange[1] ? dateRange[1] : dateRange[0]);

  const getDateTo = () => (!dateRange[1] && dateRange[0] ? dateRange[0] : dateRange[1]);

  const { data, isLoading } = useIncomes({
    offset,
    limit: rowsPerPage,
    ordering,
    search,
    issue_date_from: getDateFrom(),
    issue_date_to: getDateTo(),
  });

  const onSearch = (newSearchTerm: string) => {
    const searchTerm = newSearchTerm.trim();
    if (searchTerm === search || (searchTerm.length > 0 && searchTerm.length < 3)) return;

    searchParams.set(SearchParam.search, searchTerm);
    setSearchParams(searchParams);

    setSearch(searchTerm);
    onPageChange(1);
  };

  useEffect(() => {
    if (data) {
      setCount(data.count);
    }
  }, [data]);

  const onChangeDateRange = (newDateRange: DateRange) => {
    searchParams.set(SearchParam.dateRange, JSON.stringify(newDateRange));
    setSearchParams(searchParams);

    setDateRange(newDateRange);
    onPageChange(1);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={1}>
        <Typography variant="h5">{t('msg_income_title')}</Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={ROUTES.DASHBOARD_INCOME_NEW}
        >
          {t('btn_add_new_income')}
        </Button>
      </Stack>

      <Box>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <DateRangePicker
              placeholder={t('msg_label_invoice_date')}
              variant="filled"
              onChange={onChangeDateRange}
              hasClearButton
              fullWidth
              defaultValue={dateRange}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Search defaultValue={search} onSearch={onSearch} />
          </Grid>
        </Grid>
      </Box>

      <Paper>
        <TableContainer sx={{ position: 'relative' }}>
          <IncomesTable
            items={data?.items}
            count={count}
            onSort={onSort}
            currentSort={currentSort}
            sortDirection={sortDirection}
            isLoading={isLoading}
          />

          <TablePagination
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableContainer>
      </Paper>
    </Stack>
  );
};
