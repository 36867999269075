import HelpIcon from '@mui/icons-material/Help';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { Formik, Form, useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormikTextField } from '~/components/Form/TextField';
import { WelcomeBackLayout } from '~/components/Layout/WelcomeBackLayout';
import { Logo } from '~/components/Logo';
import { LanguageSelector } from '~/components/Selector/LanguageSelector';
import { SupportChat } from '~/components/SupportChat';
import {
  DEEPFIN_SUPPORT_EMAIL,
  PRIVACY_POLICY_URL_TRANSLATION,
  TERMS_AND_CONDITIONS_URL_TRANSLATION,
} from '~/config/constants';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useAppConfig } from '~/context/AppConfigContext';
import { AuthPages } from '~/guards';
import { useResponsive } from '~/hooks/useResponsive';
import { useUsersLocationByIp } from '~/hooks/useUsersLocationByIp';
import { useCountriesOfResidence } from '~/models/Auth/hooks/useCountriesOfResidence';
import { useEmailLogin } from '~/models/Auth/hooks/useEmailLogin';
import { ROUTES } from '~/router/Routes';
import { colors } from '~/theme/colors';
import { AmplitudeEvent } from '~/types/amplitude';
import { sendAmplitudeData } from '~/utils/amplitude';
import { logger } from '~/utils/logger';
import { shouldHideSupportChat } from '~/utils/shouldHideSupportChat';

import { PhoneNumberForm } from '../WelcomeBack/PhoneNumberForm';
import { PhoneVerificationForm } from '../WelcomeBack/PhoneVerificationForm';

import { validationSchema } from './validationSchema';

export interface LoginFormValues {
  email: string;
  password: string;
}

export enum LoginFormField {
  email = 'email',
  password = 'password',
}

export const Login: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { defaultSupportLink, enabledCountries } = useAppConfig();

  const [countryOfResidence, setCountryOfResidence] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [countryCallingCode, setCountryCallingCode] = useState<string>('');
  const [showVerificationForm, setShowVerificationForm] = useState(false);

  const location = useLocation();
  const { location: locationByIp } = useUsersLocationByIp();
  const { data: countriesOfResidence } = useCountriesOfResidence();

  const supportChatMessengerId =
    enabledCountries?.find((country) => country.code === countryOfResidence)?.support_link
      ?.messenger_id ?? defaultSupportLink.messenger_id;

  const isMobile = useResponsive('down', 'sm');

  const handleSubmit = async (values: LoginFormValues) => {
    sendAmplitudeData(AmplitudeEvent.IntroCompleted, { process: 'login' });
    sendAmplitudeData(AmplitudeEvent.LoginIDSubmitted);
    loginWithEmail(values);
  };

  const { setErrors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: handleSubmit,
  });

  const { mutate: loginWithEmail, isLoading } = useEmailLogin(setErrors);

  useEffect(() => {
    sendAmplitudeData(AmplitudeEvent.LoginStarted);
  }, []);

  useEffect(() => {
    if (location.search.includes('phone=')) {
      const urlObj = new URL(window.location.href);
      const queryParams = new URLSearchParams(urlObj.search);
      const phoneNumber = queryParams.get('phone');

      if (phoneNumber) {
        const parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`);

        setCountryCallingCode(parsedPhoneNumber.countryCallingCode);
        setPhone(parsedPhoneNumber.nationalNumber);
      }
    }
  }, [location]);

  useEffect(() => {
    const setCountryOfResidencePhoneCode = async () => {
      const countryOfResidenceLocalStorageValue = await localStorage.getItem(
        localStorageKeys.countryOfResidence,
      );

      if (countryOfResidenceLocalStorageValue) {
        setCountryOfResidence(countryOfResidenceLocalStorageValue);
      } else if (
        locationByIp &&
        countriesOfResidence?.find((country) => country.code === locationByIp.countryCode)
      ) {
        setCountryOfResidence(locationByIp?.countryCode);
      }
    };
    setCountryOfResidencePhoneCode();
  }, [locationByIp, countriesOfResidence]);

  useEffect(() => {
    localStorage.setItem(localStorageKeys.lastVisitedAuthPage, AuthPages.login);
  }, []);

  useEffect(() => {
    if (t('msg_btn_support') === 'msg_btn_support') {
      logger.logMessage('Translations are not working properly!!!');
    }
  }, [t]);

  const handleNavigateToRegister = () => {
    sendAmplitudeData(AmplitudeEvent.RegistrationRedirected);
    navigate(ROUTES.SIGNUP);
  };

  const handlePhoneSubmit = (phone: string) => {
    setPhone(phone);
    setShowVerificationForm(true);
  };

  return (
    <WelcomeBackLayout>
      <Stack
        sx={{ height: '100%' }}
        justifyContent="space-between"
        alignItems="center"
        paddingX={{ xs: 2, md: 5 }}
        paddingY={2}
        spacing={3}
      >
        <Stack width="100%">
          <Stack direction="row" justifyContent="end" sx={{ width: 1 }}>
            <LanguageSelector countryCode={countryOfResidence} />
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Logo width={142} height={24} />
          </Stack>
        </Stack>
        {phone && showVerificationForm ? (
          <PhoneVerificationForm phone={phone} onBack={() => setShowVerificationForm(false)} />
        ) : (
          <Stack gap={4}>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              enableReinitialize
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {() => (
                <Form style={{ width: isMobile ? '100%' : 'auto' }}>
                  <Stack alignItems="flex-start" width="100%">
                    <Typography fontSize={24} fontWeight={700}>
                      {t('msg_login_title')}
                    </Typography>
                    <Stack paddingTop={1} flexDirection="row" alignItems="center">
                      <Typography fontSize={14} fontWeight={400}>
                        {t('msg_dont_have_an_account_yet')}
                      </Typography>
                      <Button
                        variant="text"
                        sx={{ padding: 0, marginLeft: 1 }}
                        onClick={handleNavigateToRegister}
                      >
                        {t('msg_try_for_free')}
                      </Button>
                    </Stack>
                  </Stack>
                  <Stack
                    spacing={1}
                    mt={3}
                    sx={{ width: { xs: '100%', lg: 480 }, maxWidth: 480 }}
                    alignItems="center"
                  >
                    <FormikTextField
                      sx={{ width: '100%' }}
                      name={LoginFormField.email}
                      label={t('msg_label_email')}
                      type="text"
                    />

                    <FormikTextField
                      sx={{ width: '100%' }}
                      name={LoginFormField.password}
                      label={t('msg_label_password')}
                      type="password"
                    />
                    <Stack flexDirection="row" justifyContent="flex-end" width="100%">
                      <Button
                        variant="text"
                        sx={{ padding: 0, marginLeft: 1 }}
                        onClick={() => navigate(ROUTES.FORGOT_PASSWORD)}
                      >
                        {t('msg_btn_forgot_password')}
                      </Button>
                    </Stack>
                    <Stack width={'100%'} marginTop={2}>
                      <LoadingButton
                        loading={isLoading}
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                      >
                        {t('msg_btn_login_email')}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
            <Stack gap={4}>
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <Box height={'1px'} width="100%" sx={{ background: colors.other.divider }}></Box>
                <Typography fontSize={12} fontWeight={400} color="secondary.darkGrey">
                  {t('msg_or')}
                </Typography>
                <Box height={'1px'} width="100%" sx={{ background: colors.other.divider }}></Box>
              </Stack>

              <PhoneNumberForm
                phone={phone}
                countryCallingCode={countryCallingCode}
                onSubmit={handlePhoneSubmit}
              />
            </Stack>
          </Stack>
        )}
        <Stack alignItems="center" spacing={5} sx={{ width: 360 }}>
          <Button
            variant="outlined"
            startIcon={<HelpIcon />}
            href={`mailto:${DEEPFIN_SUPPORT_EMAIL}`}
          >
            {t('msg_btn_support')}
          </Button>
          <Typography component="p" variant="caption" color="text.secondary" textAlign="center">
            {t('msg_registered_mark')}{' '}
            <Link
              href={t(PRIVACY_POLICY_URL_TRANSLATION)}
              component="a"
              color="inherit"
              target="_blank"
            >
              {t('msg_btn_btn_privacy_policy')}
            </Link>
            .{' '}
            <Link
              href={t(TERMS_AND_CONDITIONS_URL_TRANSLATION)}
              component="a"
              color="inherit"
              target="_blank"
            >
              {t('msg_btn_terms_of_use')}
            </Link>
            .
          </Typography>
        </Stack>
      </Stack>
      {!shouldHideSupportChat && (
        <SupportChat
          supportChatMessengerId={supportChatMessengerId}
          welcomeTitle={t('msg_messenger_welcome_title')}
        />
      )}
    </WelcomeBackLayout>
  );
};
