import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, CircularProgress, Fade, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateMerchantFormDialog } from '~/components/Dialog/MerchantFormDialog';
import { useMerchants } from '~/models/Merchant/hooks';
import { Merchant } from '~/types/merchant';

import { Search } from '../../Search';
import { FooterMenuItem, Popover } from '../CustomerSelector';

import { MerchantSelectorMenuItem } from './MerchantSelectorMenuItem';

type Props = {
  anchorEl: Element | null;
  open?: boolean;
  onSelectMerchant: (merchant?: Merchant) => void;
  onClose: () => void;
  hasCreateButton?: boolean;
};

export const MerchantSelectorMenu = ({
  anchorEl,
  open = false,
  onSelectMerchant,
  onClose,
  hasCreateButton = true,
}: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [showAddMerchantDialog, setShowAddMerchantDialog] = useState(false);

  const { data: merchants, isLoading } = useMerchants({ search });

  const onSearch = (newSearchTerm: string) => {
    const searchTerm = newSearchTerm.trim();
    if (searchTerm === search || (searchTerm.length > 0 && searchTerm.length < 3)) return;

    setSearch(searchTerm);
  };

  const loadingContent = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <CircularProgress />
      </Stack>
    );
  };

  const noResults = () => {
    const hasResults = merchants?.items.length;

    if (isLoading || hasResults) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height={56}>
        <Typography>{t('msg_label_no_results')}</Typography>
      </Stack>
    );
  };

  const selectCreatedMerchant = (merchant: Merchant) => {
    onSelectMerchant(merchant);
    onClose();
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        onClose={onClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        width={anchorEl?.clientWidth}
      >
        <Box
          padding={2}
          sx={{
            minWidth: anchorEl?.clientWidth,
          }}
        >
          <Search
            onSearch={onSearch}
            fullWidth
            variant="outlined"
            hasSearchIcon={false}
            autoFocus={true}
            placeholder={t('msg_label_search_merchant')}
          />
        </Box>

        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          {loadingContent()}
          {noResults()}

          {merchants?.items?.map((merchant) => (
            <MerchantSelectorMenuItem
              key={merchant.id}
              merchant={merchant}
              onSelectMerchant={() => onSelectMerchant(merchant)}
            />
          ))}
        </Box>

        {hasCreateButton && (
          <FooterMenuItem onClick={() => setShowAddMerchantDialog(true)} selected>
            <AddCircleIcon />
            <Typography variant="button" textTransform="none">
              {t('msg_label_add_new_merchant')}
            </Typography>
          </FooterMenuItem>
        )}
      </Popover>

      <CreateMerchantFormDialog
        open={showAddMerchantDialog}
        onClose={() => setShowAddMerchantDialog(false)}
        onSuccess={selectCreatedMerchant}
      />
    </>
  );
};
