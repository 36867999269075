import { toast } from 'material-react-toastify';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { model } from '~/models/Expense';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { ExpenseFormFields } from '~/types/expense';
import { SelectedFile } from '~/types/file';
import { FormikSetErrorsFn } from '~/types/formik';
import { sendAmplitudeData } from '~/utils/amplitude';
import { formatNumber } from '~/utils/formatNumber';

import { ExpenseDataForm, initialValues } from './ExpenseDataForm';

export const CreateExpenseForm = () => {
  const { t } = useTranslation();

  useEffect(() => {
    sendAmplitudeData(AmplitudeEvent.ExpenseNewStarted);
   }, [])

  const onSave = async (
    values: ExpenseFormFields,
    setErrors: FormikSetErrorsFn<ExpenseFormFields>,
  ) => {
    let success = true;
    let expenseId: string | undefined = undefined;

    try {
      const expense = await model.create({
        ...values,
        amount: formatNumber(values.amount ?? 0),
        full_amount: formatNumber(values.full_amount ?? 0),
      });
      expenseId = expense.id;
    } catch (error) {
      if (error instanceof ValidationError && setErrors) {
        setErrors(error.formikErrors);
      }
      toast.error(t('msg_error_expense_save_failed'));
      success = false;
    }

    if (expenseId) {
      try {
        await Promise.all(values.receipts.map((receipt) => model.addReceipt(expenseId!, receipt)));
      } catch (error) {
        if (error instanceof ValidationError<SelectedFile> && error.formikErrors.file) {
          toast.error(t(error.formikErrors.file as string));
        }
        success = false;
      }

      try {
        await model.finalize(expenseId);
      } catch (error) {
        if (error instanceof ValidationError && setErrors) {
          setErrors(error.formikErrors);
        }
        toast.error(t('msg_error_expense_save_failed'));
        success = false;
      }
    }

    if (success) {
      toast.success(t('msg_success_expense_saved'));
    sendAmplitudeData(AmplitudeEvent.ExpenseCreated, { id: values.id });
    }

    return success;
  };

  return (
    <ExpenseDataForm
      initialValues={initialValues}
      onSave={onSave}
    />
  );
};
