import { Field, FieldProps, useField } from 'formik';

import { LanguageSelect } from '~/components/Select';
import { Language } from '~/types/appConfig';

type FormikLanguageSelectProps = {
  name: string;
  label: string;
  languageItems?: Language[];
};

export const FormikLanguageSelect = ({ name, label, languageItems }: FormikLanguageSelectProps) => {
  const [field] = useField({ id: name, name });

  return (
    <Field {...field}>
      {(props: FieldProps<string>) => (
        <LanguageSelect languageItems={languageItems} label={label} field={props.field} />
      )}
    </Field>
  );
};
