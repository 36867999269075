import { pxToRem } from '~/utils/getFontValue';

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    notification: true;
  }
}

export const Badge = () => {
  return {
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: pxToRem(8),
          height: pxToRem(8),
          top: pxToRem(4),
          right: pxToRem(4),
        },
      },
    },
  };
};
