import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, SxProps, Theme } from '@mui/material';
import { useField } from 'formik';
import { toast } from 'material-react-toastify';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_PHOTO_UPLOAD_SIZE } from '~/config/constants';
import { resizeImageFile } from '~/utils/file';
import { pxToRem } from '~/utils/getFontValue';

import { Avatar, ClearContainer, EditContainer } from './AvatarPicker.style';

type Props = {
  name: string;
  objectFit?: 'contain' | 'cover';
  children?: ReactNode;
  sx?: SxProps<Theme> | undefined;
};

export const AvatarPicker = ({ name, objectFit = 'cover', children, sx }: Props) => {
  const { t } = useTranslation();
  const [field] = useField({ id: name, name });
  const [selectedAvatar, setSelectedAvatar] = useState(field.value);

  const openSelectFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      let file = (e.target as HTMLInputElement).files?.[0];

      if (file) {
        if (file.size > MAX_PHOTO_UPLOAD_SIZE) {
          file = await resizeImageFile(file);
        }
        if (file.size > MAX_PHOTO_UPLOAD_SIZE) {
          toast.error(t('msg_error_picture_size'));
          return;
        }
        const imageUri = URL.createObjectURL(file);
        setSelectedAvatar(imageUri);

        field.onChange({
          target: {
            name: field.name,
            value: file,
          },
        });
      }
    };
    input.click();
  };

  const onClearAvatar = () => {
    setSelectedAvatar(undefined);
    field.onChange({
      target: {
        name: field.name,
        value: null,
      },
    });
  };

  return (
    <>
      <Box sx={{ position: 'relative', width: pxToRem(128) }}>
        <Avatar
          imgProps={{ sx: { objectFit } }}
          variant="rounded"
          src={selectedAvatar}
          alt={t('msg_label_photo_picker')}
          sx={sx}
        >
          {children}
        </Avatar>
        <EditContainer>
          <IconButton size="small" onClick={openSelectFile} aria-label={t('msg_btn_edit')}>
            <EditIcon />
          </IconButton>
        </EditContainer>
        {selectedAvatar && (
          <ClearContainer>
            <IconButton size="small" onClick={onClearAvatar} aria-label={t('msg_btn_delete')}>
              <ClearIcon />
            </IconButton>
          </ClearContainer>
        )}
      </Box>
    </>
  );
};
