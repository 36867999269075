import { useQuery } from '@tanstack/react-query';

import { VatRate } from '~/types/invoice';

import { model } from '../';

export const useVatRates = () =>
  useQuery<VatRate[]>(['vatRates'], () => model.getVatRates(), {
    onSuccess: (res) => {
      return res?.push({ vat_percentage: 1000, description: 'msg_vat_rate_other' });
    },
  });
