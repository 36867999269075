import { Income } from '~/types/income';
import { HeadCell } from '~/types/table';

export const headCells: HeadCell<Income>[] = [
  {
    id: 'customer__name',
    label: 'msg_label_customer',
    sortable: true,
  },
  {
    id: 'issue_date',
    label: 'msg_label_income_date',
    sortable: true,
  },
  {
    id: 'name',
    label: 'msg_label_income_name',
  },
  {
    id: 'amount',
    label: 'msg_label_income_amount',
    numeric: true,
    sortable: true,
  },
  {
    id: 'id',
    label: '',
  },
];
