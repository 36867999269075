import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GoBackButton } from '~/components/Button/GoBackButton';
import { DeleteInvoiceDialog } from '~/components/Dialog/DeleteInvoiceDialog';
import { MarkInvoiceAsPaidDialog } from '~/components/Dialog/MarkInvoiceAsPaidDialog';
import { InvoiceStatusLabel } from '~/components/Label';
import { CURRENT_INVOICES_LIST_SEARCH_QUERY } from '~/config/constants';
import { InvoiceStatus, InvoiceType } from '~/types/invoice';

type Props = {
  id: string;
  onGoBack?: () => void;
  status?: InvoiceStatus;
  onSaveAsPdf?: () => void;
  currencySymbol: string;
  invoiceType: InvoiceType;
};

export const Header = ({
  id,
  onGoBack,
  status = InvoiceStatus.Draft,
  onSaveAsPdf,
  currencySymbol,
  invoiceType,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showMarkAsPaidDialog, setShowMarkAsPaidDialog] = useState(false);
  const currentInvoicesListSearchQuery = sessionStorage.getItem(CURRENT_INVOICES_LIST_SEARCH_QUERY);

  const goBack = () => {
    if (onGoBack) {
      onGoBack();
    } else {
      navigate(-1);
    }
  };

  const onDeleteSuccess = () => {
    currentInvoicesListSearchQuery ? navigate(currentInvoicesListSearchQuery) : navigate(-1);
  };

  return (
    <>
      <Box sx={{ mb: 2.5 }}>
        <GoBackButton onClick={goBack} />
      </Box>

      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        justifyContent={{ lg: 'space-between', xs: 'start' }}
        alignItems={{ lg: 'center', xs: 'start' }}
        spacing={2}
        sx={{ mb: 3 }}
      >
        <Typography variant="h5">
          {invoiceType === InvoiceType.invoice ? t('msg_invoice_title') : t('msg_proforma_title')}{' '}
          <InvoiceStatusLabel status={status} />
        </Typography>

        <Box>
          <Button variant="square" startIcon={<PictureAsPdfIcon />} onClick={onSaveAsPdf}>
            {t('msg_btn_save_as_pdf')}
          </Button>

          <Button
            variant="square"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={() => setShowDeleteDialog(true)}
          >
            {t('invoice_delete_action_sheet_yes')}
          </Button>
        </Box>
      </Stack>

      <DeleteInvoiceDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onSuccess={onDeleteSuccess}
      />

      <MarkInvoiceAsPaidDialog
        invoiceId={id}
        open={showMarkAsPaidDialog}
        onClose={() => setShowMarkAsPaidDialog(false)}
        currencySymbol={currencySymbol}
      />
    </>
  );
};
