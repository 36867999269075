import { useQuery } from '@tanstack/react-query';

import { Customer, CustomerListOptions } from '~/types/customer';
import { PaginationData } from '~/types/pagination';

import { model } from '../';

export const customersQueryKey = 'customers';

export const useCustomers = (params?: CustomerListOptions) =>
  useQuery<PaginationData<Customer>>([customersQueryKey, params], () => model.index(params));
