import { useQuery } from '@tanstack/react-query';

import { CurrentUser } from '~/types/auth';

import { model } from '../';

export const currentUserQueryKey = ['currentUser'];

export const useCurrentUser = () =>
  useQuery<CurrentUser>(currentUserQueryKey, () => model.getUser());
