import { Theme } from '@mui/material';

export const AppBar = (theme: Theme) => {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[0],
        },
      },
    },
  };
};
