import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

import { FirebaseEvent } from '~/types/firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyDinNxLVWZFsJmnwGfjZ_SPwXTr8bRZmQ8',
  authDomain: 'deepfin-io.firebaseapp.com',
  databaseURL: 'https://deepfin-io.firebaseio.com',
  projectId: 'deepfin-io',
  storageBucket: 'deepfin-io.appspot.com',
  messagingSenderId: '875395294583',
  appId: '1:875395294583:web:539a3e7dda527d043bed72',
  measurementId: 'G-RFB6B9RP6S',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const logFirebaseEvent = (event: FirebaseEvent) => {
  logEvent(analytics, event);
};
