import { toast } from 'material-react-toastify';
import { useTranslation } from 'react-i18next';

import { model } from '~/models/Income';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn } from '~/types/formik';
import { IncomeFormFields } from '~/types/income';
import { formatNumber } from '~/utils/formatNumber';

import { IncomeDataForm, initialValues } from './IncomeDataForm';

export const CreateIncomeForm = () => {
  const { t } = useTranslation();

  const onSave = async (
    values: IncomeFormFields,
    setErrors: FormikSetErrorsFn<IncomeFormFields>,
  ) => {
    let success = true;

    try {
      await model.create({
        ...values,
        amount: formatNumber(values.amount ?? 0),
        is_draft: false,
      });
    } catch (error) {
      if (error instanceof ValidationError && setErrors) {
        setErrors(error.formikErrors);
      }
      toast.error(t('msg_error_income_save_failed'));
      success = false;
    }

    if (success) {
      toast.success(t('msg_income_created'));
    }

    return success;
  };

  return <IncomeDataForm initialValues={initialValues} onSave={onSave} />;
};
