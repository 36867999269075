import { TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Customer } from '~/types/customer';
import { HeadCell, SortDirection } from '~/types/table';

type Props = {
  headCells: HeadCell<Customer>[];
  currentSort?: keyof Customer | string;
  sortDirection?: SortDirection;
  onSort: (column: keyof Customer | string) => void;
};

export const TableHead = ({ headCells, currentSort, sortDirection, onSort }: Props) => {
  const { t } = useTranslation();

  return (
    <MuiTableHead
      sx={{
        position: 'relative',
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={currentSort === headCell.id ? sortDirection : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={currentSort === headCell.id}
                direction={
                  currentSort === headCell.id && sortDirection === SortDirection.Desc
                    ? SortDirection.Desc
                    : SortDirection.Asc
                }
                onClick={() => onSort(headCell.id)}
              >
                {t(headCell.label)}
              </TableSortLabel>
            ) : (
              t(headCell.label)
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};
