import { styled, TextField } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

import { FormikTextField } from './TextField';

export const numberTextFieldStyles = {
  '.MuiInputBase-input::-webkit-outer-spin-button, .MuiInputBase-input::-webkit-inner-spin-button':
    {
      WebkitAppearance: 'none',
      MozAppearance: 'textfield',
    },
};

export const TableCellTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'square',
})<{ square?: boolean }>(({ square = true }) => ({
  '.MuiInputBase-root': {
    borderRadius: square ? 0 : pxToRem(4),
  },
}));

export const FormikTableCellTextField = styled(FormikTextField)({
  '.MuiInputBase-root': {
    borderRadius: 0,
  },
});
