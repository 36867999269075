export type HeadCell<T> = {
  disablePadding?: boolean;
  id: keyof T | string;
  label: string;
  numeric?: boolean;
  sortable?: boolean;
  width?: number | string;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}
