import { Stack, SxProps, Theme } from '@mui/material';

import { ErrorAlert } from '../Alert';

type Props = {
  errors: string[];
  sx?: SxProps<Theme>;
};

export const ErrorsList = ({ errors, sx }: Props) => {
  if (!errors.length) {
    return null;
  }

  return (
    <Stack spacing={1} sx={sx}>
      {errors.map((error, index) => (
        <ErrorAlert key={index} title={error} />
      ))}
    </Stack>
  );
};
