import { FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';

import { model } from '~/models/Merchant';
import { useEditMerchant } from '~/models/Merchant/hooks';
import { ValidationError } from '~/types/error';
import { Merchant, MerchantFormFields } from '~/types/merchant';

import { initialEmptyValues } from './config';
import { MerchantFormDialog } from './MerchantFormDialog';

type Props = {
  id: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: (merchant: Merchant) => void;
};

export const EditMerchantFormDialog = ({ id, open, onClose, onSuccess }: Props) => {
  const [initialValues, setInitialValues] = useState<MerchantFormFields>(initialEmptyValues);
  const { mutateAsync, isLoading } = useEditMerchant({
    onSuccess: (merchant) => {
      if (merchant && onSuccess) {
        onSuccess(merchant);
      }
      onClose();
    },
  });

  const fetchMerchant = async () => {
    const merchant = await model.get(id);
    const initialValues: MerchantFormFields = {
      name: merchant.name,
      company_code: merchant.company_code,
      vat_number: merchant.vat_number,
      address: merchant.address,
      email: merchant.email,
      phone: merchant.phone,
      website: merchant.website,
      contact_person: merchant.contact_person,
    };

    setInitialValues(initialValues);
  };

  const onSubmit = async (
    data: MerchantFormFields,
    { setErrors, resetForm }: FormikHelpers<MerchantFormFields>,
  ) => {
    try {
      await mutateAsync({ id, data });
      resetForm();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
      }
    }
  };

  useEffect(() => {
    if (!open) return;

    fetchMerchant();
  }, [open]);

  return (
    <MerchantFormDialog
      title="msg_edit_merchant_title"
      open={open}
      initialValues={initialValues}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};
