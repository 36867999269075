import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CenteredSpinner } from '~/components/Spinner';
import { useNavigateBack } from '~/hooks/useNavigateBack';
import { model } from '~/models/Income';
import { ROUTES } from '~/router/Routes';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn } from '~/types/formik';
import { IncomeFormFields } from '~/types/income';
import { formatCurrencyNumber, formatNumber } from '~/utils/formatNumber';

import { IncomeDataForm, initialValues as emptyValues } from './IncomeDataForm';

export const EditIncomeForm = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigateBack = useNavigateBack();

  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<IncomeFormFields>(emptyValues);

  const getIncome = async () => {
    if (!id) return;

    setIsLoading(true);

    try {
      const income = await model.get(Number(id));

      const initialValues: IncomeFormFields = {
        customer: income.customer,
        issue_date: income.issue_date,
        notes: income.notes,
        amount: formatCurrencyNumber(income.amount),
      };

      setInitialValues(initialValues);
    } catch (error) {
      onIncomeFailure();
    }

    setIsLoading(false);
  };

  const onSave = async (
    values: IncomeFormFields,
    setErrors: FormikSetErrorsFn<IncomeFormFields>,
  ) => {
    if (!id) return false;

    let success = true;

    try {
      await model.update(Number(id), {
        ...values,
        amount: formatNumber(values.amount ?? 0),
      });
    } catch (error) {
      if (error instanceof ValidationError && setErrors) {
        setErrors(error.formikErrors);
      }
      toast.error(t('msg_error_income_save_failed'));
      success = false;
    }

    if (success) {
      toast.success(t('msg_success_income_saved'));
    }

    return success;
  };

  const onIncomeFailure = () => {
    toast.error(t('msg_error_get_income_failed'));
    navigateBack(ROUTES.DASHBOARD_INCOME);
  };

  useEffect(() => {
    getIncome();
  }, [id]);

  if (isLoading || !initialValues) {
    return <CenteredSpinner />;
  }

  return <IncomeDataForm initialValues={initialValues} onSave={onSave} />;
};
