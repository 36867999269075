import { IconProps } from '~/types/icon';

export const ArrowBackFilled = (props: IconProps) => {
  const { size = '32', color = 'currentColor', ...rest } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M25.2917 16C25.2917 16.75 24.7084 17.3333 24.0001 17.3333H11.2084L15.5834 21.75C16.1251 22.25 16.1251 23.125 15.5834 23.625C15.3334 23.875 15.0001 24 14.6667 24C14.2917 24 13.9584 23.875 13.7084 23.625L7.04175 16.9583C6.50008 16.4583 6.50008 15.5833 7.04175 15.0833L13.7084 8.41667C14.2084 7.87501 15.0834 7.87501 15.5834 8.41667C16.1251 8.91667 16.1251 9.79167 15.5834 10.2917L11.2084 14.6667H24.0001C24.7084 14.6667 25.2917 15.2917 25.2917 16Z"
        fill={color}
      />
    </svg>
  );
};
