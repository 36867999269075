import { Stack, Typography, Button } from '@mui/material';
import { FC } from 'react';
import { isSafari } from 'react-device-detect';

import { colors } from '~/theme/colors';
import { mobileOS, userMobileOS } from '~/utils/checkMobileDeviceOS';

interface Props {
  title: string;
  buttonTitle: string;
}

const checkIfAppInstalled = async () => {
  if (isSafari) {
    window.location.href = 'https://apps.apple.com/us/app/deepfin/id1473558217';
    return;
  }
  setTimeout(function () {
    if (userMobileOS() === mobileOS.iOS) {
      window.location.href = 'https://apps.apple.com/us/app/deepfin/id1473558217';
    }
    if (userMobileOS() === mobileOS.Android) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.deepfin&hl=en_US';
    }
  }, 250);
  window.location.href = 'deepfin://';
};

export const TopBanner: FC<Props> = ({ title, buttonTitle }) => {
  return (
    <Stack
      flexDirection="row"
      width="100%"
      padding={2}
      alignItems="center"
      justifyContent="space-between"
      borderBottom={1}
      borderColor={colors.other.divider}
      bgcolor={colors.background.default}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Stack alignItems="center" justifyContent="center">
          <img
            style={{
              width: '24px',
              height: '24px',
            }}
            src={require('~/assets/icons/df_icon.png')}
          />
        </Stack>
        <Typography fontSize={12} fontWeight={400}>
          {title}
        </Typography>
      </Stack>
      <Button size="small" variant="outlined" onClick={checkIfAppInstalled}>
        {buttonTitle}
      </Button>
    </Stack>
  );
};
