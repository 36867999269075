import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export type AIAgentChatMessageAuthor = 'user' | 'agent';

export type AIAgentChatMessage = {
  author: AIAgentChatMessageAuthor;
  message: string;
};

export const chatSessionIdAtom = atom<number | undefined>(undefined);
export const chatSessionMessagesAtom = atomWithReset<AIAgentChatMessage[]>([
  { author: 'agent', message: 'msg_initial_agent_message' },
]);
export const shouldReinitializeSession = atom<boolean>(false);
