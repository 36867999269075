import { useQuery } from '@tanstack/react-query';

import { InvoicesStatusSummary } from '~/types/invoice';

import { model } from '..';

export const invoicesStatusSummaryQueryKey = ['invoices', 'summary'];

export const useInvoicesStatusSummary = () =>
  useQuery<InvoicesStatusSummary>(invoicesStatusSummaryQueryKey, () => model.statusSummary());
