import { Components, createTheme } from '@mui/material/styles';

import { colors, text } from './colors';
import componentsOverride from './overrides';
import { createShadow } from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    text,
    primary: colors.primary,
    action: colors.action,
    background: colors.background,
    divider: colors.other.divider,
  },
  typography,
  shadows: createShadow(),
});

theme.components = componentsOverride(theme) as Components;

export { theme };
