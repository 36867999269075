import { CustomerFormFields } from '~/types/customer';

export const tabs = ['msg_label_requisites', 'msg_label_general_information', 'msg_label_settings'];

export enum CustomerFormField {
  name = 'name',
  company_number = 'company_number',
  vat_number = 'vat_number',
  address = 'address',
  email = 'email',
  phone = 'phone',
  website = 'website',
  contact_person = 'contact_person',
  soft_collection = 'soft_collection',
}

export const initialEmptyValues: CustomerFormFields = {
  [CustomerFormField.name]: '',
  [CustomerFormField.company_number]: '',
  [CustomerFormField.vat_number]: '',
  [CustomerFormField.address]: '',
  [CustomerFormField.email]: '',
  [CustomerFormField.phone]: '',
  [CustomerFormField.website]: '',
  [CustomerFormField.contact_person]: '',
  [CustomerFormField.soft_collection]: false,
};
