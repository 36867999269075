import React from 'react';
import { To, useNavigate } from 'react-router-dom';

import { useLocationHistoryContext } from '../context/LocationHistoryContext';

export const useNavigateBack = () => {
  const navigate = useNavigate();
  const locationHistory = useLocationHistoryContext();

  const navigateBack = React.useCallback(
    (location: To) => {
      if (location === locationHistory[locationHistory.length - 2]) {
        navigate(-1);
        return;
      }
      navigate(location);
    },
    [navigate],
  );

  return navigateBack;
};
