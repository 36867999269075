import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import { useField } from 'formik';

import IOSSwitch from '~/components/Switch/IOSSwitch';
import { colors } from '~/theme/colors';

type Props = Omit<FormControlLabelProps, 'id' | 'control' | 'sx'> & {
  name: string;
  sxStyle?: object;
  withBox?: boolean;
};

export const FormikIOSSwitch = ({ name, withBox, sxStyle, ...props }: Props) => {
  const [field] = useField({ id: name, name });

  return (
    <FormControlLabel
      id={name}
      control={<IOSSwitch checked={!!field.value} />}
      sx={{
        m: 0,
        gap: 1,
        ...(withBox && {
          backgroundColor: colors.background.paper,
          px: 1.5,
          py: 1,
          borderRadius: 1,
          justifyContent: 'space-between',
        }),
        ...sxStyle,
      }}
      {...props}
      {...field}
    />
  );
};
