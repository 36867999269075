import { useMutation } from '@tanstack/react-query';

import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '../';

import { incomesQueryKey } from './consts';

export const useDeleteIncome = ({ onSuccess }: MutationProps) =>
  useMutation(model.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries([incomesQueryKey]);
      onSuccess?.();
    },
  });
