import { FormikHelpers } from 'formik';

import { useCreateCustomer } from '~/models/Customer/hooks';
import { Customer, CustomerFormFields } from '~/types/customer';
import { ValidationError } from '~/types/error';

import { initialEmptyValues } from './config';
import { CustomerFormDialog } from './CustomerFormDialog';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess?: (customer: Customer) => void;
  initialCustomerName?: string;
};

export const CreateCustomerFormDialog = ({
  open,
  onClose,
  onSuccess,
  initialCustomerName,
}: Props) => {
  const { mutateAsync, isLoading } = useCreateCustomer({
    onSuccess: (customer) => {
      if (customer && onSuccess) {
        onSuccess(customer);
      }
      onClose();
    },
  });

  const onSubmit = async (
    values: CustomerFormFields,
    { setErrors, resetForm }: FormikHelpers<CustomerFormFields>,
  ) => {
    try {
      await mutateAsync(values);
      resetForm();
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error.formikErrors);
      }
    }
  };

  return (
    <CustomerFormDialog
      title="msg_add_new_customer_title"
      open={open}
      initialValues={
        initialCustomerName
          ? { ...initialEmptyValues, name: initialCustomerName }
          : initialEmptyValues
      }
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};
