import { styled } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

import { config } from './Drawer/config';

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== ('open' || 'isTablet'),
})<{
  open?: boolean;
  isTablet?: boolean;
}>(({ theme, open = true, isTablet }) => ({
  flexGrow: 1,
  padding: theme.spacing(isTablet ? 2 : 5),
  paddingBottom: theme.spacing(10),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: pxToRem(config.drawerWidth),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: pxToRem(config.drawerWidth * 2),
  }),
}));
