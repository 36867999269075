import { TableRow as MuiTableRow, TableCell, MenuItem } from '@mui/material';
import { useField } from 'formik';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikTableCellSelect } from '~/components/Form/Select';
import { FormattedFormikTextField, FormikTableCellTextField } from '~/components/Form/TextField';
import { MenuItemContainer } from '~/components/Menu/MenuItem';
import { ExpenseDescriptionSelector } from '~/components/Selector/ExpenseDescriptionSelector';
import { useAppConfig } from '~/context/AppConfigContext';
import { useAuth } from '~/context/AuthContext';
import { formatFieldValueToNumber } from '~/utils/formatNumber';

export const TableRow = () => {
  const { expenseCategories } = useAppConfig();
  const { t } = useTranslation();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const categoryFieldRef = useRef<HTMLInputElement>(
    null,
  ) as React.MutableRefObject<HTMLInputElement>;
  const categoryCellRef = useRef<HTMLTableCellElement>(
    null,
  ) as React.MutableRefObject<HTMLTableCellElement>;

  const [descriptionField] = useField('description');
  const [categoryField] = useField('category');
  const [fullAmountField] = useField<string>('full_amount');
  const [percentageField, _percentageMeta, percentageHelpers] = useField<string>('percentage');
  const [amountField, _amountMeta, amountHelpers] = useField<string>('amount');

  const onFullAmountChange = (value: string) => {
    const formattedFullAmount = formatFieldValueToNumber(value);
    const formattedPercentage = formatFieldValueToNumber(percentageField.value);

    const newValue = (formattedFullAmount * formattedPercentage) / 100;
    if (Number.isNaN(newValue)) {
      return;
    }
    amountHelpers.setValue(newValue.toFixed(2).replace('.', ','));
  };

  const onAmountChange = (value: string) => {
    const formattedAmount = formatFieldValueToNumber(value);
    const formattedFullAmount = formatFieldValueToNumber(fullAmountField.value);

    const newValue = (formattedAmount / formattedFullAmount) * 100;
    if (Number.isNaN(newValue) || !Number.isFinite(newValue)) {
      return;
    }
    percentageHelpers.setValue(newValue.toFixed(2).replace('.', ','));
  };

  const onPercentageChange = (value: string) => {
    const formattedPercentage = formatFieldValueToNumber(value);
    const formattedFullAmount = formatFieldValueToNumber(fullAmountField.value);

    const newValue = (formattedFullAmount * formattedPercentage) / 100;
    if (Number.isNaN(newValue)) {
      return;
    }
    amountHelpers.setValue(newValue.toFixed(2).replace('.', ','));
  };

  return (
    <MuiTableRow>
      <TableCell padding="none">
        <ExpenseDescriptionSelector
          onChange={(suggestion) => {
            descriptionField.onChange({
              target: {
                name: descriptionField.name,
                value: suggestion,
              },
            });

            categoryFieldRef.current?.focus();
          }}
        >
          <FormikTableCellTextField
            variant="filled"
            name={descriptionField.name}
            placeholder={t('msg_label_expense_description')}
            hiddenLabel
            fullWidth
          />
        </ExpenseDescriptionSelector>
      </TableCell>
      <TableCell ref={categoryCellRef} sx={{ maxWidth: 400 }} padding="none">
        <FormikTableCellSelect
          inputRef={categoryFieldRef}
          variant="filled"
          name={categoryField.name}
          placeholder={t('msg_label_expense_category')}
          fullWidth
          hiddenLabel
        >
          {expenseCategories.map((category) => (
            <MenuItem key={category} value={category} sx={{ whiteSpace: 'normal' }}>
              <MenuItemContainer width={categoryCellRef?.current?.clientWidth}>
                {t(`msg_label_${category}`.toLowerCase())}
              </MenuItemContainer>
            </MenuItem>
          ))}
        </FormikTableCellSelect>
      </TableCell>
      <TableCell padding="none">
        <FormattedFormikTextField
          data-testid="full-amount-input-testid"
          variant="filled"
          name={fullAmountField.name}
          InputProps={{
            endAdornment: base_currency.symbol_native,
          }}
          placeholder="0,00"
          hiddenLabel
          square={true}
          fullWidth
          onBlur={(event) => onFullAmountChange(event.target.value)}
          onChange={(event) => onFullAmountChange(event.target.value)}
        />
      </TableCell>
      <TableCell padding="none">
        <FormattedFormikTextField
          variant="filled"
          name={percentageField.name}
          InputProps={{
            endAdornment: '%',
          }}
          placeholder="0"
          hiddenLabel
          fullWidth
          onBlur={(event) => onPercentageChange(event.target.value)}
          onChange={(event) => onPercentageChange(event.target.value)}
        />
      </TableCell>
      <TableCell padding="none">
        <FormattedFormikTextField
          variant="filled"
          name={amountField.name}
          InputProps={{
            endAdornment: base_currency.symbol_native,
          }}
          placeholder="0,00"
          hiddenLabel
          square={true}
          fullWidth
          max={formatFieldValueToNumber(fullAmountField.value)}
          onBlur={(event) => onAmountChange(event.target.value)}
          onChange={(event) => onAmountChange(event.target.value)}
        />
      </TableCell>
    </MuiTableRow>
  );
};
