import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmationColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success';

type ConfirmationAction = {
  label: string;
  icon?: React.ReactNode;
  color?: ConfirmationColor;
  onClick: () => void;
  showIsLoading?: boolean;
};

type Props = DialogProps & {
  title: string;
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  actions?: ConfirmationAction[];
};

export const ConfirmationDialog = ({ title, open, onClose, isLoading, actions }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 378,
        },
      }}
    >
      <Stack direction="row" justifyContent="end" alignItems="center" px={2} pt={2}>
        <IconButton onClick={onClose} aria-label={t('msg_btn_close')}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <DialogContent>
        <Typography component="div" variant="h5" align="center">
          {title}
        </Typography>
      </DialogContent>
      <Stack>
        {actions?.map((action) => (
          <React.Fragment key={action.label}>
            <Divider />
            <LoadingButton
              color={action.color}
              onClick={action.onClick}
              variant="text"
              size="large"
              startIcon={action.icon}
              loading={action.showIsLoading ? isLoading : false}
              sx={{ borderRadius: 0, height: 58 }}
            >
              {action.label}
            </LoadingButton>
          </React.Fragment>
        ))}
      </Stack>
    </Dialog>
  );
};
