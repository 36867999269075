export const carouselItems = [
  {
    titleLabel: 'msg_label_title_first',
    subtitleLabel: 'msg_label_subtitle_first',
    image: '/assets/images/woman.webp',
  },
  {
    titleLabel: 'msg_label_title_second',
    subtitleLabel: 'msg_label_subtitle_second',
    image: '/assets/images/woman-with-computer.webp',
  },
  {
    titleLabel: 'msg_label_title_third',
    subtitleLabel: 'msg_label_subtitle_third',
    image: '/assets/images/friends.webp',
  },
];
