import { useMutation } from '@tanstack/react-query';

import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '../';

import { customersQueryKey } from './useCustomers';
import { suggestedCustomersQueryKey } from './useSuggestedCustomers';

export const useDeleteCustomer = ({ onSuccess }: MutationProps) =>
  useMutation(model.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries([customersQueryKey]);
      queryClient.invalidateQueries(suggestedCustomersQueryKey);
      onSuccess?.();
    },
  });
