import { Box, Button, Paper, Stack, TableContainer, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateServiceFormDialog } from '~/components/Dialog/ServiceFormDialog';
import { Search } from '~/components/Search';
import { TablePagination } from '~/components/Table';
import { usePagination } from '~/hooks/usePagination';
import { useInvoiceItemTemplates } from '~/models/InvoiceItemTemplate/hooks';
import { AmplitudeEvent } from '~/types/amplitude';
import { sendAmplitudeData } from '~/utils/amplitude';

import { ServicesTable } from './ServicesTable';

export const Services = () => {
  const { t } = useTranslation();

  const [showAddServiceDialog, setShowAddServiceDialog] = useState(false);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);

  const { offset, page, rowsPerPage, onPageChange, onRowsPerPageChange } = usePagination(count);

  const { data, isLoading } = useInvoiceItemTemplates({
    offset,
    limit: rowsPerPage,
    search,
  });

  const onSearch = (searchTerm: string) => {
    if (searchTerm === search) return;

    setSearch(searchTerm);
    onPageChange(1);
  };

  useEffect(() => {
    if (data) {
      setCount(data.count);
    }
  }, [data]);

  useEffect(() => {
    sendAmplitudeData(AmplitudeEvent.ServicesListRefreshed);
  }, []);

  const handleAddNewService = () => {
    sendAmplitudeData(AmplitudeEvent.ServiceNewStarted);
    setShowAddServiceDialog(true);
  };

  return (
    <>
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          mb={1}
        >
          <Typography variant="h5">{t('msg_services_title')}</Typography>
          <Button variant="contained" color="primary" size="large" onClick={handleAddNewService}>
            {t('msg_invoice_item_add_new')}
          </Button>
        </Stack>

        <Box>
          <Search onSearch={onSearch} />
        </Box>

        <Paper>
          <TableContainer sx={{ position: 'relative' }}>
            <ServicesTable items={data?.items} isLoading={isLoading} />

            <TablePagination
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableContainer>
        </Paper>
      </Stack>

      <CreateServiceFormDialog
        open={showAddServiceDialog}
        onClose={() => setShowAddServiceDialog(false)}
      />
    </>
  );
};
