import { styled } from '@mui/material';

import { FormikSelect } from './Select';

export const numberTextFieldStyles = {
  '.MuiInputBase-input::-webkit-outer-spin-button, .MuiInputBase-input::-webkit-inner-spin-button':
    {
      WebkitAppearance: 'none',
      MozAppearance: 'textfield',
    },
};

export const FormikTableCellSelect = styled(FormikSelect)({
  borderRadius: '0 !important',
});
