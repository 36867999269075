import * as Sentry from '@sentry/react';

const logException = (exception: unknown) => {
  Sentry.captureException(exception);
};

const logMessage = (message: string, captureContext?: Sentry.SeverityLevel) => {
  Sentry.captureMessage(message, captureContext);
};

export const logger = {
  logException,
  logMessage,
};
