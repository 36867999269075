import { PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { FullPageSpinner } from '~/components/Spinner';
import { localStorageKeys } from '~/config/localStorageKeys';
import { useAuth } from '~/context/AuthContext';
import { ROUTES } from '~/router/Routes';

export enum AuthPages {
  signup = '/signup',
  login = '/login',
}

export const GuestGuard = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const retrieveLastVisitedAuthPageAndSetPathname = async () => {
      if (isAuthenticated) {
        navigate(ROUTES.DASHBOARD);
      } else if (pathname === ROUTES.DEFAULT && !isAuthenticated) {
        const localStorageValue = await localStorage.getItem(localStorageKeys.lastVisitedAuthPage);
        if (localStorageValue) {
          navigate(localStorageValue);
        } else {
          navigate(AuthPages.signup);
          await localStorage.setItem(localStorageKeys.lastVisitedAuthPage, AuthPages.signup);
        }
      }
    };
    retrieveLastVisitedAuthPageAndSetPathname();
  }, [pathname]);

  if (!isInitialized) {
    return <FullPageSpinner />;
  }

  if (isAuthenticated) {
    return <Navigate to={ROUTES.DASHBOARD} />;
  }

  return <>{children}</>;
};
