import { useQuery } from '@tanstack/react-query';

import { Invoice } from '~/types/invoice';

import { model } from '../';

import { invoicesIndexQueryKey } from './useInvoices';

export const useInvoice = (id: string | undefined) =>
  useQuery<Invoice>([invoicesIndexQueryKey, id], () => model.get(id), { enabled: !!id });
