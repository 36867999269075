export enum mobileOS {
  iOS = 'iOS',
  Android = 'Android',
}

export const userMobileOS = () => {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return mobileOS.Android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return mobileOS.iOS;
  }
};
