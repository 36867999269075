import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Stack,
  MenuItem,
  TableRow as MuiTableRow,
  TableCell,
  Button,
  Typography,
  Paper,
  FormHelperText,
  Box,
} from '@mui/material';
import MUISelect from '@mui/material/Select';
import { Formik, Form, FieldArray, FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { SendInvoiceDialog } from '~/components/Dialog/SendInvoiceDialog';
import { FormikDatePicker } from '~/components/Form/DatePicker';
import { FormikLanguageSelect } from '~/components/Form/FormikLanguageSelect';
import { Select } from '~/components/Form/Select';
import { CustomerSelectorBox } from '~/components/Form/SelectorBox/CustomerSelectorBox';
import { FormikTextField } from '~/components/Form/TextField';
import { Select as TemplateSelect } from '~/components/Select/Select';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Invoices';
import {
  invoicesIndexQueryKey,
  invoicesStatusSummaryQueryKey,
  useInvoice,
  useSaveAsPdf,
} from '~/models/Invoices/hooks';
import { useEnabledInvoiceLanguages } from '~/models/Invoices/hooks/useEnabledInvoiceLanguages';
import { useInvoiceTemplateList } from '~/models/Invoices/hooks/useInvoiceTemplateList';
import { useNextInvoiceNumber } from '~/models/Invoices/hooks/useNextInvoiceNumber';
import { useVatRates } from '~/models/Invoices/hooks/useVatRates';
import { currentUserQueryKey } from '~/models/User/hooks/useCurrentUser';
import { AmplitudeEvent } from '~/types/amplitude';
import { ValidationError } from '~/types/error';
import { FormikSetErrorsFn, FormikSetFieldValueFn } from '~/types/formik';
import { Invoice, InvoiceFormFields, InvoiceType, SendInvoiceFormFields } from '~/types/invoice';
import { queryClient } from '~/utils';
import { sendAmplitudeData } from '~/utils/amplitude';
import { formatCurrency, formatNumericStringToNumber } from '~/utils/formatNumber';
import { openInNewTab } from '~/utils/openInNewTab';

import { pickFontSize } from '../../InvoicePlatform';
import { Header } from '../Header';

import { emptyInvoiceItem } from './config';
import { InvoiceItemsTable, TableRow } from './InvoiceItemsTable';
import { validationSchema } from './validationSchema';

export enum InvoiceFormField {
  id = 'id',
  customer = 'customer',
  number = 'number',
  issue_date = 'issue_date',
  payment_due = 'payment_due',
  invoice_items = 'invoice_items',
  notes = 'notes',
  language = 'language',
  currency = 'currency',
  s_spain_irpf_percentage = 's_spain_irpf_percentage',
  invoice_type = 'invoice_type',
}

export type Props = {
  initialValues: InvoiceFormFields;
  onSave: (
    data: InvoiceFormFields,
    setErrors: FormikSetErrorsFn<InvoiceFormFields>,
    setFieldValue: FormikSetFieldValueFn,
    finalize?: boolean,
    withVat?: boolean,
    vatEnabled?: boolean,
  ) => Promise<{ success: boolean; invoice: Invoice | undefined }>;
  onSaveAndClose: (
    data: InvoiceFormFields,
    setErrors: FormikSetErrorsFn<InvoiceFormFields>,
    setFieldValue: FormikSetFieldValueFn,
    finalize?: boolean,
    withVat?: boolean,
    vatEnabled?: boolean,
  ) => void;
  onDeleteAndClose: () => void;
  isSavingAndClosing?: boolean;
  isDeletingAndClosing?: boolean;
  pdf?: string;
  invoiceType?: InvoiceType;
  onInvoiceTypeChange: (type: InvoiceType) => void;
};

export const InvoiceDataForm = ({
  initialValues,
  onSave,
  onSaveAndClose,
  onDeleteAndClose,
  isSavingAndClosing,
  isDeletingAndClosing,
  pdf,
  invoiceType,
  onInvoiceTypeChange,
}: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const saveAsPdf = useSaveAsPdf(initialValues.id);
  const { user } = useAuth();
  const { data: vatRates } = useVatRates();
  const { data: enabledInvoiceLanguages } = useEnabledInvoiceLanguages(
    user?.active_company?.country,
  );

  const {
    active_company,
    active_company: { base_currency, default_invoice_pdf_template, default_proforma_pdf_template },
  } = user;

  const { data: { items: invoicePdfTemplates = [] } = {} } = useInvoiceTemplateList(
    InvoiceType.invoice,
  );
  const { data: { items: proformaPdfTemplates = [] } = {} } = useInvoiceTemplateList(
    InvoiceType.proforma,
  );

  const { data: invoiceData } = useInvoice(id);

  const vatEnabled = !!user?.active_company?.vat_code || user?.active_company?.country === 'ES';

  const [showSaveDraftDialog, setShowSaveDraftDialog] = useState(false);
  const [showSaveChangedDialog, setShowSaveChangedDialog] = useState(false);
  const [showSendInvoiceDialog, setShowSendInvoiceDialog] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [vatChoice, setVatChoice] = useState('without');
  const [template, setTemplate] = useState(
    id
      ? invoiceData?.pdf_template?.toString()
      : invoiceType === InvoiceType.invoice
      ? default_invoice_pdf_template.toString()
      : default_proforma_pdf_template.toString(),
  );

  const { data: nextInvoiceNumber } = useNextInvoiceNumber(
    invoiceType || initialValues.invoice_type,
  );

  useEffect(() => {
    if (id) {
      setTemplate(invoiceData?.pdf_template?.toString());
    } else if (!id && (!!default_invoice_pdf_template || !!default_proforma_pdf_template)) {
      setTemplate(
        invoiceType === InvoiceType.invoice
          ? default_invoice_pdf_template.toString()
          : default_proforma_pdf_template.toString(),
      );
    } else {
      setTemplate(
        invoiceType === InvoiceType.invoice
          ? String(invoicePdfTemplates.find((template) => template.is_default)?.id)
          : String(proformaPdfTemplates.find((template) => template.is_default)?.id),
      );
    }
  }, [invoiceData, user, invoiceType]);

  const onClose = () => {
    setShowSaveDraftDialog(false);
    setShowSaveChangedDialog(false);
    setShowSendInvoiceDialog(false);
    navigate(-1);
  };

  const onGoBack = () => {
    if (!id) {
      setShowSaveDraftDialog(true);
    } else if (dirty) {
      setShowSaveChangedDialog(true);
    } else {
      navigate(-1);
    }
  };

  const handleTemplateChange = (value: string) => {
    try {
      model.patch(initialValues.id, { ...initialValues, pdf_template: +value });
      setTemplate(value);
    } catch (error) {
      if (error instanceof ValidationError) {
        error.nonFieldErrors.forEach((error) => {
          toast.error(t(error));
        });
      }
    }
  };

  const onSubmit = async (
    values: InvoiceFormFields,
    { setErrors, setFieldValue, setFieldTouched }: FormikHelpers<InvoiceFormFields>,
  ) => {
    values.invoice_items.forEach((item, index) => {
      Object.keys(item).forEach((field) => {
        setFieldTouched(`invoice_items.${index}.${field}`, true, true);
      });
    });

    await onSave(
      {
        ...values,
        pdf_template: template ? +template : undefined,
      },
      setErrors,
      setFieldValue,
      false,
      vatChoice === 'with',
      vatEnabled,
    );

    setShowSendInvoiceDialog(true);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({
        values,
        errors,
        setErrors,
        setFieldTouched,
        setFieldValue,
        dirty,
        validateForm,
        isSubmitting,
      }) => {
        setDirty(dirty);

        const touchSaveAndClose = async (finalize: boolean, validate = true) => {
          if (validate) {
            Object.keys(values).forEach((field) => {
              setFieldTouched(field, true);
            });
          }

          values.invoice_items.forEach((item, index) => {
            Object.keys(item).forEach((field) => {
              setFieldTouched(`invoice_items.${index}.${field}`, true, true);
            });
          });

          if (validate) {
            const errors = await validateForm();
            if (Object.keys(errors).length) {
              return;
            }
          }

          onSaveAndClose(
            {
              ...values,
              pdf_template: template ? +template : undefined,
            },
            setErrors,
            setFieldValue,
            finalize,
            vatChoice === 'with',
            vatEnabled,
          );
        };

        const onSend = async (
          sendValues: SendInvoiceFormFields,
          setSendErrors: FormikSetErrorsFn<SendInvoiceFormFields>,
        ) => {
          const { success } = await onSave(
            {
              ...values,
              pdf_template: template ? +template : undefined,
            },
            setErrors,
            setFieldValue,
            false,
            vatChoice === 'with',
            vatEnabled,
          );
          const { customer, id, invoice_items, language } = values;

          sendAmplitudeData(AmplitudeEvent.InvoiceApproved, {
            customer,
            invoice_id: id,
            items_count: invoice_items.length,
            language,
            soft_collection: !!sendValues.soft_collection,
          });

          if (!success || !initialValues.id) {
            return;
          }

          try {
            await model.finalize(initialValues.id);
            await model.permissionSettings(initialValues.id, {
              soft_collection: !!sendValues.soft_collection,
            });
            await model.send(initialValues.id, {
              ...sendValues,
              ...(sendValues.copy_for_sender && { sender_email: active_company.email }),
              content: `<div style="white-space: pre-wrap;">${sendValues.content}</div>`,
            });

            sendAmplitudeData(AmplitudeEvent.InvoiceSent);

            queryClient.invalidateQueries([
              invoicesIndexQueryKey,
              invoicesStatusSummaryQueryKey,
              currentUserQueryKey,
            ]);

            toast(t('msg_success_invoice_sent'), { type: 'success' });
            onClose();
          } catch (error) {
            if (error instanceof ValidationError) {
              setSendErrors(error.formikErrors);
              error.nonFieldErrors.forEach((error) => {
                toast.error(t(error));
              });
            }
          }
        };

        const onSaveAsPdf = async () => {
          const { invoice, success } = await onSave(
            {
              ...values,
              pdf_template: template ? +template : undefined,
            },
            setErrors,
            setFieldValue,
            false,
            vatChoice === 'with',
            vatEnabled,
          );

          if (!success) {
            toast.error(t('msg_error_invoice_save_failed'));
            return;
          }

          if (!invoice?.pdf) {
            await saveAsPdf();
          } else {
            openInNewTab(invoice.pdf);
          }
        };

        const onDraftSave = async (showToast = false) => {
          values.invoice_items.forEach((item, index) => {
            Object.keys(item).forEach((field) => {
              setFieldTouched(`invoice_items.${index}.${field}`, true, true);
            });
          });

          const saved = await onSave(
            {
              ...values,
              pdf_template: template ? +template : undefined,
            },
            setErrors,
            setFieldValue,
            false,
            vatChoice === 'with',
            vatEnabled,
          );

          if (!showToast) return;

          if (!saved) {
            toast.error(t('msg_error_invoice_save_failed'));
          } else {
            toast.success(t('msg_success_invoice_draft_saved'));
          }
        };

        const totalWithoutVat = values.invoice_items.reduce((acc, item) => {
          const totalWithoutVat = item.total_without_vat !== undefined ? item.total_without_vat : 0;
          return acc + totalWithoutVat;
        }, 0);

        const vatSum = values.invoice_items.reduce((acc, item) => {
          const vatSum = item.vat_sum !== undefined ? item.vat_sum : 0;
          return acc + vatSum;
        }, 0);

        const total = values.invoice_items.reduce(
          (acc, item) =>
            acc + Number(item.amount) * formatNumericStringToNumber(String(item.price)),
          0,
        );

        const formattedVatSum = vatChoice === 'without' ? vatSum : total - totalWithoutVat;

        const irpfAmount = active_company.s_spain_irpf_percentage
          ? (total + vatSum) *
            (String(active_company?.s_spain_irpf_percentage).length === 1
              ? Number(`0.0${active_company?.s_spain_irpf_percentage}`)
              : Number(`0.${active_company?.s_spain_irpf_percentage}`)) *
            100
          : 0;

        const handleIssueDateChange = async (val: string) => {
          const inputDate = values.issue_date && new Date(val);

          const dayOfMonth = inputDate?.getDate();

          dayOfMonth && inputDate?.setDate(dayOfMonth + active_company?.due_date_days);

          const newDateStr = inputDate && inputDate?.toISOString().slice(0, 10);

          await setFieldValue(InvoiceFormField.payment_due, newDateStr);
        };

        return (
          <>
            <Header
              id={initialValues.id}
              status={initialValues.status}
              onGoBack={onGoBack}
              onSaveAsPdf={onSaveAsPdf}
              currencySymbol={active_company?.base_currency.symbol_native}
              invoiceType={values.invoice_type}
            />

            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  <CustomerSelectorBox name={InvoiceFormField.customer} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Select
                        name={InvoiceFormField.invoice_type}
                        variant="filled"
                        label={t('msg_label_invoice_type')}
                        defaultValue={initialValues.invoice_type}
                        onChange={(e) => {
                          setFieldValue(InvoiceFormField.invoice_type, e.target.value);
                          onInvoiceTypeChange(e.target.value as InvoiceType);
                        }}
                        fullWidth
                      >
                        <MenuItem value={InvoiceType.invoice}>
                          {t('msg_label_invoice_type_invoice')}
                        </MenuItem>
                        <MenuItem value={InvoiceType.proforma}>
                          {t('msg_label_invoice_type_proforma')}
                        </MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikTextField
                        name={InvoiceFormField.number}
                        variant="filled"
                        label={
                          values.number
                            ? t('msg_label_invoice_number')
                            : `${t('msg_label_next_invoice_number')} (${
                                nextInvoiceNumber?.next_invoice_number || ''
                              })`
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikDatePicker
                        name={InvoiceFormField.issue_date}
                        variant="filled"
                        label={`${t('msg_label_invoice_date')}*`}
                        fullWidth
                        onChange={(val) => handleIssueDateChange(val as string)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormikDatePicker
                        name={InvoiceFormField.payment_due}
                        variant="filled"
                        label={t('msg_pdf_invoice_due_date')}
                        value={values.payment_due}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        name={InvoiceFormField.currency}
                        variant="filled"
                        label={t('msg_label_currency')}
                        value={base_currency.code}
                        fullWidth
                        disabled
                      >
                        <MenuItem value={base_currency.code}>
                          {base_currency.name}, {base_currency.symbol_native}
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <InvoiceItemsTable vatEnabled={vatEnabled}>
                <FieldArray name={InvoiceFormField.invoice_items}>
                  {({ push, remove }) => (
                    <>
                      {!values?.invoice_items?.length && (
                        <MuiTableRow>
                          <TableCell colSpan={5} align="center">
                            {t('msg_label_empty_field')}
                          </TableCell>
                        </MuiTableRow>
                      )}

                      {values?.invoice_items?.map((_, index) => (
                        <TableRow
                          key={index}
                          index={index}
                          onRemove={remove}
                          canBeRemoved={values?.invoice_items?.length > 1}
                          vatEnabled={vatEnabled}
                          vatRates={vatRates}
                          withVat={vatChoice === 'with'}
                        />
                      ))}

                      <MuiTableRow>
                        <TableCell colSpan={5} sx={{ py: 1 }}>
                          <Stack direction="row" alignItems="center" gap={2}>
                            <Button
                              variant="text"
                              type="button"
                              onClick={() => push(emptyInvoiceItem(vatRates?.[0].vat_percentage))}
                              sx={{ height: 42 }}
                            >
                              {t('msg_invoice_item_add_new')}
                              <AddCircleIcon sx={{ ml: 1.25 }} />
                            </Button>

                            {errors.invoice_items && typeof errors.invoice_items === 'string' && (
                              <FormHelperText error>{t(errors.invoice_items)}</FormHelperText>
                            )}
                          </Stack>
                        </TableCell>
                      </MuiTableRow>
                    </>
                  )}
                </FieldArray>
              </InvoiceItemsTable>

              <Paper sx={{ mt: 2 }}>
                <FormikTextField
                  name={InvoiceFormField.notes}
                  variant="filled"
                  hiddenLabel
                  placeholder={t('msg_comment')}
                  multiline
                  fullWidth
                />
              </Paper>

              <Grid gap={1} container direction="row" justifyContent="space-between" my={4}>
                <Grid xs={5} item>
                  <FormikLanguageSelect
                    label={t('msg_label_invoice_language')}
                    name={InvoiceFormField.language}
                    languageItems={enabledInvoiceLanguages}
                  />
                  {invoicePdfTemplates && invoiceType === InvoiceType.invoice && template && (
                    <Box mt={2}>
                      <TemplateSelect
                        label={t('msg_label_invoice_template')}
                        value={template}
                        items={invoicePdfTemplates.map((template) => {
                          return {
                            value: template.id.toString(),
                            label: t(template.description),
                          };
                        })}
                        onChange={(e) => handleTemplateChange(e.target.value)}
                      />
                    </Box>
                  )}
                  {proformaPdfTemplates && invoiceType === InvoiceType.proforma && template && (
                    <Box mt={2}>
                      <TemplateSelect
                        label={t('msg_label_proforma_template')}
                        value={template}
                        items={proformaPdfTemplates.map((template) => {
                          return {
                            value: template.id.toString(),
                            label: t(template.description),
                          };
                        })}
                        onChange={(e) => handleTemplateChange(e.target.value)}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid xs={6} item>
                  {vatEnabled && (
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="body1">{t('msg_total_without_vat')}</Typography>
                      <Typography variant="body1">
                        {formatCurrency(totalWithoutVat, false, base_currency.symbol_native)}
                      </Typography>
                    </Stack>
                  )}
                  {vatEnabled && (
                    <Stack direction="row" justifyContent="space-between" mt={2}>
                      <Typography variant="body1">{t('msg_vat')}</Typography>
                      <Typography variant="body1">
                        {formatCurrency(formattedVatSum, false, base_currency.symbol_native)}
                      </Typography>
                    </Stack>
                  )}
                  {!!initialValues.spain_irpf_amount && irpfAmount && (
                    <Stack direction="row" justifyContent="space-between" mt={2}>
                      <Typography fontSize={pickFontSize(isMobile)} sx={{ width: { sm: '115px' } }}>
                        {t('msg_irpf_title')}
                      </Typography>
                      <Typography
                        fontSize={pickFontSize(isMobile)}
                        sx={{ width: { sm: '115px' }, textAlign: 'right' }}
                      >
                        {`-${formatCurrency(
                          irpfAmount,
                          true,
                          active_company?.base_currency.symbol_native,
                        )}`}
                      </Typography>
                    </Stack>
                  )}
                  <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
                    <Typography variant="h6">{t('msg_total_price')}</Typography>
                    <Typography variant="h6">
                      {vatEnabled
                        ? vatChoice === 'without'
                          ? formatCurrency(
                              Number(total) + Number(vatSum),
                              false,
                              base_currency.symbol_native,
                            )
                          : formatCurrency(total, false, base_currency.symbol_native)
                        : formatCurrency(total, false, base_currency.symbol_native)}
                    </Typography>
                  </Stack>

                  {vatEnabled && (
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems="center"
                      mt={2}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <Typography variant="body1">{t('msg_prices_indicated')}</Typography>
                        <MUISelect
                          sx={{ display: 'flex' }}
                          defaultValue="without"
                          onChange={(e) => setVatChoice(e.target.value)}
                        >
                          {[
                            { value: 'with', label: t('msg_with_vat') },
                            { value: 'without', label: t('msg_without_vat') },
                          ].map((item) => (
                            <MenuItem key={item.value + item.label} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </MUISelect>
                      </Box>
                    </Stack>
                  )}
                </Grid>
              </Grid>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="end"
                spacing={2}
                mt={2}
              >
                <LoadingButton
                  type="button"
                  variant="outlined"
                  size="large"
                  onClick={() => onDraftSave(true)}
                  loading={isSavingAndClosing}
                >
                  {t('msg_btn_save_draft')}
                </LoadingButton>

                <LoadingButton
                  type="button"
                  variant="outlined"
                  size="large"
                  onClick={() => touchSaveAndClose(true, true)}
                  loading={isSavingAndClosing}
                >
                  {t('msg_btn_save_invoice')}
                </LoadingButton>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  {t('msg_btn_send_invoice')}
                </LoadingButton>
              </Stack>
            </Form>

            <ConfirmationDialog
              open={showSaveDraftDialog}
              title={t('msg_save_invoice_draft_title')}
              onClose={() => setShowSaveDraftDialog(false)}
              actions={[
                {
                  label: t('msg_btn_save_draft'),
                  onClick: () => touchSaveAndClose(false),
                  color: 'inherit',
                  showIsLoading: isSavingAndClosing,
                },
                {
                  label: t('msg_btn_delete_invoice'),
                  onClick: onDeleteAndClose,
                  color: 'error',
                  showIsLoading: isDeletingAndClosing,
                },
              ]}
              isLoading={isDeletingAndClosing || isSavingAndClosing}
            />

            <ConfirmationDialog
              open={showSaveChangedDialog}
              title={t('msg_save_invoice_changes_title')}
              onClose={() => setShowSaveChangedDialog(false)}
              actions={[
                {
                  label: t('msg_btn_save_draft'),
                  onClick: () => {
                    touchSaveAndClose(false, false);
                    setShowSaveChangedDialog(false);
                  },
                  color: 'inherit',
                  showIsLoading: isSavingAndClosing,
                },
                {
                  label: t('msg_btn_save_invoice'),
                  onClick: () => {
                    touchSaveAndClose(true);
                    setShowSaveChangedDialog(false);
                  },
                  color: 'inherit',
                  showIsLoading: isSavingAndClosing,
                },
                {
                  label: t('msg_btn_leave_without_saving'),
                  onClick: onClose,
                  color: 'inherit',
                },
              ]}
              isLoading={isSavingAndClosing}
            />

            <SendInvoiceDialog
              open={showSendInvoiceDialog}
              invoiceId={initialValues.id}
              customerId={Number(values.customer)}
              onClose={() => setShowSendInvoiceDialog(false)}
              onSubmit={onSend}
              pdf={pdf}
            />
          </>
        );
      }}
    </Formik>
  );
};
