import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Checkbox, Stack, TableCell } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { DeleteExpenseDialog } from '~/components/Dialog/DeleteExpenseDialog';
import { Attachment } from '~/components/Icon/Attachment';
import { MenuItem, TableActionsMenu } from '~/components/Menu/TableActionsMenu';
import { ClickableTableRow, NoWrapCellText } from '~/components/Table';
import { useAuth } from '~/context/AuthContext';
import { ROUTES } from '~/router/Routes';
import { Color } from '~/types/color';
import { Expense } from '~/types/expense';
import { formatDateShort } from '~/utils/formatDate';
import { formatCurrency } from '~/utils/formatNumber';

export type Props = {
  row: Expense;
  selected: boolean;
  onSelect: (id: string) => void;
};

export const TableRow = ({ row, selected, onSelect }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    user: {
      active_company: { base_currency },
    },
  } = useAuth();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { id, merchant, issue_date, description, full_amount, receipt_count } = row;

  const onEdit = () => navigate(generatePath(ROUTES.DASHBOARD_EXPENSES_EDIT, { id }));

  const menuItems: MenuItem[] = [
    {
      label: t('msg_btn_edit_expense'),
      icon: <EditIcon />,
      onClick: onEdit,
    },
    {
      label: t('msg_btn_delete_expense'),
      icon: <DeleteIcon color="error" />,
      onClick: () => setShowDeleteDialog(true),
      color: Color.Error,
    },
  ];

  return (
    <>
      <ClickableTableRow hover>
        <TableCell padding="checkbox">
          <Checkbox color="primary" checked={selected} onChange={() => onSelect(id)} />
        </TableCell>
        <TableCell onClick={onEdit}>{merchant.name}</TableCell>
        <TableCell onClick={onEdit}>
          <Box
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: {
                xs: 150,
                sm: 200,
                md: 300,
                lg: 400,
              },
            }}
          >
            {description}
          </Box>
        </TableCell>
        <TableCell onClick={onEdit}>{formatDateShort(issue_date)}</TableCell>
        <TableCell onClick={onEdit}>
          {receipt_count > 0 && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Attachment />
              <NoWrapCellText>
                {receipt_count} {t('msg_label_files')}
              </NoWrapCellText>
            </Stack>
          )}
        </TableCell>
        <TableCell onClick={onEdit}>
          <NoWrapCellText>
            {formatCurrency(full_amount, true, base_currency.symbol_native)}
          </NoWrapCellText>
        </TableCell>
        <TableCell padding="checkbox">
          <TableActionsMenu menuItems={menuItems} />
        </TableCell>
      </ClickableTableRow>

      <DeleteExpenseDialog
        id={id}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onSuccess={() => selected && onSelect(id)}
      />
    </>
  );
};
