import i18next from 'i18next';
import { DateTime } from 'luxon';

export const formatDate = (date: Date) =>
  new Date(date).toLocaleDateString(i18next.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const formatDateShort = (date: Date | string, format = 'yyyy.MM.dd') =>
  DateTime.fromISO(date.toString()).toFormat(format);

export const getDateDifferenceInDays = (date: Date | string) => {
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - new Date(date).getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
