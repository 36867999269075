import { generatePath } from 'react-router-dom';

import { Language } from '~/types/appConfig';
import {
  ForwardInvoiceFormFields,
  Invoice,
  InvoiceFormFields,
  InvoiceGetPdfResponse,
  InvoiceListOptions,
  InvoicePayLinkResponse,
  InvoicePermissionSettingsFields,
  InvoiceSaveAsPdfResponse,
  InvoiceType,
  InvoicesStatusSummary,
  MarkAsPaidFormFields,
  Operation,
  SalesLeadFormFields,
  SendInvoiceFormFields,
  VatRate,
} from '~/types/invoice';
import { PaginationData } from '~/types/pagination';
import { api } from '~/utils/api';

import { PaginatedItems, PdfTemplate } from './hooks/useInvoiceTemplateList';

export const URL = {
  INDEX: '/api/invoices/',
  BULK_DELETE: '/api/operations/bulk-delete/',
  STATUS_SUMMARY: '/api/invoices/summary/',
  INVOICE: '/api/invoices/:id/',
  PERMISSION_SETTINGS: '/api/invoices/:id/permission_settings/',
  FINALIZE: '/api/invoices/:id/finalize/',
  SEND: '/api/invoices/:id/send/',
  REVOKE: '/api/invoices/:id/revoke/',
  PAYMENT: '/api/invoices/:id/payment/',
  PDF: '/api/invoices/:id/pdf/',
  PAY_LINK: 'api/public-neo-pay-link/invoice/:id/',
  PUBLIC_INVOICE: '/api/public-link/uuid/:id/',
  FORWARD_INVOICE: '/api/public-link/uuid/:id/send/',
  MARKETING_LEADS: '/api/marketing-leads/',
  VAT_RATES: '/api/cls/vat-rates/',
  ENABLED_INVOICE_LANGUAGES: (countryCode: string) =>
    `/api/cls/languages/enabled-invoice/?country_code=${countryCode}`,
  INVOICE_PDF_TEMPLATES: (invoiceType?: InvoiceType) =>
    `/api/invoice-pdf-templates/?invoice_type=${invoiceType}`,
  UNLINK_INCOME: '/api/invoices/:id/unlink-income/:incomeId/',
  LINKABLE_INCOMES: '/api/invoices/:id/linkable-incomes/',
  LINK_INCOME: '/api/invoices/:id/link-income/:incomeId/',
  NEXT_INVOICE_NUMBER: (invoiceType: InvoiceType) =>
    `/api/invoices/next-invoice-number/?invoice_type=${invoiceType}`,
  CONVERT_PROFORMA: '/api/invoices/:id/convert-to-invoice/',
};

export const model = {
  index: (options?: InvoiceListOptions) => api.get<PaginationData<Invoice>>(URL.INDEX, options),
  get: (id?: string) => api.get<Invoice>(generatePath(URL.INVOICE, { id })),
  bulkDelete: (ids: string[]) => api.delete(URL.BULK_DELETE, {}, { ids }),
  statusSummary: () => api.get<InvoicesStatusSummary>(URL.STATUS_SUMMARY),
  createDraft: () => api.post<Invoice>(URL.INDEX),
  patch: (id: string, data: InvoiceFormFields) =>
    api.patch<Invoice>(generatePath(URL.INVOICE, { id }), data),
  finalize: (id: string) => api.patch<Invoice>(generatePath(URL.FINALIZE, { id })),
  send: (id: string, data: SendInvoiceFormFields) =>
    api.post<Invoice>(generatePath(URL.SEND, { id }), data),
  delete: (id: string) => api.delete<Invoice>(generatePath(URL.INVOICE, { id })),
  revoke: (id: string) => api.patch<Invoice>(generatePath(URL.REVOKE, { id })),
  addPayment: (id: string, data: MarkAsPaidFormFields) =>
    api.post(generatePath(URL.PAYMENT, { id }), data),
  pdf: (id: string) => api.get<InvoiceSaveAsPdfResponse>(generatePath(URL.PDF, { id })),
  getPdfTemplate: (id: string) => api.get<InvoiceGetPdfResponse>(generatePath(URL.SEND, { id })),
  getPublicInvoice: (id: string) => api.get<Invoice>(generatePath(URL.PUBLIC_INVOICE, { id })),
  forwardInvoice: (id: string, data: ForwardInvoiceFormFields) =>
    api.post(generatePath(URL.FORWARD_INVOICE, { id }), data),
  permissionSettings: (id: string, data: InvoicePermissionSettingsFields) =>
    api.patch<Invoice>(generatePath(URL.PERMISSION_SETTINGS, { id }), data),
  registerMarketingLead: (data: SalesLeadFormFields) =>
    api.post(generatePath(URL.MARKETING_LEADS), data),
  getPayLink: (id: string) => api.get<InvoicePayLinkResponse>(generatePath(URL.PAY_LINK, { id })),
  getVatRates: () => api.get<VatRate[]>(URL.VAT_RATES),
  getEnabledInvoiceLanguages: (countryCode: string) =>
    api.get<Language[]>(URL.ENABLED_INVOICE_LANGUAGES(countryCode)),
  getInvoiceTemplateList: (invoiceType?: InvoiceType) =>
    api.get<PaginatedItems<PdfTemplate>>(URL.INVOICE_PDF_TEMPLATES(invoiceType)),
  unlinkIncome: (id: string, incomeId?: number) =>
    api.delete(generatePath(URL.UNLINK_INCOME, { id, incomeId })),
  getLinkableIncomes: (id?: string) =>
    api.get<Operation[]>(generatePath(URL.LINKABLE_INCOMES, { id })),
  linkIncomeToInvoice: (id: string, incomeId: number) =>
    api.post(generatePath(URL.LINK_INCOME, { id, incomeId })),
  getNextInvoiceNumber: (invoiceType: InvoiceType) =>
    api.get<{ next_invoice_number: string }>(URL.NEXT_INVOICE_NUMBER(invoiceType)),
  convertProformaToInvoice: (id: string) => api.post(generatePath(URL.CONVERT_PROFORMA, { id })),
};
