import { useQuery } from '@tanstack/react-query';

import { Invoice, InvoiceListOptions } from '~/types/invoice';
import { PaginationData } from '~/types/pagination';

import { model } from '../';

export const invoicesIndexQueryKey = 'invoices';

export const useInvoices = (options?: InvoiceListOptions) =>
  useQuery<PaginationData<Invoice>>([invoicesIndexQueryKey, options], () => model.index(options));
