import Delete from '@mui/icons-material/Delete';
import { TableCell, Checkbox, Stack, Box, Button } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { TableRow } from '~/components/Table/TableToolbar/TableToolbar.style';
import { model } from '~/models/Invoices';
import { invoicesIndexQueryKey } from '~/models/Invoices/hooks';
import { queryClient } from '~/utils';

export type Props = {
  selected: string[];
  selectedAll: boolean;
  onSelectAll: () => void;
  onClearSelection: () => void;
};

export const TableToolbar = ({ selected, selectedAll, onSelectAll, onClearSelection }: Props) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  if (!selected.length && !selectedAll) {
    return null;
  }

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await model.bulkDelete(selected);
      onClearSelection();
      queryClient.invalidateQueries([invoicesIndexQueryKey]);
      setShowModal(false);
      toast(t('msg_success_invoices_deleted'), { type: 'success' });
    } catch (error) {
      toast(t('msg_error_invoices_delete_failed'), { type: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={selected.length > 0 && !selectedAll}
            checked={selectedAll}
            onChange={onSelectAll}
          />
        </TableCell>
        <TableCell width="100%" padding="none">
          <Stack direction="row" justifyContent="space-between" alignItems="center" px={2}>
            <Box>
              {selected.length} {t('msg_label_selected')}
            </Box>
            <Box>
              <Button
                name={t('msg_btn_delete')}
                startIcon={<Delete />}
                color="error"
                onClick={() => setShowModal(true)}
              >
                {t('msg_btn_delete')}
              </Button>
            </Box>
          </Stack>
        </TableCell>
      </TableRow>

      <ConfirmationDialog
        open={showModal}
        title={t('invoice_action_sheet_dialog_message')}
        onClose={() => setShowModal(false)}
        actions={[
          {
            label: t('invoice_delete_action_sheet_yes'),
            color: 'error',
            onClick: onDelete,
            showIsLoading: true,
          },
          {
            label: t('invoice_delete_action_sheet_no'),
            color: 'inherit',
            onClick: () => setShowModal(false),
          },
        ]}
        isLoading={isLoading}
      />
    </>
  );
};
