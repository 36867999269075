import { useQuery } from '@tanstack/react-query';

import { Language } from '~/types/appConfig';

import { model } from '../';

export const useEnabledInvoiceLanguages = (countryCode: string) =>
  useQuery<Language[]>(
    ['enabledInvoiceLanguages'],
    () => model.getEnabledInvoiceLanguages(countryCode),
    {
      enabled: !!countryCode,
    },
  );
