import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

type SecondaryActionButton = {
  title: string;
  onClick: () => void;
  icon: ReactNode;
};

type Props = React.PropsWithChildren &
  DialogProps & {
    title: string;
    subtitle?: string;
    open: boolean;
    closeBtnLabel?: string;
    closeBtnIcon?: React.ReactNode;
    onClose: () => void;
    submitBtnLabel?: string;
    submitBtnIcon?: React.ReactNode;
    onSubmit: () => void;
    isLoading?: boolean;
    secondaryActionButton?: SecondaryActionButton;
  };

export const GenericDialog = ({
  children,
  title,
  subtitle,
  open,
  closeBtnLabel,
  closeBtnIcon,
  onClose,
  submitBtnLabel,
  submitBtnIcon,
  onSubmit,
  isLoading,
  secondaryActionButton,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Box>{title}</Box>
            <Typography fontSize={12}>{subtitle}</Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ ...(secondaryActionButton && { justifyContent: 'flex-end' }) }}>
        {secondaryActionButton && (
          <Button
            onClick={secondaryActionButton.onClick}
            startIcon={secondaryActionButton.icon}
            variant="outlined"
            size="large"
          >
            {secondaryActionButton.title}
          </Button>
        )}
        <LoadingButton
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="large"
          startIcon={submitBtnIcon}
          loading={isLoading}
        >
          {submitBtnLabel}
        </LoadingButton>
        {!secondaryActionButton && (
          <Button onClick={onClose} size="large" startIcon={closeBtnIcon}>
            {closeBtnLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
