export const TableBody = () => {
  return {
    MuiTableBody: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
  };
};
