import { OpenInNew, PhoneIphone } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Stack,
  Avatar,
  Typography,
  Box,
  Popper,
  Button,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ActionsButton } from '~/components/Button/ActionsButton';
import { ConfirmationDialog } from '~/components/Dialog/ConfirmationDialog';
import { Logo } from '~/components/Logo';
import {
  APP_VERSION,
  DEEPFIN_ACADEMY_URL_TRANSLATION,
  DEEPFIN_DL_MOBILE_APPS_URL_TRANSLATION,
  DEEPFIN_SUPPORT_EMAIL,
} from '~/config/constants';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Auth';
import { useAvailableCompanies } from '~/models/User/hooks/useAvailableCompanies';
import { ROUTES } from '~/router/Routes';
import { colors, text } from '~/theme/colors';
import { NotFoundError } from '~/types/error';
import { openInNewTab } from '~/utils/openInNewTab';

import { config } from './config';
import {
  DrawerHeader,
  MainDrawer,
  StyledListItem,
  SubDrawer,
  UserSettingsButton,
  Version,
  CompanyButton,
} from './Drawer.style';

type Props = {
  open: boolean;
  onToggle: () => void;
  onClose: () => void;
};

const formCompanyInitials = (name: string) => {
  const splitString = name.split(' ');
  if (splitString.length === 1) {
    return splitString[0].charAt(0);
  }
  if (splitString.length >= 2) {
    return `${splitString[0].charAt(0).toUpperCase()}${splitString[1].charAt(0).toUpperCase()}`;
  }
};

const renderInitialsLogo = (name: string) => {
  return (
    <Stack
      component={'div'}
      flexDirection="column"
      sx={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: colors.background.muiGray,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1" fontWeight={'600'} color={text.primary}>
        {formCompanyInitials(name)}
      </Typography>
    </Stack>
  );
};

export const Drawer = ({ open, onToggle, onClose }: Props) => {
  const { t } = useTranslation();
  const { user, logout, fetchUser } = useAuth();
  const { pathname } = useLocation();
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);
  const navigate = useNavigate();

  const { data } = useAvailableCompanies();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleCompanyClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getActive = (path: string) => {
    return path ? !!matchPath({ path: path, end: false }, pathname) : false;
  };

  const handleChangeActiveCompany = async (id: number) => {
    try {
      await model.changeActiveCompany({ active_company: id });
      await fetchUser();
      window.location.reload();
    } catch (error) {
      if (error instanceof NotFoundError && error.detail) {
        toast.error(t(error.detail));
      }
    }
  };

  return (
    <>
      <SubDrawer variant="persistent" anchor="left" open={open}>
        <List>
          {config.subMenuItems.map((item) => (
            <StyledListItem key={item.label} disablePadding selected={getActive(item.link)}>
              <ListItemButton component={Link} to={item.link}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={t(item.label)} />
              </ListItemButton>
            </StyledListItem>
          ))}
        </List>
        <List style={{ marginTop: 'auto' }}>
          <StyledListItem disablePadding>
            <ListItemButton onClick={() => setShowLogoutDialog(true)}>
              <ListItemIcon>
                <Stack width={24} alignItems="center" justifyContent="center">
                  <LogoutIcon fontSize="small" />
                </Stack>
              </ListItemIcon>
              <ListItemText primary={t('msg_btn_logout')} />
            </ListItemButton>
          </StyledListItem>
          <Version>
            {t('msg_label_version')} {APP_VERSION}
          </Version>
        </List>
      </SubDrawer>

      <MainDrawer variant="permanent" anchor="left">
        <DrawerHeader>
          <Box
            onClick={() => {
              navigate(ROUTES.DASHBOARD_INVOICES);
              onClose();
            }}
            sx={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Logo height={17} />
          </Box>
        </DrawerHeader>
        <List>
          {config.menuItems.map((item) => (
            <StyledListItem key={item.label} disablePadding selected={getActive(item.link)}>
              <ListItemButton component={Link} to={item.link} onClick={onClose}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={t(item.label)} />
              </ListItemButton>
            </StyledListItem>
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }} onClick={onClose} />
        <List>
          <StyledListItem disablePadding>
            <ListItemButton onClick={() => openInNewTab(t(DEEPFIN_ACADEMY_URL_TRANSLATION))}>
              <ListItemIcon>
                <Stack alignItems="center" justifyContent="center">
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                      filter:
                        'invert(41%) sepia(66%) saturate(0%) hue-rotate(70deg) brightness(98%) contrast(97%)',
                    }}
                    src={require('~/assets/icons/academy.png')}
                  />
                </Stack>
              </ListItemIcon>
              <ListItemText
                sx={{ flex: 'none' }}
                primary={t('msg_label_account_deepfin_library')}
              />
              <ListItemIcon sx={{ pl: '2px' }}>
                <Stack width={24} alignItems="center" justifyContent="center">
                  <OpenInNew fontSize="small" />
                </Stack>
              </ListItemIcon>
            </ListItemButton>
          </StyledListItem>
          <StyledListItem disablePadding>
            <ListItemButton onClick={() => openInNewTab(t(DEEPFIN_DL_MOBILE_APPS_URL_TRANSLATION))}>
              <ListItemIcon>
                <Stack width={24} alignItems="center" justifyContent="center">
                  <PhoneIphone fontSize="small" />
                </Stack>
              </ListItemIcon>
              <ListItemText sx={{ flex: 'none' }} primary={t('msg_btn_mobile_apps')} />
              <ListItemIcon sx={{ pl: '2px' }}>
                <Stack width={24} alignItems="center" justifyContent="center">
                  <OpenInNew fontSize="small" />
                </Stack>
              </ListItemIcon>
            </ListItemButton>
          </StyledListItem>
          <StyledListItem disablePadding>
            <ListItemButton href={`mailto:${DEEPFIN_SUPPORT_EMAIL}`}>
              <ListItemIcon>
                <Stack width={24} alignItems="center" justifyContent="center">
                  <HelpIcon fontSize="small" />
                </Stack>
              </ListItemIcon>
              <ListItemText primary={t('msg_btn_support')} />
            </ListItemButton>
          </StyledListItem>

          {anchorEl && data?.available_companies && data?.available_companies.length > 1 && (
            <Popper open={true} anchorEl={anchorEl} disablePortal placement="top-start">
              <Box
                sx={{
                  width: config.drawerWidth,
                  backgroundColor: (theme) => theme.palette.background.paper,
                  position: 'absolute',
                  left: -8,
                  bottom: 17,
                  boxShadow: '0px -8px 16px rgba(0, 0, 0, 0.08)',
                }}
              >
                {data?.available_companies.map((company, i) => {
                  return (
                    <Button
                      key={i}
                      fullWidth
                      sx={{ justifyContent: 'flex-start', borderRadius: 0, padding: 0 }}
                      onClick={() => handleChangeActiveCompany(company.id)}
                    >
                      <Stack
                        direction="row"
                        columnGap={1}
                        alignItems="center"
                        paddingX={2}
                        paddingY={1.25}
                      >
                        {company.avatar ? (
                          <Avatar src={company.avatar} />
                        ) : (
                          renderInitialsLogo(company.name)
                        )}
                        <Typography
                          noWrap
                          variant="body1"
                          color={text.primary}
                          sx={{ maxWidth: '180px' }}
                        >
                          {company.name}
                        </Typography>
                      </Stack>
                    </Button>
                  );
                })}
              </Box>
            </Popper>
          )}
        </List>
        {user && data?.available_companies && data?.available_companies.length > 1 ? (
          <>
            <Divider />
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              paddingLeft={1}
              paddingRight={2}
              paddingY={2}
            >
              <CompanyButton onClick={(event) => handleCompanyClick(event)}>
                {data?.available_companies && data?.available_companies.length > 1 ? (
                  user?.active_company.logo ? (
                    <Avatar src={user.active_company.logo} />
                  ) : (
                    renderInitialsLogo(user.active_company.name)
                  )
                ) : (
                  <Avatar src={user.avatar} />
                )}
                <Stack>
                  <Typography
                    noWrap
                    sx={{ maxWidth: '120px' }}
                    variant="body2"
                    color={text.primary}
                  >
                    {data?.available_companies && data?.available_companies.length > 1
                      ? user?.active_company.name
                      : user?.full_name}
                  </Typography>
                  {user.subscription.plan.description && (
                    <Typography variant="caption" color={text.primary}>
                      {t(user.subscription.plan.description)}
                    </Typography>
                  )}
                </Stack>
              </CompanyButton>
              <Box>
                <ActionsButton
                  onClick={() => {
                    onToggle();
                    navigate(ROUTES.DASHBOARD_SETTINGS_PROFILE);
                  }}
                  active={open}
                  aria-label={t('msg_btn_open_settings')}
                >
                  <SettingsIcon />
                </ActionsButton>
              </Box>
            </Stack>
          </>
        ) : (
          <>
            <Divider />
            <UserSettingsButton
              onClick={() => {
                onToggle();
                navigate(ROUTES.DASHBOARD_SETTINGS_PROFILE);
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar src={user.avatar} />
                <Stack>
                  <Typography variant="body2">{user?.full_name}</Typography>
                  {user.subscription.plan.description && (
                    <Typography variant="caption">
                      {t(user.subscription.plan.description)}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Box>
                <ActionsButton active={open} aria-label={t('msg_btn_open_settings')}>
                  <SettingsIcon />
                </ActionsButton>
              </Box>
            </UserSettingsButton>
          </>
        )}
      </MainDrawer>

      <ConfirmationDialog
        open={showLogoutDialog}
        title={t('msg_title_confirm_logout')}
        onClose={() => setShowLogoutDialog(false)}
        actions={[
          {
            label: t('msg_btn_logout'),
            onClick: logout,
            color: 'error',
          },
          {
            label: t('msg_btn_cancel'),
            onClick: () => setShowLogoutDialog(false),
            color: 'inherit',
          },
        ]}
      />
    </>
  );
};
