export const ROUTES = {
  DEFAULT: '/',

  SIGNUP: '/signup',
  LOGIN: '/login',
  REGISTER: '/register',
  REGISTER_INTRODUCE: '/register/introduce',
  REGISTER_INCOME: '/register/income',
  FORGOT_PASSWORD: '/forgot-password',
  CREATE_NEW_PASSWORD: '/create-new-password',
  VERIFY_EMAIL: '/verify-email',

  DASHBOARD: '/dashboard',
  DASHBOARD_MAIN: '/dashboard/main',
  DASHBOARD_INVOICES: '/dashboard/invoices',
  DASHBOARD_INVOICES_NEW: '/dashboard/invoices/new',
  DASHBOARD_INVOICES_PREVIEW: '/dashboard/invoices/:id',
  DASHBOARD_INVOICES_EDIT: '/dashboard/invoices/:id/edit',
  DASHBOARD_INCOME: '/dashboard/income',
  DASHBOARD_INCOME_NEW: '/dashboard/income/new',
  DASHBOARD_INCOME_EDIT: '/dashboard/income/:id/edit',
  DASHBOARD_EXPENSES: '/dashboard/expenses',
  DASHBOARD_EXPENSES_NEW: '/dashboard/expenses/new',
  DASHBOARD_EXPENSES_EDIT: '/dashboard/expenses/:id/edit',
  DASHBOARD_CUSTOMERS: '/dashboard/customers',
  DASHBOARD_SERVICES: '/dashboard/services',
  DASHBOARD_GOALS: '/dashboard/goals',
  DASHBOARD_TAXES: '/dashboard/taxes',
  DASHBOARD_BANK: '/dashboard/bank',

  DASHBOARD_SETTINGS_PROFILE: '/dashboard/profile-settings',
  DASHBOARD_SETTINGS_BUSINESS: '/dashboard/business-settings',
  DASHBOARD_SETTINGS_MY_AUTOMATION: '/dashboard/my-automation-settings',

  INVOICE: '/invoice/:id',

  PAY_SUCCESS: '/neo-pay-link/success',

  MARKETING_LEAD: '/lead',

  DELETE_USER_DATA: '/delete-user-data',

  ERROR_404: '/404',
};
