import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormikLanguageSelect } from '~/components/Form/FormikLanguageSelect';
import { FormikSelect } from '~/components/Form/FormikSelect/FormikSelect';
import { FormikRichTextEditor } from '~/components/Form/RichTextEditor';
import { FormikIOSSwitch } from '~/components/Form/Switch/FormikIOSSwitch';
import { FormikTextField } from '~/components/Form/TextField';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Company';
import { useInvoiceTemplateList } from '~/models/Invoices/hooks/useInvoiceTemplateList';
import { ValidationError } from '~/types/error';
import { InvoiceType } from '~/types/invoice';
import { MyAutomationFormFields } from '~/types/myAutomation';
import { pxToRem } from '~/utils/getFontValue';

import { validationSchema } from './validationSchema';

export enum MyAutomationFormField {
  invoice_number_pattern = 'invoice_number_pattern',
  invoice_seq = 'invoice_seq',
  proforma_number_pattern = 'proforma_number_pattern',
  proforma_seq = 'proforma_seq',
  soft_collection = 'soft_collection',
  due_date_days = 'due_date_days',
  language = 'language',
  default_invoice_pdf_template = 'default_invoice_pdf_template',
  default_proforma_pdf_template = 'default_proforma_pdf_template',
  default_invoice_note = 'default_invoice_note',
}

export const MyAutomationSettings = () => {
  const { t } = useTranslation();
  const { user, fetchUser } = useAuth();
  const { data: { items: invoiceTemplates = [] } = {} } = useInvoiceTemplateList(
    InvoiceType.invoice,
  );
  const { data: { items: proformaTemplates = [] } = {} } = useInvoiceTemplateList(
    InvoiceType.proforma,
  );

  const { active_company } = user;

  const initialValues: MyAutomationFormFields = useMemo(
    () => ({
      [MyAutomationFormField.invoice_number_pattern]: active_company.invoice_number_pattern,
      [MyAutomationFormField.invoice_seq]: active_company.invoice_seq,
      [MyAutomationFormField.proforma_number_pattern]: active_company.proforma_number_pattern,
      [MyAutomationFormField.proforma_seq]: active_company.proforma_seq,
      [MyAutomationFormField.soft_collection]: active_company.soft_collection,
      [MyAutomationFormField.due_date_days]: active_company.due_date_days,
      [MyAutomationFormField.language]: active_company.language,
      [MyAutomationFormField.default_invoice_pdf_template]:
        active_company.default_invoice_pdf_template,
      [MyAutomationFormField.default_proforma_pdf_template]:
        active_company.default_proforma_pdf_template,
      [MyAutomationFormField.default_invoice_note]: active_company.default_invoice_note,
    }),
    [user],
  );

  const onSubmit = async (
    values: MyAutomationFormFields,
    { setErrors }: FormikHelpers<MyAutomationFormFields>,
  ) => {
    try {
      await model.patch(active_company.id, values);
      await fetchUser();
    } catch (error) {
      if (error instanceof ValidationError<MyAutomationFormFields>) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((message) => toast.error(t(message)));
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" lineHeight={pxToRem(48)}>
        {t('msg_label_account_my_automation_title')}
      </Typography>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ isSubmitting }) => (
          <Form encType="multipart/form-data">
            <Stack spacing={1} mt={3}>
              <FormikTextField
                name={MyAutomationFormField.invoice_number_pattern}
                label={t('msg_label_invoice_number_pattern')}
                placeholder={t('msg_label_invoice_number_pattern')}
                variant="filled"
              />
              <FormikTextField
                name={MyAutomationFormField.invoice_seq}
                label={t('msg_label_invoice_sequence')}
                placeholder={t('msg_label_invoice_sequence')}
                variant="filled"
              />
              <FormikTextField
                name={MyAutomationFormField.proforma_number_pattern}
                label={t('msg_label_proforma_number_pattern')}
                placeholder={t('msg_label_proforma_number_pattern')}
                variant="filled"
              />
              <FormikTextField
                name={MyAutomationFormField.proforma_seq}
                label={t('msg_label_proforma_sequence')}
                placeholder={t('msg_label_proforma_sequence')}
                variant="filled"
              />
              <Box height={8} />
              <FormikTextField
                name={MyAutomationFormField.due_date_days}
                label={t('msg_label_due_date_days')}
                placeholder={t('msg_label_due_date_days')}
                variant="filled"
              />

              <FormikIOSSwitch
                withBox
                label={
                  <>
                    {t('msg_label_invoice_soft_collection')}
                    <br />
                    <Typography color="rgba(47, 48, 70, 0.75)" variant="caption">
                      {t('msg_label_invoice_soft_collection_explanation')}
                    </Typography>
                  </>
                }
                labelPlacement="start"
                name={MyAutomationFormField.soft_collection}
              />

              <FormikLanguageSelect
                label={t('msg_label_language')}
                name={MyAutomationFormField.language}
              />

              {invoiceTemplates && (
                <FormikSelect
                  name={'default_invoice_pdf_template'}
                  label={t('msg_label_invoice_template')}
                  items={invoiceTemplates.map((template) => {
                    return {
                      value: template.id.toString(),
                      label: t(template.description),
                    };
                  })}
                />
              )}

              {proformaTemplates && (
                <FormikSelect
                  name={'default_proforma_pdf_template'}
                  label={t('msg_label_proforma_template')}
                  items={proformaTemplates.map((template) => {
                    return {
                      value: template.id.toString(),
                      label: t(template.description),
                    };
                  })}
                />
              )}

              <FormikRichTextEditor
                name={MyAutomationFormField.default_invoice_note}
                placeholder={t('msg_label_invoice_email_message')}
                whiteBg
              />

              <Box pt={4.75}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  size="large"
                  startIcon={<SaveIcon />}
                  loading={isSubmitting}
                >
                  {t('msg_btn_save')}
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
