import { Paper, TableBody, TableContainer } from '@mui/material';

import { InvoiceItemsFormTable } from '~/components/Table';
import { TableHead } from '~/components/Table/TableHead';

import { headCells } from './config';

export const ExpenseItemsTable = ({ children }: React.PropsWithChildren) => (
  <TableContainer component={Paper} sx={{ mt: 3 }}>
    <InvoiceItemsFormTable>
      <TableHead headCells={headCells} />

      <TableBody>{children}</TableBody>
    </InvoiceItemsFormTable>
  </TableContainer>
);
