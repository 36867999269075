import { useEffect, useState } from 'react';

export const useDelayedSearch = (search: string) => {
  const [searchTerm, setSearchTerm] = useState(search);

  useEffect(() => {
    const timeout = setTimeout(() => setSearchTerm(search), 300);

    return () => clearTimeout(timeout);
  }, [search]);

  return searchTerm;
};
