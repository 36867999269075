import { InputLabel, styled } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const UserSegmentHandle = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'checked',
})<{ checked?: boolean }>(({ theme, checked = false }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(1.5, 2, 1.75, 2),
  borderWidth: pxToRem(1),
  borderStyle: 'solid',
  borderColor: checked ? theme.palette.primary.main : 'transparent',
  borderRadius: pxToRem(4),
  cursor: 'pointer',
  '& .MuiRadio-root': {
    display: 'none',
  },
  minHeight: pxToRem(75),
  display: 'flex',
  alignItems: 'center',
  maxWidth: '550px',
  whiteSpace: 'unset',
  wordBreak: 'break-word',
}));

export const UserSegmentSubLabel = styled('span')({
  fontSize: pxToRem(12),
  color: 'text.secondary',
});
