import { InvoiceFormItemFields } from '~/types/invoice';
import { HeadCell } from '~/types/table';

export const formHeadCells = (vatEnabled: boolean): HeadCell<InvoiceFormItemFields>[] => {
  return [
    {
      id: 'item',
      label: 'msg_label_invoice_item_name',
      width: '40%',
    },
    {
      id: 'amount',
      label: 'msg_label_invoice_amount',
      width: vatEnabled ? '10%' : '20%',
    },
    {
      id: 'price',
      label: 'msg_label_price',
      width: vatEnabled ? '15%' : '20%',
    },
    ...(vatEnabled
      ? [
          {
            id: 'vat_percentage',
            label: 'msg_label_vat_rate',
            width: '10%',
          },
        ]
      : []),
    ...(vatEnabled
      ? [
          {
            id: 'vat_sum',
            label: 'msg_vat_sum',
            width: '10%',
          },
        ]
      : []),
    {
      id: 'total',
      label: 'msg_invoice_item_total',
      width: vatEnabled ? '15%' : '20%',
      numeric: true,
    },
    {
      id: 'id',
      label: '',
    },
  ];
};
