import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, Typography, TextField, Button, IconButton, styled, List } from '@mui/material';
import { useAtom } from 'jotai';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PulsingDots } from '~/components/PulsingDots/PulsingDots';
import { useInitAIAgentChatSession } from '~/models/AIAgentChat/hooks/useInitAIAgentChatSession';
import { useSendNewAIAgentChatMessage } from '~/models/AIAgentChat/hooks/useSendNewAIAgentChatMessage';
import { colors } from '~/theme/colors';
import { AmplitudeEvent } from '~/types/amplitude';
import { sendAmplitudeData } from '~/utils/amplitude';

import {
  AIAgentChatMessage,
  chatSessionIdAtom,
  chatSessionMessagesAtom,
  shouldReinitializeSession,
} from './atoms/aiAgentChatAtom';

// TODO maybe export
const FlexRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
}));

interface AIAgentChatProps {
  onClose: () => void;
}

export const AIAgentChat: FC<AIAgentChatProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const lastListItemRef = useRef<HTMLDivElement>(null);
  const textInputRef = useRef('');

  const [textInputVal, setTextInputVal] = useState('');
  const [sessionId, setSessionId] = useAtom(chatSessionIdAtom);
  const [sessionMessages, setSessionMessages] = useAtom(chatSessionMessagesAtom);
  const [reinitializeSession, setReinitializeSession] = useAtom(shouldReinitializeSession);

  const handleAddNewMessageFromAgent = (text: string) => {
    setSessionMessages((prevMessages) => [...prevMessages, { author: 'agent', message: text }]);
  };

  const { mutate: initAIAgentChatSession } = useInitAIAgentChatSession((id) => setSessionId(id));

  const { mutate: sendNewAgentChatMessage, isLoading } = useSendNewAIAgentChatMessage(
    sessionId,
    handleAddNewMessageFromAgent,
  );

  useEffect(() => {
    if (reinitializeSession) {
      initAIAgentChatSession();
      setSessionMessages([]);
      setReinitializeSession(false);
    }
  }, [reinitializeSession]);

  // need this for the keyboard listener callback value
  useEffect(() => {
    textInputRef.current = textInputVal;
  }, [textInputVal]);

  useEffect(() => {
    !sessionId && initAIAgentChatSession();
  }, [sessionId]);

  const renderMessages = (messageItems: AIAgentChatMessage[]) =>
    messageItems.map((item, index) => {
      const { author, message } = item;
      return (
        <FlexRow
          key={message}
          justifyContent={author === 'agent' ? 'flex-start' : 'flex-end'}
          mt={index === 0 ? 0 : 2}
        >
          <Box
            p={2}
            borderRadius={2}
            sx={{
              backgroundColor:
                author === 'agent' ? colors.background.paper : colors.other.chatMessageLightGreen,
              ...(message !== 'loading' && { width: '75%' }),
            }}
          >
            {message === 'loading' ? <PulsingDots /> : t(message)}
          </Box>
        </FlexRow>
      );
    });

  useEffect(() => {
    lastListItemRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [sessionMessages]);

  useEffect(() => {
    if (isLoading) {
      setSessionMessages((prevMessages) => [
        ...prevMessages,
        { author: 'agent', message: 'loading' },
      ]);
    } else {
      setSessionMessages((prevMessages) =>
        prevMessages.filter((item) => item.message !== 'loading'),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleKeydown = (val: KeyboardEvent) => {
    if (val.key === 'Enter') handleSendNewMessage(textInputRef.current);
  };

  useEffect(() => {
    document.addEventListener('keypress', handleKeydown);

    return () => document.removeEventListener('keypress', handleKeydown);
  }, []);

  const handleSendNewMessage = (text: string) => {
    setTextInputVal('');
    setSessionMessages((prevMessages) => [...prevMessages, { author: 'user', message: text }]);
    sendNewAgentChatMessage({ human_input: text });
    sendAmplitudeData(AmplitudeEvent.AgentChatSubmitted, { sessionId, question: text });
  };

  return (
    <Stack
      p={2}
      width={500}
      height="100%"
      justifyContent="space-between"
      bgcolor={colors.background.default}
    >
      <Stack gap={1}>
        <FlexRow justifyContent="space-between">
          <Typography fontSize={24} fontWeight={700}>
            {t('msg_ai_agent_title')}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexRow>
        <FlexRow gap={2}>
          <img src="/assets/images/sofia_avatar.svg" />
          <Stack>
            <Typography fontSize={16} fontWeight={500}>
              {t('msg_label_bot_name')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography color={colors.primary.main}>•</Typography>
              <Typography fontSize={10}>{t('msg_label_bot_status')}</Typography>
            </Box>
          </Stack>
        </FlexRow>
      </Stack>
      <Stack pt={2} overflow="hidden" height="100%">
        <List sx={{ overflowY: 'auto' }}>
          {renderMessages(sessionMessages)}
          {<Box component="div" ref={lastListItemRef} />}
        </List>
      </Stack>
      <FlexRow gap={1}>
        <TextField
          fullWidth
          hiddenLabel
          value={textInputVal}
          autoFocus
          onChange={(e) => setTextInputVal(e.target.value)}
          variant="filled"
          placeholder={t('msg_agent_chat_input_placeholder')}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ padding: 2, height: 40, width: 120 }}
          onClick={() => handleSendNewMessage(textInputVal)}
        >
          {t('msg_agent_chat_send_button_title')}
        </Button>
      </FlexRow>
    </Stack>
  );
};
