import { useMemo, useState } from 'react';

export function useCachedData<T>(data?: T) {
  const [cached, setCached] = useState<T>();

  useMemo(() => {
    if (data) {
      setCached(data);
    }
  }, [data]);

  return cached;
}
