import { useMutation } from '@tanstack/react-query';

import { MutationProps } from '~/types/mutation';
import { queryClient } from '~/utils';

import { model } from '../invoices';

import { invoicesIndexQueryKey } from './useInvoices';
import { invoicesStatusSummaryQueryKey } from './useInvoicesStatusSummary';

export const useDeleteInvoice = ({ onSuccess }: MutationProps) =>
  useMutation(model.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries([invoicesIndexQueryKey]);
      queryClient.invalidateQueries(invoicesStatusSummaryQueryKey);
      onSuccess?.();
    },
  });
