import { Typography, useTheme } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import MUISelect, { SelectProps as MUISelectProps } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& > .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: 0,
    fontSize: 16,
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(
      1.5,
    )} !important`,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    textAlign: 'end',
    fontWeight: theme.typography.fontWeightMedium,
    '&:focus': {
      border: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  '& .MuiMenu-list': {
    justifyContent: 'flex-end',
  },
}));

const AlternativeInput = styled(InputBase)(({ theme }) => ({
  '& > .MuiInputBase-input': {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: `${theme.spacing(2.0625)} ${theme.spacing(1.75)} !important`,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    textAlign: 'end',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& .MuiMenu-list': {
    justifyContent: 'flex-end',
  },
}));

interface SelectProps extends MUISelectProps<string> {
  // TODO: ideally add generic types, but not necessary
  items: { value: string; label: string }[];
  alternativeStyle?: boolean;
}

const LabelDisplay = ({ label }: { label: ReactNode }) => {
  const theme = useTheme();

  return (
    <Typography sx={{ position: 'absolute', left: theme.spacing(1.5), pointerEvents: 'none' }}>
      {label}
    </Typography>
  );
};

export const Select = ({ items, label, alternativeStyle, ...props }: SelectProps) => {
  return (
    <MUISelect
      sx={{ display: 'flex' }}
      IconComponent={() => <LabelDisplay label={label} />}
      input={alternativeStyle ? <AlternativeInput /> : <BootstrapInput />}
      {...props}
    >
      {items.map((item) => (
        <MenuItem key={item.value + item.label} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </MUISelect>
  );
};
