import { FormControl, Radio, RadioProps, Stack, Typography } from '@mui/material';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { UserSegmentHandle, UserSegmentSubLabel } from './UserSegmentRadio.style';

export type Props = RadioProps & {
  name: string;
  label: string;
  labelSubtitle?: string;
};

export const UserSegmentRadio = ({ name, label, labelSubtitle, ...props }: Props) => {
  const { t } = useTranslation();
  const [field] = useField({ id: name, name });
  const isChecked = field.value == props.value;

  return (
    <UserSegmentHandle htmlFor={`${name}-${props.value}`} checked={isChecked}>
      <Stack direction="row">
        <FormControl>
          <Radio hidden id={`${name}-${props.value}`} {...field} {...props} />
        </FormControl>
        <Stack justifyContent="center">
          {label && (
            <Typography variant="body1" fontWeight={isChecked ? 600 : ''}>
              {t(label)}
            </Typography>
          )}
          {labelSubtitle && <UserSegmentSubLabel>{t(labelSubtitle)}</UserSegmentSubLabel>}
        </Stack>
      </Stack>
    </UserSegmentHandle>
  );
};
