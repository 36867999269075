import { alpha, Theme } from '@mui/material';

import { pxToRem } from '~/utils/getFontValue';

export const DatePicker = (theme: Theme) => {
  return {
    MuiDateRangePickerDay: {
      styleOverrides: {
        rangeIntervalDayHighlight: {
          backgroundColor: alpha(theme.palette.primary.main, 0.12),

          '&:first-of-type': {
            borderTopLeftRadius: pxToRem(4),
            borderBottomLeftRadius: pxToRem(4),
          },
          '&:last-of-type': {
            borderTopRightRadius: pxToRem(4),
            borderBottomRightRadius: pxToRem(4),
          },
        },
        rangeIntervalDayHighlightStart: {
          borderTopLeftRadius: pxToRem(4),
          borderBottomLeftRadius: pxToRem(4),
        },
        rangeIntervalDayHighlightEnd: {
          borderTopRightRadius: pxToRem(4),
          borderBottomRightRadius: pxToRem(4),
        },
        day: {
          borderRadius: pxToRem(4),
        },
      },
    },
  };
};
