import { Button, Grid, Stack, Typography } from '@mui/material';
import { toast } from 'material-react-toastify';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Logo } from '~/components/Logo';
import { useAppConfig } from '~/context/AppConfigContext';
import { model } from '~/models/Auth';
import { ValidationError } from '~/types/error';
import { logger } from '~/utils/logger';

export const VerifyEmail: FC = () => {
  const { t } = useTranslation();
  const [verificationDone, setVerificationDone] = useState(false);
  const [searchParams] = useSearchParams();
  const { onChangeLanguage } = useAppConfig();

  const uuid = searchParams.get('uuid'); // TODO: same move to onVerify scope
  const token = searchParams.get('token');
  const language = searchParams.get('lang');

  useEffect(() => {
    if (language) {
      onChangeLanguage(language);
    }
  }, [language]);

  const onVerify = async () => {
    try {
      const { detail, redirect_url } = await model.verifyEmail(uuid, token);
      toast.success(t(detail));
      setVerificationDone(true);
      if (redirect_url && isMobile) {
        window.location.href = redirect_url;
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        error.detail && toast.error(t(error.detail));
      }
      logger.logException(error);
    }
  };

  return (
    <Grid
      container
      component="main"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '100vh' }}
    >
      <Stack alignItems="center" sx={{ maxWidth: '360px', width: '100%', paddingX: 2 }}>
        <Logo width={142} height={24} />
        <Stack rowGap={4} sx={{ paddingTop: '72px' }}>
          <Typography textAlign={'center'} variant="h5" sx={{ wordBreak: 'break-word' }}>
            {verificationDone ? t('msg_email_verification_done') : t('msg_verify_your_email')}
          </Typography>
          {!verificationDone && (
            <Button fullWidth variant="contained" onClick={onVerify} size="large">
              {t('btn_verify_email')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Grid>
  );
};
