import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'material-react-toastify';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { AvatarPicker } from '~/components/Form/AvatarPicker';
import { FormikTextField } from '~/components/Form/TextField';
import { useAuth } from '~/context/AuthContext';
import { model } from '~/models/Company';
import { CompanyDetailsFormFields } from '~/types/company';
import { ValidationError } from '~/types/error';
import { pxToRem } from '~/utils/getFontValue';

import { validationSchema } from './validationSchema';

export const BusinessSettings = () => {
  const { t } = useTranslation();
  const { user, fetchUser } = useAuth();

  const { active_company } = user;

  const initialValues: CompanyDetailsFormFields = useMemo(
    () => ({
      name: active_company.name || user?.full_name || '',
      email: active_company.email || user?.email || '',
      phone: active_company.phone || user?.phone || '',
      address: active_company.address || '',
      iban: active_company.iban || '',
      license_no: active_company.license_no || '',
      logo: active_company.logo,
      vat_code: active_company.vat_code || '',
      s_spain_irpf_percentage: active_company.s_spain_irpf_percentage || '',
    }),
    [user],
  );

  const onSubmit = async (
    values: CompanyDetailsFormFields,
    { setErrors }: FormikHelpers<CompanyDetailsFormFields>,
  ) => {
    const { phone } = values;
    const { logo, ...valsWithoutLogo } = values;

    if (!isValidPhoneNumber(phone)) {
      setErrors({ phone: t('msg_error_invalid_phone') });
      return;
    }

    try {
      await model.patch(
        active_company.id,
        typeof values.logo === 'string' ? valsWithoutLogo : values,
      );
      await fetchUser();
    } catch (error) {
      if (error instanceof ValidationError<CompanyDetailsFormFields>) {
        setErrors(error.formikErrors);
        error.nonFieldErrors.forEach((message) => toast.error(t(message)));
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h5" lineHeight={pxToRem(48)}>
        {t('msg_label_business_details')}
      </Typography>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ isSubmitting, values }) => (
          <Form encType="multipart/form-data">
            <Stack spacing={1} mt={3}>
              <Box mb={2}>
                {!values.logo ? (
                  <AvatarPicker sx={{ fontWeight: '700' }} name="logo">
                    {t('msg_logo')}
                  </AvatarPicker>
                ) : (
                  <AvatarPicker name="logo" objectFit="contain" />
                )}
              </Box>
              <FormikTextField
                name="name"
                label={t('msg_label_company_name')}
                placeholder={t('msg_label_company_name')}
                variant="filled"
              />

              <FormikTextField
                name="phone"
                type="tel"
                label={t('msg_label_company_phone')}
                placeholder={t('msg_label_company_phone')}
                variant="filled"
              />

              <FormikTextField
                name="email"
                type="email"
                label={t('msg_label_email')}
                placeholder={t('msg_label_email')}
                variant="filled"
              />

              <FormikTextField
                name="address"
                label={t('msg_label_company_post_address')}
                placeholder={t('msg_label_company_post_address')}
                variant="filled"
              />

              <FormikTextField
                name="iban"
                label={t('msg_label_company_iban')}
                placeholder={t('msg_label_company_iban')}
                variant="filled"
              />

              <FormikTextField
                name="license_no"
                label={t('msg_label_company_license_no')}
                placeholder={t('msg_label_company_license_no')}
                variant="filled"
              />

              <FormikTextField
                name="vat_code"
                label={t('msg_label_vat_code')}
                placeholder={t('msg_label_vat_code')}
                variant="filled"
              />

              {active_company?.country === 'ES' && (
                <FormikTextField
                  name="s_spain_irpf_percentage"
                  label={t('msg_irpf_title')}
                  placeholder={t('msg_irpf_title')}
                  variant="filled"
                />
              )}

              <Box pt={4.75}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  size="large"
                  startIcon={<SaveIcon />}
                  loading={isSubmitting}
                >
                  {t('msg_btn_save')}
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
