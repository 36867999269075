import * as Yup from 'yup';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const validationSchema = Yup.object().shape({
  full_name: Yup.string().required('msg_name_required'),
  email: Yup.string().email('msg_error_invalid_email_format').required('msg_email_required'),
  password: Yup.string()
    .matches(passwordRules, { message: 'msg_stronger_password_required' })
    .required('msg_password_required'),
  phone: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, 'msg_error_invalid_phone')
    .max(15, 'msg_error_invalid_phone'),
});
