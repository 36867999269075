import { generatePath } from 'react-router-dom';

import { Expense, ExpenseFormFields, ExpenseListOptions } from '~/types/expense';
import { SelectedFile } from '~/types/file';
import { PaginationData } from '~/types/pagination';
import { api } from '~/utils/api';

const URL = {
  INDEX: '/api/expenses/',
  GET: '/api/expenses/:id/',
  FINALIZE: '/api/expenses/:id/finalize/',
  SUGGESTED_DESCRIPTIONS: '/api/suggested-expense-descriptions/',
  RECEIPTS_INDEX: '/api/expenses/:id/receipts/',
  RECEIPTS_GET: '/api/expenses/:id/receipts/:receiptId/',
  BULK_DELETE: '/api/operations/bulk-delete/',
};

export const model = {
  index: (params?: ExpenseListOptions) => api.get<PaginationData<Expense>>(URL.INDEX, params),
  get: (id: string) => api.get<Expense>(generatePath(URL.GET, { id })),
  delete: (id: string) => api.delete(generatePath(URL.GET, { id })),
  create: (data: ExpenseFormFields) => api.post<Expense>(URL.INDEX, data),
  update: (id: string, data: ExpenseFormFields) =>
    api.patch<Expense>(generatePath(URL.GET, { id }), data),
  finalize: (id: string) => api.patch(generatePath(URL.FINALIZE, { id })),
  suggestedDescriptions: () => api.get<string[]>(URL.SUGGESTED_DESCRIPTIONS),
  getReceipts: (id: string, limit: number) =>
    api.get<PaginationData<SelectedFile>>(generatePath(URL.RECEIPTS_INDEX, { id, limit })),
  addReceipt: (id: string, data: SelectedFile) =>
    api.post<Expense>(generatePath(URL.RECEIPTS_INDEX, { id }), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteReceipt: (id: string, receiptId: string) =>
    api.delete(generatePath(URL.RECEIPTS_GET, { id, receiptId })),
  bulkDelete: (ids: string[]) => api.delete(URL.BULK_DELETE, {}, { ids }),
};
