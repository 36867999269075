import { Button, Paper, Stack, Typography, TableContainer, Box, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { DateRangePicker } from '~/components/Form/DatePicker';
import { Search } from '~/components/Search';
import { CustomerSelector } from '~/components/Selector/CustomerSelector';
import { TablePagination } from '~/components/Table/TablePagination';
import { CURRENT_INVOICES_LIST_SEARCH_QUERY } from '~/config/constants';
import { usePagination } from '~/hooks/usePagination';
import { useTableSorting } from '~/hooks/useTableSorting';
import { useInvoices } from '~/models/Invoices/hooks';
import { ROUTES } from '~/router/Routes';
import { AmplitudeEvent } from '~/types/amplitude';
import { CustomerListData } from '~/types/customer';
import { DateRange } from '~/types/date';
import { Invoice, InvoiceStatus } from '~/types/invoice';
import { SortDirection } from '~/types/table';
import { sendAmplitudeData } from '~/utils/amplitude';

import { InvoicesStatusFilters } from './InvoicesStatusFilters';
import { InvoicesTable } from './InvoicesTable';

enum SearchParam {
  status = 'status',
  search = 'search',
  customer = 'customer',
  dateRange = 'dateRange',
}

const isInvoiceStatus = (status: string | null): status is InvoiceStatus => {
  if (!status) return false;
  return Object.values(InvoiceStatus).includes(status as InvoiceStatus);
};

export const InvoicesList = () => {
  const { pathname, search: querySearch } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get(SearchParam.search) || '');
  const [selectedStatus, setSelectedStatus] = useState<InvoiceStatus | undefined>(() => {
    const status = searchParams.get(SearchParam.status);
    if (isInvoiceStatus(status)) {
      return status;
    }
    return undefined;
  });
  const [count, setCount] = useState(0);

  const [customer, setCustomer] = useState<CustomerListData | undefined>(() => {
    const initialCustomer = searchParams.get(SearchParam.customer);

    if (initialCustomer) {
      return JSON.parse(initialCustomer);
    }
    return undefined;
  });

  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const initialDateRange = searchParams.get(SearchParam.dateRange);

    if (initialDateRange) {
      return JSON.parse(initialDateRange);
    }
    return [undefined, undefined];
  });

  useEffect(() => {
    sessionStorage.setItem(CURRENT_INVOICES_LIST_SEARCH_QUERY, `${pathname}${querySearch}`);
  }, [selectedStatus, search, dateRange, customer]);

  useEffect(() => {
    sendAmplitudeData(AmplitudeEvent.InvoiceListRefreshed, {
      dateRange,
      customer: customer?.name,
      customerId: customer?.id,
      searchPhrase: search,
      status: selectedStatus,
    });
  }, [dateRange, customer, search, selectedStatus]);

  const { ordering, currentSort, sortDirection, onSort } = useTableSorting<Invoice>(
    'issue_date',
    SortDirection.Desc,
  );

  const { offset, page, rowsPerPage, onPageChange, onRowsPerPageChange } = usePagination(count);

  const { t } = useTranslation();

  const getStatusFilterValue = (status?: InvoiceStatus) => {
    if (selectedStatus === status) {
      return true;
    }
  };

  const getDateFrom = () => (!dateRange[0] && dateRange[1] ? dateRange[1] : dateRange[0]);

  const getDateTo = () => (!dateRange[1] && dateRange[0] ? dateRange[0] : dateRange[1]);

  const { data, isLoading } = useInvoices({
    offset,
    limit: rowsPerPage,
    ordering,
    search,

    issue_date_from: getDateFrom(),
    issue_date_to: getDateTo(),

    is_unpaid: getStatusFilterValue(InvoiceStatus.Unpaid),
    is_paid: getStatusFilterValue(InvoiceStatus.Paid),
    is_draft: getStatusFilterValue(InvoiceStatus.Draft),
    is_overdue: getStatusFilterValue(InvoiceStatus.Overdue),
    is_proforma: getStatusFilterValue(InvoiceStatus.Proforma),

    customer: customer?.id,
  });

  const onSearch = (searchTerm: string) => {
    if (searchTerm === search) return;

    searchParams.set(SearchParam.search, searchTerm);
    setSearchParams(searchParams);

    setSearch(searchTerm);
    onPageChange(1);
  };

  const onChangeStatus = (status?: InvoiceStatus) => {
    searchParams.set(SearchParam.status, status || '');
    setSearchParams(searchParams);

    setSelectedStatus(status);
    onPageChange(1);
  };

  const onChangeDateRange = (newDateRange: DateRange) => {
    searchParams.set(SearchParam.dateRange, JSON.stringify(newDateRange));
    setSearchParams(searchParams);

    setDateRange(newDateRange);
    onPageChange(1);
  };

  useEffect(() => {
    if (data) {
      setCount(data.count);
    }
  }, [data]);

  const handleCustomerChange = (newCustomer?: CustomerListData) => {
    searchParams.set(SearchParam.customer, JSON.stringify(newCustomer) || '');
    setSearchParams(searchParams);

    setCustomer(newCustomer);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={1}>
        <Typography variant="h5">{t('msg_invoices_title')}</Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={Link}
          to={ROUTES.DASHBOARD_INVOICES_NEW}
        >
          {t('btn_add_new_invoice')}
        </Button>
      </Stack>

      <InvoicesStatusFilters selectedStatus={selectedStatus} onChangeStatus={onChangeStatus} />

      <Box>
        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <DateRangePicker
              placeholder="Date"
              variant="filled"
              onChange={onChangeDateRange}
              hasClearButton
              fullWidth
              defaultValue={dateRange}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <CustomerSelector onChange={handleCustomerChange} defaultValue={customer} />
          </Grid>
          <Grid item sm={12} md={6}>
            <Search defaultValue={search} onSearch={onSearch} />
          </Grid>
        </Grid>
      </Box>

      <Paper>
        <TableContainer sx={{ position: 'relative' }}>
          <InvoicesTable
            items={data?.items}
            count={count}
            onSort={onSort}
            currentSort={currentSort}
            sortDirection={sortDirection}
            isLoading={isLoading}
          />

          <TablePagination
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </TableContainer>
      </Paper>
    </Stack>
  );
};
