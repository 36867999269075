import { Box, FormControl, FormHelperText } from '@mui/material';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useField } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import { useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';

import { colors } from '~/theme/colors';

import { EditorWrapper } from './RichTextEditor.style';

type Props = {
  name: string;
  placeholder?: string;
  minRows?: number;
  initialContent?: string;
  autoFocus?: boolean;
  onChange?: (va: string) => void;
  whiteBg?: boolean;
};

export const FormikRichTextEditor = ({
  name,
  placeholder,
  minRows,
  initialContent,
  autoFocus,
  onChange,
  whiteBg,
}: Props) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField({ id: name, name });
  const [focused, setFocused] = useState(false);

  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    if (autoFocus) {
      return EditorState.moveFocusToEnd(editorState);
    }

    return editorState;
  };

  const initialValue = useMemo(() => {
    if (initialContent) {
      return prepareDraft(initialContent);
    } else if (meta.initialValue) {
      return prepareDraft(meta.initialValue);
    }

    return EditorState.createEmpty();
  }, [initialContent, meta.initialValue]);

  const [editorState, setEditorState] = useState(initialValue);

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    field.onChange({
      target: {
        name: field.name,
        value: forFormik,
      },
    });

    setEditorState(editorState);
    onChange?.(forFormik);
  };
  const trimmedValue = field.value.replace('<p>', '').replace('</p>', '').trim();

  return (
    <FormControl>
      <EditorWrapper
        error={meta.touched && !!meta.error}
        focused={focused}
        minRows={minRows}
        whiteBg={whiteBg}
      >
        {(focused || trimmedValue) && placeholder && (
          <Box style={{ position: 'absolute', top: '2px', left: '-2px' }}>
            <FormHelperText style={{ color: focused ? colors.primary.main : undefined }}>
              {t(placeholder)}
            </FormHelperText>
          </Box>
        )}
        <Editor
          editorStyle={{ marginTop: trimmedValue || focused ? '6px' : undefined }}
          toolbarHidden
          editorState={editorState}
          wrapperClassName="custom-wrapper"
          editorClassName="custom-editor"
          onEditorStateChange={onEditorStateChange}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
            helpers.setTouched(true);
          }}
          placeholder={placeholder}
        />
      </EditorWrapper>
      {meta.touched && meta.error && (
        <FormHelperText error={meta.touched && meta.error !== undefined}>
          {t(meta.error as string)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
